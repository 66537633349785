// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TEventShopList } from '../../../game-server/src/modules/event-shop/EventShop.interface';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const eventShopList: TEventShopList = {
	10: {
		id: 10,
		itemID: itemsIds.vacation_token,
		price: 50000,
		active: true,
		description: 'Consumable Offline Progress Time Extender - 72 hours (One-time effect)',
	},
	11: {
		id: 11,
		itemID: itemsIds.loadout_token,
		price: 40000,
		active: true,
		description: 'Additional Loadout Slot',
	},
	12: {
		id: 12,
		itemID: itemsIds.item_name_token,
		price: 20000,
		active: true,
		description: 'Rename an Item (will make it untradeable)',
	},
	13: {
		id: 13,
		itemID: itemsIds.inventory_tab_token,
		price: 40000,
		active: true,
		description: 'Additional Inventory Tab',
	},
	14: {
		id: 14,
		itemID: itemsIds.user_action_queue_count_token,
		price: 50000,
		active: true,
		description: 'Additional User Defined Action Queue Slot',
	},
	// 15: {
	// 	id: 15,
	// 	itemID: itemsIds.user_action_queue_length_token,
	// 	price: 50000,
	// 	active: true,
	// 	description: 'Additional User Defined Action Queue Length (30 minutes)',
	// },

	100: {
		id: 100,
		itemID: itemsIds.magical_snowball,
		price: 30000,
		active: true,
		description: 'Christmas Event 2020',
	},

	110: {
		id: 110,
		itemID: itemsIds.candy_cane_daggers,
		price: 30000,
		active: true,
		description: 'Christmas Event 2020',
	},

	111: {
		id: 111,
		itemID: itemsIds.festive_shroud,
		price: 45000,
		active: true,
		description: 'Christmas Event 2020',
	},

	120: {
		id: 120,
		itemID: itemsIds.snowball,
		price: 1000,
		active: false,
		description: 'Christmas Event 2020',
	},

	130: {
		id: 130,
		enchantmentID: enchantmentsIds.prospecting,
		price: 1,
		active: true,
	},

	140: {
		id: 140,
		enchantmentID: enchantmentsIds.gathering,
		price: 1,
		active: true,
	},

	150: {
		id: 150,
		enchantmentID: enchantmentsIds.superheating,
		price: 1,
		active: true,
	},

	160: {
		id: 160,
		enchantmentID: enchantmentsIds.pyromancy,
		price: 1,
		active: true,
	},

	170: {
		id: 170,
		enchantmentID: enchantmentsIds.naturalist,
		price: 1,
		active: true,
	},
	180: {
		id: 180,
		enchantmentID: enchantmentsIds.efficiency,
		price: 1,
		active: true,
	},
	190: {
		id: 190,
		enchantmentID: enchantmentsIds.fishing,
		price: 1,
		active: true,
	},
	200: {
		id: 200,
		enchantmentID: enchantmentsIds.cooking,
		price: 1,
		active: true,
	},
	220: {
		id: 220,
		enchantmentID: enchantmentsIds.refining,
		price: 1,
		active: true,
	},
	230: {
		id: 230,
		enchantmentID: enchantmentsIds.runecrafting,
		price: 1,
		active: true,
	},
	240: {
		id: 240,
		enchantmentID: enchantmentsIds.shrimp_lord,
		price: 1,
		active: true,
	},
	250: {
		id: 250,
		enchantmentID: enchantmentsIds.enlightenment,
		price: 1,
		active: true,
	},
	260: {
		id: 260,
		enchantmentID: enchantmentsIds.inferno,
		price: 1,
		active: true,
	},
	270: {
		id: 270,
		enchantmentID: enchantmentsIds.nature,
		price: 1,
		active: true,
	},
	280: {
		id: 280,
		enchantmentID: enchantmentsIds.root_digging,
		price: 1,
		active: true,
	},
	290: {
		id: 290,
		enchantmentID: enchantmentsIds.intuition,
		price: 100,
		active: true,
	},
	300: {
		id: 300,
		enchantmentID: enchantmentsIds.treasure_hunter,
		price: 100,
		active: false,
	},
	310: {
		id: 310,
		enchantmentID: enchantmentsIds.loot_goblin,
		price: 100,
		active: false,
	},
	320: {
		id: 320,
		enchantmentID: enchantmentsIds.empowered_haste,
		price: 25,
		active: true,
	},
	330: {
		id: 330,
		enchantmentID: enchantmentsIds.empowered_prospecting,
		price: 2,
		active: true,
	},
	340: {
		id: 340,
		enchantmentID: enchantmentsIds.empowered_gathering,
		price: 2,
		active: true,
	},
	350: {
		id: 350,
		enchantmentID: enchantmentsIds.archaeology,
		price: 1,
		active: true,
	},
	360: {
		id: 360,
		enchantmentID: enchantmentsIds.supercooling,
		price: 1,
		active: true,
	},
	370: {
		id: 370,
		enchantmentID: enchantmentsIds.embers,
		price: 1,
		active: true,
	},
};
