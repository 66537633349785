import { getImage } from '@idlescape/ui';
import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(accordionAnatomy.keys);
const accordionTheme = defineMultiStyleConfig({
	variants: {
		largePrimary: {
			container: {
				borderImageSource: `url(${getImage('BorderImage')})`,
				borderImageSlice: '100 fill',
				borderImageWidth: '50px',
				borderImageOutset: 0,
				borderImageRepeat: 'round',
				padding: '15px',
			},
			button: {
				backgroundColor: 'transparent',
				borderWidth: '0',
				fontSize: '4xl',
				justifyContent: 'center',
				margin: '0',
				_hover: {
					backgroundColor: 'transparent',
				},
			},
		},
		blank: {
			container: { border: 0 },
			button: { padding: 0, outline: 0 },
		},
		lootLog: {
			button: {
				border: 'none',
				margin: '0',
				backgroundPosition: 'center',
				borderRadius: '5px',
				boxShadow: '0 2px 5px #000000, 0 0 5px #000000 inset',
				zIndex: '100',
				cursor: 'pointer',
				_hover: { filter: 'brightness(1.2)' },
			},
			panel: {
				width: '95%',
				margin: '0 auto',
				borderBottomRadius: '5px',
				boxShadow: '0 2px 5px #000000, 0 0 5px #000000 inset',
				position: 'relative',
				overflow: 'hidden',
				paddingBottom: '2',
			},
		},
	},
	defaultProps: {
		variant: 'largePrimary',
	},
});

export default accordionTheme;
