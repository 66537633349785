// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TQuestListClient } from '../../../game-server/src/modules/quest/Quest.interface';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';
import { monstersIds } from '../utils/lookup-dictionaries/lookupMonsterList';
import { questsIds } from '../utils/lookup-dictionaries/lookupQuestList';
import { talentsIds } from '../utils/lookup-dictionaries/lookupTalentList';

export const questList: TQuestListClient = {
	1: {
		id: 1,
		name: 'Getting Started - Verify Email',
		description: "Verify your account's email to increase security and unlock potential perks.",
		order: 0,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Verify Your Email',
				description:
					'You can verify your email in the Security section of your Settings page. This will increase your account security and give you access to the Marketplace if your gamemode allows it.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Verify Email',
						targetID: -1,
					},
				],
				customButton: {
					name: 'Check Status',
					emit: 'quest:custom:checkVerifiedEmail',
				},
				targetAmount: 1,
				link: {
					page: 'settings',
				},
			},
		],
		questIcon: '/images/misc/notepad_icon.png',
	},
	2: {
		id: 2,
		name: 'Who is that Guy?',
		description:
			"Inspect another player's profile by clicking on their name or using the /inspect command. This shows their preferred or current cosmetics, their profile, their stats, and any other customizations they have applied to their profile page.",
		order: 6,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Inspect a Player',
				description:
					"Click on a player's name in chat and select inspect or use the command '/inspect [player name]'.",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Inspect',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'chat',
				},
			},
			{
				stepID: 2,
				name: 'Setting Cosmetics',
				description:
					"You can set your current gear and cosmetics to display on your profile character by opening your profile from the equipment tab or by clicking on yourself in chat. Once you're on your profile page, click edit in the top left and then use the 'Set Cosmetics' button to set your current gear as your profile display. Remember, you can always change this later! If you have nothing equipped while saving, it will check your gear, when someone inspects you.",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Set Cosmetics',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'attack',
				},
			},
			{
				stepID: 3,
				name: 'Profile Displays',
				description:
					"You can display up to 4 displays on your profile page (more with IS+ or tokens). Open your profile page and click the 'Edit' button in the top left. Choose two different things to display to complete the quest.",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Add Profile Display',
						targetID: -1,
					},
				],
				targetAmount: 2,
				link: {
					page: 'attack',
				},
			},
		],
		questIcon: '/images/ui/social_icon.png',
	},
	3: {
		id: 3,
		name: 'Climbing the Ranks',
		description: 'Check any hiscores board.',
		order: 7,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Check Hiscores',
				description: 'Check any hiscore board.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Hiscores',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'social',
				},
			},
		],
		questIcon: '/images/ui/social_icon.png',
	},
	4: {
		id: 4,
		name: 'Socializing',
		description: 'Learn to use the chat social features.',
		order: 8,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Add a Friend',
				description:
					"Add anyone to your friend list. You can do this by clicking on their name in chat and selecting 'Add Friend'. They will now appear in the Social tab under the Friends list and can be clicked on to access the chat features for them. They do not need to accept a friend request to complete this step, and you can remove them afterwards if you really want to :(",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Add Friend',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'chat',
				},
			},
			{
				stepID: 2,
				name: 'Blocking and Unblocking',
				description:
					"Blocking someone will prevent them from sending you any messages and is a good way to avoid harassment and spam. To block someone click on their name in chat and select 'Block'. Once they are blocked you can unblock them by going to your Blocked list in the Social tab and removing them from it there. To complete this step, successfully unblock someone.",
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Unblock Someone',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'social',
				},
			},
		],
		questIcon: '/images/ui/social_icon.png',
	},
	5: {
		id: 5,
		name: 'Le Toucan Has Arrived',
		description: 'Learn to use the Shrine.',
		order: 11,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Donate to the Shrine',
				description:
					'Donate at least 400,000 gold at once to the Shrine. Open the Shrine page and select any of the buff options to donate towards. Buff options, or altars, are categories of buffs that you can specifically donate towards to unlock their effects. Each altar has their own set of effects, but there is also the Time shrine that determines the length of all shrine buffs. There are several personal milestones that you can reach by donating to the shrine to benefit more from a shrine, and the global donations by all players that increases the overall potential buffs available. Basically, you have to donate to get buffs, the more you donate the more everyone benefits!',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Donate',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'shrine',
				},
			},
		],
		questIcon: '/images/misc/shrine_icon.png',
		questRewards: {
			achievementsToAdd: [11057, 11058, 11059, 11060, 11061],
		},
	},
	6: {
		id: 6,
		name: 'Hiring Mercenaries',
		description: 'Learn how to use mercenaries in combat.',
		order: 9,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Equipping Your Merc',
				description:
					'Mercenaries are non-player characters that use your loadouts to join you in group combat. You must supply them with their own set of equipment and food inventories to use them to their full potential. Unlike players, they eat their entire inventory at once, resulting in massive initial health pools, and they do not benefit from your Talents. Besides this, they are exactly like players in combat. It is generally recommended that your mercenaries fill roles that you or your party do not, such as supporting if you are focused on damage, or vice versa. To complete this step, mark one of your loadouts as being for a mercenary.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Mark Loadout',
						targetID: -1,
					},
				],
				link: {
					page: 'attack',
				},
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Entering Combat',
				description:
					'Mercenaries will attempt to fill any unfilled slot that is available in a party when Group Combat begins. They join based on the order that you have marked a loadout for them, with the first loadout being the first mercenary to join. To complete this step, successfully enter a Group Combat encounter with a mercenary in your party, such as the duo combat zones.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Merc Combat',
						targetID: -1,
					},
				],
				link: {
					page: 'attack',
				},
				targetAmount: 1,
			},
		],
		questIcon: '/images/combat/shared_icon.png',
	},
	7: {
		id: 7,
		name: 'The Action Queue',
		description: 'Learn how to use the Action Queue.',
		order: 10,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Adding to the Queue',
				description:
					'The Action Queue lets you queue up a list of actions to complete as well as how many times to complete them. This is an advanced way to make your character accomplish tasks without you needing to login and change your actions every time. To complete this step, add an action to your queue. If you do not have IS+ or any claimed Queue tokens you may only have one queue slot available. If you have multiple queue slots you may simply repeat the process multiple times to create a more complex queue. Your character will automatically attempt to use assigned loadouts at the locations you have selected, and skip any actions that are not possible. You can also specify if the queue should only run once or repeat indefinitely. You can access the Action Queue through its own page in the side-bar, or enable Action Queue related buttons in your Settings.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Add to Queue',
						targetID: -1,
					},
				],
				link: {
					page: 'action-queue',
				},
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Starting the Queue',
				description:
					'Once you have a valid queue, simply press the Start button to begin it! Start a queue to complete this step.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Starting the Queue',
						targetID: -1,
					},
				],
				link: {
					page: 'action-queue',
				},
				targetAmount: 1,
			},
		],
		questIcon: '/images/pontification/skill_waiting_icon.png',
	},
	100: {
		id: 100,
		name: 'Helping Hand',
		description:
			'The farms make up most of the fringes of human civilization; not only do they act as a sort of buffer zone between The City and the wilderness, they provide a majority of the food and resource necessary for humanity to survive. Many settlements out there have been built, many have been destroyed, and even more will be constructed in the future, if humanity survives that long. Although The City Guard is unable to provide support to the many farms and homesteads, rewards are available for the fledgling adventurer who is willing to lend a hand to the farmers and their families.',
		difficulty: 'Easy',
		questIcon: '/images/combat/monsters/chicken.png',
		questSteps: [
			{
				stepID: 1,
				name: 'Broken Fences and Empty Stomachs',
				description:
					'Raiding parties of goblins, too skittish to confront even mere farmers, have been stealing livestock and pillaging lone farmsteads. Before you go out to face them in glorious combat, the farmers require some supplies to help them recover from the losses they have already suffered. They could use some logs to repair their fences, or foodstocks to feed their remaining animals or their families.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 302,
					},
					{
						trigger: 'inventory',
						targetID: 2027,
					},
					{
						trigger: 'inventory',
						targetID: 61,
					},
					{
						trigger: 'inventory',
						targetID: 2001,
					},
				],
				targetAmount: 100,
			},
			{
				stepID: 2,
				name: 'Clearing Land',
				description:
					'It is simply a fact of life that some farmsteads are razed by raiders or abandoned due to lack of support. Given time, these lands are reclaimed by the wilderness and will require some effort to make usable as farms once more. Many of these lost lands devolve into Clay Pits or Grasslands; there is a small bounty in materials to be made here if you wish to help clear them. Alternatively, you could try hunting the goblin scouts or dealing with rats whilst the farmers do their work.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 50,
					},
					{
						trigger: 'location',
						targetID: 101,
					},
					{
						trigger: 'slay',
						targetID: 3,
					},
					{
						trigger: 'slay',
						targetID: 4,
					},
				],
				targetAmount: 100,
			},
			{
				stepID: 3,
				name: 'Strength of Stone! ...or Clay, or Sand...',
				description:
					'The weak shelters that most farmsteads construct of logs and sticks will not last the test of many raids. A more permanent solution is required, even if it does not truly solve the problem. Stone, Clay, and Sand are all useful materials for construction, and the farmers will be able to use them to build more defensible structures.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 109,
					},
					{
						trigger: 'inventory',
						targetID: 108,
					},
					{
						trigger: 'inventory',
						targetID: 110,
					},
				],
				targetAmount: 100,
			},
			{
				stepID: 4,
				name: 'Pest Control',
				description:
					'Goblins can never truly be exterminated, but wiping out their scouts will at least deincentivize them from raiding this particular settlement. Hopefully the others have someone as helpful as you to assist them. There are rumors that deep within some of the many caves there are passages to darker, more sinister places. Perhaps the goblins are not the only ones scouting the area...If you are too much of a coward to fight them yourself, you could give bronze to the local farmers to have them do it instead.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 3,
						progressMult: 2,
					},
					{
						trigger: 'slay',
						targetID: 8,
						progressMult: 2,
					},
					{
						trigger: 'inventory',
						targetID: 201,
					},
				],
				targetAmount: 50,
			},
		],
		questRewards: {
			questPoints: 1,
			experience: {
				foraging: 500,
				fishing: 500,
				mining: 500,
				attack: 500,
				defense: 500,
				constitution: 500,
			},
			items: [
				{
					id: 2017,
					amount: 100,
				},
				{
					id: 2001,
					amount: 100,
				},
				{
					id: 2023,
					amount: 100,
				},
				{
					id: 2021,
					amount: 100,
				},
				{
					id: 2029,
					amount: 100,
				},
				{
					id: 155,
					amount: 16,
				},
				{
					id: 151,
					amount: 16,
				},
				{
					id: 154,
					amount: 16,
				},
				{
					id: 1817,
					amount: 1,
				},
			],
			achievementsToAdd: [5000, 5008],
		},
	},
	101: {
		id: 101,
		name: 'Caves and Caverns',
		description:
			'Although your chosen settlement may be safe, the surrounding wilderness is not. The farmers need your help to clear out the caves and caverns that hide goblins and their kind.  Once again, the City Guard are unable to provide meaningful support, but they will reward you for your efforts once more in the form of basic materials and training.',
		difficulty: 'Easy',
		questIcon: '/images/combat/monsters/goblin.png',
		questRequirements: {
			requiredQuests: [100],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Supplies for Exploration',
				description:
					'An experienced adventurer could easily head into the Caves with no preparation, but you may not yet be there. The farmers you have already helped have offered insist on fashioning you a cart or longlasting rations for your journey, but you will still need to provide the materials.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 303,
					},
					{
						trigger: 'inventory',
						targetID: 103,
					},
					{
						trigger: 'inventory',
						targetID: 3004,
					},
					{
						trigger: 'inventory',
						targetID: 2016,
					},
				],
				targetAmount: 200,
			},
			{
				stepID: 2,
				name: 'Tunnels or Hideouts',
				description:
					'The City Outskirts is home to a massive mining operation and the Verdant Valley lays just outside the City; both would be a prime location for a tunnel or hideout for raiders seeking to exploit the farms. Moving through the City Outskirts Mine or the Verdant Valley may reveal some of these hidden places, or you may find better luck in finding them while hunting Goblins and Greater Imps.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 51,
					},
					{
						trigger: 'location',
						targetID: 102,
					},
					{
						trigger: 'slay',
						targetID: 3,
					},
					{
						trigger: 'slay',
						targetID: 8,
					},
				],
				targetAmount: 200,
			},
			{
				stepID: 3,
				name: 'Convincing the Guard',
				description:
					'Without your protection you fear the farms would be vulnerable to Goblin attacks, but the City Guard refuses to station anyone at the settlement. With a resupply of Bronze and Iron Bars, the Guard may be convinced to outfit and train some of these farmers to defend themselves.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 201,
					},
					{
						trigger: 'inventory',
						targetID: 202,
					},
				],
				targetAmount: 200,
			},
			{
				stepID: 4,
				name: 'Spelunking',
				description:
					'Goblins, like any other pest, cannot ever truly be eliminated. No matter how many you slay here, they will always return, but with enough slain now the settlement will be safe long enough for the farmers to prepare themselves for the raids. With luck, you will have saved this settlement from the fate of so many others,  though there is no guarantee that any other adventurer would be so responsible with the settlement they have been tasked to defend. Alternatively, you could supply someone braver than yourself to try and face these foes...',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 3,
					},
					{
						trigger: 'slay',
						targetID: 8,
					},
					{
						trigger: 'inventory',
						targetID: 201,
						progressMult: 3,
					},
					{
						trigger: 'inventory',
						targetID: 202,
						progressMult: 3,
					},
					{
						trigger: 'inventory',
						targetID: 3004,
						progressMult: 2,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			questPoints: 1,
			experience: {
				foraging: 5000,
				fishing: 5000,
				mining: 5000,
				attack: 5000,
				defense: 5000,
				constitution: 5000,
			},
			items: [
				{
					id: 303,
					amount: 100,
				},
				{
					id: 302,
					amount: 100,
				},
				{
					id: 103,
					amount: 100,
				},
				{
					id: 102,
					amount: 100,
				},
				{
					id: 101,
					amount: 100,
				},
				{
					id: 3004,
					amount: 100,
				},
				{
					id: 3006,
					amount: 100,
				},
				{
					id: 1602,
					amount: 1,
				},
				{
					id: 1635,
					amount: 1,
				},
				{
					id: 1826,
					amount: 1,
				},
			],
			achievementsToAdd: [5001],
		},
	},
	102: {
		id: 102,
		name: 'City Services',
		description:
			'With that settlement no longer needing your help, the City Guard has decided that you may be of use to them. The City Guard is comprised almost solely of enforcers and soldiers, with little time or patience for the needs of the people. Originally, The City was once many different smaller towns and cities, but as threats from the wilderness grew, so too did the need for a centralized defense. With the armies and militias of these smaller polities absorbed, the binds of bureaucracy have drastically limited the helpfulness of the Guard as they maintain a massive force with little training or supply available.',
		difficulty: 'Easy',
		questIcon: '/images/combat/monsters/guard.png',
		questRequirements: {
			requiredQuests: [101],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Food for the Hungry',
				description:
					'From the rooftop of any tall place one can see The City stretching out to the horizon in all directions. Hidden by the grand palaces and sprawling infrastructure, many of the people suffer in silence. The City Guard has tasked you with gathering food for the hungry, as the Guard is unable to provide for them outside of an almost draconian enforcement of the law.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 2026,
					},
					{
						trigger: 'inventory',
						targetID: 2028,
					},
					{
						trigger: 'inventory',
						targetID: 2020,
					},
					{
						trigger: 'inventory',
						targetID: 62,
					},
					{
						trigger: 'inventory',
						targetID: 63,
					},
				],
				targetAmount: 500,
			},
			{
				stepID: 2,
				name: 'Training Session',
				description:
					'The skill level of most city dwellers is unforgiveably low; decades of peace within the City and reliance on outer settlements have made them unable to properly care for themselves, both within and outside of the Guard. With your experience in the farms and caves, you may be able to show these people how to provide for themselves. Show them how to forage, fish, mine out in the wilds, or spar with the Guard to improve their combat skills.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 103,
					},
					{
						trigger: 'location',
						targetID: 152,
					},
					{
						trigger: 'location',
						targetID: 52,
					},
					{
						trigger: 'slay',
						targetID: 5,
					},
					{
						trigger: 'slay',
						targetID: 6,
					},
				],
				targetAmount: 500,
			},
			{
				stepID: 3,
				name: 'Heat for the Forges',
				description:
					'Once a town of its own, The City Forges are both massive and renowned. As expected, this massive sprawling complex of forges and smelters requires a massive amount of fuel to keep running. Your task is to gather a portion of the materials required to keep the forges running. In return, the Guard will provide you with one of their uniforms, random confiscated goods in Satchels, and various resources you may find useful.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 112,
					},
					{
						trigger: 'inventory',
						targetID: 304,
					},
					{
						trigger: 'inventory',
						targetID: 11031,
					},
				],
				targetAmount: 500,
			},
		],
		questRewards: {
			questPoints: 1,
			experience: {
				foraging: 10000,
				fishing: 10000,
				mining: 10000,
				smithing: 10000,
			},
			items: [
				{
					id: 176,
					amount: 32,
				},
				{
					id: 172,
					amount: 32,
				},
				{
					id: 202,
					amount: 100,
				},
				{
					id: 1172,
					amount: 1,
				},
				{
					id: 1093,
					amount: 1,
				},
				{
					id: 1051,
					amount: 1,
				},
				{
					id: 903,
					amount: 2,
				},
				{
					id: 1613,
					amount: 2,
				},
				{
					id: 1624,
					amount: 2,
				},
				{
					id: 1006,
					amount: 1,
				},
				{
					id: 1207,
					amount: 1,
				},
			],
			achievementsToAdd: [5002],
		},
	},
	103: {
		id: 103,
		name: 'City Secrets',
		description:
			'Your efforts have not gone unnoticed by the Guard and their superiors. The City council, a group of wizards that have been in power since the fall of the Academy, have some tasks that someone of your capabilities should be able to handle. The City Guard is known to be woefully incapable of handling true threats, so they are hoping to introduce new tools that can more adequately protect the City: the Black Knights, golems of obsidian armor that are controlled solely by the wizards.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/black_knight.png',
		questRequirements: {
			requiredQuests: [102],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Alloys and Reagents',
				description:
					"The Council's research requires a massive amount of mithril ores and various alchemy reagents. With these the wizards are capable of manipulating the essence of metals and potentially create new alloys. Your task is to gather the necessary materials for the latest experiments.",
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 73,
					},
					{
						trigger: 'inventory',
						targetID: 72,
					},
					{
						trigger: 'inventory',
						targetID: 3018,
					},
					{
						trigger: 'inventory',
						targetID: 3020,
					},
					{
						trigger: 'inventory',
						targetID: 105,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Testing Data',
				description:
					'The Black Knights are ready to see some combat and have been integrated into the City Guard as a form of higher level shock troop. Due to the unskilled nature of the guards and militia, you have been tasked with providing the Black Knights with some combat data or providing mithril bars to the wizards to help them create more Black Knights. One of the wizards has even promised you some of their created obsidian and a helmet for you to study or use, if you so desire. Perhaps there is more latent magic in the obsidian than they let on...',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 13,
					},
					{
						trigger: 'inventory',
						targetID: 204,
					},
				],
				targetAmount: 500,
			},
		],
		questRewards: {
			questPoints: 2,
			experience: {
				foraging: 7500,
				fishing: 7500,
				mining: 7500,
				smithing: 15000,
				crafting: 15000,
				defense: 15000,
			},
			items: [
				{
					id: 185,
					amount: 64,
				},
				{
					id: 903,
					amount: 4,
				},
				{
					id: 209,
					amount: 8,
				},
				{
					id: 1052,
					amount: 1,
				},
				{
					id: 1633,
					amount: 2,
				},
				{
					id: 1616,
					amount: 2,
				},
				{
					id: 1827,
					amount: 1,
				},
			],
		},
	},
	104: {
		id: 104,
		name: 'Secrets Unveiled',
		description:
			'The mass production of Black Knights has been a boon to the City Guard, but the wizards have been increasing overstepping their boundaries. Some within the Guard fear that the wizards may seek to take even more control over the City and use the Black Knights to enforce their will. Although you were just one of many adventurers involved in the creation of the Black Knights, you should still probably do something about this.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/black_knight.png',
		questRequirements: {
			requiredQuests: [103],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Re-equipping the Guard',
				description:
					'The City Guard has been overly reliant on the Black Knights and have let their equipment fall into disrepair. Providing them with new stocks of ingots should be enough for them to get their gear back in fighting shape. Some among the Guard warn you that there will still be groups within the Guard that will remain under the control of the wizards.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 202,
					},
					{
						trigger: 'inventory',
						targetID: 204,
						progressMult: 2,
					},
				],
				targetAmount: 800,
			},
			{
				stepID: 2,
				name: 'Oppressing Oppressors',
				description:
					'Some districts of The City have fallen completely under the control of the more zealous wizards. You can liberate these areas by scrapping the Black Knights and scattering their support, or provide more higher quality materials to the loyal among the City Guard to help them fight back against these golems. Regardless of the route you take, the wizards will not be pleased with your actions; the Guard is prepared to offer you some of the more valuable materials from the Black Knights in return for your help.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 13,
					},
					{
						trigger: 'inventory',
						targetID: 204,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			questPoints: 2,
			experience: {
				smithing: 30000,
				attack: 30000,
				strength: 10000,
				magic: 10000,
				range: 10000,
			},
			items: [
				{
					id: 903,
					amount: 6,
				},
				{
					id: 209,
					amount: 24,
				},
				{
					id: 1062,
					amount: 1,
				},
				{
					id: 1095,
					amount: 1,
				},
				{
					id: 1011,
					amount: 1,
				},
				{
					id: 1619,
					amount: 2,
				},
				{
					id: 1621,
					amount: 2,
				},
			],
			achievementsToAdd: [5003, 5010],
		},
	},
	105: {
		id: 105,
		name: 'The Fallen Academy',
		description:
			'Decades have passed since the fall of the Academy, yet it still remains a center of power and knowledge, of a sort. Countless towers of all shapes, sizes, and constructions dot the landscape surrounding this once magnificent place with many of them still housing the remnants of wizards that have strayed too far in their path to power. Ironically, the Academy proper is the safest place to explore, as the wizards that remain here are more interested in their dark rites and research than in the outside world. You will be tasked with exploring and recovering some of the lost knowledge and materials from the Academy at the behest of the City. Hopefully it is put to good use.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/dark_mage.png',
		questRequirements: {
			requiredQuests: [103],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Travelling to the Academy',
				description:
					'Located well outside of the City, the Academy requires traversing quite a distance to reach. The most common routes to take are either through the Desert, the Tangle, or meandering down the Lazy River. It would be wise to stock up on supplies or material during your journey there, as the wizards are not known for their hospitality.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 104,
					},
					{
						trigger: 'inventory',
						targetID: 3008,
					},
					{
						trigger: 'inventory',
						targetID: 3009,
					},
					{
						trigger: 'inventory',
						targetID: 73,
					},
					{
						trigger: 'inventory',
						targetID: 63,
					},
					{
						trigger: 'location',
						targetID: 53,
					},
					{
						trigger: 'location',
						targetID: 104,
					},
					{
						trigger: 'location',
						targetID: 152,
					},
				],
				targetAmount: 5000,
			},
			{
				stepID: 2,
				name: 'Braving the Academy',
				description:
					'The campus of the Academy is a sprawling complex of towers, libraries, and laboratories. Some of them have been abandoned for decades, while others remain mostly functional even now. The safer buildings have already been scoured clean by other adventurers, so you will need to delve into the more dangerous areas to find anything of value. Alternatively, you could try looking into experimenting on about 20 Unstable Talismans from the General Store that are rumored to originate here.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 32,
					},
					{
						trigger: 'slay',
						targetID: 33,
					},
					{
						trigger: 'slay',
						targetID: 11,
					},
					{
						trigger: 'slay',
						targetID: 12,
					},
					{
						trigger: 'slay',
						targetID: 521,
					},
					{
						trigger: 'slay',
						targetID: 522,
					},
					{
						trigger: 'slay',
						targetID: 529,
					},
					{
						trigger: 'inventory',
						targetID: 520,
						progressMult: 50,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 3,
				name: 'Essence Offering',
				description:
					'During your exploration, you managed to find an essence imbuing chamber. Essence is the raw, magical energy that makes up all of reality, and when properly harnessed can be used to create powerful artifacts. There is much debate regarding the origins and nature of the essences, but for practical purposes they are simply a resource to be gathered and used. A set of uncommon unstable talismans have been left here as well as the notes on how to imbue them; follow the instructions and in the future you will be able to more easily create your own. Talismans are capable of enhancing your own essence collecting abilities, so they are a common tool for any fledgling mage to use. Unstable talismans are not particularly rare, but may cost some gold to purchase in the City. There are tales of talismans that were created by others not within the Academy, but those are probably only myths...',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 3,
					},
					{
						trigger: 'inventory',
						targetID: 4,
					},
					{
						trigger: 'inventory',
						targetID: 5,
					},
					{
						trigger: 'inventory',
						targetID: 6,
					},
					{
						trigger: 'inventory',
						targetID: 7,
					},
					{
						trigger: 'inventory',
						targetID: 8,
					},
					{
						trigger: 'inventory',
						targetID: 9,
					},
					{
						trigger: 'inventory',
						targetID: 10,
					},
					{
						trigger: 'inventory',
						targetID: 11,
					},
					{
						trigger: 'inventory',
						targetID: 12,
					},
				],
				targetAmount: 50000,
			},
		],
		questRewards: {
			questPoints: 2,
			experience: {
				mining: 10000,
				foraging: 10000,
				fishing: 10000,
				runecrafting: 20000,
				constitution: 15000,
				defense: 20000,
				magic: 15000,
			},
			items: [
				{
					id: 520,
					amount: 2,
				},
				{
					id: 500,
					amount: 1,
				},
				{
					id: 3,
					amount: 25000,
				},
				{
					id: 4,
					amount: 25000,
				},
				{
					id: 5,
					amount: 25000,
				},
				{
					id: 6,
					amount: 25000,
				},
				{
					id: 1630,
					amount: 2,
				},
				{
					id: 1627,
					amount: 2,
				},
				{
					id: 1818,
					amount: 1,
				},
			],
			achievementsToAdd: [5005, 5006, 5007, 5014, 5020, 5021, 5022, 5024],
		},
	},
	106: {
		id: 106,
		name: 'Runes of the Lava Maze',
		description:
			'The Lava Maze was a site of ancient and powerful magic. It once hosted a sanctum of runecrafters, with an order of mages who specialized in creating powerful rune slates-magical tablets inscribed with runes that could manipulate reality itselfThese runes were used to protect the realm, imbue items with immense power, and even control the elements. This power would be sought after by demons and adventurers alike.When the demons emerged from the fiery depths many mages were forced to abandon the sanctum. The maze, formed by volcanic lava over centuries, would become a battleground with adventurers and demons vying for power.Over time it became a dangerous and enigmatic place guarded by the demons and creatures, now making it their home.',
		difficulty: 'Hard',
		questIcon: '/images/combat/monsters/greater_demon.png',
		questRequirements: {
			requiredQuests: [104],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'The Runic Path',
				description:
					'The Slate Spires is a cold, forbidding region known for its towering darkish slate formations that jut out from the frozen ground like the jagged teeth of an ancient beast.The air is crisp and frigid, with icy winds howling through the narrow valleys between the mountains of slate. Snow covers the ground in thick layers, and patches of ice make travel unforgiving.Despite the harsh conditions, the Slate Spires are a valuable resource, rich in the high-quality slate coveted by runecrafters and merchants alike. It would be wise to gather slate for runecrafting as you journey to the lava maze.Gathering from the Slate Spires counts as double progress for this step.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 57,
						progressMult: 2,
					},
					{
						trigger: 'inventory',
						targetID: 113,
					},
				],
				targetAmount: 3000,
			},
			{
				stepID: 2,
				name: 'Harvesting the Last Bastion',
				description:
					'The Misty Marsh is a sprawling expanse of wetlands shrouded in a perpetual eerie fog.The thick mist hangs low over the landscape, obscurring vision and muting sounds, creating an atmosphere of mystery and foreboding.The ground is a mix of soggy earth with water channels winding through the area. Willow and Oak trees are in abundance in the area along with some seeds and vegetables.It would be a good idea to gather some flora during your trek through this area. The Still Lake is a far-reaching tranquil body of water surrounded by dense, ancient forests.The lakes surface is perfectly calm and mirror like, reflecting the sky and surrounding trees with uncanny clarity.Despite its serene appearance, the lake holds a myriad of aquatic wildlife, and magic infused flora, perfect for adventurers and anglers alike.The Underground Mine is a vast network of tunnels and caverns beneath the earth, filled with rich veins of precious mithril, gold, and some runeslate from the Slate Spires far above.The mine is a dark and dangerous place, with winding passageways that can easily disorient and trap the unwary.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 105,
					},
					{
						trigger: 'location',
						targetID: 153,
					},
					{
						trigger: 'location',
						targetID: 54,
					},
					{
						trigger: 'inventory',
						targetID: 111,
						progressMult: 3,
					},
					{
						trigger: 'inventory',
						targetID: 74,
					},
					{
						trigger: 'inventory',
						targetID: 4022,
					},
					{
						trigger: 'inventory',
						targetID: 3006,
					},
					{
						trigger: 'inventory',
						targetID: 2024,
					},
				],
				targetAmount: 3000,
			},
			{
				stepID: 3,
				name: "Inferno's Labyrinth",
				description:
					'The Lava Maze is a perilous network of fiery corridors, treacherous paths, and molten rivers, all set within a massive volcanic obsidian landscape.It is a place of ancient power and danger, where the very ground pulses with heat and the air shimmers with intense energy.Make your way to the focal point of the labyrinth in order to reach the inner sanctum and produce powerful runes like the mages of old had once done.Demonic inhabitants have since overrun the area and settled in the ruins and throughout the maze, you will need to dispatch them before you can reach your goal.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 11,
					},
					{
						trigger: 'slay',
						targetID: 12,
					},
					{
						trigger: 'slay',
						targetID: 9,
					},
					{
						trigger: 'slay',
						targetID: 10,
					},
					{
						trigger: 'slay',
						targetID: 502,
					},
					{
						trigger: 'slay',
						targetID: 504,
					},
					{
						trigger: 'slay',
						targetID: 503,
					},
				],
				targetAmount: 1500,
			},
			{
				stepID: 4,
				name: 'The Art of Runecrafting',
				description:
					'Once inside the sanctum you can finally start procuring your own runes using the methods long lost at this ancient site.You search through shelves and aged research tables filled with scrolls and tomes detailing the history, theory, and practice of runecrafting.At the heart of the sanctum lies a massive runic altar, a raised stone platform inscribed with concentric circles of runes. Surrounding the altar are towering runic pillars, each covered in detailed inscriptions and diagrams.These pillars channel the latent magical energies of the sanctum. In finality you will need to craft runes using your magical essence, runic inscriptions, and infusing it into the runeslates.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 510,
					},
					{
						trigger: 'inventory',
						targetID: 511,
					},
					{
						trigger: 'inventory',
						targetID: 512,
					},
					{
						trigger: 'inventory',
						targetID: 513,
					},
					{
						trigger: 'inventory',
						targetID: 514,
					},
					{
						trigger: 'inventory',
						targetID: 515,
					},
					{
						trigger: 'inventory',
						targetID: 516,
					},
					{
						trigger: 'inventory',
						targetID: 517,
					},
					{
						trigger: 'inventory',
						targetID: 518,
					},
					{
						trigger: 'inventory',
						targetID: 519,
					},
				],
				targetAmount: 2000,
			},
		],
		questRewards: {
			questPoints: 3,
			experience: {
				mining: 15000,
				fishing: 15000,
				foraging: 15000,
				runecrafting: 30000,
				constitution: 15000,
				attack: 15000,
				defense: 15000,
			},
			items: [
				{
					id: 113,
					amount: 5000,
				},
				{
					id: 160,
					amount: 128,
				},
				{
					id: 25011,
					amount: 20,
				},
				{
					id: 800,
					amount: 1000,
				},
				{
					id: 1663,
					amount: 2,
				},
				{
					id: 1657,
					amount: 2,
				},
			],
			achievementsToAdd: [5015, 6008],
		},
	},
	107: {
		id: 107,
		name: 'Back to the City',
		description:
			"After having dealt with the black knights that were being used to control certain sections of the city by power hungry wizards, you are once again called upon by the council for further golem testing. While some of the wizards have good intentions, it's clear that some of them hold contempt for you. Regardless, they have invited you to the Enchanted Armory to test their latest variant of golems, made of glass and gems instead of obsidian.",
		difficulty: 'Hard',
		questIcon: '/images/combat/monsters/glass_knight.png',
		questRequirements: {
			requiredQuests: [106],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Wild Wizard Wandering',
				description:
					'You agree to assist the wizards against your better judgement, they give you a map with a rough idea of where the Armory is and send you on your way… Looks like you might have to explore a bit if you want to locate the Armory. Thankfully you’re no stranger to trekking in odd locations… Or fighting.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 57,
					},
					{
						trigger: 'location',
						targetID: 56,
					},
					{
						trigger: 'location',
						targetID: 55,
					},
					{
						trigger: 'location',
						targetID: 106,
					},
					{
						trigger: 'location',
						targetID: 105,
					},
					{
						trigger: 'location',
						targetID: 104,
					},
					{
						trigger: 'location',
						targetID: 153,
					},
					{
						trigger: 'location',
						targetID: 154,
					},
					{
						trigger: 'location',
						targetID: 1002,
						progressMult: 2,
					},
				],
				targetAmount: 2000,
			},
			{
				stepID: 2,
				name: 'Cut Glass or Polished Gems',
				description:
					'You’ve finally arrived at the Enchanted Armory, which probably could have been less painful with a better map… Nevertheless you are here and are greeted by wizards and black knights. They explain to you that they’ve made newer prototype golems made of glass and gems which hold magic better, resulting in better control over the golems, results they could never get with mundane metals, even obsidian. Afterwards they ask you to fight them to gather data or help them get more materials to make even more glass golems. ',
				validTargets: [
					{
						trigger: 'location',
						targetID: 1007,
					},
					{
						trigger: 'inventory',
						targetID: 400,
					},
					{
						trigger: 'inventory',
						targetID: 401,
						progressMult: 2,
					},
					{
						trigger: 'inventory',
						targetID: 402,
						progressMult: 5,
					},
					{
						trigger: 'inventory',
						targetID: 403,
						progressMult: 10,
					},
					{
						trigger: 'inventory',
						targetID: 404,
						progressMult: 20,
					},
				],
				targetAmount: 200,
			},
			{
				stepID: 3,
				name: 'Prototype Testing',
				description:
					'Happy with the results so far, one of them says you could probably help test the more advanced prototypes they’ve created. A few of the other wizards seem to be against the idea at first, but are left with little choice seeing as they have already been summoned. They seem to be pretty hostile towards anything, especially you. Best take care of them.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 1007,
					},
					{
						trigger: 'slay',
						targetID: 37,
					},
					{
						trigger: 'slay',
						targetID: 36,
					},
					{
						trigger: 'slay',
						targetID: 38,
					},
					{
						trigger: 'slay',
						targetID: 519,
						progressMult: 5,
					},
					{
						trigger: 'slay',
						targetID: 518,
						progressMult: 5,
					},
					{
						trigger: 'slay',
						targetID: 520,
						progressMult: 5,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 4,
				name: 'Finding a Fake ID',
				description:
					'After having defeated the more advanced variants, the wizards apologize on behalf of the other wizard who seemed to have it out for you, they promise to add something extra as compensation for what happened but seem to want to ask you for help once again. It seems the rogue wizard escaped in the chaos deeper into the Armory, locking himself in the Dark Vault and is now sending out a constant stream of corrupted golems to attack the Armory. They ask for you to go in and clear it out if you are able, or help them clean up the mess.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 9034,
					},
					{
						trigger: 'inventory',
						targetID: 9033,
						progressMult: 5,
					},
					{
						trigger: 'dungeon',
						targetID: 3015,
						progressMult: 10,
					},
				],
				targetAmount: 10,
			},
		],
		questRewards: {
			questPoints: 3,
			experience: {
				mining: 20000,
				fishing: 20000,
				foraging: 20000,
				constitution: 30000,
				attack: 30000,
				defense: 30000,
				strength: 30000,
				magic: 30000,
				range: 30000,
			},
			items: [
				{
					id: 10003,
					amount: 30,
				},
				{
					id: 25025,
					amount: 4444,
				},
				{
					id: 9034,
					amount: 3,
				},
				{
					id: 1680,
					amount: 4,
				},
				{
					id: 1681,
					amount: 4,
				},
			],
		},
	},
	200: {
		id: 200,
		name: 'The Path to Enhancement',
		description: 'Begin the journey of at least 30 augmentations and unlock the Lesser Magic Tome recipe.',
		difficulty: 'Easy',
		questRequirements: {
			skillRequirements: {
				enchanting: 10,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment or research 50 times.',
				description: 'Augment or research anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: 8,
					},
					{
						trigger: 'location',
						targetID: 12,
					},
				],
				targetAmount: 50,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 2,
				name: 'Craft 3 enchanted scrolls.',
				description: 'Create three enchanted scrolls. Any scroll works.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 11,
					},
				],
				targetAmount: 3,
				link: {
					page: 'enchanting',
				},
			},
		],
		questIcon: '/images/enchanting/Lesser_Magic_Tome_embossed.png',
		questRewards: {
			items: [
				{
					id: 1676,
					amount: 1,
				},
			],
		},
	},
	201: {
		id: 201,
		name: 'Realizing Your Enchanting Potential',
		description: 'Continue to increase your knowledge and create a Major Magic Tome.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [200],
			skillRequirements: {
				enchanting: 20,
			},
			questPoints: 1,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment or research 200 times.',
				description: 'Augment or research anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: 8,
					},
					{
						trigger: 'location',
						targetID: 12,
					},
				],
				targetAmount: 200,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 2,
				name: 'Craft 6 enchanted scrolls.',
				description: 'Create six enchanted scrolls. Any scroll works.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 11,
					},
				],
				targetAmount: 6,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 3,
				name: 'Create Major Magic Tome',
				description:
					'Augment your Lesser Magic Tome until it transforms. If you have not crafted one yet, it is in the Crafting skill under the Skilling category.',
				targetAmount: 1,
				validTargets: [
					{
						trigger: 'transform',
						targetID: 673,
					},
				],
				link: {
					page: 'enchanting',
				},
			},
		],
		questIcon: '/images/enchanting/Major_Magic_Tome.png',
		questRewards: {
			items: [
				{
					id: 672,
					amount: 1,
				},
				{
					id: 1676,
					amount: 2,
				},
			],
		},
	},
	202: {
		id: 202,
		name: 'Going Beyond the Academy',
		description:
			'As your abilities grow, so does your need for new materials and experience. You will need a Greater Magic Tome to go further.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [201],
			skillRequirements: {
				enchanting: 30,
			},
			questPoints: 2,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment or research 1000 times.',
				description: 'Augment or research anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: 8,
					},
					{
						trigger: 'location',
						targetID: 12,
					},
				],
				targetAmount: 1000,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 2,
				name: 'Craft 10 enchanted scrolls.',
				description: 'Create ten enchanted scrolls. Any scroll works.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 11,
					},
				],
				targetAmount: 10,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 3,
				name: 'Create Greater Magic Tome',
				description: 'Augment your Major Magic Tome until it transforms.',
				targetAmount: 1,
				validTargets: [
					{
						trigger: 'transform',
						targetID: 674,
					},
				],
				link: {
					page: 'enchanting',
				},
			},
		],
		questIcon: '/images/enchanting/Greater_Magic_Tome.png',
		questRewards: {
			items: [
				{
					id: 673,
					amount: 1,
				},
				{
					id: 1628,
					amount: 3,
				},
			],
		},
	},
	203: {
		id: 203,
		name: 'Unearthing Elder Knowledge',
		description:
			'The civilizations of the past hold many secrets. Follow in their footsteps and unlock previously unknown powers with an Elder Magic Tome.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [202],
			skillRequirements: {
				enchanting: 40,
			},
			questPoints: 3,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment or research 5000 times.',
				description: 'Augment or research anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: 8,
					},
					{
						trigger: 'location',
						targetID: 12,
					},
				],
				targetAmount: 5000,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 2,
				name: 'Craft 20 enchanted scrolls.',
				description: 'Create 20 enchanted scrolls. Any scroll works.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 11,
					},
				],
				targetAmount: 20,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 3,
				name: 'Create Elder Magic Tome',
				description: 'Augment your Greater Magic Tome until it transforms.',
				targetAmount: 1,
				validTargets: [
					{
						trigger: 'transform',
						targetID: 675,
					},
				],
				link: {
					page: 'enchanting',
				},
			},
		],
		questIcon: '/images/enchanting/Elder_Magic_Tome.png',
		questRewards: {
			questPoints: 1,
			items: [
				{
					id: 674,
					amount: 1,
				},
				{
					id: 1677,
					amount: 3,
				},
			],
		},
	},
	204: {
		id: 204,
		name: 'Unleashing Ancient Knowledge',
		description:
			'Older, darker pasts haunt even the oldest of known history. True mastery lies there, waiting to be unlocked. You will need an Ancient Magic Tome to store these powers.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [203],
			skillRequirements: {
				enchanting: 50,
			},
			questPoints: 5,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Augment or research 10000 times.',
				description: 'Augment or research anything, success or failure, just keep trying!',
				validTargets: [
					{
						trigger: 'location',
						targetID: 8,
					},
					{
						trigger: 'location',
						targetID: 12,
					},
				],
				targetAmount: 10000,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 2,
				name: 'Craft 30 enchanted scrolls.',
				description: 'Create 30 enchanted scrolls. Any scroll works.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 11,
					},
				],
				targetAmount: 30,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 3,
				name: 'Create Ancient Magic Tome',
				description: 'Augment your Elder Magic Tome until it transforms.',
				targetAmount: 1,
				validTargets: [
					{
						trigger: 'transform',
						targetID: 676,
					},
				],
				link: {
					page: 'enchanting',
				},
			},
		],
		questIcon: '/images/enchanting/Ancient_Magic_Tome.png',
		questRewards: {
			questPoints: 1,
			items: [
				{
					id: 675,
					amount: 1,
				},
				{
					id: 1679,
					amount: 3,
				},
			],
		},
	},
	205: {
		id: 205,
		name: 'Crafting Tools I',
		description: 'Your crafting stations are shoddy and low quality. Perhaps you should look into improving them?',
		difficulty: 'Easy',
		questRequirements: {
			skillRequirements: {
				crafting: 10,
			},
			requiredQuests: [2001],
			questPoints: 1,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Basic Metalworking and Carpentry',
				description:
					'Practice making some basic tools and equipment to hone your abilities. You may not need it all, but the Vendor will be happy to take any extras off your hands!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 602,
						progressMult: 3,
					},
					{
						trigger: 'craft',
						targetID: 601,
					},
					{
						trigger: 'craft',
						targetID: 612,
						progressMult: 3,
					},
					{
						trigger: 'craft',
						targetID: 611,
					},
					{
						trigger: 'craft',
						targetID: 11002,
						progressMult: 3,
					},
					{
						trigger: 'craft',
						targetID: 11001,
					},
					{
						trigger: 'craft',
						targetID: 677,
					},
					{
						trigger: 'craft',
						targetID: 668,
						progressMult: 3,
					},
					{
						trigger: 'craft',
						targetID: 667,
					},
					{
						trigger: 'craft',
						targetID: 662,
						progressMult: 3,
					},
					{
						trigger: 'craft',
						targetID: 661,
					},
				],
				targetAmount: 10,
				link: {
					page: 'crafting',
				},
			},
		],
		questRewards: {
			experience: {
				crafting: 10000,
			},
			relatedTalent: [2002],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	206: {
		id: 206,
		name: 'Crafting Tools II',
		description:
			'Your tools are of passable quality now. Perhaps you can draw some inspirations on efficiency by studying combat equipment?',
		difficulty: 'Medium',
		questRequirements: {
			skillRequirements: {
				crafting: 40,
			},
			requiredQuests: [205],
			questPoints: 2,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Combat Equipment Study',
				description:
					'Making some decent combat equipment will help you understand the intricacies of crafting. Try making some!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 1018,
					},
					{
						trigger: 'craft',
						targetID: 1114,
					},
					{
						trigger: 'craft',
						targetID: 1099,
					},
					{
						trigger: 'craft',
						targetID: 1054,
					},
					{
						trigger: 'craft',
						targetID: 1169,
					},
					{
						trigger: 'craft',
						targetID: 1176,
					},
					{
						trigger: 'craft',
						targetID: 1162,
					},
					{
						trigger: 'craft',
						targetID: 1211,
					},
					{
						trigger: 'craft',
						targetID: 1190,
					},
					{
						trigger: 'craft',
						targetID: 1197,
					},
					{
						trigger: 'craft',
						targetID: 1183,
					},
					{
						trigger: 'craft',
						targetID: 1239,
					},
				],
				targetAmount: 10,
				link: {
					page: 'crafting',
				},
			},
		],
		questRewards: {
			experience: {
				crafting: 25000,
			},
			relatedTalent: [2003],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	207: {
		id: 207,
		name: 'Crafting Tools III',
		description: 'You can make equipment, but how is your precision?',
		difficulty: 'Hard',
		questRequirements: {
			skillRequirements: {
				crafting: 60,
			},
			requiredQuests: [206],
			questPoints: 3,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Precision Engineering',
				description:
					'Black Opal is a notoriously hard gem to work with. Make a few sets of rings and necklaces to prove your skill!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 631,
					},
					{
						trigger: 'craft',
						targetID: 630,
					},
					{
						trigger: 'craft',
						targetID: 642,
					},
				],
				targetAmount: 5,
				link: {
					page: 'crafting',
				},
			},
		],
		questRewards: {
			experience: {
				crafting: 50000,
			},
			relatedTalent: [2004],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	208: {
		id: 208,
		name: 'Crafting Tools IV',
		description:
			'Tools, basic equipment, and jewelry are far too mundane to further your skills now. You need a new challenge- how about some reverse engineering?',
		difficulty: 'Elite',
		questRequirements: {
			skillRequirements: {
				crafting: 80,
			},
			requiredQuests: [207],
			questPoints: 4,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Dungeons and Artifacts',
				description: 'Reverse engineer some artifacts and rebuild some keys to expand your skillset.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 9008,
					},
					{
						trigger: 'craft',
						targetID: 9010,
					},
					{
						trigger: 'craft',
						targetID: 9011,
					},
					{
						trigger: 'craft',
						targetID: 9017,
					},
					{
						trigger: 'craft',
						targetID: 9016,
					},
					{
						trigger: 'craft',
						targetID: 9018,
					},
					{
						trigger: 'craft',
						targetID: 9000,
					},
					{
						trigger: 'craft',
						targetID: 9025,
					},
					{
						trigger: 'craft',
						targetID: 9027,
					},
					{
						trigger: 'craft',
						targetID: 9033,
					},
					{
						trigger: 'craft',
						targetID: 9019,
					},
				],
				targetAmount: 10,
				link: {
					page: 'crafting',
				},
			},
		],
		questRewards: {
			experience: {
				crafting: 75000,
			},
			relatedTalent: [2005],
			questPoints: 1,
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	209: {
		id: 209,
		name: 'Crafting Tools V',
		description: 'All that is left for you to master is Legendary equipment...',
		difficulty: 'Master',
		questRequirements: {
			skillRequirements: {
				crafting: 99,
			},
			requiredQuests: [208],
			questPoints: 5,
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Restoration or Crude Imitation?',
				description: 'Create some legendary equipment to prove your mastery.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 1547,
					},
					{
						trigger: 'craft',
						targetID: 1571,
					},
					{
						trigger: 'craft',
						targetID: 1570,
					},
					{
						trigger: 'craft',
						targetID: 628,
					},
					{
						trigger: 'craft',
						targetID: 629,
					},
					{
						trigger: 'craft',
						targetID: 12015,
					},
					{
						trigger: 'craft',
						targetID: 680,
					},
					{
						trigger: 'craft',
						targetID: 12030,
					},
					{
						trigger: 'craft',
						targetID: 12032,
					},
					{
						trigger: 'craft',
						targetID: 11005,
					},
					{
						trigger: 'craft',
						targetID: 616,
					},
					{
						trigger: 'craft',
						targetID: 606,
					},
				],
				targetAmount: 9,
				link: {
					page: 'crafting',
				},
			},
		],
		questRewards: {
			experience: {
				crafting: 150000,
			},
			relatedTalent: [2006],
			questPoints: 1,
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	210: {
		id: 210,
		name: 'Mining Equipment',
		description:
			'You could improve your mining experience by investing in a set of protective equipment that specializes in keeping you safe from bumping your head or dropping rocks on your toes!',
		difficulty: 'Medium',
		questIcon: '/images/mining/iron_pickaxe.png',
		questRequirements: {
			skillRequirements: {
				mining: 50,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Research the Mining Set',
				description: 'Mine 10,000 times to figure out what kind of equipment you need.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 50,
					},
					{
						trigger: 'location',
						targetID: 51,
					},
					{
						trigger: 'location',
						targetID: 52,
					},
					{
						trigger: 'location',
						targetID: 53,
					},
					{
						trigger: 'location',
						targetID: 54,
					},
					{
						trigger: 'location',
						targetID: 55,
					},
					{
						trigger: 'location',
						targetID: 56,
					},
					{
						trigger: 'location',
						targetID: 57,
					},
				],
				targetAmount: 10000,
				link: {
					page: 'mining',
				},
			},
		],
		questRewards: {
			experience: {
				mining: 100000,
			},
			items: [
				{
					id: 12010,
					amount: 1000,
				},
				{
					id: 12004,
					amount: 1,
					extra: {
						name: "Prototype Miner's Lantern Helmet",
						enchantmentID: 83,
						enchantmentStrength: 1,
					},
				},
				{
					id: 12003,
					amount: 1,
					extra: {
						name: "Prototype Miner's Smock",
						enchantmentID: 68,
						enchantmentStrength: 1,
					},
				},
				{
					id: 12005,
					amount: 1,
					extra: {
						name: "Prototype Miner's Trousers",
						enchantmentID: 67,
						enchantmentStrength: 1,
					},
				},
			],
			questPoints: 1,
		},
	},
	211: {
		id: 211,
		name: 'Legendary Pickaxe',
		description:
			"Legends tell of the unquestionable prowess of the Dwarves' mining capabilities. Deep in the Wilderness there are various Dwarven ruins that may hold the key to recreating their favored tools.",
		difficulty: 'Master',
		questIcon: '/images/mining/dwarven_pickaxe.png',
		questRequirements: {
			requiredQuests: [210],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Dwarven Research Station',
				description:
					'There are many of these stations scattered throughout the wilderness; clear one of them to get examples of their equipment, or forego the rewards and have contract another to do it for you by turning in your key.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3014,
					},
					{
						trigger: 'inventory',
						targetID: 9025,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Miner could hope to reproduce Dwarven tools; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 12010,
					},
				],
				targetAmount: 9000,
				link: {
					page: 'mining',
				},
			},
		],
		questRewards: {
			experience: {
				mining: 500000,
			},
			items: [
				{
					id: 606,
					amount: 1,
				},
			],
			questPoints: 1,
		},
	},
	212: {
		id: 212,
		name: 'Foraging Equipment',
		description:
			'Creating some tailored equipment for foraging should make the whole process more comfortable and efficient.',
		difficulty: 'Medium',
		questIcon: '/images/foraging/iron_hatchet.png',
		questRequirements: {
			skillRequirements: {
				foraging: 50,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Research the Foraging Set',
				description: 'Forage 10,000 times to figure out what kind of equipment you need.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 101,
					},
					{
						trigger: 'location',
						targetID: 102,
					},
					{
						trigger: 'location',
						targetID: 103,
					},
					{
						trigger: 'location',
						targetID: 104,
					},
					{
						trigger: 'location',
						targetID: 105,
					},
					{
						trigger: 'location',
						targetID: 106,
					},
					{
						trigger: 'location',
						targetID: 107,
					},
					{
						trigger: 'location',
						targetID: 108,
					},
				],
				targetAmount: 10000,
				link: {
					page: 'foraging',
				},
			},
		],
		questRewards: {
			experience: {
				foraging: 100000,
			},
			items: [
				{
					id: 12011,
					amount: 1000,
				},
				{
					id: 12007,
					amount: 1,
					extra: {
						name: "Prototype Lumberjack's Hat",
						enchantmentID: 55,
						enchantmentStrength: 1,
					},
				},
				{
					id: 12006,
					amount: 1,
					extra: {
						name: "Prototype Lumberjack's Vest",
						enchantmentID: 35,
						enchantmentStrength: 1,
					},
				},
				{
					id: 12008,
					amount: 1,
					extra: {
						name: "Prototype Lumberjack's Trousers",
						enchantmentID: 56,
						enchantmentStrength: 1,
					},
				},
			],
			questPoints: 1,
		},
	},
	213: {
		id: 213,
		name: 'Legendary Hatchet',
		description: 'The ruins of the elves may reveal secrets.',
		difficulty: 'Master',
		questIcon: '/images/foraging/grovekeeper.png',
		questRequirements: {
			requiredQuests: [212],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Nature Atheneum',
				description:
					'The history and technology of the Elves are buried deep within the forest, find these hidden libraries their lost knowledge, or forego the rewards and have contract another to do it for you by turning in your key',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3013,
					},
					{
						trigger: 'inventory',
						targetID: 9026,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Forager could hope to reproduce Elvish tools; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 12011,
					},
				],
				targetAmount: 9000,
				link: {
					page: 'foraging',
				},
			},
		],
		questRewards: {
			experience: {
				foraging: 500000,
			},
			items: [
				{
					id: 616,
					amount: 1,
				},
			],
			questPoints: 1,
		},
	},
	214: {
		id: 214,
		name: 'Fishing Set Quest',
		description: 'A full tacklebox is a good starting point, but a proper angler needs more than that.',
		difficulty: 'Medium',
		questIcon: '/images/fishing/Advanced_Tacklebox.png',
		questRequirements: {
			skillRequirements: {
				fishing: 50,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Master the Art of Fishing',
				description: 'Fish 10,000 times to figure out what kind of equipment you need.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 151,
					},
					{
						trigger: 'location',
						targetID: 152,
					},
					{
						trigger: 'location',
						targetID: 153,
					},
					{
						trigger: 'location',
						targetID: 154,
					},
					{
						trigger: 'location',
						targetID: 155,
					},
				],
				targetAmount: 10000,
				link: {
					page: 'fishing',
				},
			},
		],
		questRewards: {
			experience: {
				fishing: 100000,
			},
			items: [
				{
					id: 12009,
					amount: 1000,
				},
				{
					id: 12001,
					amount: 1,
					extra: {
						name: "Prototype Fisherman's Bucket Hat",
						enchantmentID: 51,
						enchantmentStrength: 1,
					},
				},
				{
					id: 12000,
					amount: 1,
					extra: {
						name: "Prototype Fisherman's Smock",
						enchantmentID: 50,
						enchantmentStrength: 1,
					},
				},
				{
					id: 12002,
					amount: 1,
					extra: {
						name: "Prototype Fisherman's Waders",
						enchantmentID: 49,
						enchantmentStrength: 1,
					},
				},
			],
			questPoints: 1,
		},
	},
	215: {
		id: 215,
		name: 'Legendary Tacklebox',
		description:
			'Researchers have been attempting to plumb the depths of the ocean for centuries, find their research and use it for more practical purposes.',
		difficulty: 'Master',
		questIcon: '/images/fishing/Master_Tacklebox.png',
		questRequirements: {
			requiredQuests: [214],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Abandoned Academy Base',
				description:
					"Before the Academy's fall, it had many research bases on the fringes of civilization; some say it holds occult knowledge based on ancient technology and magicks.",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3012,
					},
					{
						trigger: 'inventory',
						targetID: 9027,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Fisher could hope to produce legendary fishing tools; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 12009,
					},
				],
				targetAmount: 9000,
				link: {
					page: 'fishing',
				},
			},
		],
		questRewards: {
			experience: {
				fishing: 500000,
			},
			items: [
				{
					id: 11005,
					amount: 1,
				},
			],
			questPoints: 1,
		},
	},
	216: {
		id: 216,
		name: 'Runecrafting Set Quest',
		description:
			"Runecrafting is a specialized subset of magic and would benefit from a specialist's set of robes.",
		difficulty: 'Medium',
		questIcon: '/images/runecrafting/air_rune.png',
		questRequirements: {
			skillRequirements: {
				runecrafting: 50,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Runecrafting Equipment',
				description: 'Do 3000 runecrafting actions to prove your mastery of the skill.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 9,
					},
				],
				targetAmount: 3000,
				link: {
					page: 'runecrafting',
				},
			},
		],
		questRewards: {
			experience: {
				runecrafting: 100000,
			},
			items: [
				{
					id: 12017,
					amount: 1000,
				},
			],
			questPoints: 1,
		},
	},
	217: {
		id: 217,
		name: 'Legendary Chisel',
		description:
			'The true power of runes continues to be one of the greatest mysteries of this world. Could a better chisel be the answer?',
		difficulty: 'Master',
		questIcon: '/images/runecrafting/chisel_2.png',
		questRequirements: {
			requiredQuests: [216],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Abandoned Academy Base',
				description:
					"Before the Academy's fall, it had many research bases on the fringes of civilization; some say it holds occult knowledge based on ancient technology and magicks.",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3012,
					},
					{
						trigger: 'inventory',
						targetID: 9027,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Runecrafter could hope to produce a legendary chisel; consume 9,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 12017,
					},
				],
				targetAmount: 9000,
				link: {
					page: 'runecrafting',
				},
			},
		],
		questRewards: {
			experience: {
				runecrafting: 500000,
			},
			items: [
				{
					id: 680,
					amount: 1,
				},
			],
			questPoints: 1,
		},
	},
	218: {
		id: 218,
		name: 'Smithing Equipment',
		description: 'Smelting metals requires more than a solid pair of tongs, find out what else you can use here.',
		difficulty: 'Medium',
		questIcon: '/images/smithing/Iron_Tongs.png',
		questRequirements: {
			skillRequirements: {
				smithing: 50,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Master the Art of Smithing',
				description: 'Smith 3000 times to prove your mastery of the skill.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 6,
					},
				],
				targetAmount: 3000,
				link: {
					page: 'smithing',
				},
			},
		],
		questRewards: {
			experience: {
				smithing: 100000,
			},
			items: [
				{
					id: 12031,
					amount: 1000,
				},
			],
			questPoints: 1,
		},
	},
	219: {
		id: 219,
		name: 'Legendary Tongs',
		description: 'Master the art of smithing and unlock the recipe for the Dwarven Tongs.',
		difficulty: 'Master',
		questIcon: '/images/smithing/dwarven_tongs.png',
		questRequirements: {
			requiredQuests: [218],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Dwarven Research Station',
				description:
					'There are many of these stations scattered throughout the wilderness; clear one of them to get examples of their equipment, or forego the rewards and have contract another to do it for you by turning in your key.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3014,
					},
					{
						trigger: 'inventory',
						targetID: 9025,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Learn the Legendary Recipe',
				description: 'Research the recipe by gathering 9,000 skilling shards.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 12031,
					},
				],
				targetAmount: 9000,
				link: {
					page: 'smithing',
				},
			},
		],
		questRewards: {
			experience: {
				smithing: 500000,
			},
			items: [
				{
					id: 12030,
					amount: 1,
				},
			],
			questPoints: 1,
			achievementsToAdd: [7008],
		},
	},
	220: {
		id: 220,
		name: 'Cooking Set Quest',
		description: 'Master the art of cooking and unlock the recipe for the Cooking Set.',
		difficulty: 'Medium',
		questIcon: '/images/cooking/raw_chicken.png',
		questRequirements: {
			skillRequirements: {
				cooking: 50,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'The Art of Cooking',
				description: 'Cook or prepare 3000 times to prove your mastery of the skill.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 7,
					},
					{
						trigger: 'location',
						targetID: 10,
					},
				],
				targetAmount: 3000,
				link: {
					page: 'cooking',
				},
			},
		],
		questRewards: {
			experience: {
				cooking: 100000,
			},
			items: [
				{
					id: 12016,
					amount: 1000,
				},
			],
			questPoints: 1,
		},
	},
	221: {
		id: 221,
		name: 'Legendary Ladle',
		description: 'Master the art of cooking and unlock the recipe for the Golden Spoon.',
		difficulty: 'Master',
		questIcon: '/images/cooking/golden_spoon.png',
		questRequirements: {
			requiredQuests: [220],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Clear the Master Chicken's Kitchen",
				description: 'The art of cooking can only be truly unlocked by defeating the existing master.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3016,
					},
					{
						trigger: 'inventory',
						targetID: 9035,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Learn the Legendary Recipe',
				description: 'Research the recipe by gathering 9,000 skilling shards.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 12016,
					},
				],
				targetAmount: 9000,
				link: {
					page: 'cooking',
				},
			},
		],
		questRewards: {
			experience: {
				cooking: 500000,
			},
			items: [
				{
					id: 12015,
					amount: 1,
				},
			],
			questPoints: 1,
			achievementsToAdd: [7010],
		},
	},
	222: {
		id: 222,
		name: 'Legendary Hoe',
		description: 'Master the art of farming and unlock the recipe for the Elvish Hoe.',
		difficulty: 'Master',
		questIcon: '/images/farming/elven_hoe.png',
		questRequirements: {
			skillRequirements: {
				farming: 60,
			},
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Clear the Nature Atheneum',
				description:
					'The history and technology of the Elves are buried deep within the forest, find these hidden libraries their lost knowledge.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3013,
					},
					{
						trigger: 'inventory',
						targetID: 9026,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Research the Legendary Recipe',
				description:
					'Only a Master Farmer could hope to reproduce Elvish tools; consume 24,000 skilling shards to research and produce a prototype.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 12029,
					},
				],
				targetAmount: 24000,
				link: {
					page: 'farming',
				},
			},
		],
		questRewards: {
			experience: {
				farming: 500000,
			},
			items: [
				{
					id: 12032,
					amount: 1,
				},
			],
			questPoints: 1,
		},
	},
	223: {
		id: 223,
		name: 'From Plague Doctor to Alchemist.',
		description:
			'To successfully approach Limpe, the master chef, you must become a true alchemist. A fellow alchemist will teach you. A well-known alchemist is currently working on potions to combat a certain virus. An old story says that in the depths of the farm are animals that spread these, perhaps you can solve the riddle of an old alchemist...',
		difficulty: 'Medium',
		questIcon: '/images/cooking/alchemy/legendary.png',
		questRequirements: {
			requiredQuests: [220],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'As always in the Kitchen, Preparation is Key.',
				description:
					'To be able to get to brew potions you will need a few ingredients and probably some ores to repair an old cauldron.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 2028,
					},
					{
						trigger: 'inventory',
						targetID: 107,
					},
					{
						trigger: 'location',
						targetID: 55,
					},
					{
						trigger: 'location',
						targetID: 104,
					},
				],
				targetAmount: 2500,
			},
			{
				stepID: 2,
				name: 'Fire up the Cauldron.',
				description: 'No heat, no potion - simple as it is.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 305,
						progressMult: 2,
					},
					{
						trigger: 'inventory',
						targetID: 306,
						progressMult: 4,
					},
					{
						trigger: 'inventory',
						targetID: 112,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 3,
				name: 'Identification of the Plague Virus.',
				description: 'You need to know what youre fighting, find the host of the virus.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 505,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 4,
					},
				],
				targetAmount: 2000,
			},
			{
				stepID: 4,
				name: 'Finally brew something...',
				description:
					'Once you have realised that you cannot fight the plague with a potion, as that would require magic, make some gathering potions instead to stop the goblin slavery on the farm!',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 2139,
					},
					{
						trigger: 'inventory',
						targetID: 2142,
						progressMult: 2,
					},
					{
						trigger: 'inventory',
						targetID: 2145,
						progressMult: 3,
					},
					{
						trigger: 'inventory',
						targetID: 2147,
						progressMult: 4,
					},
				],
				targetAmount: 500,
			},
			{
				stepID: 5,
				name: 'Fight for the Ancient Cauldron! Or build your own..',
				description:
					'You heard Limpe talk about rumors from a forgotten magic cauldron in the fallen academy. Now you have to decide if you want to fight for the ancient cauldron of an old alchemist or you try to figure out how to build your own. Either way Limpe will be proud of you.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 206,
						progressMult: 2,
					},
					{
						trigger: 'slay',
						targetID: 33,
					},
					{
						trigger: 'slay',
						targetID: 521,
					},
				],
				targetAmount: 200,
			},
		],
		questRewards: {
			questPoints: 1,
			experience: {
				cooking: 75000,
				foraging: 25000,
				mining: 25000,
				smithing: 25000,
				attack: 10000,
				defense: 10000,
				constitution: 10000,
			},
			items: [
				{
					id: 3001,
					amount: 500,
				},
				{
					id: 1624,
					amount: 4,
				},
				{
					id: 2031,
					amount: 40,
				},
				{
					id: 1503,
					amount: 5,
				},
				{
					id: 50,
					amount: 1,
					extra: {
						storyID: 101,
					},
				},
			],
		},
	},
	300: {
		id: 300,
		name: 'Cave Cleanup',
		description:
			'The farms are secure, now it is time to take care of the source of the problem. Actually, there are many sources, these are goblins after all.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/goblin.png',
		questRequirements: {
			requiredQuests: [101],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Know Thy Enemy',
				description:
					"Various Goblin elites appear to be leading the incursions into human territory, find and defeat them where you can.\n\t\t\t\tThey can be encountered at high difficulty waves in the Caves, the Caves elite scroll (Stew Stained Page) encounter, or the Caves dungeon (Torn Goblin Settlement Map).\n\t\t\t\tElite enemies are found in every combat zone and appear more often as difficulty rises. Each combat zone drops its own Elite Scroll that can be augmented to increase its difficulty.\n\t\t\t\tOn completing an Elite Scroll your maximum possible difficulty in a zone is increased, allowing you to engage higher difficulty enemies for greater rewards, capping at +20.\n\t\t\t\tIf you can't manage that, wipe out a bunch of their underlings instead.",
				validTargets: [
					{
						trigger: 'slay',
						targetID: 1022,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 507,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 3,
					},
					{
						trigger: 'slay',
						targetID: 7,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Kitchen Chaos',
				description:
					'Where there are goblins there is a kitchen. Find and defeat one of their kitchens to disrupt their supply lines.\n\t\t\t\tThe Goblin Kitchen is the Elite Encounter for the Caves. It can be accessed through the Elite Scroll (Stew Stained Page) that is dropped rarely by enemies in the Caves.\n\t\t\t\tFor this quest you can complete any difficulty level, but to upgrade the zone you need to complete at least a +1 difficulty. Your highest difficulty completion is the upgrade level for the zone.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2003,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 3,
				name: 'Settlement Sabotage',
				description:
					'With enough intel you could bring a small group to take the fight to one of the Goblin Settlements. Destroy one of their settlements!\n\t\t\t\tDungeons are often co-operative content that can be done with other players or with mercenaries. Dungeons can only be accessed with a relevant Dungeon Key. In this case it is the Torn Goblin Settlement Map.\n\t\t\t\tEach augment level on the Dungeon Key greatly increases the difficulty of the dungeon, and the rewards. Every Dungeon drops their own unique rewards that make Dungeons worth doing.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3001,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				cooking: 10000,
				attack: 10000,
				defense: 10000,
				constitution: 10000,
			},
			items: [
				{
					id: 12013,
					amount: 1,
				},
				{
					id: 1503,
					amount: 1,
				},
				{
					id: 1638,
					amount: 3,
				},
			],
			questPoints: 1,
			achievementsToAdd: [5009],
		},
	},
	301: {
		id: 301,
		name: 'First Place Farm Fiasco',
		description:
			'With the external threats handled, it is time to look inward. There are reports of one farmer in particular winning far too many awards for his animals. Investigate if there is dark magic at play!',
		difficulty: 'Medium',
		questIcon: '/images/farming/farming_icon.png',
		questRequirements: {
			requiredQuests: [100],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Magic Animal Feed Doping',
				description:
					"There is clearly dark magic influencing this farmer's animal feed, just look at the monstrosities that are being created here!\n\t\t\t\t\tStray livestock may have been affected as well, so you may need to take care of them. The elite monsters can be most readily found in the Farm Elite Encounter,\n\t\t\t\t\t Ot_to's Farm, accessible through the Cluster-Cluck Elite Scroll. Alternatively, you can push the difficulty of the Farm to have a chance to encounter them.",
				validTargets: [
					{
						trigger: 'slay',
						targetID: 501,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 500,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 505,
						progressMult: 500,
					},
					{
						trigger: 'slay',
						targetID: 506,
						progressMult: 500,
					},
					{
						trigger: 'slay',
						targetID: 2,
					},
					{
						trigger: 'slay',
						targetID: 1,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Clearing the Fields',
				description:
					"You're powerless to quell the influence of the doped animal feed, but you can at least clear the fields temporarily. No river rerouting necessary!",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2004,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				farming: 10000,
				attack: 10000,
				constitution: 10000,
				magic: 10000,
			},
			items: [
				{
					id: 2032,
					amount: 100,
				},
				{
					id: 2017,
					amount: 1000,
				},
				{
					id: 2001,
					amount: 1000,
				},
				{
					id: 2000,
					amount: 1000,
				},
				{
					id: 700,
					amount: 1000,
				},
				{
					id: 1613,
					amount: 3,
				},
			],
			questPoints: 1,
		},
	},
	302: {
		id: 302,
		name: 'Punishing the Punishers',
		description:
			'Some of the city guards have been taking their duties a bit too far. It is time to show them that the citizens are not to be trifled with.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/guard.png',
		questRequirements: {
			requiredQuests: [103],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Enforcing the Law',
				description:
					'Destroy the golems that the guards have been using to enforce their will on the citizens.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 512,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 528,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 13,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Vigilante Justice',
				description:
					'The armories these golems originate from are scattered throughout the city. Destroy one of them to send a message.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2000,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				smithing: 10000,
				attack: 10000,
				defense: 10000,
				strength: 10000,
			},
			items: [
				{
					id: 209,
					amount: 128,
				},
				{
					id: 9009,
					amount: 6,
				},
				{
					id: 903,
					amount: 12,
				},
				{
					id: 1619,
					amount: 4,
				},
				{
					id: 1664,
					amount: 4,
				},
				{
					id: 1618,
					amount: 4,
				},
			],
			questPoints: 1,
		},
	},
	303: {
		id: 303,
		name: 'Purging the Cultists',
		description: 'The Academy used to be a bastion of knowledge but has been reduced to the hideout of a cult.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/dark_mage.png',
		questRequirements: {
			requiredQuests: [102],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Anti-Magic Measures',
				description: 'Purge as many cultists as you can find.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 521,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 522,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 529,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 33,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Tilting the Tower',
				description: 'Clear out a tower of its denizens.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2010,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				runecrafting: 20000,
				magic: 20000,
				attack: 20000,
			},
			items: [
				{
					id: 25020,
					amount: 1,
				},
				{
					id: 113,
					amount: 2000,
				},
				{
					id: 9032,
					amount: 1,
				},
				{
					id: 902,
					amount: 12,
				},
				{
					id: 1668,
					amount: 4,
				},
			],
			questPoints: 1,
		},
	},
	304: {
		id: 304,
		name: 'Exorcising the Pits',
		description:
			'The Lava Maze is a perilous and complex system of pathways, with molten rivers and towering, jagged obsidian walls that have been formed over time from volcanic activity. The air is thick with sulfur and heat, making it difficult to breathe. Glowing pools of lava cast an eerie light on the surroundings, and the echoes of monstrous demons roaring and clawing resonate through the maze. Certainly, outnumbered you must swiftly come up with a plan to rid yourself of the numerous demons and make your way deeper into the Pit. By completing these quests, adventurers not only prove their mettle but also pave the way for deeper exploration and discovery inside the secretive Lava Maze.',
		difficulty: 'Hard',
		questIcon: '/images/combat/monsters/elite/greater_demon_elite.png',
		questRequirements: {
			requiredQuests: [106],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Purge the Overseers',
				description:
					'You devise that you will need to weaken the demonic forces that guard the deeper secrets of the Lava Maze, by first culling the numbers of the Overseer and Vassal demons. These higher tier demons are not only stronger but also serve as commanders, orchestrating the chaos and enforcing the will of their infernal overlords. Slaying them will interfere with the demon hierarchy and create an opening for further expedition into the maze. It would seem that these monsters being fire oriented would suffer more from ice type attacks and possibly some slashing attacks as they are still fleshy beings, however the Overseers look like a real challenge and are more muscular. You will have to encounter them more to see what works best.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 502,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 504,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 11,
					},
					{
						trigger: 'slay',
						targetID: 12,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'The Pits Depths',
				description:
					"Having disrupted the demonic hierarchy, the path is now cleared to the elite challenge of one of the Pits. Here, some of the maze's most formidable guardians await, ready to unleash wave after wave of relentless assault. Demonic beings will attack you systematically, getting more intense as time passes. Demons and spiders alike emerge from the depths of hell, culminating in a final confrontation with the strongest champions. Smite the demons and emerge victorious from one of the many pits in the Lava Maze, and reap any rewards you find.",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2005,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				mining: 30000,
				magic: 30000,
				attack: 30000,
			},
			items: [
				{
					id: 800,
					amount: 2500,
				},
				{
					id: 9013,
					amount: 6,
				},
				{
					id: 9014,
					amount: 6,
				},
				{
					id: 25011,
					amount: 12,
				},
				{
					id: 1659,
					amount: 4,
				},
			],
			questPoints: 1,
		},
	},
	305: {
		id: 305,
		name: 'Clearing the Undergrowth',
		description: 'This forest is deep and thick...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/wolf.png',
		questRequirements: {
			requiredQuests: [101],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Into the Thicket',
				description:
					'You encounter some dangerous foes in the outskirts of the woods... You may need to bring an ally.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 31,
					},
					{
						trigger: 'slay',
						targetID: 30,
					},
					{
						trigger: 'slay',
						targetID: 523,
						progressMult: 100,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Nox Woods',
				description: 'One of the darkest parts of the woods, this area harbors only evil.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2011,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				foraging: 30000,
				strength: 30000,
				attack: 30000,
			},
			items: [
				{
					id: 4004,
					amount: 2500,
				},
				{
					id: 4005,
					amount: 1000,
				},
				{
					id: 9022,
					amount: 10,
				},
				{
					id: 185,
					amount: 32,
				},
				{
					id: 1611,
					amount: 4,
				},
			],
			questPoints: 1,
			achievementsToAdd: [5013],
		},
	},
	306: {
		id: 306,
		name: 'Entering the Armory',
		description: 'Strange magic has warped the suits of armor here into dangerous mechanical golems...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/black_knight_magic.png',
		questRequirements: {
			requiredQuests: [305],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Discovering Ancient Magic',
				description:
					'Suits of armor meet you at the entrance to the armory! These ones seem to be warped by magic...',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 37,
					},
					{
						trigger: 'slay',
						targetID: 36,
					},
					{
						trigger: 'slay',
						targetID: 38,
					},
					{
						trigger: 'slay',
						targetID: 519,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 518,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 520,
						progressMult: 100,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Obsidian Research Facility',
				description:
					"A section of the armory appears to be where the magic was unleashed. Conquer it in order to understand it's secrets!",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2009,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				crafting: 20000,
				attack: 20000,
				strength: 20000,
				magic: 20000,
				range: 20000,
			},
			items: [
				{
					id: 25025,
					amount: 5000,
				},
				{
					id: 209,
					amount: 50,
				},
				{
					id: 9034,
					amount: 12,
				},
				{
					id: 210,
					amount: 500,
				},
				{
					id: 1621,
					amount: 4,
				},
			],
			questPoints: 1,
			achievementsToAdd: [5019],
		},
	},
	307: {
		id: 307,
		name: 'Conquering the Corruption',
		description: 'Poisonous substances have corrupted the creatures here!',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/bone_giant.png',
		questRequirements: {
			requiredQuests: [304],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Cruel Corruption',
				description:
					'Toxic substances appear to be the cause for the vile creatures found in the corrupted lands.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 18,
					},
					{
						trigger: 'slay',
						targetID: 19,
					},
					{
						trigger: 'slay',
						targetID: 17,
					},
					{
						trigger: 'slay',
						targetID: 514,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 530,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 513,
						progressMult: 100,
					},
				],
				targetAmount: 1000,
				link: {
					page: 'attack',
				},
			},
			{
				stepID: 2,
				name: 'Corroded Battlefield',
				description:
					'Ruins of a long-forgotten battle appear in front of you. The monsters here are more corrupted than ever!',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2002,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				enchanting: 50000,
				attack: 50000,
				constitution: 50000,
			},
			items: [
				{
					id: 9004,
					amount: 1,
				},
				{
					id: 306,
					amount: 500,
				},
				{
					id: 800,
					amount: 500,
				},
				{
					id: 1544,
					amount: 1,
				},
				{
					id: 8,
					amount: 30000,
				},
				{
					id: 1662,
					amount: 4,
				},
			],
			questPoints: 1,
		},
	},
	308: {
		id: 308,
		name: "Entering the Elves' Domain",
		description: 'Nestled in a large clearing is a ring of tents. Elves mingle about the area.',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/elite/elven_king.png',
		questRequirements: {
			requiredQuests: [305],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Hostile Elves',
				description: 'The elves appear to be hostile! Defend yourself!',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 26,
					},
					{
						trigger: 'slay',
						targetID: 29,
					},
					{
						trigger: 'slay',
						targetID: 27,
					},
					{
						trigger: 'slay',
						targetID: 28,
					},
					{
						trigger: 'slay',
						targetID: 524,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 527,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 525,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 526,
						progressMult: 100,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Royal Encampment',
				description:
					'You come across a truly massive encampment of elves. This camp seems more prepared than you have ever seen...',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2012,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				farming: 30000,
				range: 30000,
				attack: 30000,
				defense: 60000,
			},
			items: [
				{
					id: 1466,
					amount: 1000,
				},
				{
					id: 4028,
					amount: 5,
				},
				{
					id: 9023,
					amount: 1,
				},
				{
					id: 4011,
					amount: 12,
				},
				{
					id: 1658,
					amount: 3,
				},
				{
					id: 1678,
					amount: 3,
				},
			],
			questPoints: 1,
			achievementsToAdd: [5012],
		},
	},
	309: {
		id: 309,
		name: 'A Giant Valley',
		description:
			'In the far north lie a civilization of giants. They wield weapons made with knowledge that has long been dead...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/ice_giant.png',
		questRequirements: {
			requiredQuests: [307],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Furious Foes',
				description: "The giant's don't want to be disturbed, but you need the weapons they wield!",
				validTargets: [
					{
						trigger: 'slay',
						targetID: 16,
					},
					{
						trigger: 'slay',
						targetID: 15,
					},
					{
						trigger: 'slay',
						targetID: 14,
					},
					{
						trigger: 'slay',
						targetID: 511,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 510,
						progressMult: 100,
					},
					{
						trigger: 'slay',
						targetID: 509,
						progressMult: 100,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: "Giant's Hills",
				description:
					'Futher into the valley you discover frozen hills, containing giants much older than the ones you have fought before...',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2001,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				crafting: 30000,
				constitution: 30000,
				defense: 30000,
				attack: 30000,
				strength: 30000,
				range: 30000,
				magic: 30000,
			},
			items: [
				{
					id: 513,
					amount: 1000,
				},
				{
					id: 512,
					amount: 1000,
				},
				{
					id: 517,
					amount: 1000,
				},
				{
					id: 6,
					amount: 30000,
				},
				{
					id: 5,
					amount: 30000,
				},
				{
					id: 10,
					amount: 30000,
				},
				{
					id: 503,
					amount: 1,
				},
				{
					id: 502,
					amount: 1,
				},
				{
					id: 507,
					amount: 1,
				},
				{
					id: 9002,
					amount: 1,
				},
				{
					id: 9003,
					amount: 1,
				},
				{
					id: 9001,
					amount: 1,
				},
				{
					id: 1637,
					amount: 8,
				},
				{
					id: 1643,
					amount: 4,
				},
			],
			questPoints: 1,
			achievementsToAdd: [5011],
		},
	},
	310: {
		id: 310,
		name: 'The Chaotic Plane',
		description:
			'Entering another plane of existence, you discover chaotic beings more powerful than anything in the overworld...',
		difficulty: 'Medium',
		questIcon: '/images/combat/monsters/chaotic_abomination.png',
		questRequirements: {
			requiredQuests: [308],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Complete and Utter Chaos',
				description: 'The enemies here have been warped by forces beyond your understanding.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 21,
					},
					{
						trigger: 'slay',
						targetID: 20,
					},
					{
						trigger: 'slay',
						targetID: 517,
						progressMult: 100,
					},
				],
				targetAmount: 1000,
			},
			{
				stepID: 2,
				name: 'Chaotic Rift',
				description: 'A tear within reality itself spews forth nightmare-ish creatures.',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 2007,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			experience: {
				enchanting: 60000,
				magic: 60000,
				attack: 60000,
				defense: 60000,
				constitution: 60000,
			},
			items: [
				{
					id: 506,
					amount: 1,
				},
				{
					id: 9,
					amount: 30000,
				},
				{
					id: 9005,
					amount: 1,
				},
				{
					id: 516,
					amount: 1000,
				},
				{
					id: 9020,
					amount: 4,
				},
				{
					id: 1632,
					amount: 8,
				},
			],
			questPoints: 1,
			achievementsToAdd: [5016, 5017, 5018],
		},
	},
	2000: {
		id: 2000,
		name: 'Gathering Tutorial',
		description:
			'Learn how to gather! The Gathering skills are the primary produces of raw materials in Idlescape and have the lowest barrier to entry of all skills. By default every 5 minutes spent Gathering produces the relevant Gathering Shard for that skill that can eventually be used to craft very powerful gathering equipment.',
		order: 1,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Mining',
				description:
					'Mining produces ores that can be processed by Smithing in order to craft weapons, armor, and tools. Pickaxes increase the speed at which you mine ores. Mining primarily feeds into the Melee combat style. If you are lucky then you may find a Geode or its Greater Geode sibling that can be opened for a random reward.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 50,
					},
				],
				targetAmount: 10,
				link: {
					page: 'mining',
				},
			},
			{
				stepID: 2,
				name: 'Foraging',
				description:
					'Foraging produces logs, plants, and seeds. Logs can be used in crafting or burned for Heat. Plants can be used by Cooking to create various foods or potions. Seeds can be planted on your farm. Hatchets increase the speed at which you forage. Foraging primarily feeds into the Ranged combat style. If you are lucky then you may find a Birds Nest or its Greater Birds Nest sibling that can be opened for a random reward.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 101,
					},
				],
				targetAmount: 10,
				link: {
					page: 'foraging',
				},
			},
			{
				stepID: 3,
				name: 'Fishing',
				description:
					'Fishing produces fish, driftwood, and plant fibers. Fish are used in cooking, driftwood can be burned or crafted with, and plant fibers can be weaved for crafting. Fishing uses stats from tackleboxes and various baits and is the most complex gathering skill. Fishing primarily feeds into the Magic combat style. If you are lucky then you may find a Sunken Treasure or its Ancient Treasure sibling that can be opened for a random reward.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 151,
					},
				],
				targetAmount: 10,
				link: {
					page: 'fishing',
				},
			},
		],
		questRewards: {
			experience: {
				mining: 250,
				foraging: 250,
				fishing: 250,
			},
			items: [
				{
					id: 600,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Pickaxe',
					},
				},
				{
					id: 610,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Hatchet',
					},
				},
				{
					id: 11000,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Tacklebox',
					},
				},
				{
					id: 185,
					amount: 16,
				},
				{
					id: 900,
					amount: 1,
				},
				{
					id: 901,
					amount: 1,
				},
				{
					id: 902,
					amount: 1,
				},
				{
					id: 113,
					amount: 100,
				},
				{
					id: 301,
					amount: 100,
				},
				{
					id: 302,
					amount: 40,
				},
				{
					id: 151,
					amount: 1,
				},
				{
					id: 102,
					amount: 10,
				},
				{
					id: 101,
					amount: 10,
				},
				{
					id: 112,
					amount: 1,
				},
				{
					id: 4020,
					amount: 50,
				},
				{
					id: 500,
					amount: 2,
				},
			],
			achievementsToAdd: [6001, 6002, 6003, 6004, 6005, 6006, 6007, 6009],
		},
		questIcon: '/images/mining/iron_pickaxe.png',
	},
	2001: {
		id: 2001,
		name: 'Processing Tutorial',
		description:
			'Learn how to process! The Processing skills are used to convert raw materials into usable items and are the primary source of refined materials and finished products in Idlescape.',
		order: 2,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Crafting',
				description:
					'Crafting is used to create a wide variety of useful items and is the primary way for you to create finished products from various materials. For this tutorial you will need to craft a Box Trap. You can find the recipe in the Crafting menu under the Farming tab.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 4500,
					},
				],
				image: {
					image: '/images/ui/crafting_icon.png',
					size: 'medium',
				},
				targetAmount: 1,
				link: {
					page: 'crafting',
				},
			},
			{
				stepID: 2,
				name: 'Farming',
				description:
					'Farming is a completely passive skill; plant a seed or lay a trap and wait! For this tutorial you will need to put down a Box Trap that you crafted earlier. You can use fertilizer to boost the output yield and experience of any Farming plot, seed or trap, but it is not required.',
				validTargets: [
					{
						trigger: 'plant',
						targetID: 4500,
					},
				],
				targetAmount: 1,
				link: {
					page: 'farming',
				},
			},
			{
				stepID: 3,
				name: 'Getting Heat',
				description:
					'Heat is used in Cooking and Smithing. For this tutorial you will need to burn at least 10 heat worth of items, such as a single piece of Coal or a few Logs. You can do this by selecting the item in your inventory and clicking the Burn button.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'inventory',
				},
			},
			{
				stepID: 4,
				name: 'Smithing',
				description:
					'Metal bars have a wide variety of uses, but are primarily used in crafting Melee weapons and armor as well as produce several tools. For this tutorial you will need to either produce 10 bronze bars; you can find Tin and Copper Ore in the Clay Pit while Mining.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 201,
					},
				],
				targetAmount: 1,
				link: {
					page: 'smithing',
				},
			},
			{
				stepID: 5,
				name: 'Runecrafting (Runes)',
				description:
					'Runes are used to power most magical effects in Idlescape, ranging from enchanted scrolls to powerful spells. Each rune type has a different essence that is produced by various activities that you can find by hovering over your essence count in the Runecrafting menu. For this tutorial you will need to produce some of the most basic runes: Air (passively earned), Earth (mining), Water (fishing), and Nature (foraging). Simply doing the activity will produce the essence, though essence generated can be boosted through absorbing the power of its respective Talisman or by using enchantments. You received two Air Talismans from the Gathering Tutorial, select them in the inventory to consume them and gain their power and some spare Air Essence.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 510,
					},
					{
						trigger: 'craft',
						targetID: 511,
					},
					{
						trigger: 'craft',
						targetID: 513,
					},
					{
						trigger: 'craft',
						targetID: 517,
					},
				],
				targetAmount: 20,
				link: {
					page: 'runecrafting',
				},
			},
			{
				stepID: 6,
				name: 'Runecrafting (Cloth)',
				description:
					'Cloth is used to craft magical items and is sourced entirely from fibers found while Fishing. For this tutorial you will need to either produce or find 10 rough cloth; you can find the requisite fiber, Aqueous Grass, in the Shallow Pond while Fishing.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 4013,
					},
				],
				targetAmount: 10,
				link: {
					page: 'runecrafting',
				},
			},
		],
		questRewards: {
			experience: {
				crafting: 250,
				runecrafting: 250,
				smithing: 250,
				farming: 250,
			},
			items: [
				{
					id: 660,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Hoe',
					},
				},
				{
					id: 677,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Apprentice Chisel',
					},
				},
				{
					id: 666,
					amount: 1,
					extra: {
						forceUntradeable: true,
						name: 'Bronze Tongs',
					},
				},
				{
					id: 155,
					amount: 32,
				},
				{
					id: 150,
					amount: 64,
				},
				{
					id: 113,
					amount: 100,
				},
				{
					id: 101,
					amount: 64,
				},
				{
					id: 102,
					amount: 64,
				},
				{
					id: 301,
					amount: 100,
				},
				{
					id: 302,
					amount: 64,
				},
				{
					id: 2,
					amount: 1000,
				},
				{
					id: 111,
					amount: 100,
				},
				{
					id: 1600,
					amount: 1,
				},
			],
			achievementsToAdd: [7006, 7007, 7012, 7013],
		},
		questIcon: '/images/ui/crafting_icon.png',
	},
	2002: {
		id: 2002,
		name: 'Combat Tutorial',
		description:
			'Learn how to fight! Combat combines the efforts of its Gathering and Processing counterparts to challenge difficult opponents and unlock various content or rewards. Combat is the primary source of raw gold coins and is the most complex aspect of Idlescape. There are three combat styles: Melee, Ranged, and Magic. Each style has its own unique weapons and armor and perform differently based on the opponent faced.',
		order: 3,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Gearing Up',
				description:
					'Choose a combat style! Most styles have many weapons to choose from, but for simplicity you should focus on these specific ones for now.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 1002,
					},
					{
						trigger: 'craft',
						targetID: 1234,
					},
					{
						trigger: 'craft',
						targetID: 1199,
					},
				],
				targetAmount: 1,
				link: {
					page: 'crafting',
				},
			},
			{
				stepID: 2,
				name: 'Combat',
				description: 'Head to the Farms and slay 100 of its denizens!',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 2,
					},
					{
						trigger: 'slay',
						targetID: 1,
					},
					{
						trigger: 'slay',
						targetID: 3,
					},
				],
				targetAmount: 100,
				link: {
					page: 'attack',
				},
			},
		],
		questRewards: {
			experience: {
				attack: 250,
				defense: 250,
			},
			items: [
				{
					id: 201,
					amount: 50,
				},
				{
					id: 302,
					amount: 50,
				},
				{
					id: 11030,
					amount: 50,
				},
				{
					id: 4013,
					amount: 50,
				},
				{
					id: 4007,
					amount: 50,
				},
			],
			achievementsToAdd: [5004, 5023],
		},
		questIcon: '/images/combat/combat_level.png',
	},
	2003: {
		id: 2003,
		name: 'Item Improvement Tutorial',
		description:
			'There are several avenues you can take to improve your items with stacking effects and bonuses. This tutorial will cover the basics of Augmentations and Scrolled Enchantments in the Enchanting skill.',
		order: 4,
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Scrollcrafting',
				description:
					'Enchanted Scrolls are the easiest way to improve an item. Items have a limited number of slots for enchantments and enchantments apply a variety of effects to an item. For this step you will need to craft a Scroll of the Shrimp Lord. You can find the recipe in the Scrollcrafting menu under the Enchanting tab. You can find silver through mining, scrolls in the General Shop, and runes from Runecrafting.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 1629,
					},
				],
				targetAmount: 1,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 2,
				name: 'Enchanting',
				description:
					'Enchanted Scrolls have specific item slots they can be applied to. The Scroll of the Shrimp Lord can only be applied to Boots or Gloves which can be purchased from the General Shop. You can find the Enchanting menu under the Enchanting tab. Select the item to enchant and the scroll to enchant it with and click the Enchant button to complete this step.',
				validTargets: [
					{
						trigger: 'enchant-scroll',
						targetID: 1629,
					},
				],
				targetAmount: 1,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 3,
				name: 'Augmenting',
				description:
					'Augmenting improves the stats of items but requires a small amount of resources to do so. To complete this tutorial step you will need to attempt to augment any item. You can find the Augmenting menu under the Enchanting tab. Place the item to augment into the Augmenting Queue and confirm that you have the materials to augment and click the Start button to attempt augmentation.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'enchanting',
				},
			},
		],
		questRewards: {
			experience: {
				enchanting: 500,
			},
			items: [
				{
					id: 1600,
					amount: 3,
				},
				{
					id: 111,
					amount: 300,
				},
			],
			achievementsToAdd: [7003, 7004, 7005],
		},
		questIcon: '/images/enchanting/augmenting_logo.png',
	},
	2004: {
		id: 2004,
		name: 'Affixing Tutorial',
		description:
			'Affixes are a more advanced way to improve your equipment and enhance it for your personal niche.',
		difficulty: 'Tutorial',
		order: 5,
		questRequirements: {
			requiredQuests: [2003],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Runic Dust',
				description:
					'Runic Dust is used to apply or reroll the affixes on an item. Each item has its own rarity with a corresponding tier of dust. Craft 30 or turn in any tier of dust, either from materials gathered or from monster drops.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 550,
					},
					{
						trigger: 'inventory',
						targetID: 550,
					},
					{
						trigger: 'craft',
						targetID: 551,
					},
					{
						trigger: 'inventory',
						targetID: 551,
					},
					{
						trigger: 'craft',
						targetID: 552,
					},
					{
						trigger: 'inventory',
						targetID: 552,
					},
					{
						trigger: 'inventory',
						targetID: 553,
					},
					{
						trigger: 'craft',
						targetID: 553,
					},
					{
						trigger: 'craft',
						targetID: 554,
					},
					{
						trigger: 'inventory',
						targetID: 554,
					},
				],
				targetAmount: 30,
				link: {
					page: 'enchanting',
				},
			},
			{
				stepID: 2,
				name: 'Gear Scraps',
				description:
					'Gear Scraps are made by researching equipment until it breaks. The obtained scrap matches the rarity of the item. Gear Scraps are consumed on each reroll attempt of an affix. Turn in 1 of any tier of gear scraps.',
				validTargets: [
					{
						trigger: 'inventory',
						targetID: 555,
					},
					{
						trigger: 'inventory',
						targetID: 556,
					},
					{
						trigger: 'inventory',
						targetID: 557,
					},
					{
						trigger: 'inventory',
						targetID: 558,
					},
					{
						trigger: 'inventory',
						targetID: 559,
					},
					{
						trigger: 'craft',
						targetID: 555,
					},
					{
						trigger: 'craft',
						targetID: 556,
					},
					{
						trigger: 'craft',
						targetID: 557,
					},
					{
						trigger: 'craft',
						targetID: 558,
					},
					{
						trigger: 'craft',
						targetID: 559,
					},
				],
				link: {
					page: 'enchanting',
				},
				targetAmount: 1,
			},
			{
				stepID: 3,
				name: 'Time to Affix!',
				description:
					'You will need some dust to apply an affix to an item. The dust must be of the same rarity tier as the item. Selecting specific affixes increases the cost to affix massively depending on the rarity of the affixes selected. Simply affix anything to any item once to complete this step.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Affix Anything',
						targetID: -1,
					},
				],
				link: {
					page: 'runecrafting',
				},
				targetAmount: 1,
			},
			{
				stepID: 4,
				name: 'Time to Reroll!',
				description:
					'You will need some dust and a gear scrap to reroll the affixes on an item. The dust and scraps must be of the same rarity tier as the item. Locking an affix causes it to not be rerolled; unlocked affixes will reroll its strength range but NOT replace it with another affix.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Reroll Anything',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'runecrafting',
				},
			},
		],
		questRewards: {
			experience: {
				runecrafting: 500,
			},
			items: [
				{
					id: 550,
					amount: 300,
				},
				{
					id: 551,
					amount: 150,
				},
				{
					id: 555,
					amount: 3,
				},
				{
					id: 556,
					amount: 3,
				},
			],
			achievementsToAdd: [7001, 7002],
		},
		questIcon: '/images/runecrafting/dust/runic_dust_legendary.png',
	},
	2005: {
		id: 2005,
		name: 'Talent Tutorial',
		description:
			'Talents are a mostly lategame specialization tool that will allow you to passively modify your character through unlocking different, highly unique perks/talents in a tree.',
		difficulty: 'Tutorial',
		questSteps: [
			{
				stepID: 1,
				name: 'Pick a Talent',
				description:
					'To complete this tutorial you simply have to select any one of the talents available. You are given a maximum talent allowance based on your mastery levels and quest/achievement points; most talents have a cost associated with them and you may select up to you allowance. Some Talents unlock other, stronger Talents to allow greater specialization. These are semi-permanent additions to your character and require the use of a Respec Token to reset your Talent Tree, so pick carefully!',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'talents',
				},
			},
		],
		questRewards: {
			items: [
				{
					id: 15003,
					amount: 1,
				},
			],
		},
		questIcon: '/images/total_level_mastery_icon.png',
	},
	5000: {
		id: 5000,
		name: 'Farm Fiend',
		questIcon: '/images/combat/monsters/chicken.png',
		description: 'Terrorize the farms and kill ALL the farm animals!',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [100],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 farm animals. You monster.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 1,
					},
					{
						trigger: 'slay',
						targetID: 2,
					},
					{
						trigger: 'slay',
						targetID: 4,
					},
					{
						trigger: 'slay',
						targetID: 500,
					},
					{
						trigger: 'slay',
						targetID: 501,
					},
					{
						trigger: 'slay',
						targetID: 505,
					},
					{
						trigger: 'slay',
						targetID: 506,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5001: {
		id: 5001,
		name: 'Cavern Crusader',
		questIcon: '/images/combat/monsters/goblin.png',
		description: 'The only good goblin is a dead goblin.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [101],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 goblins.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 3,
					},
					{
						trigger: 'slay',
						targetID: 507,
					},
					{
						trigger: 'slay',
						targetID: 1022,
					},
					{
						trigger: 'slay',
						targetID: 1024,
					},
					{
						trigger: 'slay',
						targetID: 7,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5002: {
		id: 5002,
		name: 'Internal Auditor',
		questIcon: '/images/combat/monsters/guard.png',
		description: 'It is time to determine who on the guard deserves that honor.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [102],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 guards and militia. How exactly does this help the city?',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 5,
					},
					{
						trigger: 'slay',
						targetID: 6,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5003: {
		id: 5003,
		name: 'Obsidian Obliterator',
		questIcon: '/images/combat/monsters/black_knight.png',
		description: 'These living golems are just man-made monsters.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [104],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Black Knights.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 13,
					},
					{
						trigger: 'slay',
						targetID: 512,
					},
					{
						trigger: 'slay',
						targetID: 528,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5004: {
		id: 5004,
		name: 'Giant Slayer',
		questIcon: '/images/combat/monsters/ice_giant.png',
		description: 'The bigger they are, the harder they fall.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [2002],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Giants.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 14,
					},
					{
						trigger: 'slay',
						targetID: 15,
					},
					{
						trigger: 'slay',
						targetID: 16,
					},
					{
						trigger: 'slay',
						targetID: 17,
					},
					{
						trigger: 'slay',
						targetID: 20,
					},
					{
						trigger: 'slay',
						targetID: 509,
					},
					{
						trigger: 'slay',
						targetID: 510,
					},
					{
						trigger: 'slay',
						targetID: 511,
					},
					{
						trigger: 'slay',
						targetID: 513,
					},
					{
						trigger: 'slay',
						targetID: 517,
					},
					{
						trigger: 'slay',
						targetID: 528,
					},
					{
						trigger: 'slay',
						targetID: 1006,
					},
					{
						trigger: 'slay',
						targetID: 1007,
					},
					{
						trigger: 'slay',
						targetID: 1013,
					},
					{
						trigger: 'slay',
						targetID: 1028,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	5005: {
		id: 5005,
		name: 'Cult Crusher',
		questIcon: '/images/combat/monsters/dark_mage.png',
		description: 'Purge the cultists!',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Mages.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 33,
					},
					{
						trigger: 'slay',
						targetID: 521,
					},
					{
						trigger: 'slay',
						targetID: 522,
					},
					{
						trigger: 'slay',
						targetID: 529,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	5006: {
		id: 5006,
		name: 'Demon Destroyer',
		questIcon: '/images/combat/monsters/lesser_demon_no_highlight.png',
		description: 'Rip and Tear until it is done.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Kill 'em All!",
				description: 'Slay a total of 100,000 Demons.',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 8,
					},
					{
						trigger: 'slay',
						targetID: 11,
					},
					{
						trigger: 'slay',
						targetID: 12,
					},
					{
						trigger: 'slay',
						targetID: 39,
					},
					{
						trigger: 'slay',
						targetID: 502,
					},
					{
						trigger: 'slay',
						targetID: 504,
					},
					{
						trigger: 'slay',
						targetID: 1023,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	5007: {
		id: 5007,
		name: 'Elemental Exiles',
		questIcon: '/images/combat/monsters/elite/exiled_preceptor.png',
		description:
			'The most powerful within the Academy sought to channel wild magical energy. Can you finish their research?',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Appropriating Research Notes',
				description: 'Slay Exiled Preceptors',
				validTargets: [
					{
						trigger: 'slay',
						targetID: 529,
					},
				],
				targetAmount: 10000,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [1078],
		},
		achievement: true,
	},
	5008: {
		id: 5008,
		name: 'Shrimp Aberrancy',
		questIcon: '/images/combat/monsters/shrimp_abberation.png',
		description: 'Clear the Gazing into the Abyss location 100 times',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [100],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Gazing into the Abyss',
				description: 'Clear the Gazing into the Abyss location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3000,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 2,
			relatedTalent: [1083],
		},
		achievement: true,
	},
	5009: {
		id: 5009,
		name: 'Goblin Extermination',
		questIcon: '/images/combat/monsters/elite/goblin_chef.png',
		description: 'Clear the Goblin Settlement location 100 times',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [300],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Goblin Settlement',
				description: 'Clear the Goblin Settlement location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3001,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 2,
			relatedTalent: [1084],
		},
		achievement: true,
	},
	5010: {
		id: 5010,
		name: 'Dark Necessities',
		questIcon: '/images/combat/monsters/elite/bone_giant_elite.png',
		description: 'Clear the Dark Fortress location 100 times',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [104],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Dark Fortress',
				description: 'Clear the Dark Fortress location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3002,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 2,
			relatedTalent: [1085],
		},
		achievement: true,
	},
	5011: {
		id: 5011,
		name: 'Giant Problems',
		questIcon: '/images/combat/monsters/Giant_King_Silver_C.png',
		description: "Clear the Giant's Keep location 100 times",
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [309],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Giant's Keep",
				description: "Clear the Giant's Keep location 100 times",
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3003,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1080],
		},
		achievement: true,
	},
	5012: {
		id: 5012,
		name: 'Elder Error',
		questIcon: '/images/combat/monsters/elite/elven_king.png',
		description: 'Clear the Elder Ruins location 100 times',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [308],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Elder Ruins',
				description: '',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3004,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1086],
		},
		achievement: true,
	},
	5013: {
		id: 5013,
		name: 'Castle Hunting',
		questIcon: '/images/combat/monsters/elite/firewolf.png',
		description: 'Clear the Overgrown Castle location 100 times',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [305],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Overgrown Castle',
				description: 'Clear the Overgrown Castle location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3005,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 2,
			relatedTalent: [1087],
		},
		achievement: true,
	},
	5014: {
		id: 5014,
		name: 'Archaeology in Force',
		questIcon: '/images/combat/monsters/elite/pactbound_sorceror.png',
		description: 'Clear the Ancient Barrows location 100 times',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Ancient Barrows',
				description: 'Clear the Ancient Barrows location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3006,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 2,
			relatedTalent: [1088],
		},
		achievement: true,
	},
	5015: {
		id: 5015,
		name: 'Enjoying Demonic Architecture',
		questIcon: '/images/combat/monsters/elite/flame_chicken_4.png',
		description: 'Clear the Palace of Flame location 100 times',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [106],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Palace of Flame',
				description: 'Clear the Palace of Flame location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3007,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 2,
			relatedTalent: [1089],
		},
		achievement: true,
	},
	5016: {
		id: 5016,
		name: 'Chaotic Seas',
		questIcon: '/images/combat/monsters/elite/storm_watcher.png',
		description: 'Clear the Eye of the Storm location 100 times',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [310],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Eye of the Storm',
				description: 'Clear the Eye of the Storm location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3008,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1090],
		},
		achievement: true,
	},
	5017: {
		id: 5017,
		name: 'Hurricane Hunter',
		questIcon: '/images/combat/monsters/elite/storm_watcher.png',
		description: 'Clear The Maelstrom location 100 times',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [310],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'The Maelstrom',
				description: 'Clear The Maelstrom location 100 times',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3009,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1091],
		},
		achievement: true,
	},
	5018: {
		id: 5018,
		name: 'Shrimp God Slayer',
		questIcon: '/images/combat/monsters/elite/shrimp_god.png',
		description: 'Clear the Beyond the Edge location 100 times',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [310],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Beyond the Edge',
				description: '',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3011,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [1082],
		},
		achievement: true,
	},
	5019: {
		id: 5019,
		name: 'Vault Hunter',
		questIcon: '/images/combat/monsters/elite/black_knight_elite.png',
		description: 'Clear the Dark Vault location 100 times',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [306],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Dark Vault',
				description: '',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3015,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1081],
		},
		achievement: true,
	},
	5020: {
		id: 5020,
		name: 'Academy Archaeologist',
		questIcon: '/images/combat/monsters/elite/water_elemental2.png',
		description: 'Clear the Abandoned Academy Base location 100 times',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Abandoned Academy Base',
				description: '',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3012,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2101],
		},
		achievement: true,
	},
	5021: {
		id: 5021,
		name: 'All Natural',
		questIcon: '/images/combat/monsters/elite/living_wood_wolf.png',
		description: 'Clear the Nature Atheneum location 100 times',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Nature Atheneum',
				description: '',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3013,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2102],
		},
		achievement: true,
	},
	5022: {
		id: 5022,
		name: 'Dwarven Researcher',
		questIcon: '/images/combat/monsters/elite/dwarven_automaton2.png',
		description: 'Clear the Dwarven Research Station location 100 times',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Dwarven Research Station',
				description: '',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3014,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2103],
		},
		achievement: true,
	},
	5023: {
		id: 5023,
		name: 'Welcome to Combat',
		questIcon: '/images/combat/combat_level.png',
		description: 'Die once, but not really?',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [2002],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Die Once',
				description: '',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	5024: {
		id: 5024,
		name: 'Five Star Chef',
		questIcon: '/images/combat/monsters/elite/chicken_chef.png',
		description: "Clear the Master Chicken's Kitchen location 100 times",
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [105],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Master Chicken's Kitchen",
				description: '',
				validTargets: [
					{
						trigger: 'dungeon',
						targetID: 3016,
					},
				],
				targetAmount: 100,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2104],
		},
		achievement: true,
	},
	6001: {
		id: 6001,
		name: 'Master Miner',
		questIcon: '/images/mining/iron_pickaxe.svg',
		description: 'Complete one million mining actions.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: "Mine 'em All!",
				description: 'Mine 1,000,000 times.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 50,
					},
					{
						trigger: 'location',
						targetID: 51,
					},
					{
						trigger: 'location',
						targetID: 52,
					},
					{
						trigger: 'location',
						targetID: 53,
					},
					{
						trigger: 'location',
						targetID: 54,
					},
					{
						trigger: 'location',
						targetID: 55,
					},
					{
						trigger: 'location',
						targetID: 56,
					},
					{
						trigger: 'location',
						targetID: 57,
					},
					{
						trigger: 'location',
						targetID: 80,
					},
				],
				targetAmount: 1000000,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	6002: {
		id: 6002,
		name: 'Castle',
		questIcon: '/images/mining/stone.png',
		description: 'Gather one million Stone.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Castle',
				description: 'Gather one million Stone.',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 109,
					},
				],
				targetAmount: 1000000,
			},
		],
		questRewards: {
			achievementPoints: 3,
		},
		achievement: true,
	},
	6003: {
		id: 6003,
		name: 'Master of the Shrimp',
		questIcon: '/images/fishing/raw_shrimp.png',
		description: 'Gather 100,000 Raw Shrimp to please the Shrimp God.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Shrimp',
				description: 'SHRIMP',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 60,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	6004: {
		id: 6004,
		name: 'Clay-More',
		questIcon: '/images/mining/clay.png',
		description: 'Gather 1,000 Clay, preferably from the Clay Pit.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Gather 1,000 Clay',
				description: 'The best place to get Clay is the Clay Pit, duh!',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 108,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	6005: {
		id: 6005,
		name: "It's Definitely Spelled 'Mellon'",
		questIcon: '/images/cooking/alchemy/ingredients/watermelon.png',
		description: 'Collect 100,000 watermelons.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'MELLONS',
				description: 'Collect 100,000 watermelons.',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 3004,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 4,
		},
		achievement: true,
	},
	6006: {
		id: 6006,
		name: 'Destroying History',
		questIcon: '/images/foraging/elder_log.png',
		description: 'Collect 100,000 Elder Logs from Foraging.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Elder Logs',
				description: 'Collect 100,000 Elder Logs. They grow back!',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 307,
					},
				],
				targetAmount: 100000,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	6007: {
		id: 6007,
		name: 'Feeling Lucky?',
		questIcon: '/images/cooking/alchemy/ingredients/lucky_clover.png',
		description: 'Collect 1,000 Four-leaf Clovers.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Only the ones with four leaves!',
				description: 'Collect 1,000 Four-leaf Clovers.',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 3017,
					},
				],
				targetAmount: 1000,
			},
		],
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	6008: {
		id: 6008,
		name: 'Runic Spires',
		questIcon: '/images/runecrafting/rune_slate.png',
		description: 'Gather 1,000,000 Rune Slates, preferably from the Slate Spires.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [106],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Gather 1,000,000 Rune Slates',
				description: 'I wonder where the best place to get Rune Slates is...',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 113,
					},
				],
				targetAmount: 1000000,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	6009: {
		id: 6009,
		name: 'Distilled Dusty Deceptions',
		questIcon: '/images/cooking/alchemy/ingredients/fairy_dust.png',
		description: 'You really need to get away from this stuff. It makes you see things ...',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [2000],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Gather 50,000 Fairy Dust',
				description: 'Gather 50,000 Fairy Dust',
				validTargets: [
					{
						trigger: 'gather',
						targetID: 3015,
					},
				],
				targetAmount: 50000,
			},
		],
		questRewards: {
			achievementPoints: 3,
			items: [
				{
					id: 50,
					amount: 1,
					extra: {
						storyID: 100,
					},
				},
			],
		},
		achievement: true,
	},
	7001: {
		id: 7001,
		name: 'Legendary Roll',
		questIcon: '/images/magic/buffs/chances-icon.png',
		description: 'Get perfect rolls for every affix slot on a Legendary item on the first try.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2004],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Perfect',
				description: 'Get perfect rolls for every affix slot on a Legendary item on the first try.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	7002: {
		id: 7002,
		name: 'Legendary Re-Rolls',
		questIcon: '/images/fishing/chance_icon.png',
		description: 'Get the perfect affix rolls on a Legendary rarity item.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [2004],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Perfect',
				description: 'Get the perfect affix rolls on a Legendary rarity item.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
		},
		achievement: true,
	},
	7003: {
		id: 7003,
		name: 'Prismatic Power',
		questIcon: '/images/jewellery/prismatic_necklace.png',
		description:
			'Transform two Prismatic jewelry pieces. I would recommend trying to get both a necklace and ring, but any combination will do.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2003],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Prismatic Power',
				description: 'Transform two Prismatic jewelry pieces.',
				validTargets: [
					{
						trigger: 'transform',
						targetID: 628,
					},
					{
						trigger: 'transform',
						targetID: 629,
					},
				],
				targetAmount: 2,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [2052],
		},
		achievement: true,
	},
	7004: {
		id: 7004,
		name: 'Pity Prismatic',
		questIcon: '/images/jewellery/prismatic_ring.png',
		description: 'Craft a Prismatic jewelry piece from the pity dust.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [2003],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Pity Prismatic',
				description: 'Craft a Prismatic jewelry piece from pity dust.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 628,
					},
					{
						trigger: 'craft',
						targetID: 629,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
		},
		achievement: true,
	},
	7005: {
		id: 7005,
		name: 'On the Rainbow Road',
		questIcon: '/images/cooking/alchemy/rainbow_pot.png',
		description: 'Augment any item to +25.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2003],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'On the Rainbow Road',
				description: 'Augment any item to +25.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	7006: {
		id: 7006,
		name: 'Burning with Enthusiasm',
		questIcon: '/images/heat_icon.png',
		description: 'In a single burn action, gain 1,000,000 heat.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [2001],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Just a little warm',
				description: 'Gain 1,000,000 heat in one burn action from your inventory.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	7007: {
		id: 7007,
		name: 'BURN IT ALL',
		questIcon: '/images/heat_icon.png',
		description: 'In a single burn action, gain 1,000,000,000 heat.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2001],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Way too warm',
				description: 'Gain 1,000,000,000 heat in one burn action from your inventory.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
	7008: {
		id: 7008,
		name: 'Smithing Grandmaster',
		questIcon: '/images/smithing/city_forge.png',
		description: 'Smelt a larger number of bars. Better bars are give more progress!',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [219],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Smelt Bars',
				description: 'Smelt a lot of bars. Better bars give more progress!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 201,
					},
					{
						trigger: 'craft',
						targetID: 202,
						progressMult: 2,
					},
					{
						trigger: 'craft',
						targetID: 203,
						progressMult: 3,
					},
					{
						trigger: 'craft',
						targetID: 204,
						progressMult: 4,
					},
					{
						trigger: 'craft',
						targetID: 205,
						progressMult: 5,
					},
					{
						trigger: 'craft',
						targetID: 206,
						progressMult: 7,
					},
					{
						trigger: 'craft',
						targetID: 207,
						progressMult: 10,
					},
					{
						trigger: 'craft',
						targetID: 208,
						progressMult: 20,
					},
					{
						trigger: 'craft',
						targetID: 9029,
						progressMult: 500,
					},
				],
				targetAmount: 1000000,
			},
		],
		questRewards: {
			achievementPoints: 4,
			relatedTalent: [2045],
			achievementsToAdd: [7009],
		},
		achievement: true,
	},
	7009: {
		id: 7009,
		name: 'Smithing Legend',
		questIcon: '/images/smithing/city_forge.png',
		description: 'Smelt a larger number of bars. Better bars give more progress!',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [7008],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Smelt Bars',
				description: 'Smelt a lot of bars. Better bars give more progress!',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 201,
					},
					{
						trigger: 'craft',
						targetID: 202,
						progressMult: 2,
					},
					{
						trigger: 'craft',
						targetID: 203,
						progressMult: 3,
					},
					{
						trigger: 'craft',
						targetID: 204,
						progressMult: 4,
					},
					{
						trigger: 'craft',
						targetID: 205,
						progressMult: 5,
					},
					{
						trigger: 'craft',
						targetID: 206,
						progressMult: 7,
					},
					{
						trigger: 'craft',
						targetID: 207,
						progressMult: 10,
					},
					{
						trigger: 'craft',
						targetID: 208,
						progressMult: 20,
					},
					{
						trigger: 'craft',
						targetID: 9029,
						progressMult: 500,
					},
				],
				targetAmount: 3000000,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [2046],
		},
		achievement: true,
	},
	7010: {
		id: 7010,
		name: 'Cooking Grandmaster',
		questIcon: '/images/cooking/equipment/cooking_hat.png',
		description: 'Cook or prepare a large number of meals.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [221],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Cook Meals',
				description: 'Cooking full meals gives more progress than preparing ingredients.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 7,
						progressMult: 3,
					},
					{
						trigger: 'location',
						targetID: 10,
					},
				],
				targetAmount: 2500000,
			},
		],
		questRewards: {
			achievementPoints: 4,
			relatedTalent: [2047],
			achievementsToAdd: [7011],
		},
		achievement: true,
	},
	7011: {
		id: 7011,
		name: 'Cooking Legend',
		questIcon: '/images/cooking/equipment/cooking_hat.png',
		description: 'Cook or prepare a large number of meals.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [7010],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Cook Meals',
				description: 'Cooking full meals gives more progress than preparing ingredients.',
				validTargets: [
					{
						trigger: 'location',
						targetID: 7,
						progressMult: 3,
					},
					{
						trigger: 'location',
						targetID: 10,
					},
				],
				targetAmount: 10000000,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [2048],
		},
		achievement: true,
	},
	7012: {
		id: 7012,
		name: 'Do Ya Membah',
		questIcon: '/images/farming/sageberry_seed.png',
		description: 'Plant or Harvest 10000 Sageberry Bushes.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2001],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Sageberry Farming',
				description: 'Plant or Harvest 10000 Sageberry Bushes.',
				validTargets: [
					{
						trigger: 'plant',
						targetID: 180,
					},
					{
						trigger: 'farm',
						targetID: 180,
					},
				],
				targetAmount: 10000,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [2059],
		},
		achievement: true,
	},
	7013: {
		id: 7013,
		name: 'Slow Burn',
		questIcon: '/images/heat_icon.png',
		description: 'Gain 2,000,000,000 heat total through inventory burn actions.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [2001],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Getting hot in here',
				description:
					'Gain 2,000,000,000 heat, can be multiple inventory burn actions (not embers or heat stones).',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 2000000000,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [2065],
		},
		achievement: true,
	},
	8001: {
		id: 8001,
		name: 'Idling Like a Champ',
		questIcon: '/images/pontification/skill_waiting_icon.png',
		description: 'Be offline for 12 hours straight.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Idling Like a Champ',
				description: 'Be offline for 12 hours straight.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			relatedTalent: [2061],
		},
		achievement: true,
		achievementStandalone: true,
	},
	10001: {
		id: 10001,
		name: 'Winter 2024 Quest',
		questIcon: '/images/christmas/snow_essence.png',
		description: 'Learn how to be festive!',
		difficulty: 'Easy',
		questRestrictions: {
			questStartDate: '2024-12-01T00:00:00Z',
			questEndDate: '2025-01-31T23:59:59Z',
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Making a Snowball',
				description:
					'Craft a snowball! During Winter snow can be found anywhere, doing almost anything, and can then be crafted under the Crafting Event category.',
				validTargets: [
					{
						trigger: 'craft',
						targetID: 7001,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 2,
				name: 'Throwing Snowballs',
				description:
					'Throw the snowball! You can do this by using the /throw command in chat. You can use /throw [name] to throw it at a specific person, or use /throw [name] large to use a special large snowball. The large snowballs can be created by Researching normal snowballs.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 3,
				name: 'Snowball Fight Alerts',
				description:
					'The Guard will notify you about impending Snowball fights if you ask them to! Go to your Settings, Chat, and toggle Chat Event Ping at the very bottom.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
			{
				stepID: 4,
				name: 'The Spoils of War',
				description:
					'After a snowball fight all participants will receive a reward! Doing well in the fight gets you extra loot.',
				validTargets: [
					{
						trigger: 'custom',
						targetID: -1,
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			items: [
				{
					id: 7000,
					amount: 2000,
				},
				{
					id: 7042,
					amount: 3,
				},
				{
					id: 7043,
					amount: 1,
				},
				{
					id: 6950,
					amount: 5000,
				},
			],
		},
	},
	11001: {
		id: 11001,
		name: 'Level 99',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach level 99 in any skill. Almost there!',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Level 99',
				description: 'Reach level 99 in any skill. Just one off?',
				validTargets: [
					{
						trigger: 'level',
						targetID: 99,
						targetOptions: {
							level: {
								mastery: false,
								skill: 'any',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11002],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11002: {
		id: 11002,
		name: 'Mastery 5',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach Mastery level 5 in any skill.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [11001],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mastery 5',
				description: 'Reach Mastery 5 in any skill.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 5,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'any',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11003],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11003: {
		id: 11003,
		name: 'Mastery 10',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach Mastery level 10 in any skill.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11002],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mastery 10',
				description: 'Reach Mastery 10 in any skill.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'any',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11004],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11004: {
		id: 11004,
		name: 'Mastery 20',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach Mastery level 20 in any skill.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11003],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mastery 20',
				description: 'Reach Mastery 20 in any skill.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 20,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'any',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			achievementsToAdd: [11005],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11005: {
		id: 11005,
		name: 'Mastery 35',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach Mastery level 35 in any skill.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [11004],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mastery 35',
				description: 'Reach Mastery 35 in any skill.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 35,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'any',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 4,
			achievementsToAdd: [11006],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11006: {
		id: 11006,
		name: 'Mastery 50',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach Mastery level 50 in any skill.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [11005],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mastery 50',
				description: 'Reach Mastery 50 in any skill.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'any',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11007: {
		id: 11007,
		name: 'Total Mastery 100',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach a total Mastery level of 100.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Total Mastery 100',
				description: 'Reach a total Mastery level of 100.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 100,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'total',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11008],
			relatedTalent: [2078],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11008: {
		id: 11008,
		name: 'Total Mastery 200',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach a total Mastery level of 200.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11007],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Total Mastery 200',
				description: 'Reach a total Mastery level of 200.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 200,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'total',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11009],
			relatedTalent: [2079],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11009: {
		id: 11009,
		name: 'Total Mastery 300',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach a total Mastery level of 300.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11008],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Total Mastery 300',
				description: 'Reach a total Mastery level of 300.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 300,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'total',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			achievementsToAdd: [11010],
			relatedTalent: [2080],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11010: {
		id: 11010,
		name: 'Total Mastery 400',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach a total Mastery level of 400.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [11009],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Total Mastery 400',
				description: 'Reach a total Mastery level of 400.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 400,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'total',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 4,
			achievementsToAdd: [11011],
			relatedTalent: [2081],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11011: {
		id: 11011,
		name: 'Total Mastery 500',
		questIcon: '/images/total_level_mastery_icon.png',
		description: 'Reach a total Mastery level of 500.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [11010],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Total Mastery 500',
				description: 'Reach a total Mastery level of 500.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 500,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'total',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 5,
			relatedTalent: [2082],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11012: {
		id: 11012,
		name: 'Mining Mastery 10',
		questIcon: '/images/mining/iron_pickaxe.svg',
		description: 'Reach Mastery level 10 in mining.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Mining Mastery 10',
				description: 'Reach Mastery level 10 in mining.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'mining',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11013],
			relatedTalent: [31],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11013: {
		id: 11013,
		name: 'Mining Mastery 25',
		questIcon: '/images/mining/iron_pickaxe.svg',
		description: 'Reach Mastery level 25 in mining.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11012],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mining Mastery 25',
				description: 'Reach Mastery level 25 in mining.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'mining',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11014],
			relatedTalent: [32],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11014: {
		id: 11014,
		name: 'Mining Mastery 50',
		questIcon: '/images/mining/iron_pickaxe.svg',
		description: 'Reach Mastery level 50 in mining.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11013],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mining Mastery 50',
				description: 'Reach Mastery level 50 in mining.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'mining',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [33],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11015: {
		id: 11015,
		name: 'Foraging Mastery 10',
		questIcon: '/images/foraging/foraging_icon.png',
		description: 'Reach Mastery level 10 in foraging.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Foraging Mastery 10',
				description: 'Reach Mastery level 10 in foraging.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'foraging',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11016],
			relatedTalent: [34],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11016: {
		id: 11016,
		name: 'Foraging Mastery 25',
		questIcon: '/images/foraging/foraging_icon.png',
		description: 'Reach Mastery level 25 in foraging.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11015],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Mining Mastery 25',
				description: 'Reach Mastery level 25 in foraging.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'foraging',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11017],
			relatedTalent: [35],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11017: {
		id: 11017,
		name: 'Foraging Mastery 50',
		questIcon: '/images/foraging/foraging_icon.png',
		description: 'Reach Mastery level 50 in foraging.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11016],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Foraging Mastery 50',
				description: 'Reach Mastery level 50 in foraging.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'foraging',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [36],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11018: {
		id: 11018,
		name: 'Fishing Mastery 10',
		questIcon: '/images/fishing/fishing_logo.png',
		description: 'Reach Mastery level 10 in fishing.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Fishing Mastery 10',
				description: 'Reach Mastery level 10 in fishing.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'fishing',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11019],
			relatedTalent: [37],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11019: {
		id: 11019,
		name: 'Fishing Mastery 25',
		questIcon: '/images/fishing/fishing_logo.png',
		description: 'Reach Mastery level 25 in fishing.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11018],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Fishing Mastery 25',
				description: 'Reach Mastery level 25 in fishing.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'fishing',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11020],
			relatedTalent: [38],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11020: {
		id: 11020,
		name: 'Fishing Mastery 50',
		questIcon: '/images/fishing/fishing_logo.png',
		description: 'Reach Mastery level 50 in fishing.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11019],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Fishing Mastery 50',
				description: 'Reach Mastery level 50 in fishing.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'fishing',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [39],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11021: {
		id: 11021,
		name: 'Farming Mastery 10',
		questIcon: '/images/farming/farming_icon.png',
		description: 'Reach Mastery level 10 in farming.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Farming Mastery 10',
				description: 'Reach Mastery level 10 in farming.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'farming',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11022],
			relatedTalent: [2083],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11022: {
		id: 11022,
		name: 'Farming Mastery 25',
		questIcon: '/images/farming/farming_icon.png',
		description: 'Reach Mastery level 25 in farming.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11021],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Farming Mastery 25',
				description: 'Reach Mastery level 25 in farming.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'farming',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11023],
			relatedTalent: [2084],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11023: {
		id: 11023,
		name: 'Farming Mastery 50',
		questIcon: '/images/farming/farming_icon.png',
		description: 'Reach Mastery level 50 in farming.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11022],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Farming Mastery 50',
				description: 'Reach Mastery level 50 in farming.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'farming',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2085],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11024: {
		id: 11024,
		name: 'Enchanting Mastery 10',
		questIcon: '/images/enchanting/enchanting_logo.png',
		description: 'Reach Mastery level 10 in enchanting.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Enchanting Mastery 10',
				description: 'Reach Mastery level 10 in enchanting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'enchanting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11025],
			relatedTalent: [2086],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11025: {
		id: 11025,
		name: 'Enchanting Mastery 25',
		questIcon: '/images/enchanting/enchanting_logo.png',
		description: 'Reach Mastery level 25 in enchanting.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11024],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Enchanting Mastery 25',
				description: 'Reach Mastery level 25 in enchanting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'enchanting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11026],
			relatedTalent: [2087],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11026: {
		id: 11026,
		name: 'Enchanting Mastery 50',
		questIcon: '/images/enchanting/enchanting_logo.png',
		description: 'Reach Mastery level 50 in enchanting.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11025],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Enchanting Mastery 50',
				description: 'Reach Mastery level 50 in enchanting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'enchanting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2088],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11027: {
		id: 11027,
		name: 'Runecrafting Mastery 10',
		questIcon: '/images/runecrafting/RuneCraftingIcon.png',
		description: 'Reach Mastery level 10 in runecrafting.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Runecrafting Mastery 10',
				description: 'Reach Mastery level 10 in runecrafting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'runecrafting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11028],
			relatedTalent: [2089],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11028: {
		id: 11028,
		name: 'Runecrafting Mastery 25',
		questIcon: '/images/runecrafting/RuneCraftingIcon.png',
		description: 'Reach Mastery level 25 in runecrafting.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11027],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Runecrafting Mastery 25',
				description: 'Reach Mastery level 25 in runecrafting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'runecrafting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11029],
			relatedTalent: [2090],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11029: {
		id: 11029,
		name: 'Runecrafting Mastery 50',
		questIcon: '/images/runecrafting/RuneCraftingIcon.png',
		description: 'Reach Mastery level 50 in runecrafting.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11028],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Runecrafting Mastery 50',
				description: 'Reach Mastery level 50 in runecrafting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'runecrafting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2091],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11030: {
		id: 11030,
		name: 'Smithing Mastery 10',
		questIcon: '/images/smithing/smithing_icon.png',
		description: 'Reach Mastery level 10 in smithing.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Smithing Mastery 10',
				description: 'Reach Mastery level 10 in smithing.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'smithing',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11031],
			relatedTalent: [2092],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11031: {
		id: 11031,
		name: 'Smithing Mastery 25',
		questIcon: '/images/smithing/smithing_icon.png',
		description: 'Reach Mastery level 25 in smithing.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11030],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Smithing Mastery 25',
				description: 'Reach Mastery level 25 in smithing.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'smithing',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11032],
			relatedTalent: [2093],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11032: {
		id: 11032,
		name: 'Smithing Mastery 50',
		questIcon: '/images/smithing/smithing_icon.png',
		description: 'Reach Mastery level 50 in smithing.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11031],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Smithing Mastery 50',
				description: 'Reach Mastery level 50 in smithing.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'smithing',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2094],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11033: {
		id: 11033,
		name: 'Crafting Mastery 10',
		questIcon: '/images/ui/crafting_icon.png',
		description: 'Reach Mastery level 10 in crafting.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Crafting Mastery 10',
				description: 'Reach Mastery level 10 in crafting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'crafting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11034],
			relatedTalent: [2095],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11034: {
		id: 11034,
		name: 'Crafting Mastery 25',
		questIcon: '/images/ui/crafting_icon.png',
		description: 'Reach Mastery level 25 in crafting.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11033],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Crafting Mastery 25',
				description: 'Reach Mastery level 25 in crafting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'crafting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11035],
			relatedTalent: [2096],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11035: {
		id: 11035,
		name: 'Crafting Mastery 50',
		questIcon: '/images/ui/crafting_icon.png',
		description: 'Reach Mastery level 50 in crafting.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11034],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Crafting Mastery 50',
				description: 'Reach Mastery level 50 in crafting.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'crafting',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2097],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11036: {
		id: 11036,
		name: 'Constitution Mastery 10',
		questIcon: '/images/combat/constitution_icon.png',
		description: 'Reach Mastery level 10 in constitution.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Constitution Mastery 10',
				description: 'Reach Mastery level 10 in constitution.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'constitution',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11037],
			relatedTalent: [1100],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11037: {
		id: 11037,
		name: 'Constitution Mastery 25',
		questIcon: '/images/combat/constitution_icon.png',
		description: 'Reach Mastery level 25 in constitution.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11036],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Constitution Mastery 25',
				description: 'Reach Mastery level 25 in constitution.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'constitution',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11038],
			relatedTalent: [1101],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11038: {
		id: 11038,
		name: 'Constitution Mastery 50',
		questIcon: '/images/combat/constitution_icon.png',
		description: 'Reach Mastery level 50 in constitution.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11037],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Constitution Mastery 50',
				description: 'Reach Mastery level 50 in constitution.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'constitution',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1102],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11039: {
		id: 11039,
		name: 'Attack Mastery 10',
		questIcon: '/images/combat/attack_icon.png',
		description: 'Reach Mastery level 10 in attack.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Attack Mastery 10',
				description: 'Reach Mastery level 10 in attack.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'attack',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11040],
			relatedTalent: [1103],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11040: {
		id: 11040,
		name: 'Attack Mastery 25',
		questIcon: '/images/combat/attack_icon.png',
		description: 'Reach Mastery level 25 in attack.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11039],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Attack Mastery 25',
				description: 'Reach Mastery level 25 in attack.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'attack',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11041],
			relatedTalent: [1104],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11041: {
		id: 11041,
		name: 'Attack Mastery 50',
		questIcon: '/images/combat/attack_icon.png',
		description: 'Reach Mastery level 50 in attack.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11040],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Attack Mastery 50',
				description: 'Reach Mastery level 50 in attack.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'attack',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1105],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11042: {
		id: 11042,
		name: 'Defense Mastery 10',
		questIcon: '/images/combat/defense_icon.png',
		description: 'Reach Mastery level 10 in defense.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Defense Mastery 10',
				description: 'Reach Mastery level 10 in defense.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'defense',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11043],
			relatedTalent: [1106],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11043: {
		id: 11043,
		name: 'Defense Mastery 25',
		questIcon: '/images/combat/defense_icon.png',
		description: 'Reach Mastery level 25 in defense.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11042],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Defense Mastery 25',
				description: 'Reach Mastery level 25 in defense.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'defense',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11044],
			relatedTalent: [1107],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11044: {
		id: 11044,
		name: 'Defense Mastery 50',
		questIcon: '/images/combat/defense_icon.png',
		description: 'Reach Mastery level 50 in defense.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11043],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Defense Mastery 50',
				description: 'Reach Mastery level 50 in defense.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'defense',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1108],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11045: {
		id: 11045,
		name: 'Strength Mastery 10',
		questIcon: '/images/combat/strength_icon.png',
		description: 'Reach Mastery level 10 in strength.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Strength Mastery 10',
				description: 'Reach Mastery level 10 in strength.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'strength',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11046],
			relatedTalent: [1109],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11046: {
		id: 11046,
		name: 'Strength Mastery 25',
		questIcon: '/images/combat/strength_icon.png',
		description: 'Reach Mastery level 25 in strength.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11045],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Strength Mastery 25',
				description: 'Reach Mastery level 25 in strength.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'strength',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11047],
			relatedTalent: [1110],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11047: {
		id: 11047,
		name: 'Strength Mastery 50',
		questIcon: '/images/combat/strength_icon.png',
		description: 'Reach Mastery level 50 in strength.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11046],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Strength Mastery 50',
				description: 'Reach Mastery level 50 in strength.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'strength',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1111],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11048: {
		id: 11048,
		name: 'Magic Mastery 10',
		questIcon: '/images/magic/magic_logo.png',
		description: 'Reach Mastery level 10 in magic.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Magic Mastery 10',
				description: 'Reach Mastery level 10 in magic.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'magic',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11049],
			relatedTalent: [1112],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11049: {
		id: 11049,
		name: 'Magic Mastery 25',
		questIcon: '/images/magic/magic_logo.png',
		description: 'Reach Mastery level 25 in magic.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11048],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Magic Mastery 25',
				description: 'Reach Mastery level 25 in magic.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'magic',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11050],
			relatedTalent: [1113],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11050: {
		id: 11050,
		name: 'Magic Mastery 50',
		questIcon: '/images/magic/magic_logo.png',
		description: 'Reach Mastery level 50 in magic.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11049],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Magic Mastery 50',
				description: 'Reach Mastery level 50 in magic.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'magic',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1114],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11051: {
		id: 11051,
		name: 'Range Mastery 10',
		questIcon: '/images/combat/range_icon.png',
		description: 'Reach Mastery level 10 in range.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Range Mastery 10',
				description: 'Reach Mastery level 10 in range.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'range',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11052],
			relatedTalent: [1115],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11052: {
		id: 11052,
		name: 'Range Mastery 25',
		questIcon: '/images/combat/range_icon.png',
		description: 'Reach Mastery level 25 in range.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11051],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Range Mastery 25',
				description: 'Reach Mastery level 25 in range.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'range',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11053],
			relatedTalent: [1116],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11053: {
		id: 11053,
		name: 'Range Mastery 50',
		questIcon: '/images/combat/range_icon.png',
		description: 'Reach Mastery level 50 in range.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11052],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Range Mastery 50',
				description: 'Reach Mastery level 50 in range.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'range',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [1117],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11054: {
		id: 11054,
		name: 'Cooking Mastery 10',
		questIcon: '/images/cooking/cooking_icon.png',
		description: 'Reach Mastery level 10 in cooking.',
		difficulty: 'Easy',
		questSteps: [
			{
				stepID: 1,
				name: 'Cooking Mastery 10',
				description: 'Reach Mastery level 10 in cooking.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 10,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'cooking',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 1,
			achievementsToAdd: [11055],
			relatedTalent: [2098],
		},
		achievement: true,
		achievementStandalone: true,
		achievementCategory: 'Leveling',
	},
	11055: {
		id: 11055,
		name: 'Cooking Mastery 25',
		questIcon: '/images/cooking/cooking_icon.png',
		description: 'Reach Mastery level 25 in cooking.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [11054],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Cooking Mastery 25',
				description: 'Reach Mastery level 25 in cooking.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 25,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'cooking',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 2,
			achievementsToAdd: [11056],
			relatedTalent: [2099],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11056: {
		id: 11056,
		name: 'Cooking Mastery 50',
		questIcon: '/images/cooking/cooking_icon.png',
		description: 'Reach Mastery level 50 in cooking.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [11055],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Cooking Mastery 50',
				description: 'Reach Mastery level 50 in cooking.',
				validTargets: [
					{
						trigger: 'level',
						targetID: 50,
						targetOptions: {
							level: {
								mastery: true,
								skill: 'cooking',
							},
						},
					},
				],
				targetAmount: 1,
			},
		],
		questRewards: {
			achievementPoints: 3,
			relatedTalent: [2100],
		},
		achievement: true,
		achievementCategory: 'Leveling',
	},
	11057: {
		id: 11057,
		name: 'Toucan Enthusiast',
		description: 'Donate 10 million gold at once to the Shrine.',
		difficulty: 'Easy',
		questRequirements: {
			requiredQuests: [5],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Donate to the Shrine',
				description: 'Donate 10 million gold at once to the Shrine.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Donate',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'shrine',
				},
			},
		],
		questIcon: '/images/misc/shrine_icon.png',
		questRewards: {
			achievementPoints: 1,
		},
		achievement: true,
	},
	11058: {
		id: 11058,
		name: 'Toucan Initiate',
		description: 'Donate 100 million gold at once to the Shrine.',
		difficulty: 'Medium',
		questRequirements: {
			requiredQuests: [5],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Donate to the Shrine',
				description: 'Donate 100 million gold at once to the Shrine.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Donate',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'shrine',
				},
			},
		],
		questIcon: '/images/misc/shrine_icon.png',
		questRewards: {
			achievementPoints: 2,
		},
		achievement: true,
	},
	11059: {
		id: 11059,
		name: 'Toucan Disciple',
		description: 'Donate 1 billion gold at once to the Shrine.',
		difficulty: 'Hard',
		questRequirements: {
			requiredQuests: [5],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Donate to the Shrine',
				description: 'Donate 1 billion gold at once to the Shrine.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Donate',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'shrine',
				},
			},
		],
		questIcon: '/images/misc/shrine_icon.png',
		questRewards: {
			achievementPoints: 3,
		},
		achievement: true,
	},
	11060: {
		id: 11060,
		name: 'Toucan Priest',
		description: 'Donate 10 billion gold at once to the Shrine.',
		difficulty: 'Elite',
		questRequirements: {
			requiredQuests: [5],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Donate to the Shrine',
				description: 'Donate 10 billion gold at once to the Shrine.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Donate',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'shrine',
				},
			},
		],
		questIcon: '/images/misc/shrine_icon.png',
		questRewards: {
			achievementPoints: 4,
		},
		achievement: true,
	},
	11061: {
		id: 11061,
		name: 'Toucan Prophet',
		description: 'Donate 100 billion gold at once to the Shrine.',
		difficulty: 'Master',
		questRequirements: {
			requiredQuests: [5],
		},
		questSteps: [
			{
				stepID: 1,
				name: 'Donate to the Shrine',
				description: 'Donate 100 billion gold at once to the Shrine.',
				validTargets: [
					{
						trigger: 'custom',
						triggerString: 'Donate',
						targetID: -1,
					},
				],
				targetAmount: 1,
				link: {
					page: 'shrine',
				},
			},
		],
		questIcon: '/images/misc/shrine_icon.png',
		questRewards: {
			achievementPoints: 5,
		},
		achievement: true,
	},
};
