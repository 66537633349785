import { Flex, Heading, Image, List, Text } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import { useEffect, useState } from 'react';
import { usePlayerField } from '../../../../hooks/hooks';
import { socket } from '../../../../services/socket.service';
import { PLATINUM_PER_MONTH_FROM_PLUS } from '../../../../utils/constantsCollection';
import { itemList } from '../../../../utils/itemList';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import ChatItem from '../../Chat/Links/ChatItem';

export const PlatinumShopIdlescapePlus = () => {
	//Not sure why this isn't used instead
	//return <PlatinumShopPurchaseReview isSubscription={true} />;
	const [loading, setLoading] = useState(false);
	const subscription = usePlayerField('subscription');

	const presentData = itemList[itemsIds.present];

	function handleSubscription() {
		setLoading(true);
		if (subscription.active) {
			socket.emit('monetization:idlescape-plus:manage');
			return;
		}
		socket.emit('monetization:purchase:idlescape-plus');
		return;
	}

	useEffect(() => {
		const stopLoading = () => {
			setLoading(false);
		};
		socket.on('monetization:checkout-url', stopLoading);

		return () => {
			socket.off('monetization:checkout-url', stopLoading);
		};
	}, []);

	const Loader = () => {
		return (
			<div className='lds-ring'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		);
	};

	return (
		<IdlescapeContainer variant='platinum' maxWidth='600px' margin='auto' justifyContent='center'>
			<Heading
				fontSize='48px'
				fontWeight='unset'
				textShadow='0 0 10px #fff, 0 0 20px #fff, 0 0 30px rgb(54 188 250 / 80%), 0 0 40px rgb(54 188 250 / 80%), 0 0 50px rgb(54 188 250 / 80%), 0 0 60px rgb(54 188 250 / 80%), 0 0 70px #e60073'
				marginTop='0'
				marginBottom='20px'
				width='100%'
				textAlign='center'
			>
				Idlescape Plus {subscription.active ? 'Active' : ''}
			</Heading>
			<Image float='left' width='40%' src={'/images/premium/premium_icon.svg'} />
			<div className='platinum-shop-purchase-review-info'>
				<span>Idlescape Plus</span> will give you quality of life improvements in the game through a monthly
				subscription
				<br />
				<span>Idlescape Plus</span> includes:
				<List paddingLeft={0}>
					<li>
						{PLATINUM_PER_MONTH_FROM_PLUS} <ChatItem item={{ itemID: 20, name: 'Platinum' }}></ChatItem>{' '}
						every month
					</li>
					<li>+1 Active Character</li>
					<li>+5 Character Slots</li>
					<li>+5 Loadout Slots</li>
					<li>+3 Action Queue Count</li>
					<li>+4 Profile Slots</li>
					<li>Live Combat Instance Stats</li>
					<li>
						<img
							className='chat-skill-icon'
							src='/images/premium/premium_icon.svg'
							alt='Idlescape Plus Chat Badge'
						/>{' '}
						Idlescape Plus Chat Badge
					</li>
					<li>Idlescape Plus Chat Channel</li>
					<li>Offline Time Increased from 12h to 24h</li>
					{/* <li>
				One <ChatItem item={{ itemID: 1, name: 'Idle Token' }}></ChatItem> Idle Token Every
				Month
			</li> */}
				</List>
			</div>
			<Flex flexDirection='column'>
				{subscription.giftedByName && subscription.active ? (
					<>
						<IdlescapeButton position='relative' margin='10px auto' variant='disabled' size='large'>
							<Image src={presentData.itemImage} alt={presentData.name} height='70%' marginRight='3px' />
							Active gift sub
						</IdlescapeButton>
						<Text textAlign='center' marginTop='5px'>
							{subscription.giftedByName} gifted you a gift sub. You cannot manage your subscription right
							now.
						</Text>
					</>
				) : (
					<IdlescapeButton
						onClick={() => handleSubscription()}
						margin='10px auto'
						variant='blue'
						size='large'
					>
						{loading ? (
							<Loader />
						) : subscription.active ? (
							'Manage My Subscription'
						) : (
							'Subscribe €4.95/month'
						)}
					</IdlescapeButton>
				)}
			</Flex>
		</IdlescapeContainer>
	);
};
