import { Box, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeContainer } from '@idlescape/ui';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { IItem, TPartialItemWithItemID } from '../../../../../../../game-server/src/modules/items/items.interface';
import { socket } from '../../../../../services/socket.service';
import { getAffixStrength } from '../../../../../utils/affixFunctions';
import { affixList } from '../../../../../utils/affixList';
import { itemList } from '../../../../../utils/itemList';
import FakeItem from '../../../Inventory/FakeItem';
import { TooltipLiterals } from '../../../Tooltips/Data/TooltipLiterals';
import { formatEquipmentAffixStrength } from '../AffixTooltip';

export default function AffixRerollPicker({
	affixRerollSelected,
	affixRerollOption,
}: {
	affixRerollSelected: IItem;
	affixRerollOption: TPartialItemWithItemID<IItem>;
}) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selected, setSelected] = useState(null as boolean | null);
	const itemData = itemList[affixRerollSelected.itemID];

	function confirmSelection(selected: boolean) {
		onOpen();
		setSelected(selected);
	}

	function selection() {
		if (selected) {
			// Clear the rolls
			socket.emit('runecrafting:takeOldAffixed');
		} else {
			// Select the new rolls
			socket.emit('runecrafting:takeNewAffixed');
		}
		setSelected(null);
		onClose();
	}

	return (
		<IdlescapeContainer>
			<Heading as='h1' textAlign='center'>
				Reroll Selection
			</Heading>
			<Box placeItems='center' width='100%'>
				<IdlescapeContainer width='100%' textAlign='center'>
					<Flex margin='auto' alignItems='center' justifyContent='center'>
						<div className={'enchanting-window-item'}>
							<FakeItem item={affixRerollSelected} />
						</div>
					</Flex>
					<Heading textAlign='center'>Rolls</Heading>
					{affixRerollSelected.affixes?.map((affix, index) => {
						const affixData = affixList[affix.path];
						const foundOption = affixRerollOption.affixes?.find((a) => a.path === affix.path);
						if (!foundOption) return;
						const oldValue = getAffixStrength(affixData, affix.value, itemData);
						const newValue = getAffixStrength(affixData, foundOption.value, itemData);
						let color = 'white';
						if (oldValue < newValue) {
							color = 'green';
						} else if (oldValue > newValue) {
							color = 'red';
						}
						return (
							<div key={affix.path}>
								<Text>{TooltipLiterals.affixesLiterals[affix.path]}: </Text>
								<Text>
									{formatEquipmentAffixStrength(
										affixData,
										affix.value,
										affixRerollSelected.itemID || 0
									)}{' '}
									<FaArrowRight />{' '}
									<Text as='span' color={color}>
										{formatEquipmentAffixStrength(
											affixData,
											foundOption.value || 0,
											affixRerollOption.itemID || 0
										)}
									</Text>
								</Text>
							</div>
						);
					})}
					<Box width='100%' alignContent='center'>
						<IdlescapeButton
							size='large'
							variant='purple'
							className='enchanting-glowing-button'
							onClick={() => confirmSelection(true)}
							margin='20px'
						>
							Keep Current
						</IdlescapeButton>
						<IdlescapeButton
							size='large'
							variant='purple'
							className='enchanting-glowing-button'
							onClick={() => confirmSelection(false)}
							margin='20px'
						>
							Use New Rolls
						</IdlescapeButton>
					</Box>
				</IdlescapeContainer>
			</Box>

			<Dialog open={isOpen} onClose={onClose} className='sell-item-dialog'>
				<DialogTitle>Selection Confirmation</DialogTitle>
				<DialogContent>
					<Text>Are you sure you want to select {selected ? 'the old roll' : 'the new roll'}?</Text>
				</DialogContent>
				<DialogActions>
					<IdlescapeButton variant='red' onClick={onClose}>
						Close
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={selection}>
						Confirm
					</IdlescapeButton>
				</DialogActions>
			</Dialog>
		</IdlescapeContainer>
	);
}
