import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tooltip from '../../layout/UI/Tooltip';
import { LegacyTooltip } from './LegacyTooltip';
import { IItem, IItemData, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { ModernTooltip } from './ModernTooltip';
import { BaseTooltip } from './Types/BaseTooltip';
import { itemList } from '../../../utils/itemList';
import { DefaultTooltip } from './Types/DefaultTooltip';
import { EquipmentTooltip } from './Types/EquipmentTooltip';
import { FishingBaitTooltip } from './Types/FishingBaitTooltip';
import { ChestTooltip } from './Types/ChestTooltip';
import { AmmunitionTooltip } from './Types/AmmunitionTooltip';
import { FoodTooltip } from './Types/FoodTooltip';
import { DungeonKeyTooltip } from './Types/DungeonKeyTooltip';
import { EliteScrollTooltip } from './Types/EliteScrollTooltip';
import { SeedTooltip } from './Types/SeedTooltip';
import { BookTooltip } from './Types/BookTooltip';
import { ErrorBoundary } from '../ErrorBoundary';
import { ISettings } from '../../../../../game-server/src/modules/player/PlayerSettings';
import { TSkills } from '../../../../../game-server/src/Atypes/Skills';
import { EnchantmentScrollTooltip } from './Types/EnchantmentScrollTooltip';
import { PopoverPosition } from 'react-tiny-popover';
import { cookingList } from '../../../utils/cookingList';
import { RootState } from '../../../store';
import { TEquipments } from '../../../../../game-server/src/modules/player/PlayerTypes';

interface IItemTooltip {
	item: TPartialItemWithItemID<IItem>;
	equipment: TEquipments;
	skills: TSkills;
	settings: ISettings;
	forceDefaultPositions?: boolean;
	listingDate?: Date; // For market
	embed?: boolean;
}

class ItemTooltip extends Component<IItemTooltip, { tooltipEnabled: boolean }> {
	constructor(props: IItemTooltip) {
		super(props);
		this.disableTooltip = this.disableTooltip.bind(this);
		this.enableTooltip = this.enableTooltip.bind(this);
		this.lazyRender = this.lazyRender.bind(this);
		this.state = { tooltipEnabled: false };
	}

	disableTooltip() {
		this.setState({ tooltipEnabled: false });
	}

	enableTooltip() {
		if (!this.state.tooltipEnabled) this.setState({ tooltipEnabled: true });
	}

	private getTooltipManager(item: TPartialItemWithItemID<IItem>, itemResource: IItemData): BaseTooltip {
		if (!itemResource) return new DefaultTooltip();
		if (itemResource.equipmentStats) return new EquipmentTooltip();
		if (itemResource.fishingBait) return new FishingBaitTooltip();
		if (itemResource.canBeOpened) return new ChestTooltip();
		if (itemResource.tags?.includes('ammunition')) return new AmmunitionTooltip();
		const cookingData = cookingList[itemResource.id];
		if (cookingData || itemResource.healing) return new FoodTooltip();
		if (itemResource.tags?.includes('dungeon')) return new DungeonKeyTooltip();
		if (itemResource.tags?.includes('elite')) return new EliteScrollTooltip();
		if (itemResource.tags?.includes('seed')) return new SeedTooltip();
		if (itemResource.tags?.includes('book')) return new BookTooltip();
		if (itemResource.categories) return new EnchantmentScrollTooltip();
		return new DefaultTooltip();
	}

	private useEquippedComparison() {
		return this.props.settings.tooltips.useEquipComparison;
	}

	private useModernTooltips() {
		return this.props.settings.tooltips.useModernTooltips;
	}

	private useCompactTooltips() {
		return this.props.settings.tooltips.useCompactTooltips;
	}

	lazyRender() {
		const itemResource = itemList[this.props.item.itemID];
		const tooltipManager = this.getTooltipManager(this.props.item, itemResource);
		const equippedItemSameSlot =
			itemResource.equipmentStats?.slot && this.props.equipment[itemResource.equipmentStats.slot];
		const tooltips: React.ReactElement[] = [];
		if (this.useModernTooltips()) {
			tooltips.push(
				<div>
					<ModernTooltip
						item={this.props.item}
						itemResource={itemResource}
						equipment={this.props.equipment}
						tooltipManager={tooltipManager}
						skills={this.props.skills}
						compactVersion={this.useCompactTooltips()}
						listingDate={this.props.listingDate}
					/>
				</div>
			);
			if (this.useEquippedComparison() && equippedItemSameSlot) {
				tooltips.push(
					<div className={'tooltip-compared-item'}>
						<ModernTooltip
							item={equippedItemSameSlot}
							itemResource={itemList[equippedItemSameSlot.itemID]}
							equipment={this.props.equipment}
							tooltipManager={tooltipManager}
							skills={this.props.skills}
							compactVersion={true}
							listingDate={this.props.listingDate}
						/>
					</div>
				);
			}
		} else {
			tooltips.push(
				<div className={'idlescape-tooltip item-tooltip'}>
					<LegacyTooltip
						item={this.props.item}
						itemResource={itemResource}
						equipment={this.props.equipment}
						tooltipManager={tooltipManager}
						skills={this.props.skills}
						compactVersion={this.useCompactTooltips()}
						listingDate={this.props.listingDate}
					/>
				</div>
			);
			if (this.useEquippedComparison() && equippedItemSameSlot) {
				tooltips.push(
					<div className={'idlescape-tooltip item-tooltip tooltip-compared-item'}>
						<LegacyTooltip
							item={equippedItemSameSlot}
							itemResource={itemList[equippedItemSameSlot.itemID]}
							equipment={this.props.equipment}
							tooltipManager={tooltipManager}
							skills={this.props.skills}
							compactVersion={true}
							listingDate={this.props.listingDate}
						/>
					</div>
				);
			}
		}

		return (
			<ErrorBoundary>
				<div style={{ display: 'flex', alignItems: 'flex-start' }} className={'item-tooltip-flexbox'}>
					{tooltips[0]}
					{tooltips.length > 1 && tooltips[1]}
				</div>
			</ErrorBoundary>
		);
	}

	private calculatePositioning() {
		if (!this.useEquippedComparison() || this.props.forceDefaultPositions) return undefined;

		const itemResource = itemList[this.props.item.itemID];
		const equippedItemSameSlot =
			itemResource.equipmentStats?.slot && this.props.equipment[itemResource.equipmentStats.slot];
		return equippedItemSameSlot ? (['left', 'right'] as PopoverPosition[]) : undefined;
	}

	render() {
		const positions = this.calculatePositioning();
		return this.props.embed ? (
			this.lazyRender()
		) : (
			<Tooltip noDefaultClassName={true} onVisibleLazyRenderLambda={this.lazyRender} positions={positions} />
		);
	}
}

// In case this comes in to question again
// this is required to update the tooltip of the item because allitems doesn't update unless the inventory length changes
const mapStateToProps = function (store: RootState) {
	const equipment = store.game.playerInformation.equipment;
	const skills = store.game.playerInformation.skills;
	const settings = store.game.playerInformation.settings;
	return { equipment: equipment, skills: skills, settings: settings };
};
export default connect(mapStateToProps)(ItemTooltip);
