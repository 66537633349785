import { Box, ChakraProps, Image } from '@chakra-ui/react';
import { IdlescapeTooltip } from '@idlescape/ui';
import React from 'react';
import { IItem, IItemData, TPartialItemWithItemID } from '../../../../../game-server/src/modules/items/items.interface';
import { usePlayerField } from '../../../hooks/hooks';
import { formatNumberToString } from '../../../helper/helperFunctions';
import { filterForBaseItems } from '../../../utils/itemFunctions';
import { itemList } from '../../../utils/itemList';
import ItemTooltip from '../Tooltips/ItemTooltip';

const theme: { [keys: string]: { [keys: string]: ChakraProps } } = {
	default: {
		box1: {
			display: 'grid',
			gridTemplateColumns: '1fr 1fr minmax(70px, max-content)',
			justifyContent: 'space-around',
		},
		image: {
			justifySelf: 'left',
			marginY: 'auto',
		},
		amount: {},
		stockpile: {
			textAlign: 'right',
		},
	},
	cooking: {
		box1: {
			justifyContent: 'center',
			display: 'flex',
		},
		image: {
			backgroundColor: 'rgba(0, 0, 0, 0.5);',
			borderRadius: '10px',
			padding: '5px',
			border: '1px solid rgba(255, 230, 0, 0.356)',
		},
		amount: {
			position: 'absolute',
			top: '60%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			fontSize: '1.5rem',
			textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
		},
		stockpile: {
			textAlign: 'center',
		},
	},
};

export default function ResourceCost({
	resourceCosts,
	amount,
	variant = 'default',
	imageSize = 40,
	itemStats,
	replaceTooltip = null,
	showTooltip = true,
}: {
	resourceCosts: { resource: IItemData; count: number }[];
	amount?: number;
	variant?: 'default' | 'cooking';
	imageSize?: number;
	itemStats?: TPartialItemWithItemID<IItem>[];
	replaceTooltip?: React.ReactNode;
	showTooltip?: boolean;
}) {
	const stockpile = usePlayerField('stockpile');
	const filteredStockpile: IItem[] = [];
	if (itemStats) {
		filteredStockpile.push(
			...stockpile.filter((inventoryItem) => {
				for (const item of itemStats) {
					if (isMatchingItem(inventoryItem, item)) return true;
				}
				return false;
			})
		);
	} else {
		filteredStockpile.push(
			...filterForBaseItems(
				stockpile,
				resourceCosts.map((cost) => cost.resource.id),
				itemList
			)
		);
	}
	return (
		<>
			{resourceCosts.map(({ resource, count }, index) => {
				const totalStackSize = filteredStockpile.reduce(
					(acc: number, item: IItem) => (item.itemID === resource.id ? acc + item.stackSize : acc),
					0
				);
				let item: IItem | undefined;
				if (itemStats) {
					item = filteredStockpile.find((item) => isMatchingItem(item, itemStats[index]));
				}
				return (
					<Box
						key={`${resource.id}-${index}`}
						className='anchor-resource-cost'
						height={(variant === 'cooking' ? 20 : 0) + imageSize + 'px'}
						width={variant === 'cooking' ? imageSize + 'px' : ''}
						position='relative'
						justifyItems='center'
						verticalAlign='middle'
						color={count * (amount || 1) > totalStackSize ? 'red' : 'white'}
						data-itemid={resource.id}
						flexDirection='column'
						{...theme[variant].box1}
					>
						<Image
							src={resource.itemIcon ?? resource.itemImage}
							className='anchor-resource-cost-icon'
							width={imageSize + 'px'}
							height={imageSize + 'px'}
							objectFit='contain'
							display='block'
							{...theme[variant].image}
						/>
						<Box
							className='anchor-resource-cost-amount'
							lineHeight='40px'
							fontSize='1.25rem'
							justifySelf='left'
							height='40px'
							{...theme[variant].amount}
						>
							{formatNumberToString(count)}
						</Box>
						<Box
							className='anchor-resource-cost-owned'
							fontSize='0.9rem'
							justifySelf='right'
							display='inline-block'
							margin='auto 0'
							{...theme[variant].stockpile}
						>
							<Image
								src='/images/ui/inventory_icon.png'
								alt='inventory'
								line-height='40px'
								justifySelf='right'
								width='16px'
								height='16px'
								display='inline-block'
								verticalAlign='middle'
							/>{' '}
							{formatNumberToString(totalStackSize)}
							{amount !== undefined && (
								<Box className='smithing-totals'>
									(~
									{formatNumberToString(amount * count)})
								</Box>
							)}
						</Box>
						{showTooltip &&
							(replaceTooltip ? (
								<IdlescapeTooltip>{replaceTooltip}</IdlescapeTooltip>
							) : (
								<ItemTooltip item={item ? item : { itemID: resource.id }} />
							))}
					</Box>
				);
			})}
		</>
	);
}

function isMatchingItem(inventoryItem: IItem, item: TPartialItemWithItemID<IItem>) {
	for (const [key, value] of Object.entries(item)) {
		if (value !== inventoryItem[key as keyof IItem]) {
			return false;
		}
	}
	return true;
}
