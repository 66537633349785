import { atom } from 'recoil';
import { TEMPLATE_LOADOUT } from '../utils/constantsCollection';

export const loadoutEquipmentAtom = atom({
	key: 'loadoutEquipmentAtom',
	default: { changed: false, equipment: structuredClone(TEMPLATE_LOADOUT.equipment) },
});
export const loadoutAbilityAtom = atom({
	key: 'loadoutAbilityAtom',
	default: { changed: false, abilities: structuredClone(TEMPLATE_LOADOUT.abilityRotation) },
});
export const loadoutFoodAtom = atom({
	key: 'loadoutFoodAtom',
	default: { changed: false, food: structuredClone(TEMPLATE_LOADOUT.foodInventory) },
});
