import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);
const tabsTheme = defineMultiStyleConfig({
	baseStyle: {
		tab: {
			backgroundColor: 'transparent',
			backgroundImage:
				'linear-gradient(to bottom, rgba(0, 0, 0, 0.582), rgba(0, 0, 0, 0.329)), url(/images/ui/frame_panel_light.png)',
			backgroundSize: '100% 100%',
			backgroundRepeat: 'no-repeat',
			cursor: 'pointer',
			paddingX: '5px',
			transition: 'transform 0.05s',
			border: 'none',
			borderRadius: '3px',
			_hover: {
				transform: 'scale(1.05)',
			},
			_selected: {
				backgroundImage:
					'linear-gradient(to bottom, rgba(179, 202, 255, 0.202), rgba(255, 255, 255, 0.112)), url(/images/ui/frame_panel.png)',
				borderRadius: '10px',
			},
		},
	},
	variants: {
		unstyled: {},
		dark: {
			tab: {
				backgroundImage: 'url(/images/ui/frame_panel_dark.png)',
			},
		},
	},
	defaultProps: {
		variant: 'unstyled',
	},
});

export default tabsTheme;
