import { Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { TAccountSubscriptionDetails } from '../../../../../../game-server/src/modules/chat/Chat.interface';
import { details } from './Details';

export default function Subscriptions(props: {
	subscriptions: TAccountSubscriptionDetails[];
	subsGiftedBy: TAccountSubscriptionDetails[];
}) {
	return (
		<>
			<Heading>Subscriptions</Heading>
			<TableContainer>
				<Table size='small'>
					<Thead>
						<Tr>
							<Th>Gifted By</Th>
							<Th>Customer ID</Th>
							<Th>Subscription ID</Th>
							<Th>Created At</Th>
							<Th>Updated At</Th>
							<Th>Status</Th>
							<Th>Start Date</Th>
							<Th>Period Start</Th>
							<Th>Period End</Th>
							<Th>Canceled At</Th>
						</Tr>
					</Thead>
					<Tbody>
						{props.subscriptions.map((sub, index) => (
							<Tr key={index}>
								<Td
									cursor='pointer'
									_hover={{ textDecoration: 'underline' }}
									onClick={() => sub.giftedBy && details(sub.giftedBy.toString(), 'accountid')}
								>
									{sub.giftedByName}
								</Td>
								<Td>{sub.customerId}</Td>
								<Td>{sub.subscriptionId}</Td>
								<Td>{new Date(sub.createdAt).toLocaleString()}</Td>
								<Td>{new Date(sub.updatedAt).toLocaleString()}</Td>
								<Td>{sub.status}</Td>
								<Td>{new Date(sub.startDate).toLocaleString()}</Td>
								<Td>{new Date(sub.periodStart).toLocaleString()}</Td>
								<Td>{new Date(sub.periodEnd).toLocaleString()}</Td>
								<Td>{new Date(sub.canceledAt).toLocaleString()}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
			<Heading>Gifted Subscriptions</Heading>
			<TableContainer>
				<Table size='small'>
					<Thead>
						<Tr>
							<Th>Gifted To</Th>
							<Th>Customer ID</Th>
							<Th>Subscription ID</Th>
							<Th>Created At</Th>
							<Th>Updated At</Th>
							<Th>Status</Th>
							<Th>Start Date</Th>
							<Th>Period Start</Th>
							<Th>Period End</Th>
							<Th>Canceled At</Th>
						</Tr>
					</Thead>
					<Tbody>
						{props.subsGiftedBy.map((sub, index) => (
							<Tr key={index}>
								<Td
									cursor='pointer'
									_hover={{ textDecoration: 'underline' }}
									onClick={() => details(sub.accountId.toString(), 'accountid')}
								>
									{sub.username}
								</Td>
								<Td>{sub.customerId}</Td>
								<Td>{sub.subscriptionId}</Td>
								<Td>{new Date(sub.createdAt).toLocaleString()}</Td>
								<Td>{new Date(sub.updatedAt).toLocaleString()}</Td>
								<Td>{sub.status}</Td>
								<Td>{new Date(sub.startDate).toLocaleString()}</Td>
								<Td>{new Date(sub.periodStart).toLocaleString()}</Td>
								<Td>{new Date(sub.periodEnd).toLocaleString()}</Td>
								<Td>{new Date(sub.canceledAt).toLocaleString()}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</>
	);
}
