import { Box, Image, VStack } from '@chakra-ui/react';
import { IdlescapePill, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { IChatIcon } from '../../../../../game-server/src/modules/chat/Chat.interface';
import { usePlayerField } from '../../../hooks/hooks';
import { chatIconList } from '../../../utils/chatIconList';

export function PlatinumSpentIconProgress() {
	const platinumSpent = usePlayerField('platinumSpent');

	const icons = Object.values(chatIconList).filter((iconData) => iconData.platinumUnlock);
	const milestones = icons.map((iconData) => iconData.platinumUnlock ?? 1);
	return <IconProgress progress={platinumSpent} milestones={milestones} icons={icons} type='platinum' />;
}

export function SubsGiftedIconProgress() {
	const subsGifted = usePlayerField('subsGifted');

	const icons = Object.values(chatIconList).filter((iconData) => iconData.subGiftUnlock);
	const milestones = icons.map((iconData) => iconData.subGiftUnlock ?? 1);
	return <IconProgress progress={subsGifted} milestones={milestones} icons={icons} type='subgifts' />;
}

function IconProgress(props: {
	progress: number;
	milestones: number[];
	icons: IChatIcon[];
	type: 'platinum' | 'subgifts';
}) {
	if (props.milestones.length !== props.icons.length) {
		throw new Error('IconProgress: milestones and icons must have the same length');
	}

	let currentIndex = props.milestones.findIndex((milestone) => props.progress < milestone);
	if (currentIndex === -1) {
		// If all milestones are unlocked, show the last one
		currentIndex = props.milestones.length - 1;
	}
	const nextMilestone = props.milestones[currentIndex];
	const nextIcon = props.icons[currentIndex];
	return (
		<IdlescapeWrappingTooltip
			content={
				<>
					<Box textAlign='center'>
						{props.type === 'platinum'
							? 'Spending Platinum in the shop will give you progress towards unlocking chat icons. '
							: 'Gifting subscriptions will give you progress towards unlocking chat icons. '}
						{props.progress < nextMilestone ? (
							<>
								Your next unlock is{' '}
								<Image src={nextIcon.source} height='20px' width='20px' alt={nextIcon.name} />
								{nextIcon.name}, which requires{' '}
								{(nextMilestone - props.progress).toLocaleString('en-us')}{' '}
								{props.type === 'platinum'
									? 'more Platinum to be spent.'
									: 'more gifted subscriptions.'}
							</>
						) : (
							'You have unlocked all icons.'
						)}
					</Box>
					<VStack>
						All icons:
						{props.icons.map((iconData, index) => {
							const milestone = props.milestones[index];
							return (
								<IdlescapePill
									key={'platinum-shop-unlock-icon-' + iconData.id}
									marginTop='0'
									padding='0'
									height='20px'
									width='115px'
									lineHeight='20px'
									fontSize='12px'
									variant='progress'
									progress={Math.min(1, props.progress / milestone) * 100}
									fontWeight='bold'
									textAlign='left'
									image={iconData.source}
								>
									{milestone.toLocaleString('en-us')}
								</IdlescapePill>
							);
						})}
					</VStack>
				</>
			}
		>
			{/* Tooltip borks without this Box. Idk why */}
			<Box>
				<IdlescapePill height='26px' lineHeight='16px' fontSize='16px' image={nextIcon.source}>
					{props.progress.toLocaleString('en-us')} / {nextMilestone.toLocaleString('en-us')}
				</IdlescapePill>
			</Box>
		</IdlescapeWrappingTooltip>
	);
}
