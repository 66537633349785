import React, { useState } from 'react';
import { Flex, FormLabel, Heading, Switch } from '@chakra-ui/react';
import { IdlescapeContainer } from '@idlescape/ui';
import { usePlayerField } from '../../../../hooks/hooks';
import { COMBAT_INVENTORY_SIZE } from '../../../../utils/constantsCollection';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import { itemList } from '../../../../utils/itemList';
import { Item } from '../../Inventory/Item';
import { useRecoilState } from 'recoil';
import { loadoutFoodAtom } from '../../../../atoms/loadoutAtom';
import { doItemsMatch } from '../../../../utils/itemFunctions';

export default function LoadoutFoodStockpile() {
	const [loadoutFood, setLoadoutFood] = useRecoilState(loadoutFoodAtom);
	const combatInventory = usePlayerField('combatInventory');
	const stockpile = usePlayerField('stockpile');
	const [fillRemaining, setFillRemaining] = useState(false);

	const possibleFood = structuredClone(
		stockpile.filter((item) => {
			const info = itemList[item.itemID];
			return info.healing;
		})
	);
	// Combine duplicates
	for (const food of combatInventory) {
		const found = possibleFood.find((item) => doItemsMatch(item, food));
		if (found) {
			found.stackSize++;
		} else {
			possibleFood.push(structuredClone(food));
		}
	}

	function addToInventory(e: React.MouseEvent, item: IItem) {
		const fill = e.shiftKey || fillRemaining;
		const newFood = structuredClone(loadoutFood.food);
		for (let i = 0; i < COMBAT_INVENTORY_SIZE; i++) {
			if (!newFood[i]) {
				newFood[i] = {
					id: item.id,
					itemID: item.itemID,
					augmentations: item.augmentations,
					enchantmentID: item.enchantmentID,
				};
				if (!fill) break;
			}
		}
		setLoadoutFood({ changed: true, food: newFood });
	}

	return (
		<IdlescapeContainer flex='1'>
			<Heading size='md' textAlign='center'>
				Inventory
			</Heading>
			<Flex justifyContent='center' margin='10px'>
				<FormLabel htmlFor='fill-remaining' textAlign='center'>
					Fill remaining slots
				</FormLabel>
				<Switch
					id='fill-remaining'
					isChecked={fillRemaining}
					onChange={(e) => setFillRemaining(e.target.checked)}
				/>
			</Flex>
			<div className='all-items'>
				{possibleFood.map((item) => (
					<Item key={item.id} item={item} onClick={(e) => addToInventory(e, item)} />
				))}
			</div>
		</IdlescapeContainer>
	);
}
