import { useEffect, useState } from 'react';
import { usePlayerField } from '../../hooks/hooks';
import { leagueList } from '../../utils/leagueList';
import './Header.css';
import './Currencies.css';
import HeaderClaimAccount from './HeaderClaimAccount';
import PlayerCount from './PlayerCount';
import Clock from 'react-live-clock';
import { currentVersion } from 'idlescape-globals';
import { useLocalStorage } from 'usehooks-ts';
import {
	Badge,
	Box,
	Flex,
	HStack,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	Tag,
	Text,
	VStack,
} from '@chakra-ui/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { drawerAtom } from '../../atoms/drawerAtom';
import useNavbarPinned from '../../hooks/useNavbarPinned';
import React from 'react';
import { addToChatInputSelector } from '../../atoms/chat/chatHistoryAtom';
import { ResetUIButton } from './UI/ResetUIButton';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import { FaSteam } from 'react-icons/fa';
import { gameUrls } from '../../GameUrls';

export interface IPatchNote {
	title: string;
	/** Date as String */
	publishedAt: string;
	categories: { title: string }[];
	slug: string;
	version: string;
}

export const Header = ({ loaded }: { loaded?: boolean }) => {
	const [drawerOpen, setDrawerOpen] = useRecoilState(drawerAtom);
	// These fields are undefined if !loaded
	const username = usePlayerField('username');
	const league = usePlayerField('league');
	const claimed = usePlayerField('claimed');
	const verified = usePlayerField('verifiedEmail');
	const subscription = usePlayerField('subscription');

	const [news, setNews] = useState<IPatchNote[]>([]);
	const [unreadNews, setUnreadNews] = useState<IPatchNote[]>([]);

	const [lastOpenedNews, setLastOpenedNews] = useLocalStorage('last-opened-news', '');

	const addToChatInput = useSetRecoilState(addToChatInputSelector);

	const locale = navigator.language;

	let leagueIcon: React.ReactNode;
	const premiumIcon = <img className='header-league-icon' src='/images/premium/premium_icon.svg' alt='Subscribed' />;

	if (loaded) {
		const leagueData = leagueList[league];
		leagueIcon = <img className='header-league-icon' src={leagueData.icon} alt={leagueData.name} />;

		if (!claimed) {
			leagueIcon = <HeaderClaimAccount />;
		} else if (!verified) {
			leagueIcon = (
				<IdlescapeWrappingTooltip content='Go to Settings to verify your email!'>
					<Box color='red'>Unverified</Box>
				</IdlescapeWrappingTooltip>
			);
		}
	}

	useEffect(() => {
		getNews();
	}, []);

	useEffect(() => {
		handleUnreads();
	}, [lastOpenedNews]);

	async function getNews() {
		const news = await fetch(`${process.env.REACT_APP_PUBLIC_LANDING_URL}/api/news`);
		if (!news.ok) {
			return;
		}
		const newsJson = (await news.json()) as IPatchNote[];
		// Preprocess the news to handle invalid data
		// It is considered invalid if it is missing any of the required fields
		for (let i = 0; i < newsJson.length; i++) {
			if (!newsJson[i].title || !newsJson[i].publishedAt || !newsJson[i].categories || !newsJson[i].slug) {
				console.log(
					`Invalid news item found at index ${i}, fields: title - ${newsJson[i].title}, publishedAt - ${newsJson[i].publishedAt}, categories - ${newsJson[i].categories}, slug - ${newsJson[i].slug}`
				);
				newsJson.splice(i, 1);
				i--;
			}
		}
		setNews(newsJson);
		handleUnreads();
	}

	function handleUnreads() {
		const unreadNewsList: IPatchNote[] = [];
		for (let i = 0; i < news.length; i++) {
			if (news[i].slug === lastOpenedNews) {
				break;
			}
			unreadNewsList.push(news[i]);
		}

		setUnreadNews(unreadNewsList);
	}

	const { navbarPinned } = useNavbarPinned();

	function toggleDrawer(stateToChangeTo = !drawerOpen) {
		if (navbarPinned && drawerOpen) return;

		setDrawerOpen(stateToChangeTo);
	}

	function openNews(e: React.MouseEvent, slug: string) {
		const target = gameUrls.news + slug;
		if (e.ctrlKey || e.metaKey) {
			addToChatInput(target);
			return;
		}
		window.open(target, '_blank');

		for (let i = 0; i < news.length; i++) {
			if (news[i].slug === lastOpenedNews) {
				return;
			}
			if (news[i].slug === slug) {
				setLastOpenedNews(slug);
				return;
			}
		}
	}

	function openSteam(e: React.MouseEvent) {
		const target = gameUrls.steam;
		if (e.ctrlKey || e.metaKey) {
			addToChatInput(target);
			return;
		}
		window.open(target, '_blank');
	}

	return (
		<div className='navbar1'>
			<Flex className='navbar1-box left drawer-button' onClick={() => toggleDrawer()}>
				&#9776; <Box maxWidth='80%'>{username}</Box> {leagueIcon} {subscription?.active ? premiumIcon : null}
			</Flex>
			{loaded && <PlayerCount />}

			<HStack className='navbar1-box right' justifyContent='end'>
				<ResetUIButton />
				<Box fontSize={['12px', '16px', '20px']} display={['none', 'block']}>
					<VStack>
						<Box fontSize='10px' lineHeight='10px' padding='0' margin='0'>
							Server Time
						</Box>
						<Box marginTop='0 !important' paddingTop='0' lineHeight='16px'>
							<Clock
								format={locale && locale.toLowerCase() === 'en-us' ? 'h:mm A' : 'HH:mm'}
								timezone='Etc/UCT'
								ticking={true}
								interval={10000}
							/>
						</Box>
					</VStack>
				</Box>
				<Box display={['none', 'block']}>|</Box>
				<Menu>
					<MenuButton
						background='unset'
						border='unset'
						cursor='pointer'
						_hover={{ textDecoration: 'underline' }}
						_focus={{ boxShadow: 'unset', outline: 'unset' }}
						color={unreadNews.length > 0 ? 'blue.300' : 'white.200'}
						fontSize={['12px', '16px', '20px']}
						marginTop='2px !important'
						marginLeft='0 !important'
					>
						{currentVersion}
						{unreadNews.length > 0 && (
							<Tag marginLeft='5px' size={['sm']} colorScheme='blue' _hover={{ textDecoration: 'none' }}>
								{unreadNews.length}
							</Tag>
						)}
						<Flex fontSize='12px' alignItems='center' marginTop='-2px' gap='5px' justifyContent='center'>
							Coming to Steam <FaSteam />
						</Flex>
					</MenuButton>

					<Portal>
						<MenuList zIndex='1000'>
							<MenuItem
								border='none'
								cursor='pointer'
								onClick={openSteam}
								paddingTop='10px'
								paddingBottom='10px'
							>
								<Flex justifyContent='space-between' width='100%' alignItems='center'>
									<VStack alignItems='flex-start'>
										<Text
											maxWidth='300px'
											whiteSpace='nowrap'
											overflow='hidden'
											textOverflow='ellipsis'
											margin='0'
											color='red.300'
										>
											Steam Store Page
										</Text>
										<Text
											maxWidth='300px'
											whiteSpace='nowrap'
											overflow='hidden'
											textOverflow='ellipsis'
											margin='0'
										>
											Please wishlist to help in the launch!
										</Text>
									</VStack>
									<FaSteam fontSize='30px' />
								</Flex>
							</MenuItem>
							<MenuDivider padding={0} margin={0} />
							{news.map((n, index) => {
								const isUnread = unreadNews.find((un) => un.slug === n.slug) !== undefined;
								return (
									<React.Fragment key={index}>
										{index !== 0 && <MenuDivider padding={0} margin={0} />}
										<MenuItem
											border='none'
											cursor='pointer'
											onClick={(e) => openNews(e, n.slug)}
											paddingTop='10px'
											paddingBottom='10px'
											color={isUnread ? 'blue.300' : 'white.200'}
										>
											<VStack alignItems='flex-start'>
												<Text
													maxWidth='300px'
													whiteSpace='nowrap'
													overflow='hidden'
													textOverflow='ellipsis'
													margin='0'
												>
													{n.title}
												</Text>
												<HStack>
													{n.categories.map((c, index) => {
														return <Badge key={index}>{c.title}</Badge>;
													})}
													<Badge>{n.version}</Badge>
												</HStack>
											</VStack>
											{isUnread && (
												<Badge marginLeft='auto' colorScheme='blue'>
													Unread
												</Badge>
											)}
										</MenuItem>
									</React.Fragment>
								);
							})}
						</MenuList>
					</Portal>
				</Menu>
			</HStack>
		</div>
	);
};
