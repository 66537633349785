import { useMediaQuery } from '@chakra-ui/react';
import useIsMobile from '../../hooks/useIsMobile';
import useNavbarPinned from '../../hooks/useNavbarPinned';
import FullWidth from './levels/FullWidth';
import SmallWidth from './levels/SmallWidth';

export const Levels = () => {
	const { navbarPinned } = useNavbarPinned();
	const isMobile = useIsMobile();
	const [isSmallerThan1300] = useMediaQuery('(max-width: 1300px)', { ssr: false });

	function getSkillLevels() {
		if (isMobile || (navbarPinned && isSmallerThan1300)) return <SmallWidth />;
		return <FullWidth />;
	}

	return <div className='header-level-container'>{getSkillLevels()}</div>;
};
