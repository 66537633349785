import React, { useEffect, useState } from 'react';
import { socket } from '../../../services/socket.service';
import { itemList } from '../../../utils/itemList';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import { IdlescapeButton, IdlescapeNumberInput, IdlescapeWrappingTooltip } from '@idlescape/ui';
import {
	Box,
	Button,
	Flex,
	Img,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { marketplaceItemAtom } from '../../../atoms/marketplaceItemAtom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IMarketOffer } from '../../../../../game-server/src/repositories/MarketOffer.repository';
import { enchantmentsList } from '../../../utils/enchantmentList';
import { IMarketListingWithStackSize } from '../../game/Marketplace/MarketplaceBuyItemList';
import { coinIcon } from '../../../helper/itemHelperFunctions';
import { IEnchantmentData } from '../../../../../game-server/src/modules/enchantment/enchantment.interface';
import { cookingList } from '../../../utils/cookingList';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { MAXIMUM_MARKET_PRICE } from '../../../utils/constantsCollection';
import { marketplaceTabAtom } from '../../../atoms/marketplaceTabAtom';

export const BuyMarketplaceDialogue = ({
	itemIdProp,
	onClose,
	listingProp,
}: {
	itemIdProp: number;
	onClose: () => void;
	listingProp?: IMarketListingWithStackSize;
}) => {
	const [itemToBuy, setItemToBuy] = useRecoilState(marketplaceItemAtom);
	const setMarketTab = useSetRecoilState(marketplaceTabAtom);
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const item: IItem | undefined = listingProp?.item; //getItem();
	const listing = listingProp ?? undefined;
	const itemID = itemIdProp;
	const itemData = itemList[itemID];

	const [lowestPrice, setLowestPrice] = useState(0);
	const variableConsumable = cookingList[itemID]?.variableConsumable;
	const isFood = variableConsumable && Boolean(itemData?.healing);
	const isPot = variableConsumable && !isFood;
	const isEquipment = itemData?.equipmentStats !== undefined;
	const isCombat = itemData?.champEncounter !== undefined;
	const [buyAmount, setBuyAmount] = useState(listing?.stackSize ?? 0);
	const [buyPrice, setBuyPrice] = useState(listing?.price ?? 0);
	const [buyAugmentations, setBuyAugmentations] = useState(item?.augmentations ?? 0);
	const [buyHigherAugments, setBuyHigherAugments] = useState(!!listing?.higherAugments);
	const [buyAnyEnchantment, setBuyAnyEnchantment] = useState(!!listing?.anyEnchantment);
	const [buyEnchantmentID, setBuyEnchantmentID] = useState(
		(item ? item?.enchantmentID : itemData.forcedEnchant) ?? -1
	);
	const [buyEnchantmentSearch, setBuyEnchantmentSearch] = useState(
		item?.enchantmentID ? enchantmentsList[item.enchantmentID].name : ''
	);
	const [buyEnchantmentStrength, setBuyEnchantmentStrength] = useState(
		(item ? item?.enchantmentStrength : itemData.forcedEnchantAmount) ?? 0
	);
	const [buyData, setBuyData] = useState<IMarketOffer[]>([]);
	const vendor = itemData.value ?? 0;

	useEffect(() => {
		socket.on('marketplace:offer:item:send', (data) => {
			setBuyData(data);
		});
		socket.on('marketplace:listing:item:price', (data) => {
			if (!data?.itemID) return;
			const lowestPrice = data.lowestPrice;

			if (lowestPrice) {
				setLowestPrice(lowestPrice);
			}
		});
		socket.emit('marketplace:listing:item:price', itemID);

		if (itemToBuy) {
			socket.emit('marketplace:offer:request', { itemID: itemToBuy });
		}

		return () => {
			socket.off('marketplace:listing:item:price');
			socket.off('marketplace:offer:item:send');
		};
	}, []);

	function canSendOffer() {
		if (!isValidBuyPrice()) return false;
		if (!isValidBuyAmount()) return false;
		if (!isValidMultiBuy()) return false;
		return true;
	}

	function isValidBuyPrice() {
		if (buyPrice % 1 !== 0) return false;
		if (buyPrice <= 0) return false;
		if (buyPrice < vendor) return false;
		return true;
	}

	function isValidBuyAmount() {
		if (buyAmount % 1 !== 0) return false;
		if (buyAmount <= 0) return false;
		return true;
	}

	function isValidMultiBuy() {
		if (!isFood && !isEquipment && !isCombat) {
			// Check if the offer already exists
			for (const offer of buyData) {
				if (!listing && offer.itemID === itemToBuy) return false;
			}
		} else {
			// Check if the offer already exists
			const augmentations = !buyAugmentations ? null : buyAugmentations;
			const enchantmentID = buyEnchantmentID === -1 ? null : buyEnchantmentID;
			const enchantmentStrength = !buyEnchantmentStrength ? null : buyEnchantmentStrength;
			for (const offer of buyData) {
				if (offer.itemID === itemToBuy && offer.offerId != listing?.listingId) {
					if (
						offer.augmentations === augmentations &&
						offer.enchantment_id === enchantmentID &&
						offer.enchantment_strength === enchantmentStrength
					)
						return false;
				}
			}
		}
		return true;
	}

	function sendNewOffer() {
		if (!canSendOffer()) return;
		const itemID = itemToBuy ?? itemData.id;
		let augmentations: number | undefined = undefined;
		let enchantmentID: number | undefined = undefined;
		let enchantmentStrength: number | undefined = undefined;
		let higherAugments: boolean | undefined = undefined;
		let anyEnchantment: boolean | undefined = undefined;
		let offerId: number | undefined = undefined;
		const refreshItemId = itemID;
		if (isEquipment) {
			augmentations = buyAugmentations;
			enchantmentID = buyEnchantmentID === -1 ? undefined : buyEnchantmentID;
			enchantmentStrength = buyEnchantmentStrength;
			higherAugments = buyHigherAugments;
			anyEnchantment = buyAnyEnchantment;
		}
		if (isFood) {
			augmentations = buyAugmentations;
			enchantmentID = buyEnchantmentID === -1 ? undefined : buyEnchantmentID;
			higherAugments = buyHigherAugments;
			anyEnchantment = buyAnyEnchantment;
		}
		if (isCombat) {
			augmentations = buyAugmentations;
			higherAugments = buyHigherAugments;
		}
		if (anyEnchantment || !enchantmentID) {
			enchantmentID = undefined;
			enchantmentStrength = undefined;
		}
		if (listing) {
			offerId = listing.listingId;
		}

		socket.emit('marketplace:offer:send', {
			itemID,
			price: buyPrice,
			amount: buyAmount,
			augmentations,
			enchantmentID,
			enchantmentStrength,
			higherAugments,
			anyEnchantment,
			refreshItemId,
			offerId,
		});
		onClose();
	}

	function cancelOffer(offerID: number) {
		socket.emit('marketplace:offer:end', { offerID, refreshItemId: itemToBuy });
		onClose();
	}

	function getEnchantmentButtons() {
		const enchantmentArray: IEnchantmentData['id'][] = [];
		if (buyEnchantmentSearch.length !== 0) {
			if (isFood) {
				for (const food of Object.values(cookingList)) {
					if (food.cookingEnchantment) {
						enchantmentArray.push(food.cookingEnchantment);
					}
				}
			} else if (isPot) {
				for (const food of Object.values(cookingList)) {
					if (food.alchemyEnchantment) {
						enchantmentArray.push(food.alchemyEnchantment);
					}
				}
			} else if (isEquipment) {
				// Search only enchantments from scrolls
				for (const i of Object.values(itemList)) {
					if (!i.enchantmentID) continue;
					const slot = itemData.enchantmentOverrideSlot ?? itemData.equipmentStats?.slot;
					if (!slot || !i.categories?.includes(slot)) continue;
					enchantmentArray.push(i.enchantmentID);
				}
			}
		}
		// Add default enchantments
		if (itemData.forcedEnchant) {
			enchantmentArray.push(itemData.forcedEnchant);
		}
		const searchReg = new RegExp(buyEnchantmentSearch, 'i');
		const filteredEnchantmentArray = enchantmentArray.reduce((acc, enchantID) => {
			// Ignore duplicates and filter by search
			if (!acc.includes(enchantID) && enchantmentsList[enchantID].name.match(searchReg)) {
				acc.push(enchantID);
			}
			return acc;
		}, [] as number[]);
		filteredEnchantmentArray.sort((a, b) => {
			return enchantmentsList[a].name.localeCompare(enchantmentsList[b].name);
		});
		if (buyEnchantmentID !== -1 && !filteredEnchantmentArray.includes(buyEnchantmentID)) {
			filteredEnchantmentArray.unshift(buyEnchantmentID);
		}
		return filteredEnchantmentArray.map((enchantmentID) => {
			const enchantment = enchantmentsList[enchantmentID];
			if (!enchantment) return null;
			const variant = enchantmentID === buyEnchantmentID ? 'green' : 'gray';
			return (
				<IdlescapeButton
					key={enchantmentID}
					variant={variant}
					onClick={() => setBuyEnchantmentID(buyEnchantmentID === enchantmentID ? -1 : enchantmentID)}
					width='unset'
				>
					{enchantment.name}
				</IdlescapeButton>
			);
		});
	}

	function currentListingBlurb() {
		if (!canSendOffer()) {
			let errorString = '';
			if (!isValidBuyPrice()) errorString += 'Invalid Price. ';
			if (!isValidBuyAmount()) errorString += 'Invalid Amount. ';
			if (!isValidMultiBuy()) errorString += 'You may already have an offer for this item. ';
			return <>{errorString.trim()}</>;
		}
		const amount = buyAmount.toLocaleString('en-us');
		const price = buyPrice.toLocaleString('en-us');
		let totalNum = buyPrice * buyAmount;
		totalNum = listing ? totalNum - listing.price * listing.stackSize : totalNum;
		const refund = totalNum < 0;
		const total = (refund ? -totalNum : totalNum).toLocaleString('en-us');
		return (
			<>
				Current Offer: {amount}x {itemData?.name} for {price} {coinIcon(16)}
				<br />{' '}
				{listing
					? refund
						? 'Refund when updating offer: ' + total
						: 'Total cost to update offer: ' + total
					: 'Total cost to create offer: ' + total}{' '}
				{coinIcon(16)}
			</>
		);
	}

	const goToListing = (
		<IdlescapeWrappingTooltip content='Open marketplace listing'>
			<Box position='absolute' top='60px' right='18px'>
				<Img
					src='images/ui/marketplace_icon.png'
					alt='Open marketplace listing'
					className='share-marketplace-listing-button'
					filter='brightness(1.2) drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
					onClick={() => {
						setActiveTab('marketplace');
						setMarketTab('buy');
						setItemToBuy(itemID);
					}}
				></Img>
			</Box>
		</IdlescapeWrappingTooltip>
	);

	return (
		<Modal isOpen={true} onClose={onClose} size='lg'>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog anchor-buy-item-dialog'>
				<ModalHeader>
					{listing ? 'Update ' + itemData.name + ' Offer' : 'Create Offer: ' + itemData.name}
				</ModalHeader>
				<ModalCloseButton />
				{goToListing}
				<ModalBody>
					<Text margin='0'>How many do you want to buy?</Text>
					<IdlescapeNumberInput
						value={buyAmount}
						setValue={setBuyAmount}
						placeholder='Buy Amount'
						maxLength={12}
						onKeyUp={(e) => {
							if (e.key === 'Enter') sendNewOffer();
						}}
					/>
					<InputGroup>
						<IdlescapeNumberInput
							value={buyPrice}
							setValue={setBuyPrice}
							className='anchor-buy-price-input'
							max={MAXIMUM_MARKET_PRICE}
							placeholder='Buy Price'
							maxLength={12}
							onKeyUp={(e) => {
								if (e.key === 'Enter') sendNewOffer();
							}}
						/>
						<InputRightElement right='25px'>{coinIcon(16)}</InputRightElement>
					</InputGroup>
					{(isFood || isEquipment || isCombat) && (
						<Flex>
							<Button onClick={() => setBuyHigherAugments(!buyHigherAugments)}>
								{buyHigherAugments ? '≥' : '='}
							</Button>
							<IdlescapeNumberInput
								setValue={setBuyAugmentations}
								value={buyAugmentations}
								placeholder='Augmentation Level'
								min={0}
								max={30}
								onKeyUp={(e) => {
									if (e.key === 'Enter') sendNewOffer();
								}}
							/>
						</Flex>
					)}
					{(isFood || isPot || isEquipment) && (
						<div>
							<Flex>
								<Button onClick={() => setBuyAnyEnchantment(!buyAnyEnchantment)}>
									{buyAnyEnchantment ? 'Any' : '='}
								</Button>
								<Input
									onChange={(e) => setBuyEnchantmentSearch(e.target.value)}
									value={buyEnchantmentSearch}
									type='search'
									placeholder='Search For An Enchantment'
									disabled={buyAnyEnchantment}
									maxLength={50}
									onKeyUp={(e) => {
										if (e.key === 'Enter') sendNewOffer();
									}}
								/>
							</Flex>
							{!buyAnyEnchantment && (
								<Flex flexWrap='wrap' gap='3px'>
									{getEnchantmentButtons()}
								</Flex>
							)}
						</div>
					)}
					{isEquipment && (
						<IdlescapeNumberInput
							setValue={setBuyEnchantmentStrength}
							value={buyEnchantmentStrength}
							placeholder='Enchantment Strength'
							min={0}
							max={10}
							disabled={buyAnyEnchantment}
							onKeyUp={(e) => {
								if (e.key === 'Enter') sendNewOffer();
							}}
						/>
					)}
					<Flex flexWrap='wrap' justifyContent='space-between' width='100%' columnGap='20px'>
						<div id='lowest-price'>
							Lowest price on market: {lowestPrice.toLocaleString('en-US', { useGrouping: true })}{' '}
							{coinIcon(10)}
						</div>
						{itemData.value && (
							<div id='lowest-price-npc'>
								Item sells to NPCs for:{' '}
								{itemData.value.toLocaleString('en-US', {
									useGrouping: true,
								})}{' '}
								{coinIcon(10)}
							</div>
						)}
					</Flex>
					<Text margin='0'>{currentListingBlurb()}</Text>
				</ModalBody>
				<ModalFooter gap='5px' justifyContent='center' flexWrap='wrap' maxWidth='100%'>
					{listing && (
						<IdlescapeButton variant='red' size='medium' onClick={() => cancelOffer(listing.listingId)}>
							Cancel Offer
						</IdlescapeButton>
					)}
					<IdlescapeButton
						variant={canSendOffer() ? 'green' : 'disabled'}
						size='medium'
						onClick={sendNewOffer}
						className='anchor-sell-confirm-button'
					>
						{listing ? 'Update Offer' : 'Create Offer'}
					</IdlescapeButton>
				</ModalFooter>
				<Text fontSize='sm' color='gray.400' textAlign='center' marginTop='0'>
					Inputs support short notation, e.g. 1.5k = 1500.
				</Text>
			</ModalContent>
		</Modal>
	);
};
