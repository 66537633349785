import React from 'react';
import './Quests.css';
import { IQuestData, IQuestStep } from '../../../../../game-server/src/modules/quest/Quest.interface';
import { Box } from '@chakra-ui/react';
import { usePlayerField } from '../../../hooks/hooks';
import { checkRequirements, getQuestPoints } from './QuestFunctions';
import { locations } from '../../../utils/locationList';
import { monsters } from '../../../utils/monsterList';
import { itemList } from '../../../utils/itemList';
import Tooltip from '../../layout/UI/Tooltip';
import { tabs } from '../../../helper/NavigationTabsList';
import { TSkillName } from '../../../../../game-server/src/Atypes/Skills';
import { questList } from '../../../utils/questList';
import { talentList } from '../../../utils/talentList';

export default function QuestTooltip({
	quest,
	step,
	isAchievement = false,
}: {
	quest: IQuestData;
	step: IQuestStep;
	isAchievement?: boolean;
}) {
	const skills = usePlayerField('skills');
	const questsCompleted = usePlayerField('questsCompleted');
	const questProgress = usePlayerField('questProgress');
	const questIdsCompleted = questsCompleted.map((quest) => quest.id);
	const metRequirements = checkRequirements(quest, skills, questIdsCompleted);
	const questPoints = getQuestPoints(questIdsCompleted);
	const inProgress = questProgress.filter((questProgress) => questProgress.id === quest.id);

	const currentProgress = inProgress[0]?.current ?? 0;

	function renderQuestRequirements() {
		const requirements: React.ReactNode[] = [];
		if (!quest.questRequirements) return null;
		if (quest.questRequirements.questPoints) {
			requirements.push(
				<Box
					key='point-requirement'
					color={questPoints < quest.questRequirements.questPoints ? 'rgb(255, 65, 65)' : ''}
				>
					Quest Points: {quest.questRequirements.questPoints}
				</Box>
			);
		}
		if (quest.questRequirements.requiredQuests) {
			const requiredQuests = quest.questRequirements.requiredQuests.filter(
				(requiredQuest) => !questList[requiredQuest].achievement
			);
			const requiredAchievements = quest.questRequirements.requiredQuests.filter(
				(requiredQuest) => questList[requiredQuest].achievement
			);
			if (requiredQuests.length > 0) {
				requirements.push(
					<Box key='quest-requirement'>
						Quests:{' '}
						{requiredQuests.map((requiredQuest, index) => {
							const reqQuest = questList[requiredQuest];
							const pass = questIdsCompleted.includes(reqQuest.id);
							return (
								<React.Fragment key={reqQuest.id}>
									{index > 0 &&
										index < (quest.questRequirements?.requiredQuests?.length ?? 0) &&
										', '}
									<Box as='span' color={pass ? '' : 'rgb(255, 65, 65)'}>
										{reqQuest?.name}
									</Box>
								</React.Fragment>
							);
						})}
					</Box>
				);
			}
			if (requiredAchievements.length > 0) {
				requirements.push(
					<Box key='achievement-requirement'>
						Achievements:{' '}
						{requiredAchievements.map((requiredQuest, index) => {
							const reqQuest = questList[requiredQuest];
							const pass = questIdsCompleted.includes(reqQuest.id);
							return (
								<React.Fragment key={reqQuest.id}>
									{index > 0 &&
										index < (quest.questRequirements?.requiredQuests?.length ?? 0) &&
										', '}
									<Box as='span' color={pass ? '' : 'rgb(255, 65, 65)'}>
										{reqQuest?.name}
									</Box>
								</React.Fragment>
							);
						})}
					</Box>
				);
			}
		}
		if (quest.questRequirements.skillRequirements) {
			for (const [skillName, level] of Object.entries(quest.questRequirements.skillRequirements)) {
				tabs[skillName]?.name;
				requirements.push(
					<Box
						key={skillName}
						color={skills[skillName as TSkillName].level < level ? 'rgb(255, 65, 65)' : ''}
					>
						{tabs[skillName]?.name} level: {level}
					</Box>
				);
			}
		}
		return requirements;
	}

	let renderReqs = false;
	const stepReqs: React.ReactNode[] = step.validTargets.map((requirement) => {
		if (requirement.triggerString) {
			renderReqs = true;
			return <div key={requirement.triggerString}>{requirement.triggerString}</div>;
		}
		const trigger = requirement.trigger;
		let targetNameString = '';
		let triggerString = '';
		switch (trigger) {
			case 'custom':
				return null;
			case 'location': {
				const location = locations[requirement.targetID];
				targetNameString = location.name;
				triggerString = location.actionType.split('-')[1];
				break;
			}
			case 'dungeon': {
				const location = locations[requirement.targetID];
				targetNameString = location.name;
				triggerString = 'Complete';
				break;
			}
			case 'slay':
				triggerString = 'Slay';
				targetNameString = monsters[requirement.targetID].name;
				break;
			case 'gather':
				triggerString = 'Gather';
				targetNameString = itemList[requirement.targetID].name;
				break;
			case 'craft':
				triggerString = 'Craft';
				targetNameString = itemList[requirement.targetID].name;
				break;
			case 'farm':
				triggerString = 'Harvest';
				targetNameString = itemList[requirement.targetID].name;
				break;
			case 'plant':
				triggerString = 'Plant';
				targetNameString = itemList[requirement.targetID].name;
				break;
			case 'enchant-scroll':
				triggerString = 'Enchant an item with';
				targetNameString = itemList[requirement.targetID].name;
				break;
			case 'inventory':
				triggerString = 'Turn in';
				targetNameString = itemList[requirement.targetID].name;
				break;
		}
		renderReqs = true;
		return (
			<div key={`${targetNameString}-${requirement.targetID}-${trigger}`}>
				{triggerString} {targetNameString}
			</div>
		);
	});

	if (!isAchievement) {
		return <Tooltip>{renderReqs ? stepReqs : step.description}</Tooltip>;
	}

	// Adding a callback for the tooltip to close when the user clicks outside of it is weird because you have to click
	// on the achievement to open it anyways, didn't really try to get it working tho
	return (
		<Box>
			<h2>
				{quest.name} ({quest.achievement && (quest.questRewards?.achievementPoints ?? 0)})
			</h2>
			<div>{quest.description}</div>
			<div>{quest.description !== step.description && step.description}</div>
			{!metRequirements ? (
				<div>Complete the following requirements first: {renderQuestRequirements()}</div>
			) : (
				<>
					<div>
						Current Progress: {currentProgress.toLocaleString('en-us')} /{' '}
						{step.targetAmount.toLocaleString('en-us')}
					</div>
					{renderReqs && stepReqs}
				</>
			)}
			{quest.questRewards?.relatedTalent && (
				<>
					<h3>Talent Rewards</h3>
					<div>
						{quest.questRewards.relatedTalent.map((talent) => (
							<p key={talent} className='achievement-talent'>
								{talentList[talent].name}:<br />
								<span>{talentList[talent].description}</span>
							</p>
						))}
					</div>
				</>
			)}
		</Box>
	);
}
