import React from 'react';
import './PlatinumShop.css';
import PlatinumShopGlobalBuffs from './Buffs/PlatinumShopGlobalBuffs';
import { PlatinumShopPlatinumContainer } from './Platinum/PlatinumShopPlatinumContainer';
import { ShopContainer } from './Shop/ShopContainer';
import { PlatinumShopIdlescapePlus } from './IdlescapePlus/PlatinumShopIdlescapePlus';
import { useRecoilState } from 'recoil';
import { platinumTabAtom } from '../../../atoms/platinumTabAtom';
import { PlatinumSpentIconProgress, SubsGiftedIconProgress } from './PlatinumSpentIcon';
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import PlatinumShopIdlescapeBonds from './IdlescapePlus/PlatinumShopIdlescapeBonds';

export const platinumTabs = ['Idlescape Plus', 'Idlescape Bonds', 'Platinum', 'Shop', 'Buffs'] as const;

const PlatinumShopContainer = () => {
	const [activeTab, setActiveTab] = useRecoilState(platinumTabAtom);

	function handleTabSwitch(index: number) {
		setActiveTab(platinumTabs[index]);
	}

	return (
		<div className='platinum-shop-container'>
			<Tabs onChange={handleTabSwitch} index={platinumTabs.indexOf(activeTab)} variant='dark'>
				<TabList className='platinum-shop-container-buttons' justifyContent='center'>
					{platinumTabs.map((elem) => (
						<Tab
							key={'platinum-shop-key-button-' + elem.replaceAll(' ', '')}
							className={'platinum-shop-container-button idlescape-frame-panel-dark'}
						>
							{elem}
						</Tab>
					))}
				</TabList>
				<Flex justifyContent='space-between'>
					<SubsGiftedIconProgress />
					<PlatinumSpentIconProgress />
				</Flex>
				<TabPanels>
					{/* <Center color={'red'} backgroundColor={'black'}>
						<Box>
							NOTE: This is still a development version of the premium store. You can't spend real money
							on this. Please go to this link
							<a href='https://stripe.com/docs/testing' target={'_blank'} rel='noreferrer'>
								https://stripe.com/docs/testing
							</a>{' '}
							to find the Test Cards you can use to help test the functionality.
						</Box>
					</Center> */}
					<TabPanel>
						<PlatinumShopIdlescapePlus />
					</TabPanel>
					<TabPanel>
						<PlatinumShopIdlescapeBonds />
					</TabPanel>
					<TabPanel>
						<PlatinumShopPlatinumContainer />
					</TabPanel>
					<TabPanel>
						<ShopContainer />
					</TabPanel>
					<TabPanel>
						<PlatinumShopGlobalBuffs />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</div>
	);
};

export default PlatinumShopContainer;
