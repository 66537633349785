import { BoxProps, Grid } from '@chakra-ui/react';
import React from 'react';
import { IItem } from '../../../../../game-server/src/modules/items/items.interface';
import useIsMobile from '../../../hooks/useIsMobile';
import InventoryItem from './InventoryItem';

export default function ItemSelector({
	items,
	selectedId,
	selectedIds,
	select,
	multiSelection,
	...props
}:
	| (
			| {
					items: IItem[];
					selectedId: IItem['id'];
					selectedIds?: undefined;
					select: (item: IItem) => void;
					multiSelection: false;
			  }
			| {
					items: IItem[];
					selectedId?: undefined;
					selectedIds: IItem['id'][];
					select: (items: IItem[]) => void;
					multiSelection: true;
			  }
	  ) &
			BoxProps) {
	const isMobile = useIsMobile();

	function onClick(item: IItem) {
		if (multiSelection) {
			if (isSelected(item.id)) {
				select(items.filter((item) => selectedIds.filter((id) => id !== item.id).includes(item.id)));
			} else {
				select(items.filter((item) => selectedIds.includes(item.id) || item.id === item.id));
			}
		} else {
			select(item);
		}
		return true;
	}

	function isSelected(itemId: IItem['id']) {
		if (multiSelection) {
			return selectedIds.includes(itemId);
		} else {
			return selectedId === itemId;
		}
	}

	const selectedStyle = {
		transform: 'scale(1.05)',
		border: '3px solid white',
		boxShadow: 'black 4px 4px 5px',
	};

	return (
		<Grid
			templateColumns={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
			templateRows={isMobile ? 'repeat(auto-fill, 50px)' : 'repeat(auto-fill, 60px)'}
			gap='0.5rem'
			padding='2px'
			className='anchor-item-selector'
			{...props}
		>
			{items.map((item) => {
				const selected = isSelected(item.id);
				return (
					<InventoryItem
						key={item.id}
						onClick={() => onClick(item)}
						item={item}
						allowAnimation={false}
						style={selected ? selectedStyle : undefined}
						updaterList={[selected]}
					/>
				);
			})}
			{props.children}
		</Grid>
	);
}
