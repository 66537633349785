import React, { ReactElement, useEffect, useState } from 'react';
import { usePlayerField } from '../../../../hooks/hooks';
import { LoadoutSlot } from './LoadoutSlot';
import './Loadout.css';
import { socket } from '../../../../services/socket.service';
import { locations } from '../../../../utils/locationList';
import { IdlescapeButton, IdlescapeContainer, IdlescapeInput } from '@idlescape/ui';
import LoadoutView from './LoadoutView';
import { getLoadoutIcon } from '../../../../helper/helperFunctions';
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	FormLabel,
	Grid,
	Heading,
	Image,
	Switch,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { shareItemSetSelector } from '../../../../atoms/chat/chatHistoryAtom';
import ConfirmationModal from '../../../layout/UI/FastModal';
import { baseLocations } from '../CombatZones';
import {
	AFFIX_TOOL_SLOTS,
	ALL_EQUIPMENT_SLOTS,
	COMBAT_INVENTORY_SIZE,
	TEMPLATE_LOADOUT,
} from '../../../../utils/constantsCollection';
import { TEquipmentSlots } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import { ILoadout } from '../../../../../../game-server/src/modules/loadout/loadout.interface';
import { loadoutAbilityAtom, loadoutEquipmentAtom, loadoutFoodAtom } from '../../../../atoms/loadoutAtom';
import LoadoutGenerator from './LoadoutGenerator';
import { AiFillEdit } from 'react-icons/ai';
import { FaSave, FaTrashAlt, FaTshirt } from 'react-icons/fa';
import { IoChatbubbleEllipses, IoShirt } from 'react-icons/io5';
import { MdCancel } from 'react-icons/md';
import { TiPlus } from 'react-icons/ti';
import { GiChickenOven } from 'react-icons/gi';
import { abilitiesIds } from '../../../../utils/lookup-dictionaries/lookupAbilityList';

const loadoutButtonStyle = {
	minWidth: '100px',
	maxWidth: '200px',
	width: '100%',
	height: '45px',
};

export const LoadoutEditor = () => {
	const loadouts = usePlayerField('loadouts');
	const equipment = usePlayerField('equipment');
	const combatInventory = usePlayerField('combatInventory');
	const combatStats = usePlayerField('combatStats');
	const bonusLoadoutSlots = usePlayerField('bonusLoadoutSlots');
	const [selectedLoadoutId, setSelectedLoadoutId] = useState(loadouts[0]?.id || 0);
	const [editMode, setEditMode] = useState(false);
	const selectedLoadout = loadouts.find((loadout) => loadout.id === selectedLoadoutId);
	const [customName, setCustomName] = useState<string | undefined>(selectedLoadout?.name);
	const [ignoresTools, setIgnoresTools] = useState(Boolean(selectedLoadout?.options.ignoresTools));
	const [unequipEmptySlots, setUnequipEmptySlots] = useState(Boolean(selectedLoadout?.options.unequipEmptySlots));
	const shareLoadout = useSetRecoilState(shareItemSetSelector);
	const [loadoutEquipment, setLoadoutEquipment] = useRecoilState(loadoutEquipmentAtom);
	const [loadoutAbility, setLoadoutAbility] = useRecoilState(loadoutAbilityAtom);
	const [loadoutFood, setLoadoutFood] = useRecoilState(loadoutFoodAtom);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isSaveDialog, setIsSaveDialog] = useState(true);

	useEffect(() => {
		socket.on('inventory:selectLoadout', (loadoutId: number) => {
			setSelectedLoadoutId(loadoutId);
		});

		return () => {
			socket.off('inventory:selectLoadout');
		};
	}, []);

	useEffect(() => {
		const currentLoadout = loadouts.find((l) => l.id === selectedLoadoutId) || loadouts[0];
		updateLoadout(currentLoadout);
	}, [loadouts]);

	const currentLoadoutsAmount = loadouts.length;

	let loadoutsUnlocked = 0;
	const subscribed = usePlayerField('subscription');
	if (subscribed.active) {
		loadoutsUnlocked = 5;
	}
	const bonusLoadouts = 0;
	loadoutsUnlocked += bonusLoadouts;

	// 5 is our base loadout count for everyone
	const maxLoadouts = 5 + loadoutsUnlocked + bonusLoadoutSlots;

	const templateLoadouts: ReactElement[] = [];
	for (let i = 0; i < maxLoadouts - currentLoadoutsAmount; i++) {
		templateLoadouts.push(
			<LoadoutSlot
				setSelectedLoadoutId={changeLoadout}
				key={i}
				loadout={structuredClone(TEMPLATE_LOADOUT)}
				icon={{ icon: '/images/premium/premium_icon.svg', alt: 'Empty Slot' }}
				isSelected={false}
			/>
		);
	}

	const selectedLoadoutData =
		loadouts.find((loadout) => loadout.id === selectedLoadoutId) || structuredClone(TEMPLATE_LOADOUT);

	function createLoadout() {
		setEditMode(true);
		setSelectedLoadoutId(0);
		updateLoadout();
	}

	const saveDialog = (
		<ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={saveLoadout} header={`Save ${customName}`}>
			Are you sure you want to overwrite this loadout?
		</ConfirmationModal>
	);

	const deleteDialog = (
		<ConfirmationModal isOpen={isOpen} onClose={onClose} onConfirm={deleteLoadout} header={`Delete ${customName}`}>
			Are you sure you want to delete this loadout?
		</ConfirmationModal>
	);

	function handleSave() {
		if (selectedLoadoutId) {
			setIsSaveDialog(true);
			onOpen();
		} else {
			saveLoadout();
		}
	}

	function updateLoadout(selectedLoadout?: ILoadout) {
		if (selectedLoadout) {
			setSelectedLoadoutId(selectedLoadout.id);
			setLoadoutEquipment({ changed: false, equipment: selectedLoadout.equipment });
			setLoadoutAbility({
				changed: false,
				abilities:
					selectedLoadout.abilityRotation.length !== 0
						? selectedLoadout.abilityRotation
						: [abilitiesIds.melee_auto_attack],
			});
			setLoadoutFood({ changed: false, food: selectedLoadout.foodInventory });
			setCustomName(selectedLoadout.name);
			setIgnoresTools(Boolean(selectedLoadout.options.ignoresTools));
		} else {
			setSelectedLoadoutId(0);
			setLoadoutEquipment({ changed: false, equipment: TEMPLATE_LOADOUT.equipment });
			setLoadoutAbility({ changed: false, abilities: TEMPLATE_LOADOUT.abilityRotation });
			setLoadoutFood({ changed: false, food: TEMPLATE_LOADOUT.foodInventory });
			setCustomName(TEMPLATE_LOADOUT.name);
			setIgnoresTools(Boolean(TEMPLATE_LOADOUT.options.ignoresTools));
		}
	}

	function updateIgnoresTools(checked: boolean) {
		if (checked) {
			// Remove tools
			setLoadoutEquipment((equipment) => {
				const newEquipment = structuredClone(equipment);
				for (const slot of AFFIX_TOOL_SLOTS) {
					if (newEquipment.equipment[slot as TEquipmentSlots]) {
						newEquipment.equipment[slot as TEquipmentSlots] = undefined;
						newEquipment.changed = true;
					}
				}
				return newEquipment;
			});
		}
		setIgnoresTools(checked);
	}

	function changeLoadout(loadoutId: number) {
		setSelectedLoadoutId(loadoutId);
		const selectedLoadout = loadouts.find((loadout) => loadout.id === loadoutId);
		updateLoadout(selectedLoadout);
	}

	function saveLoadout() {
		let newEquipment: Partial<Record<TEquipmentSlots, number>> | undefined;
		if (loadoutEquipment.changed) {
			newEquipment = {};
			for (const [slot, item] of Object.entries(loadoutEquipment.equipment)) {
				if (item) {
					// If it has an id, update it
					if (item.id) {
						newEquipment[slot as TEquipmentSlots] = item.id;
					}
					// Do nothing
				} else {
					// Remove
					newEquipment[slot as TEquipmentSlots] = 0;
				}
			}
		}
		let newFood: number[] | undefined;
		if (loadoutFood.changed) {
			newFood = [];
			for (let i = 0; i < COMBAT_INVENTORY_SIZE; i++) {
				const item = loadoutFood.food[i];
				if (item) {
					// If it has an id, update it
					if (item.id) {
						newFood[i] = item.id;
					}
					// Do nothing
				} else {
					// Remove
					newFood[i] = 0;
				}
			}
		}
		socket.emit('loadout:save', {
			loadoutID: selectedLoadoutId,
			loadoutName: customName,
			equipment: newEquipment,
			foodInventory: newFood,
			abilityRotation: loadoutAbility.abilities,
			ignoresTools: ignoresTools,
			unequipEmptySlots: unequipEmptySlots,
		});
		setEditMode(false);
		onClose();
	}

	function loadLoadout() {
		socket.emit('loadout:equip', { loadoutID: selectedLoadoutId });
	}

	function deleteLoadout() {
		socket.emit('loadout:delete', { loadoutID: selectedLoadoutId });
		changeLoadout(loadouts[0]?.id || 0);
		setEditMode(false);
		onClose();
	}

	function editLoadout() {
		setEditMode(!editMode);
		updateLoadout(selectedLoadoutData);
	}

	function copyEquipment() {
		const newEquipment: ILoadout['equipment'] = {};
		for (const slot of ALL_EQUIPMENT_SLOTS) {
			const equipmentItem = equipment[slot];
			if (ignoresTools && AFFIX_TOOL_SLOTS.includes(slot as TEquipmentSlots)) continue;
			if (!equipmentItem) {
				// Unequip if the loadout slot is filled but the equip is empty
				if (selectedLoadout?.equipment[slot]) {
					newEquipment[slot] = undefined;
				}
			} else {
				newEquipment[slot] = {
					id: equipmentItem.id,
					itemID: equipmentItem.itemID,
					augmentations: equipmentItem.augmentations,
					enchantmentStrength: equipmentItem.enchantmentStrength,
					enchantmentID: equipmentItem.enchantmentID,
					affixes: equipmentItem.affixes,
				};
			}
		}
		setLoadoutEquipment({ changed: true, equipment: newEquipment });
		setLoadoutAbility({
			changed: true,
			abilities:
				combatStats.abilities.length !== 0
					? combatStats.abilities.map((ability) => ability.id)
					: [abilitiesIds.melee_auto_attack],
		});
	}

	function copyFood() {
		const newFood: ILoadout['foodInventory'] = combatInventory.map((item) => {
			return {
				id: item.id,
				itemID: item.itemID,
				augmentations: item.augmentations,
				enchantmentID: item.enchantmentID,
			};
		});
		setLoadoutFood({ changed: true, food: newFood });
	}

	function markLoadout(locationID: number) {
		socket.emit('loadout:mark', {
			loadoutID: selectedLoadoutId,
			newLocationIDs: [locationID],
		});
	}

	function markCategory(locationIDs: number[]) {
		socket.emit('loadout:mark', {
			loadoutID: selectedLoadoutId,
			newLocationIDs: locationIDs,
		});
	}

	let locationsObj: ReactElement[] = [];
	if (selectedLoadoutId !== 0) {
		const validLocations: {
			category: string;
			locationID: number;
			locationName: string;
			duration: number;
			requiredLevel: number;
			bestiaryLocation: number;
		}[] = [];
		// iterate through all the location values
		const blacklistedLocations = [1, 2, 3, 4, 5, 200, 201, 202];
		const eliteRange = [2000, 2999];
		const dungeonRange = [3000, 3999];
		for (const locIdx in locations) {
			const location = locations[locIdx];
			if (blacklistedLocations.includes(location.locID)) continue;
			const recommendedLevel = Number(location.extraTooltipInfo?.split(/[-+]/)[0]);
			const validLoc = {
				category: location.actionType.replace('Action-', ''),
				locationID: location.locID,
				locationName: location.name,
				duration: location.baseDuration,
				requiredLevel:
					location.accessRequirements?.requiredSkills?.[0]?.level ??
					(isNaN(recommendedLevel) ? 0 : recommendedLevel),
				bestiaryLocation: location.bestiaryBaseLocationID ?? location.locID,
			};
			// if the location is an elite zone, change the category to 'Elite Combat'
			if (location.locID >= eliteRange[0] && location.locID <= eliteRange[1]) {
				validLoc.category = 'Elite Combat';
			}
			// if the location is a dungeon, change the category to 'Dungeon'
			if (location.locID >= dungeonRange[0] && location.locID <= dungeonRange[1]) {
				validLoc.category = 'Dungeon';
			}
			validLocations.push(validLoc);
		}
		// Sort the locations by category, then by level, then by duration
		validLocations.sort((a, b) => {
			if (a.category < b.category) return -1;
			if (a.category > b.category) return 1;
			if (a.category !== 'Combat' && a.category !== 'Elite Combat' && a.requiredLevel !== b.requiredLevel)
				return a.requiredLevel - b.requiredLevel;
			const indexA = baseLocations.findIndex((i) => i === a.bestiaryLocation);
			const indexB = baseLocations.findIndex((i) => i === b.bestiaryLocation);
			if (indexA !== indexB) return indexA - indexB;
			if (a.duration < b.duration) return -1;
			if (a.duration > b.duration) return 1;
			return 0;
		});

		const locationCategories = validLocations.reduce((acc, curr) => {
			if (!(curr.category in acc)) {
				acc[curr.category] = [];
			}
			acc[curr.category].push(curr);
			return acc;
		}, {} as { [key: string]: typeof validLocations });

		locationsObj = Object.keys(locationCategories).map((category) => {
			return (
				<React.Fragment key={category}>
					<Grid gap='5px' alignItems='center' justifyContent='center' gridTemplateColumns='100px 1fr 100px'>
						<Heading size='small' textAlign='center' gridColumn='2 / 3'>
							{category}
						</Heading>
						{locationCategories[category].length > 1 && (
							<IdlescapeButton
								variant='green'
								width='auto'
								onClick={() => markCategory(locationCategories[category].map((e) => e.locationID))}
								gridColumn='3 / 4'
							>
								Toggle All
							</IdlescapeButton>
						)}
					</Grid>
					<Grid
						key={`location-grid-${category}`}
						gridTemplateColumns='repeat(auto-fill, minmax(200px, 1fr))'
						columnGap='10px'
						justifyContent='center'
					>
						{locationCategories[category].map((e, i) => {
							const connectedLoadout = loadouts.find((l) => l.relatedZones.includes(e.locationID));
							const variant = connectedLoadout
								? connectedLoadout.id === selectedLoadoutId
									? 'green'
									: 'red'
								: 'orange';
							const icon = connectedLoadout ? getLoadoutIcon(connectedLoadout) : undefined;
							return (
								<IdlescapeButton
									key={i}
									variant={variant}
									onClick={() => markLoadout(e.locationID)}
									size='large'
									justifyContent='start'
									width='100%'
								>
									{connectedLoadout && (
										<Image
											src={icon?.icon}
											alt={icon?.alt}
											width='20px'
											height='20px'
											objectFit='contain'
											filter='drop-shadow(2px 2px 2px black)'
											paddingRight='5px'
										/>
									)}
									<Text textOverflow='ellipsis' overflow='hidden'>
										{e.locationName}
									</Text>
								</IdlescapeButton>
							);
						})}
					</Grid>
				</React.Fragment>
			);
		});
	}

	return (
		<div className='loadout-container'>
			{editMode ? (
				<IdlescapeInput
					type='text'
					textAlign='center'
					placeholder='Custom loadout name'
					value={customName}
					setValue={setCustomName}
					width='200px'
					margin='5px auto'
					maxLength={30}
				/>
			) : (
				<>
					<div className='loadout-slots'>
						{loadouts.map((loadout, index) => {
							const icon = getLoadoutIcon(loadout);
							return (
								<LoadoutSlot
									key={index}
									setSelectedLoadoutId={changeLoadout}
									loadout={loadout}
									icon={icon}
									isSelected={selectedLoadoutId === loadout.id}
								/>
							);
						})}
						{templateLoadouts}
					</div>
					<IdlescapeContainer
						variant='primary'
						position='sticky'
						top='0'
						zIndex='1'
						width='fit-content'
						margin='auto'
						fontSize='28px'
						fontWeight='bold'
						textAlign='center'
					>
						{customName ?? 'Empty'}
					</IdlescapeContainer>
				</>
			)}

			<LoadoutView loadoutId={selectedLoadoutId} editMode={editMode} ignoresTools={ignoresTools} />

			<div className='idlescape-container thin idlescape-container-light'>
				{editMode && (
					<div className='loadout-action-buttons'>
						<Flex justifyContent='center' margin='10px'>
							<FormLabel htmlFor='loadout-ignore-tools' textAlign='center'>
								Ignore all tools
							</FormLabel>
							<Switch
								id='loadout-ignore-tools'
								isChecked={ignoresTools}
								onChange={(e) => updateIgnoresTools(e.target.checked)}
							/>
						</Flex>
						<Flex justifyContent='center' margin='10px'>
							<FormLabel htmlFor='unequip-empty-slots' textAlign='center'>
								Unequip empty slots
							</FormLabel>
							<Switch
								id='unequip-empty-slots'
								isChecked={unequipEmptySlots}
								onChange={(e) => setUnequipEmptySlots(e.target.checked)}
							/>
						</Flex>
					</div>
				)}
				<div className='loadout-action-buttons'>
					{editMode ? (
						<>
							<IdlescapeButton variant='orange' {...loadoutButtonStyle} onClick={editLoadout}>
								<MdCancel />
								Cancel
							</IdlescapeButton>
							<IdlescapeButton variant='green' {...loadoutButtonStyle} onClick={handleSave}>
								<FaSave />
								Save
							</IdlescapeButton>
							{selectedLoadoutId > 0 && (
								<IdlescapeButton
									variant='red'
									{...loadoutButtonStyle}
									onClick={() => {
										setIsSaveDialog(false);
										onOpen();
									}}
								>
									<FaTrashAlt />
									Delete
								</IdlescapeButton>
							)}
							<IdlescapeButton variant='blue' {...loadoutButtonStyle} onClick={copyEquipment}>
								<IoShirt />
								Copy Equipment
							</IdlescapeButton>
							<IdlescapeButton variant='blue' {...loadoutButtonStyle} onClick={copyFood}>
								<GiChickenOven />
								Copy Food
							</IdlescapeButton>
						</>
					) : selectedLoadoutId === 0 ? (
						<IdlescapeButton variant='green' {...loadoutButtonStyle} onClick={createLoadout}>
							<TiPlus />
							Create new Loadout
						</IdlescapeButton>
					) : (
						<>
							<IdlescapeButton variant='green' {...loadoutButtonStyle} onClick={editLoadout}>
								<AiFillEdit />
								Edit
							</IdlescapeButton>
							<IdlescapeButton variant='orange' {...loadoutButtonStyle} onClick={loadLoadout}>
								<FaTshirt />
								Load
							</IdlescapeButton>
							<IdlescapeButton
								variant='blue'
								{...loadoutButtonStyle}
								onClick={() => shareLoadout(selectedLoadoutData.id.toString())}
							>
								<IoChatbubbleEllipses />
								Share
							</IdlescapeButton>
						</>
					)}
				</div>
				{selectedLoadoutId === 0 && <LoadoutGenerator />}
			</div>
			{selectedLoadoutId !== 0 && (
				<Accordion allowToggle>
					<AccordionItem>
						<AccordionButton>
							Location Selection
							<AccordionIcon />
						</AccordionButton>
						<AccordionPanel>
							<div className='loadout-location-container'>{locationsObj}</div>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			)}
			{isSaveDialog ? saveDialog : deleteDialog}
		</div>
	);
};
