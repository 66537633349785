// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TAbilityList } from '../../../game-server/src/modules/ability/ability.interface';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';

export const abilities: TAbilityList = {
	1: {
		id: 1,
		abilityName: 'Melee Auto Attack',
		abilityImage: '/images/combat/ability_icons/basic_melee.png',
		description:
			'A completely standard melee attack that benefits from no particular style or skill. It is the most basic of attacks.',
		damageType: 'Melee',
		autoAttackStyle: 'Melee',

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	2: {
		id: 2,
		abilityName: 'Magic Auto Attack',
		abilityImage: '/images/combat/ability_icons/basic_magic.png',
		description:
			'An unattuned magical attack that draws from the magical abilities of its caster or their equipment without favoring any elemental affinity.',
		damageType: 'Magic',
		autoAttackStyle: 'Magic',

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	3: {
		id: 3,
		abilityName: 'Range Auto Attack',
		abilityImage: '/images/combat/ability_icons/basic_range.png',
		description:
			'Fire a basic, unspecialized projectile. This projectile is fully mundane and can be fired infinitely without needing to restock.',
		damageType: 'Range',
		autoAttackStyle: 'Range',

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	4: {
		id: 4,
		abilityName: 'Slash',
		abilityImage: '/images/combat/ability_icons/cleave.png',
		description: 'Use the blade of your weapon to slash at your target.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	5: {
		id: 5,
		abilityName: 'Stab',
		abilityImage: '/images/combat/ability_icons/stab.png',
		description: 'Use the tip of your weapon to stab your target.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	6: {
		id: 6,
		abilityName: 'Crush',
		abilityImage: '/images/combat/ability_icons/crush.png',
		description: 'Use the weight of your weapon to crush your target.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	7: {
		id: 7,
		abilityName: 'Fire Ball',
		abilityImage: '/images/combat/ability_icons/fireball.png',
		description: 'Harness a ball of fire to throw at your enemies. Not a good idea to play catch with.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.fire_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	8: {
		id: 8,
		abilityName: 'Enhanced Ice Shard',
		abilityImage: '/images/combat/ability_icons/ice_shard.png',
		description:
			'Launch an enhanced version of a magically hardened ice into your opponent to deal an impressive amount of piercing and ice damage.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	9: {
		id: 9,
		abilityName: "Nature's Sanctuary",
		abilityImage: '/images/combat/ability_icons/reinforce.png',
		description:
			"Manifest some of nature's power to defend yourself and deal minor nature damage to your opponent.",
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1.5,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.natures_sanctuary,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	10: {
		// Boss version of Chaos Rend
		id: 10,
		abilityName: 'Greater Chaos Rend',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'A greater chaos melee attack',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 0.9,

		maxTargets: 3,
		diminishingAOEDamageMult: 0.1,
	},
	11: {
		// Player version of Chaos Rend
		id: 11,
		abilityName: 'Lesser Chaos Rend',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description:
			'Though an imperfect copy of an attack known only to the Giant King, it is far from weak. Tear forth a new rend in reality and hope nothing is on the other side.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.05,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.15,
		baseAccuracyCoeff: 0.8,

		maxTargets: 1,
	},
	12: {
		id: 12,
		abilityName: 'Artillery',
		abilityImage: '/images/combat/ability_icons/artillery.png',
		description:
			'Fire a barrage of explosive projectiles into the midst of your enemies, allowing you to deal an extreme amount of damage to multiple targets.',
		damageType: 'Range',
		cooldown: 60000,
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.15 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.15 },
		],

		baseSpeedCoeff: 3,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 0.75,

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	13: {
		id: 13,
		abilityName: 'Shatter Strike',
		abilityImage: '/images/combat/ability_icons/shatterstrike.png',
		description: 'Put your might into a single heavy hitting crushing attack at the expense of speed and accuracy.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.75,
		baseMinimumDamageCoeff: 1.5,
		baseMaximumDamageCoeff: 3,
		baseAccuracyCoeff: 0.75,

		maxTargets: 1,
	},
	14: {
		id: 14,
		abilityName: 'Crystal Fracture',
		abilityImage: '/images/combat/ability_icons/shatterstrike.png',
		description:
			'Harness the strength of chaotic crystals to fracture your foes with an impressive crushing strike.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 1.35,
		baseAccuracyCoeff: 1.05,

		maxTargets: 1,
	},
	15: {
		id: 15,
		abilityName: 'Titanic Strike',
		abilityImage: '/images/combat/ability_icons/titanicstrike.png',
		description: 'A slow but incredibly heavy hitting blunt attack.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 1.66 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 1.66 },
		],

		baseSpeedCoeff: 1.66,
		baseMinimumDamageCoeff: 1.1,
		baseMaximumDamageCoeff: 2.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	16: {
		id: 16,
		abilityName: 'Lightning Strike',
		abilityImage: '/images/combat/ability_icons/lightning_strike.png',
		description: 'Strike as quick as lightning!',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.25 },
			{ affinity: 'Lightning', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.25 },
			{ affinity: 'Lightning', scaling: 1.25 },
		],

		baseSpeedCoeff: 0.6,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.66,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	17: {
		id: 17,
		abilityName: 'Entropic Burst',
		abilityImage: '/images/combat/ability_icons/chaos_missile_2.png',
		description: 'All things eventually come to an end.',
		damageType: 'Magic',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1.75,

		maxTargets: 1,
	},
	18: {
		id: 18,
		abilityName: 'Flamethrower',
		abilityImage: '/images/combat/ability_icons/flamethrower.png',
		description: 'Fire an intense gout of flame!',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.fire_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.3,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 0.66,

		maxTargets: 3,
		diminishingAOEDamageMult: 0.1,
	},
	19: {
		id: 19,
		abilityName: 'Regal Might',
		abilityImage: '/images/combat/ability_icons/regal_might.png',
		description:
			'Harness your regal power to summon a small magical ice storm to deal minor ice damage and increase your defenses somewhat.',
		damageType: 'Magic',
		cooldown: 60000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 3,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.regal_might,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	20: {
		id: 20,
		abilityName: 'Krakatoa',
		abilityImage: '/images/combat/ability_icons/krakatoa.png',
		description: 'Kra-ka-to-ahhhh! Let loose a magical blast of fire from your head. This is definitely safe!',
		damageType: 'Magic',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.fire_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 2,
		baseMinimumDamageCoeff: 1.75,
		baseMaximumDamageCoeff: 2.5,
		baseAccuracyCoeff: 0.9,

		maxTargets: 5,
		diminishingAOEDamageMult: 0.2,
	},
	21: {
		id: 21,
		abilityName: 'Triple Shot',
		abilityImage: '/images/combat/ability_icons/tripleshot.png',
		description: 'Quickly fire up to three arrows at the enemy party! Inherently incredibly inaccurate.',
		damageType: 'Range',
		cooldown: 30000,
		useRangedAmmo: true,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 0.5,

		maxTargets: 3,
		diminishingAOEDamageMult: 0.1,
	},
	22: {
		id: 22,
		abilityName: 'Cleave',
		abilityImage: '/images/combat/ability_icons/cleave.png',
		description:
			'Let loose a mighty swing to strike up to two enemies at once! Due to having to cleave through two enemies, this attack deals slightly less damage than a single target attack.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.15,
		baseMinimumDamageCoeff: 0.66,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 2,
	},
	23: {
		id: 23,
		abilityName: 'Deadshot',
		abilityImage: '/images/combat/ability_icons/deadshot_alt.png',
		description: "One shot, one kill. Fire a well placed arrow at an enemy's weakspot.",
		damageType: 'Range',
		cooldown: 30000,
		useRangedAmmo: true,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1.5 }],

		baseSpeedCoeff: 1.3,
		baseMinimumDamageCoeff: 1.5,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 2.25,

		maxTargets: 1,
	},
	24: {
		id: 24,
		abilityName: 'Retreating Shot',
		abilityImage: '/images/combat/ability_icons/camo.png',
		description:
			'Fire a distraction shot and retreat from the frontline temporarily, significantly reducing your threat.',
		damageType: 'Range',
		cooldown: 30000,
		useRangedAmmo: true,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 0.75,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.camouflage,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 6,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	25: {
		id: 25,
		abilityName: 'Apocalyptic Ritual',
		abilityImage: '/images/combat/ability_icons/apocalypse.png',
		description:
			'Release a hellish wave of sulphuric flames to destroy nearly everything in its wake. Magic of this scale may destroy the entire enemy party, but the incredibly long incantation time leaves the caster wide open to counter attacks.',
		damageType: 'Magic',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.fire_rune, amount: 5 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 3.5,
		baseMinimumDamageCoeff: 4,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 5,

		maxTargets: 10,
		diminishingAOEDamageMult: 0.05,
	},
	26: {
		id: 26,
		abilityName: 'Bulwark',
		abilityImage: '/images/combat/ability_icons/bulwark.png',
		description: 'Ready yourself to defend against the next attack!',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.1,
		baseAccuracyCoeff: 5,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.deflect,
			enchantmentStrength: 6,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 1,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	27: {
		id: 27,
		abilityName: 'Piercing Ammo',
		abilityImage: '/images/combat/ability_icons/piercing_shot.png',
		description: 'Use piercing ammo to deal additional piercing damage at the expense of lower base damage.',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	28: {
		id: 28,
		abilityName: 'Heavy Ammo',
		abilityImage: '/images/combat/ability_icons/piercing_shot.png',
		description: 'Use heavy ammo to deal additional blunt damage at the expense of lower base damage.',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	29: {
		id: 29,
		abilityName: 'Serrated Ammo',
		abilityImage: '/images/combat/ability_icons/piercing_shot.png',
		description: 'Use serrated ammo to deal additional slashing damage at the expense of lower base damage.',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	30: {
		id: 30,
		abilityName: 'Lesser Fire Bolt',
		abilityImage: '/images/combat/ability_icons/fire_bolt.png',
		description: 'Attune your magic to fire to deal additional fire damage at the expense of lower base damage.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	31: {
		id: 31,
		abilityName: 'Lesser Ice Bolt',
		abilityImage: '/images/combat/ability_icons/ice_bolt.png',
		description: 'Attune your magic to ice to deal additional ice damage at the expense of lower base damage.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	32: {
		id: 32,
		abilityName: 'Lesser Nature Bolt',
		abilityImage: '/images/combat/ability_icons/nature_bolt.png',
		description:
			'Attune your magic to nature to deal additional nature damage at the expense of lower base damage.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	33: {
		id: 33,
		abilityName: 'Lesser Earth Bolt',
		abilityImage: '/images/combat/ability_icons/earth_bolt.png',
		description: 'Attune your magic to earth to deal additional blunt damage at the expense of lower base damage.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.4 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.4 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.8,
		baseMaximumDamageCoeff: 0.8,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	34: {
		id: 34,
		abilityName: 'Lesser Air Bolt',
		abilityImage: '/images/combat/ability_icons/air_bolt.png',
		description: 'Attune your magic to air to deal additional slashing damage at the expense of lower base damage.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.4 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.4 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.8,
		baseMaximumDamageCoeff: 0.8,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	35: {
		id: 35,
		abilityName: 'Greater Soul Steal',
		abilityImage: '/images/combat/ability_icons/lifesteal.png',
		description: 'Leach the very essence of your enemies to rejuvenate yourself.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 3,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.soul_stealing,
			enchantmentStrength: 10,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	36: {
		id: 36,
		abilityName: 'Infernal Cleave',
		abilityImage: '/images/combat/ability_icons/cleave.png',
		description: 'An enhanced cleave attack that can hit up to 3 enemies at once.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.15,
		baseMinimumDamageCoeff: 0.85,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 3,
	},
	37: {
		id: 37,
		abilityName: 'Corrupted Soul Steal',
		abilityImage: '/images/combat/ability_icons/lifesteal.png',
		description:
			'Leach the very essence of your enemies to rejuvenate yourself while infecting them with a minor amount of corruption.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Poison', scaling: 1.5 },
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Poison', scaling: 1.5 },
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 3,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.soul_stealing,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 99,
		},

		healthChangeEvent: {
			dotCount: 10,
			healthChange: -25,
			healthChangeDelay: 1000,
		},

		maxTargets: 1,
	},
	38: {
		id: 38,
		abilityName: 'Shapeless Cleave',
		abilityImage: '/images/combat/ability_icons/cleave.png',
		description: 'An enhanced cleave attack that can hit up to 3 enemies at once.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 3,
	},
	39: {
		id: 39,
		abilityName: "Chef's Fury",
		abilityImage: '/images/combat/ability_icons/berserk.png',
		description: 'Channel your inner chef to become the most feared thing on the battlefield!',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1.05,

		maxTargets: 1,
	},
	40: {
		id: 40,
		abilityName: 'Wild Slash',
		abilityImage: '/images/combat/ability_icons/bleed.png',
		description: 'Learn from the woodland beasts to slash your enemies with a wild and uncontrolled slash!',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.33,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	41: {
		id: 41,
		abilityName: 'Greater Wild Slash',
		abilityImage: '/images/combat/ability_icons/bleed.png',
		description: 'Learn from the woodland beasts to slash your enemies with a wild and uncontrolled slash!',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.33,
		baseMinimumDamageCoeff: 0.33,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	42: {
		id: 42,
		abilityName: 'Elven Deadshot',
		abilityImage: '/images/combat/ability_icons/deadshot_alt.png',
		description: 'What do your elven eyes see?',
		damageType: 'Range',
		cooldown: 30000,
		useRangedAmmo: true,

		damageScaling: [{ affinity: 'Range', scaling: 1.1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1.5 }],

		baseSpeedCoeff: 1.3,
		baseMinimumDamageCoeff: 1.66,
		baseMaximumDamageCoeff: 2.15,
		baseAccuracyCoeff: 2.25,

		maxTargets: 1,
	},
	43: {
		id: 43,
		abilityName: "Ocean's Wrath",
		abilityImage: '/images/combat/ability_icons/oceans_wrath.png',
		description: 'Unleash the wrath of the ocean upon your enemies!',
		damageType: 'Magic',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 5 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 3.5,
		baseMinimumDamageCoeff: 4,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 5,

		maxTargets: 10,
	},
	44: {
		id: 44,
		abilityName: "The Ol' One-Two : 1st",
		abilityImage: '/images/combat/ability_icons/onetwo.png',
		description: "Hit 'em with the ol' one-two!",
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.33,
		baseMinimumDamageCoeff: 0.33,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	45: {
		id: 45,
		abilityName: 'Lesser Earth Strike',
		abilityImage: '/images/combat/ability_icons/titanicstrike.png',
		description: 'Use the power of the earth to enhance your crushing attacks. BONK!',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1.1,
		baseMaximumDamageCoeff: 1.1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	46: {
		id: 46,
		abilityName: 'Parry This',
		abilityImage: '/images/combat/ability_icons/deflect.png',
		description: 'Ready yourself to attempt to parry the next attack!',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.2,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,
		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.parry,
			enchantmentStrength: 20,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},

		maxTargets: 1,
	},
	47: {
		id: 47,
		abilityName: 'Bandage Friendly',
		abilityImage: '/images/combat/ability_icons/heal.png',
		description: 'Bandage the lowest HP member of your party to heal them for 300 health over 15 seconds.',
		damageType: 'Melee',
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 1 }],
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0,
		baseMaximumDamageCoeff: 0,
		baseAccuracyCoeff: 999,

		targetFriendly: true,
		canTargetSelf: false,
		target: 'lowestHPPercent',

		healthChangeEvent: {
			dotCount: 15,
			healthChange: 20,
			healthChangeDelay: 1000,
		},

		skipReactives: true,
		dealsNoDamage: true,

		maxTargets: 1,
	},
	48: {
		id: 48,
		abilityName: 'Call For Aid',
		abilityImage: '/images/combat/ability_icons/call_for_aid.PNG',
		description: 'Summons two friendly guards to aid you in battle.',
		damageType: 'Melee',
		cooldown: 86400000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 3,
		baseMinimumDamageCoeff: 0,
		baseMaximumDamageCoeff: 0,
		baseAccuracyCoeff: 0,

		summonFriendly: {
			monsterID: 5,
			count: 2,
		},
		dealsNoDamage: true,

		maxTargets: 1,
	},
	49: {
		id: 49,
		abilityName: 'Defend Ally',
		abilityImage: '/images/combat/ability_icons/bulwark.png',
		description: 'Increase the defenses of an ally at the expense of your own.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0,
		baseMaximumDamageCoeff: 0,
		baseAccuracyCoeff: 999,

		targetFriendly: true,
		canTargetSelf: false,
		target: 'lowestDef',

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.wide_open,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 99,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.regal_might,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 99,
		},
		dealsNoDamage: true,

		skipReactives: true,

		maxTargets: 1,
	},
	50: {
		id: 50,
		abilityName: 'Lightning Strikes Twice',
		abilityImage: '/images/combat/ability_icons/lightning_strike.png',
		description: 'Surely this is an anomaly?',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Lightning', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Lightning', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.4,
		baseMinimumDamageCoeff: 0.6,
		baseMaximumDamageCoeff: 0.6,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	51: {
		id: 51,
		abilityName: "The Ol' One-Two : 2nd",
		abilityImage: '/images/combat/ability_icons/onetwo.png',
		description: "Hit 'em with the ol' one-two!",
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.33,
		baseMinimumDamageCoeff: 0.33,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	52: {
		id: 52,
		abilityName: 'Execute',
		abilityImage: '/images/combat/ability_icons/execute.png',
		description:
			"Executes the enemy! Deals up to 33% more damage based on the enemy's missing health, beginning at 50% target health.",
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1.25 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1.25 }],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1.25,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1.1,

		maxTargets: 1,
	},
	53: {
		id: 53,
		abilityName: 'The Limpe Sauce',
		abilityImage: '/images/combat/ability_icons/skull_flame.png',
		description: "WHERE'S THE LIMPE SAUCE?!",
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.fire_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1.05,

		maxTargets: 1,
	},
	54: {
		id: 54,
		abilityName: 'Taunt',
		abilityImage: '/images/combat/ability_icons/taunt.png',
		description: 'Taunts the enemy, significantly increasing your threat level.',
		damageType: 'Melee',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.taunting,
			enchantmentStrength: 8,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 40,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	55: {
		id: 55,
		abilityName: 'Poisonous Strike',
		abilityImage: '/images/combat/ability_icons/necrotic_touch_2.png',
		description: 'Poison!',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	56: {
		id: 56,
		abilityName: "INFO's Lesson",
		abilityImage: '/images/combat/ability_icons/apocalypse.png',
		description: "Did you know you won't survive this?",
		damageType: 'Magic',
		cooldown: 3000000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Chaos', scaling: 5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Chaos', scaling: 5 },
		],

		baseSpeedCoeff: 30,
		baseMinimumDamageCoeff: 25,
		baseMaximumDamageCoeff: 50,
		baseAccuracyCoeff: 99999,

		maxTargets: 10,

		skipReactives: true,
	},
	57: {
		id: 57,
		abilityName: 'Invisibility',
		abilityImage: '/images/combat/ability_icons/invisible.png',
		description:
			"Actually you're probably closer to just a little translucent, but if you claim to be invisible to sound cooler that's okay too.",
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.chaos_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		baseSpeedCoeff: 0.25,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.hard_to_hit,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 10,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	58: {
		id: 58,
		abilityName: 'Camouflage',
		abilityImage: '/images/combat/ability_icons/camo.png',
		description:
			'Wait, where did you go? Makes you harder to hit but does not inherently decrease your threat generation.',
		damageType: 'Range',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.25,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.hard_to_hit,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	59: {
		id: 59,
		abilityName: 'Icy Shell',
		abilityImage: '/images/combat/ability_icons/ice_armor.png',
		description:
			'Temporarily encase yourself in a shell of magical ice, increasing protection, resistance, and defensive ice affinity at the cost of agility. Melts very quickly!',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 2 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		baseSpeedCoeff: 0.333,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.ice_armor,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 16,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	60: {
		id: 60,
		abilityName: 'Set Traps',
		abilityImage: '/images/combat/ability_icons/trap.png',
		description: 'Sets a trap that significantly reduces agility of an enemy if they are hit by it.',
		damageType: 'Range',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1.25 }],

		baseSpeedCoeff: 2,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1.5,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.rooted,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 24,
		},

		maxTargets: 1,
	},
	61: {
		id: 61,
		abilityName: 'Infernal Impale',
		abilityImage: '/images/combat/ability_icons/infernal_impale.PNG',
		description: 'An enhanced impaling attack that can hit up to 2 enemies at once.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.15,
		baseMinimumDamageCoeff: 0.85,
		baseMaximumDamageCoeff: 1.2,
		baseAccuracyCoeff: 1,

		maxTargets: 2,
	},
	62: {
		id: 62,
		abilityName: 'Sever',
		abilityImage: '/images/combat/ability_icons/bleed.png',
		description: 'Target a weakpoint and cut open an artery, does 75 damage every 2 seconds 10 times.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.1,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,

		healthChangeEvent: {
			dotCount: 10,
			healthChange: -75,
			healthChangeDelay: 2000,
		},
	},
	63: {
		id: 63,
		abilityName: 'Quick Shot',
		abilityImage: '/images/combat/ability_icons/tripleshot.png',
		description: 'Fire off a quick shot.',
		damageType: 'Range',
		useRangedAmmo: true,
		cooldown: 10000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.8,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	64: {
		id: 64,
		abilityName: 'Swift Shot',
		abilityImage: '/images/combat/ability_icons/tripleshot.png',
		description: 'Fire off a shot enhanced by the wind!',
		damageType: 'Range',
		useRangedAmmo: true,
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.66,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 0.95,

		maxTargets: 1,
	},
	65: {
		id: 65,
		abilityName: 'Tank Taunt',
		abilityImage: '/images/combat/ability_icons/taunt.png',
		description: 'An enhanced taunt. Significantly increases your threat and armor.',
		damageType: 'Melee',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.tank_taunting,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 99,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	66: {
		id: 66,
		abilityName: 'Impale',
		abilityImage: '/images/combat/ability_icons/infernal_impale.PNG',
		description: 'An impaling attack that can hit up to 2 enemies at once.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.15,
		baseMinimumDamageCoeff: 0.66,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 2,
	},
	67: {
		id: 67,
		abilityName: 'Ice Shard',
		abilityImage: '/images/combat/ability_icons/ice_shard.png',
		description:
			'Launch a magically hardened ice into your opponent to deal an impressive amount of piercing and ice damage.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	68: {
		id: 68,
		abilityName: 'Enhanced Ice Slash',
		abilityImage: '/images/combat/ability_icons/ice_shard.png',
		description: 'Harness the power of ice to temporarily add an incredibly sharp edge to your blade.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Ice', scaling: 1.1 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Ice', scaling: 1.1 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	69: {
		id: 69,
		abilityName: 'Flaming Fist: Jab',
		abilityImage: '/images/combat/ability_icons/flame_fist.png',
		description: 'Throw a piercing punch wreathed in flames.',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.3 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.3 },
		],

		baseSpeedCoeff: 0.66,
		baseMinimumDamageCoeff: 0.66,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	70: {
		id: 70,
		abilityName: 'Flaming Fist: Haymaker',
		abilityImage: '/images/combat/ability_icons/flame_fist.png',
		description: 'Throw a powerful punch wreathed in flames.',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.3 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.3 },
		],

		baseSpeedCoeff: 0.8,
		baseMinimumDamageCoeff: 0.8,
		baseMaximumDamageCoeff: 0.95,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	71: {
		id: 71,
		abilityName: 'Flaming Fist: Chop',
		abilityImage: '/images/combat/ability_icons/flame_fist.png',
		description: 'Throw a powerful chopping attack wreathed in flames. Can somehow hit two targets!',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.3 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.5 },
			{ affinity: 'Fire', scaling: 1.3 },
		],

		baseSpeedCoeff: 0.66,
		baseMinimumDamageCoeff: 0.66,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 0.9,

		maxTargets: 2,
		diminishingAOEDamageMult: 0.75,
	},
	72: {
		id: 72,
		abilityName: 'Obliterating *Snap*',
		abilityImage: '/images/combat/ability_icons/snap.png',
		description: 'All it would take is a snap of your fingers to obliterate your targets.',
		damageType: 'Melee',
		cooldown: 60000,

		damageScaling: [{ affinity: 'Melee', scaling: 1.5 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1.5 }],

		baseSpeedCoeff: 0.33,
		baseMinimumDamageCoeff: 1.25,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1.5,

		maxTargets: 2,
		diminishingAOEDamageMult: 0.25,
	},
	73: {
		id: 73,
		abilityName: 'Annihilating *Snap*',
		abilityImage: '/images/combat/ability_icons/snap.png',
		description: 'All it would take is a snap of your fingers to annihilate your targets.',
		damageType: 'Magic',
		cooldown: 60000,

		damageScaling: [{ affinity: 'Magic', scaling: 1.5 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1.5 }],

		baseSpeedCoeff: 0.33,
		baseMinimumDamageCoeff: 1.25,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1.5,

		maxTargets: 2,
		diminishingAOEDamageMult: 0.25,
	},
	74: {
		id: 74,
		abilityName: 'Erasing *Snap*',
		abilityImage: '/images/combat/ability_icons/snap.png',
		description: 'All it would take is a snap of your fingers to erase your targets.',
		damageType: 'Range',
		cooldown: 60000,

		damageScaling: [{ affinity: 'Range', scaling: 1.5 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1.5 }],

		baseSpeedCoeff: 0.33,
		baseMinimumDamageCoeff: 1.25,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1.5,

		maxTargets: 2,
		diminishingAOEDamageMult: 0.25,
	},
	75: {
		id: 75,
		abilityName: "Phoenix's Flames",
		abilityImage: '/images/combat/ability_icons/phoenix.png',
		description: 'The flames of the phoenix burn brightly, if only for a short while.',
		damageType: 'Magic',
		cooldown: 60000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		baseSpeedCoeff: 1.5,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1.5,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.overheating,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 0.5,
			enchantmentAmount: 16,
		},

		maxTargets: 2,
		diminishingAOEDamageMult: 0.15,
	},
	76: {
		id: 76,
		abilityName: 'Acid Splash',
		abilityImage: '/images/combat/ability_icons/acid_wave.png',
		description: 'Splash an enemy with acid, heavily reducing their defenses.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 1.05,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.corrosion,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 0.9,
			enchantmentAmount: 4,
		},

		maxTargets: 1,
	},
	77: {
		id: 77,
		abilityName: 'Acid Wave',
		abilityImage: '/images/combat/ability_icons/acid_wave.png',
		description: 'Splash your enemies with acid, heavily reducing their defenses.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1.05,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.corrosion,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 0.75,
			enchantmentAmount: 3,
		},

		maxTargets: 3,
		diminishingAOEDamageMult: 0.25,
	},
	78: {
		id: 78,
		abilityName: 'Armor Rend',
		abilityImage: '/images/combat/ability_icons/armor_rend.png',
		description: 'Tear at the enemy armor, reducing its effectiveness.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 1.05,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.corrosion,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 0.8,
			enchantmentAmount: 5,
		},

		maxTargets: 1,
	},
	// This is for monsters since it can self target
	79: {
		id: 79,
		abilityName: 'Greater Elven Healing',
		abilityImage: '/images/combat/ability_icons/heal_2.png',
		description: 'Use nature magic to heal the lowest HP member of your party for 500 health over 30 seconds.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0,
		baseMaximumDamageCoeff: 0,
		baseAccuracyCoeff: 999,

		targetFriendly: true,
		canTargetSelf: true,
		target: 'lowestHP',

		healthChangeEvent: {
			dotCount: 30,
			healthChange: 20,
			healthChangeDelay: 1000,
		},

		skipReactives: true,
		dealsNoDamage: true,

		maxTargets: 1,
	},
	80: {
		id: 80,
		abilityName: 'Cleanse Ally',
		abilityImage: '/images/combat/ability_icons/bulwark.png',
		description: 'Cleanse an ally of some debuffs.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0,
		baseMaximumDamageCoeff: 0,
		baseAccuracyCoeff: 999,

		targetFriendly: true,
		canTargetSelf: false,

		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.cleanse,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
		},
		dealsNoDamage: true,

		skipReactives: true,

		maxTargets: 1,
	},
	81: {
		id: 81,
		abilityName: 'Skirmisher Stance',
		abilityImage: '/images/combat/ability_icons/skirmish.png',
		description:
			'Enter a skirmish stance, increases your ability to dodge attacks but limited your range damage output. Drains quickly.',
		damageType: 'Range',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.skirmishing_stance,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 40,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	82: {
		id: 82,
		abilityName: 'Sniper Stance',
		abilityImage: '/images/combat/ability_icons/skirmish.png',
		description:
			'Enter a sniper stance, decreases your ability to dodge attacks but increases your range damage output. Drains quickly.',
		damageType: 'Range',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.sniping_stance,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 40,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	83: {
		id: 83,
		abilityName: 'Shrimp Sting',
		abilityImage: '/images/combat/ability_icons/chaos_spider.png',
		description:
			'Throw the Shrimp Stinger at a target to poison them and deal 1500 damage over 15 seconds on top of your base damage.',
		damageType: 'Range',
		cooldown: 9000,
		runeCost: [{ itemID: itemsIds.chaos_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Range', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.8,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1,

		healthChangeEvent: {
			dotCount: 15,
			healthChange: -100,
			healthChangeDelay: 1000,
		},

		maxTargets: 1,
	},
	84: {
		id: 84,
		abilityName: 'Shrimp Whip',
		abilityImage: '/images/combat/ability_icons/chaos_melee_2.png',
		description: 'Whip a target to poison them and deal 1500 damage over 15 seconds on top of your base damage.',
		damageType: 'Melee',
		cooldown: 9000,
		runeCost: [{ itemID: itemsIds.chaos_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.8,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1,

		healthChangeEvent: {
			dotCount: 15,
			healthChange: -100,
			healthChangeDelay: 1000,
		},

		maxTargets: 1,
	},
	85: {
		id: 85,
		abilityName: 'Shrimp Mist',
		abilityImage: '/images/combat/ability_icons/chaos_swirl.png',
		description:
			'Spray poison mist at up to 3 enemies, doing 750 damage over 15 seconds to each on top of your base damage.',
		damageType: 'Magic',
		cooldown: 9000,
		runeCost: [{ itemID: itemsIds.chaos_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1,

		healthChangeEvent: {
			dotCount: 15,
			healthChange: -50,
			healthChangeDelay: 1000,
		},

		maxTargets: 3,
		diminishingAOEDamageMult: 0.15,
	},
	86: {
		id: 86,
		abilityName: 'Lesser Ward',
		abilityImage: '/images/combat/ability_icons/ward.png',
		description:
			'Use your focus to conjure a low strength temporary ward, increasing protection and resistance but decreasing intellect.',
		damageType: 'Magic',
		cooldown: 20000,

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.warded,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 16,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	87: {
		id: 87,
		abilityName: 'Deadshot Stance',
		abilityImage: '/images/combat/ability_icons/deadshot_alt2.png',
		description:
			'Focus your entire being into striking true; massively decreases your ability to dodge attacks but increases your range damage output. Drains quickly.',
		damageType: 'Range',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.sniping_stance,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 40,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	88: {
		id: 88,
		abilityName: 'Dwarven Strike',
		abilityImage: '/images/combat/ability_icons/titanicstrike.png',
		description: 'A slow but incredibly heavy hitting blunt attack.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 2.25 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 2.25 },
		],

		baseSpeedCoeff: 2,
		baseMinimumDamageCoeff: 1.1,
		baseMaximumDamageCoeff: 2.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	89: {
		id: 89,
		abilityName: 'Greater Nature Bolt',
		abilityImage: '/images/combat/ability_icons/nature_bolt.png',
		description: 'Channel ancient powers to cast a nearly pure bolt of nature magic.',
		damageType: 'Magic',
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 2 }],
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 2 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 3 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1.05,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	90: {
		id: 90,
		abilityName: 'Greater Icy Shell',
		abilityImage: '/images/combat/ability_icons/ice_armor.png',
		description:
			'Temporarily encase yourself in a shell of magical ice, increasing protection, resistance, and defensive ice affinity at the cost of agility. Melts very quickly!',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 3 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		baseSpeedCoeff: 0.333,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.ice_armor,
			enchantmentStrength: 8,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 16,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	91: {
		id: 91,
		abilityName: 'Greater Ice Bolt',
		abilityImage: '/images/combat/ability_icons/ice_bolt.png',
		description: 'Channel ancient powers to cast a nearly pure bolt of ice magic.',
		damageType: 'Magic',
		runeCost: [{ itemID: itemsIds.water_rune, amount: 3 }],
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 2 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 3 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1.05,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	92: {
		id: 92,
		abilityName: 'Hailstorm',
		abilityImage: '/images/combat/ability_icons/snowflake.png',
		description: 'Send a stream of hail at your enemies!',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 3 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.3,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 0.66,

		maxTargets: 3,
		diminishingAOEDamageMult: 0.25,
	},
	93: {
		id: 93,
		abilityName: 'Ice Trident',
		abilityImage: '/images/combat/ability_icons/trident.png',
		description: 'Throw a magical trident of ice!',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1.15,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1.5,

		maxTargets: 1,
	},
	94: {
		id: 94,
		abilityName: 'Ebb',
		abilityImage: '/images/combat/ability_icons/ice_blade.png',
		description: '... and strike like a wave!',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1,

		maxTargets: 2,
		diminishingAOEDamageMult: 0.1,
	},
	95: {
		id: 95,
		abilityName: 'Flow',
		abilityImage: '/images/combat/ability_icons/trident.png',
		description: 'Flow like water...',
		damageType: 'Melee',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1,

		maxTargets: 2,
		diminishingAOEDamageMult: 0.1,
	},
	97: {
		id: 97,
		abilityName: 'Lightspeed',
		abilityImage: '/images/combat/ability_icons/empower.PNG',
		description:
			'Channel the near infinite potential of lightning to empower yourself and reach incredible speeds, if only for a short while.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Lightning', scaling: 5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Lightning', scaling: 5 },
		],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.2,
		baseMaximumDamageCoeff: 0.2,
		baseAccuracyCoeff: 2,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.acrobatics,
			enchantmentStrength: 11,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 10,
			refreshToAmount: 99,
		},

		maxTargets: 1,
	},
	98: {
		id: 98,
		abilityName: 'Lightning Bolt',
		abilityImage: '/images/combat/ability_icons/lightning_strike.png',
		description: 'Cast a bolt of lightning at your enemy!',
		damageType: 'Magic',
		runeCost: [{ itemID: itemsIds.air_rune, amount: 1 }],
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Lightning', scaling: 3 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Lightning', scaling: 3 },
		],

		baseSpeedCoeff: 0.8,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	99: {
		id: 99,
		abilityName: 'Bestial Camouflage',
		abilityImage: '/images/combat/ability_icons/camo.png',
		description: 'Blend into your surroundings like the predators of the forest.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.25,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.hard_to_hit,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 2,
		},

		maxTargets: 1,
	},
	100: {
		id: 100,
		abilityName: 'Wailing Souls',
		abilityImage: '/images/combat/ability_icons/wailing.png',
		description: 'Unleash some of the souls trapped in your weapon to demoralize your enemy.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Poison', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 0.5 },
		],
		accuracyScaling: [
			{ affinity: 'Poison', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 0.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.3,
		baseAccuracyCoeff: 3,

		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.weakness,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
		},

		healthChangeEvent: {
			dotCount: 10,
			healthChange: -10,
			healthChangeDelay: 1000,
		},

		maxTargets: 3,
	},
	101: {
		id: 101,
		abilityName: 'Blinding Souls',
		abilityImage: '/images/combat/ability_icons/wailing_2.png',
		description: 'Unleash some of the souls trapped in your weapon to blind your enemy.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Poison', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 0.5 },
		],
		accuracyScaling: [
			{ affinity: 'Poison', scaling: 1.5 },
			{ affinity: 'Melee', scaling: 0.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.3,
		baseAccuracyCoeff: 3,

		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.blind,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
		},

		healthChangeEvent: {
			dotCount: 10,
			healthChange: -10,
			healthChangeDelay: 1000,
		},

		maxTargets: 3,
	},
	102: {
		id: 102,
		abilityName: "Greater Nature's Sanctuary",
		abilityImage: '/images/combat/ability_icons/reinforce.png',
		description: "Command the staff to manifest nature's power to defend yourself and your allies.",
		damageType: 'Magic',
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 1 }],
		cooldown: 20000,

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		targetFriendly: true,
		canTargetSelf: true,

		skipReactives: true,
		dealsNoDamage: true,
		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.natures_sanctuary,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 99,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.natures_sanctuary,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 99,
		},

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	103: {
		id: 103,
		abilityName: 'Entangle',
		abilityImage: '/images/combat/ability_icons/root.png',
		description: "Command the staff to manifest nature's power to entangle your enemies with roots.",
		damageType: 'Magic',
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 1 }],
		cooldown: 20000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 3 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 3 },
		],

		baseSpeedCoeff: 1.5,
		baseMinimumDamageCoeff: 0.2,
		baseMaximumDamageCoeff: 0.8,
		baseAccuracyCoeff: 1.5,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.rooted,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 16,
		},

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	104: {
		id: 104,
		abilityName: 'Entangle - Thorns',
		abilityImage: '/images/combat/ability_icons/root.png',
		description:
			"Command the staff to manifest nature's power to entangle your enemies with roots bristling with thorns. These roots are much more fragile than their unthorned counterparts.",
		damageType: 'Magic',
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 1 }],
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 3 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Nature', scaling: 3 },
		],

		baseSpeedCoeff: 1.5,
		baseMinimumDamageCoeff: 0.2,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1.5,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.rooted,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 8,
		},
		healthChangeEvent: {
			dotCount: 5,
			healthChange: -20,
			healthChangeDelay: 1000,
		},

		maxTargets: 3,
		diminishingAOEDamageMult: 0.25,
	},
	105: {
		id: 105,
		abilityName: 'Daze',
		abilityImage: '/images/combat/ability_icons/daze.png',
		description: 'Strike your enemy and stun them momentarily to decrease their attack speed.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.66 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.66 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 1,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.stunned,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
		},

		maxTargets: 1,
	},
	106: {
		id: 106,
		abilityName: 'Bleed',
		abilityImage: '/images/combat/ability_icons/bleed.png',
		description: 'Stab at your enemy and cause them to bleed.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.1,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,

		healthChangeEvent: {
			dotCount: 10,
			healthChange: -30,
			healthChangeDelay: 2000,
		},
	},
	107: {
		id: 107,
		abilityName: "Forest's Embrace",
		abilityImage: '/images/combat/ability_icons/forest_heal.png',
		description: "Command the staff to manifest nature's power to heal yourself and your allies.",
		damageType: 'Magic',
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 1 }],
		cooldown: 30000,

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		targetFriendly: true,
		canTargetSelf: true,
		target: 'lowestHP',

		skipReactives: true,
		dealsNoDamage: true,
		baseSpeedCoeff: 3,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		healthChangeEvent: {
			dotCount: 5,
			healthChange: 50,
			healthChangeDelay: 1000,
		},

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	109: {
		id: 109,
		abilityName: 'Channel Resurrection',
		abilityImage: '/images/combat/ability_icons/ascend.PNG',
		description:
			'Use yourself as the catalyst for resurrection, sacrificing your own lifeforce and strength to save your allies. Takes some time to fully cast.',
		damageType: 'Magic',
		runeCost: [
			{ itemID: itemsIds.fire_rune, amount: 15 }, // fire rune
			{ itemID: itemsIds.nature_rune, amount: 15 }, // nature rune
			{ itemID: itemsIds.death_rune, amount: 15 }, // death rune
		],
		cooldown: 86400000,

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		targetFriendly: true,
		canTargetSelf: false,

		skipReactives: true,
		dealsNoDamage: true,
		baseSpeedCoeff: 20,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 100,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.resurrection_sickness,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 999999,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.pending_resurrection,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	110: {
		id: 110,
		abilityName: 'Prepare Resurrection',
		abilityImage: '/images/combat/ability_icons/ascend.PNG',
		description:
			'Use the latent power of the Phoenix to prepare yourself for resurrection. Takes some time to fully cast but may singe any enemy who gets close during the process.',
		damageType: 'Magic',
		runeCost: [
			{ itemID: itemsIds.fire_rune, amount: 5 }, // fire rune
			{ itemID: itemsIds.nature_rune, amount: 5 }, // nature rune
			{ itemID: itemsIds.death_rune, amount: 5 }, // death rune
		],
		cooldown: 86400000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 3 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 3 },
		],

		baseSpeedCoeff: 20,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.pending_resurrection,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},

		maxTargets: 1,
	},
	111: {
		id: 111,
		abilityName: 'Wrath',
		abilityImage: '/images/combat/ability_icons/skull_flame.png',
		description:
			'Channel your wrath to inflict massive damage. Deals up to 100% more damage based on your health; the lower your health, the higher the multiplier.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.25 },
			{ affinity: 'Fire', scaling: 1.25 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1.1,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	112: {
		id: 112,
		abilityName: 'War',
		abilityImage: '/images/combat/ability_icons/skull_flame.png',
		description: 'Enrage yourself and your allies.',
		damageType: 'Magic',
		runeCost: [
			{ itemID: 512, amount: 6 },
			{ itemID: 514, amount: 6 },
		],
		cooldown: 30000,

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],

		targetFriendly: true,
		canTargetSelf: true,
		target: 'lowestHP',

		skipReactives: true,
		dealsNoDamage: true,
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.enraged,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 10,
			refreshToAmount: 30,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.enraged,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 10,
			refreshToAmount: 30,
		},

		maxTargets: 5,
	},
	113: {
		id: 113,
		abilityName: "Blademaster's Slash",
		abilityImage: '/images/combat/ability_icons/cleave.png',
		description: 'Training and supernatural intuition have enhanced your ability to slash.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	114: {
		id: 114,
		abilityName: "Blademaster's Stab",
		abilityImage: '/images/combat/ability_icons/stab.png',
		description: 'Training and supernatural intuition have enhanced your ability to stab.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	115: {
		id: 115,
		abilityName: "Blademaster's Crush",
		abilityImage: '/images/combat/ability_icons/crush.png',
		description: 'Training and supernatural intuition have enhanced your ability to crush.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Blunt', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	116: {
		id: 116,
		abilityName: 'Blade Storm',
		abilityImage: '/images/combat/ability_icons/bladestorm.png',
		description: 'Become one with your blade and reap the battlefield.',
		damageType: 'Melee',
		cooldown: 60000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.75 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.75 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1,

		maxTargets: 5,
		diminishingAOEDamageMult: 0.05,
	},
	117: {
		id: 117,
		abilityName: 'Rallying Cry',
		abilityImage: '/images/combat/equipment/melee/banner.png',
		description: 'Rally your allies to increase their defenses.',
		damageType: 'Melee',
		cooldown: 60000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.25,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		targetFriendly: true,
		canTargetSelf: false,

		skipReactives: true,
		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.hold_the_line,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 12,
			refreshToAmount: 12,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.guarded,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 6,
			refreshToAmount: 6,
		},

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	118: {
		id: 118,
		abilityName: 'Taunting Rally',
		abilityImage: '/images/combat/equipment/melee/banner.png',
		description: 'Draw the enemy to you by taunting them with the banner.',
		damageType: 'Melee',
		cooldown: 60000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.25,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		targetFriendly: true,
		canTargetSelf: false,

		skipReactives: true,
		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.taunting,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 6,
			refreshToAmount: 6,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.camouflage,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 12,
			refreshToAmount: 12,
		},

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	119: {
		id: 119,
		abilityName: 'Demoralizing Shout',
		abilityImage: '/images/combat/equipment/melee/banner.png',
		description: 'Demoralize the enemy with the banner.',
		damageType: 'Melee',
		cooldown: 60000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 0.25,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		canTargetSelf: false,

		skipReactives: true,
		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.taunting,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 6,
			refreshToAmount: 6,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.stunned,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
		},

		maxTargets: 5,
		diminishingAOEDamageMult: 0.25,
	},
	120: {
		id: 120,
		abilityName: 'Necrotic Splash',
		abilityImage: '/images/combat/ability_icons/poison_ball.png',
		description: 'Overcome your foe with decay, reducing their defenses.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.death_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 1.05,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.corrosion,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 5,
		},

		maxTargets: 1,
	},
	121: {
		id: 121,
		abilityName: 'Necrotic Wave',
		abilityImage: '/images/combat/ability_icons/poison_ball.png',
		description: 'Overcome your foes with decay, reducing their defenses.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.death_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1.05,

		targetBuff: {
			onlyOnHit: true,
			enchantmentApply: enchantmentsIds.corrosion,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 7,
		},

		maxTargets: 3,
		diminishingAOEDamageMult: 0.25,
	},
	122: {
		id: 122,
		abilityName: 'Necrotic Touch',
		abilityImage: '/images/combat/ability_icons/necrotic_touch.png',
		description: 'Touch your enemy with the power of death.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.death_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.9,
		baseMinimumDamageCoeff: 1.25,
		baseMaximumDamageCoeff: 1.5,
		baseAccuracyCoeff: 1.25,

		maxTargets: 1,
	},
	123: {
		id: 123,
		abilityName: 'Mark',
		abilityImage: '/images/combat/ability_icons/mark.png',
		description: 'Marks your target, increasing their generated threat.',
		damageType: 'Range',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.marked,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.marked,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 2,
			refreshToAmount: 4,
		},

		maxTargets: 1,
	},
	124: {
		id: 124,
		abilityName: 'Defend',
		abilityImage: '/images/combat/ability_icons/ward.png',
		description: 'Protect yourself!',
		damageType: 'Melee',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 2,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.regal_might,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 8,
			refreshToAmount: 16,
		},

		maxTargets: 1,
	},
	125: {
		id: 125,
		abilityName: 'Sacrificial Pawns',
		abilityImage: '/images/combat/ability_icons/mark.png',
		description: 'Your teammates are pawns, sacrifice their defenses to save yourself.',
		damageType: 'Melee',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],

		baseSpeedCoeff: 2,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,
		targetFriendly: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.regal_might,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 8,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.wide_open,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 8,
		},

		maxTargets: 3,
	},
	126: {
		id: 126,
		abilityName: 'Ice Stab',
		abilityImage: '/images/combat/ability_icons/ice_shard.png',
		description: 'Stab at your enemy with a magically sharpened blade of ice.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.2,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	127: {
		id: 127,
		abilityName: 'Blood Offering',
		abilityImage: '/images/combat/ability_icons/blood_magic.png',
		description: 'Channel your life force to deal a massive amount of typeless damage.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.blood_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Typeless', scaling: 1.5 }],
		accuracyScaling: [{ affinity: 'Typeless', scaling: 3 }],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1.5,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 1.5,

		healthChangeEvent: {
			targetsSelf: true,
			dotCount: 5,
			healthChange: -200,
			healthChangeDelay: 1000,
		},

		maxTargets: 1,
	},
	128: {
		id: 128,
		abilityName: 'Blood Sacrifice',
		abilityImage: '/images/combat/ability_icons/blood_magic_2.png',
		description: 'Channel your life force to deal a massive amount of typeless damage.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.blood_rune, amount: 2 }],

		damageScaling: [{ affinity: 'Typeless', scaling: 1.5 }],
		accuracyScaling: [{ affinity: 'Typeless', scaling: 3 }],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 2,
		baseMaximumDamageCoeff: 4,
		baseAccuracyCoeff: 1.5,

		healthChangeEvent: {
			targetsSelf: true,
			dotCount: 10,
			healthChange: -200,
			healthChangeDelay: 1000,
		},

		maxTargets: 1,
	},
	129: {
		id: 129,
		abilityName: 'Chaotic Slash',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'A chaotic slash from your scythe.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.05 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Slashing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	130: {
		id: 130,
		abilityName: 'Tidal Fury',
		abilityImage: '/images/combat/ability_icons/trident.png',
		description: 'Use the power of the tides to rip through your enemies.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.75 },
			{ affinity: 'Piercing', scaling: 1.75 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.75 },
			{ affinity: 'Piercing', scaling: 1.75 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	131: {
		id: 131,
		abilityName: "Necromancer's Touch",
		abilityImage: '/images/combat/ability_icons/chaos_hand.png',
		description: 'Touch your enemy with the power of death.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	132: {
		id: 132,
		abilityName: 'Shrimp Crack',
		abilityImage: '/images/combat/ability_icons/shrimp.png',
		description: 'Whip it good!',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{
				affinity: 'Poison',
				scaling: 1.25,
			},
			{
				affinity: 'Slashing',
				scaling: 1.25,
			},
			{
				affinity: 'Chaos',
				scaling: 1.5,
			},
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{
				affinity: 'Poison',
				scaling: 1.25,
			},
			{
				affinity: 'Slashing',
				scaling: 1.25,
			},
			{
				affinity: 'Chaos',
				scaling: 1.5,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	133: {
		id: 133,
		abilityName: 'Shrimp Bolt',
		abilityImage: '/images/combat/ability_icons/shrimp.png',
		description: 'Fire a bolt of chaotic energy.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{
				affinity: 'Poison',
				scaling: 1.25,
			},
			{
				affinity: 'Chaos',
				scaling: 1.25,
			},
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{
				affinity: 'Poison',
				scaling: 1.25,
			},
			{
				affinity: 'Chaos',
				scaling: 1.25,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 0.9,

		maxTargets: 1,
	},
	134: {
		id: 134,
		abilityName: "Nature's Nocking",
		abilityImage: '/images/combat/ability_icons/deadshot_alt.png',
		description: 'The spirit of the forest nocks your arrow with you, enhancing its power.',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1.1 },
			{ affinity: 'Nature', scaling: 1.5 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1.1 },
			{ affinity: 'Nature', scaling: 1.5 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	135: {
		id: 135,
		abilityName: 'Infernal Puncture',
		abilityImage: '/images/combat/ability_icons/infernal_impale.PNG',
		description: 'The nature of your weapon enhances your stabs.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Fire', scaling: 1.5 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Fire', scaling: 1.5 },
			{ affinity: 'Piercing', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	136: {
		id: 136,
		abilityName: 'Gaia Strike',
		abilityImage: '/images/combat/ability_icons/titanicstrike.png',
		description: 'You are essentially hitting the enemy with a boulder.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 1.66 },
			{ affinity: 'Nature', scaling: 1.75 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 1.66 },
			{ affinity: 'Nature', scaling: 1.75 },
		],

		baseSpeedCoeff: 1.5,
		baseMinimumDamageCoeff: 1.1,
		baseMaximumDamageCoeff: 2.25,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	137: {
		id: 137,
		abilityName: 'Earthquake',
		abilityImage: '/images/combat/ability_icons/titanicstrike.png',
		description: 'Shatter the earth!',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 1.66 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Blunt', scaling: 1.66 },
		],

		baseSpeedCoeff: 1.33,
		baseMinimumDamageCoeff: 1.1,
		baseMaximumDamageCoeff: 1.75,
		baseAccuracyCoeff: 1,

		maxTargets: 5,
	},
	138: {
		id: 138,
		abilityName: 'Lesser Miasma',
		abilityImage: '/images/combat/ability_icons/miasma_1.png',
		description: 'Expel a cloud of lesser miasma at your enemies, dealing damage over time.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		maxTargets: 3,

		healthChangeEvent: {
			dotCount: 30,
			healthChange: -30,
			healthChangeDelay: 1000,
		},
	},
	139: {
		id: 139,
		abilityName: 'Decaying Grasp',
		abilityImage: '/images/combat/ability_icons/necrotic_touch.png',
		description: 'Imbue your grasp with dark energy and cause your enemy to rapidly decay.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.death_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1.25,

		maxTargets: 1,

		healthChangeEvent: {
			dotCount: 10,
			healthChange: -250,
			healthChangeDelay: 3000,
		},
	},
	140: {
		id: 140,
		abilityName: 'Lingering Flame',
		abilityImage: '/images/combat/ability_icons/fireball.png',
		description: 'Use magical flame to cause long-lasting burn damage.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.fire_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1,

		maxTargets: 3,

		healthChangeEvent: {
			dotCount: 20,
			healthChange: -33,
			healthChangeDelay: 1000,
		},
	},
	141: {
		id: 141,
		abilityName: 'Greater Miasma',
		abilityImage: '/images/combat/ability_icons/miasma_2.png',
		description: 'Expel a cloud of greater miasma at your enemies, dealing damage over time.',
		damageType: 'Magic',
		cooldown: 10000,
		runeCost: [{ itemID: itemsIds.death_rune, amount: 2 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 2.5,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		maxTargets: 3,

		healthChangeEvent: {
			dotCount: 30,
			healthChange: -150,
			healthChangeDelay: 1000,
		},
	},
	142: {
		id: 142,
		abilityName: 'Flaming Fist',
		abilityImage: '/images/combat/ability_icons/flame_fist.png',
		description: 'Your fists are on FIRE!!! …should you be worried?',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Fire', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.25 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.7,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	143: {
		id: 143,
		abilityName: 'Lesser Poison Bolt',
		abilityImage: '/images/combat/ability_icons/poison_bolt_2.png',
		description: 'Attune your magic to the element of poison and corruption...',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.8,
		baseMaximumDamageCoeff: 0.8,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	144: {
		id: 144,
		abilityName: 'Riptide',
		abilityImage: '/images/combat/ability_icons/ice_blade.png',
		description: 'Manifest the strength of the tides as you slash your enemies.',
		damageType: 'Melee',
		damageScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Ice',
				scaling: 1.33,
			},
		],
		accuracyScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Ice',
				scaling: 1.33,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	145: {
		id: 145,
		abilityName: 'Manifest Kalanahmatti',
		abilityImage: '/images/combat/ability_icons/ice_blade.png',
		description: 'This ancient shard is capable of manifesting itself in the most powerful ways...',
		damageType: 'Melee',
		runeCost: [{ itemID: itemsIds.water_rune, amount: 1 }],

		damageScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Ice',
				scaling: 1.5,
			},
		],
		accuracyScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Ice',
				scaling: 1.5,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	146: {
		id: 146,
		abilityName: 'Mauling Blow',
		abilityImage: '/images/combat/ability_icons/ice_shard.png',
		description: 'Swing your maul! Swing it!',
		damageType: 'Melee',
		runeCost: [{ itemID: itemsIds.earth_rune, amount: 1 }],

		damageScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Nature',
				scaling: 1.33,
			},
			{
				affinity: 'Blunt',
				scaling: 1.5,
			},
		],
		accuracyScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Nature',
				scaling: 1.33,
			},
			{
				affinity: 'Blunt',
				scaling: 1.5,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	147: {
		id: 147,
		abilityName: 'Fury',
		abilityImage: '/images/combat/ability_icons/skull_flame.png',
		description: 'Your weapon fills you with fury!',
		damageType: 'Melee',
		runeCost: [{ itemID: itemsIds.blood_rune, amount: 1 }],

		damageScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Blunt',
				scaling: 1.33,
			},
			{
				affinity: 'Slashing',
				scaling: 1.33,
			},
		],
		accuracyScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Blunt',
				scaling: 1.5,
			},
			{
				affinity: 'Slashing',
				scaling: 1.5,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.33,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	148: {
		id: 148,
		abilityName: 'Coral Claw',
		abilityImage: '/images/combat/ability_icons/onetwo.png',
		description: 'Coral is actually pretty sharp, definitely capable of damaging something.',
		damageType: 'Melee',

		damageScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Piercing',
				scaling: 1.5,
			},
		],
		accuracyScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Piercing',
				scaling: 1.5,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	149: {
		id: 149,
		abilityName: 'Crystal Crush',
		abilityImage: '/images/combat/ability_icons/shatterstrike.png',
		description: 'Crush your enemies with shards of crystal.',
		damageType: 'Melee',

		damageScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Blunt',
				scaling: 1.5,
			},
			{
				affinity: 'Chaos',
				scaling: 1.5,
			},
		],
		accuracyScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Blunt',
				scaling: 1.5,
			},
			{
				affinity: 'Chaos',
				scaling: 1.5,
			},
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.9,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	150: {
		id: 150,
		abilityName: 'Entropic Strike',
		abilityImage: '/images/combat/ability_icons/chaos_melee_3.png',
		description: 'All things eventually come to an end.',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 0.5,
		baseAccuracyCoeff: 1.75,

		maxTargets: 1,
	},
	151: {
		id: 151,
		abilityName: 'Piercing Ammo Specialization',
		abilityImage: '/images/combat/ability_icons/piercing_shot.png',
		description: 'Use piercing ammo to deal additional piercing damage at the expense of lower base damage.',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.6 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Piercing', scaling: 1.6 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	152: {
		id: 152,
		abilityName: 'Heavy Ammo Specialization',
		abilityImage: '/images/combat/ability_icons/piercing_shot.png',
		description: 'Use heavy ammo to deal additional blunt damage at the expense of lower base damage.',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.6 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.6 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	153: {
		id: 153,
		abilityName: 'Serrated Ammo Specialization',
		abilityImage: '/images/combat/ability_icons/piercing_shot.png',
		description: 'Use serrated ammo to deal additional slashing damage at the expense of lower base damage.',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.6 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Slashing', scaling: 1.6 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	154: {
		id: 154,
		abilityName: 'Mark II',
		abilityImage: '/images/combat/ability_icons/mark.png',
		description: 'Enhanced variant of the Mark ability; marks your target, increasing their generated threat.',
		damageType: 'Range',
		cooldown: 25000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,

		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.marked,
			enchantmentStrength: 4,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 4,
			refreshToAmount: 4,
		},

		maxTargets: 1,
	},
	155: {
		id: 155,
		abilityName: 'Mantis Punch',
		abilityImage: '/images/combat/ability_icons/snap.png',
		description: 'Melee Mantis Punch',
		damageType: 'Melee',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.95,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	156: {
		id: 156,
		abilityName: 'Mantis Blast',
		abilityImage: '/images/combat/ability_icons/snap.png',
		description: 'Range Mantis Punch',
		damageType: 'Range',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Range', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.95,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	157: {
		id: 157,
		abilityName: 'Mantis Bolt',
		abilityImage: '/images/combat/ability_icons/snap.png',
		description: 'Magic Mantis Punch',
		damageType: 'Magic',
		cooldown: 10000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.95,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	158: {
		id: 158,
		abilityName: 'Mortar',
		abilityImage: '/images/combat/ability_icons/mortar.png',
		description: 'Lob an indirect shot into the midst of your enemies.',
		damageType: 'Range',
		cooldown: 30000,
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.33 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.33 },
		],

		baseSpeedCoeff: 2,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 0.75,

		maxTargets: 3,
		diminishingAOEDamageMult: 0.1,
	},
	159: {
		id: 159,
		abilityName: 'Grapeshot',
		abilityImage: '/images/combat/ability_icons/cannon_shot.png',
		description: 'Fire a spray of smaller projectiles. Hard to miss, but lots of variance in damage.',
		damageType: 'Range',
		cooldown: 15000,
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.33 },
			{ affinity: 'Piercing', scaling: 1.33 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.33 },
			{ affinity: 'Piercing', scaling: 1.33 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 1.25,

		maxTargets: 2,
		diminishingAOEDamageMult: 0.1,
	},
	160: {
		id: 160,
		abilityName: 'Hot Shot',
		abilityImage: '/images/combat/ability_icons/hot_shot.png',
		description: 'Use an excessive amount of powder to overcharge this shot.',
		damageType: 'Range',
		cooldown: 15000,
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.75 },
			{ affinity: 'Piercing', scaling: 1.75 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.33 },
			{ affinity: 'Piercing', scaling: 1.33 },
		],

		baseSpeedCoeff: 1.1,
		baseMinimumDamageCoeff: 1.25,
		baseMaximumDamageCoeff: 2,
		baseAccuracyCoeff: 1.25,

		maxTargets: 1,
	},
	161: {
		id: 161,
		abilityName: 'Prepare Volley',
		abilityImage: '/images/combat/ability_icons/deadshot_alt2.png',
		description:
			'Prepare yourself to unleash your next shots with enhanced damage, at the cost of agility. Drains very quickly!',
		damageType: 'Range',
		cooldown: 20000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,
		skipReactives: true,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.sniping_stance,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 9,
		},

		maxTargets: 1,
	},
	162: {
		id: 162,
		abilityName: 'Fire Cannon',
		abilityImage: '/images/combat/ability_icons/cannon.png',
		description: 'Fire your cannon!',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1.05 },
			{ affinity: 'Blunt', scaling: 1.66 },
			{ affinity: 'Piercing', scaling: 1.66 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1.05 },
			{ affinity: 'Blunt', scaling: 1.1 },
			{ affinity: 'Piercing', scaling: 1.1 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	163: {
		id: 163,
		abilityName: 'Fire Handgonne',
		abilityImage: '/images/combat/ability_icons/cannon.png',
		description: 'Pew!',
		damageType: 'Range',
		useRangedAmmo: true,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.4 },
			{ affinity: 'Piercing', scaling: 1.4 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Blunt', scaling: 1.1 },
			{ affinity: 'Piercing', scaling: 1.1 },
		],

		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.1,
		baseAccuracyCoeff: 1,

		maxTargets: 1,
	},
	164: {
		id: 164,
		abilityName: 'Master of Flames',
		abilityImage: '/images/combat/ability_icons/fireball.png',
		description: 'Burns your enemies with little up front damage, but a lingering effect.',
		damageType: 'Magic',
		cooldown: 25000,
		runeCost: [{ itemID: itemsIds.fire_rune, amount: 1 }],
		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.4 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Fire', scaling: 1.1 },
		],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.4,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		healthChangeEvent: {
			dotCount: 5,
			healthChange: -500,
			healthChangeDelay: 5000,
		},
	},
	165: {
		id: 165,
		abilityName: 'Frostbite',
		abilityImage: '/images/combat/ability_icons/snowflake.png',
		description:
			'Applies 3 stacks of Frostbite 3 to your enemy, reducing their Protection and Resistance by 45%, as well as a DoT.',
		damageType: 'Magic',
		cooldown: 25000,
		runeCost: [{ itemID: itemsIds.water_rune, amount: 2 }],
		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.4 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.1 },
		],
		baseSpeedCoeff: 0.6,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		healthChangeEvent: {
			dotCount: 5,
			healthChange: -1000,
			healthChangeDelay: 5000,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.frostbite,
			enchantmentStrength: 3,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 3,
		},
	},
	166: {
		id: 166,
		abilityName: 'Third Eye',
		abilityImage: '/images/combat/ability_icons/third_eye.png',
		description:
			'Release powerful magical energy and let the physical world fall away; enter a trance where the winds of magic are revealed to you, increasing your intellect by 50%, but decreasing your protection and resistance by the same amount. This state cannot be maintained for long!',
		damageType: 'Magic',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.mind_rune, amount: 1 }],
		damageScaling: [{ affinity: 'Magic', scaling: 5 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 5 }],
		baseSpeedCoeff: 0.5,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.05,
		baseAccuracyCoeff: 1,
		maxTargets: 5,
		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.winds_of_magic,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 5,
			refreshToAmount: 5,
		},
	},
	167: {
		id: 167,
		abilityName: 'Grasp Heart',
		abilityImage: '/images/combat/ability_icons/heart.png',
		description:
			'Reach out and tear the life force from your enemy. If the enemy is below 33% health, this ability will deal 300% more damage, otherwise it will deal 25% damage. Applies the Heart Attack debuff, which lowers the damage the enemy deals.',
		damageType: 'Magic',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.blood_rune, amount: 1 }],
		damageScaling: [{ affinity: 'Magic', scaling: 1.1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1.1 }],
		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.5,
		baseMaximumDamageCoeff: 0.75,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.heart_attack,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 2,
			refreshToAmount: 10,
		},
	},
	168: {
		id: 168,
		abilityName: 'Virulent Plague',
		abilityImage: '/images/combat/ability_icons/plague.png',
		description: 'Cast a cloud of plague onto an enemy, dealing damage over time.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		maxTargets: 3,

		healthChangeEvent: {
			dotCount: 30,
			healthChange: -75,
			healthChangeDelay: 1000,
		},
	},
	169: {
		id: 169,
		abilityName: 'Noxious Dart',
		abilityImage: '/images/combat/ability_icons/range_poison.png',
		description:
			'The spirit of the forest will target anything you strike with this dart, dealing damage over time.',
		damageType: 'Range',
		useRangedAmmo: true,
		cooldown: 20000,

		damageScaling: [
			{ affinity: 'Range', scaling: 1.1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1.1 },
			{ affinity: 'Nature', scaling: 1.5 },
		],
		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.66,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 1,

		maxTargets: 1,

		healthChangeEvent: {
			dotCount: 30,
			healthChange: -60,
			healthChangeDelay: 1000,
		},
	},
	170: {
		id: 170,
		abilityName: 'Poison Arrow',
		abilityImage: '/images/combat/ability_icons/poison_arrow.png',
		damageType: 'Range',
		damageScaling: [
			{ affinity: 'Range', scaling: 1.1 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1.0 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		baseSpeedCoeff: 1.0,
		baseMinimumDamageCoeff: 0.2,
		baseMaximumDamageCoeff: 0.95,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		cooldown: 30000,
		description: 'Fire a specialized arrow dipped in a potent poison.',
		healthChangeEvent: {
			dotCount: 15,
			healthChange: -75,
			healthChangeDelay: 1500,
		},
		useRangedAmmo: true,
	},

	171: {
		id: 171,
		abilityName: 'Double Tap',
		damageType: 'Range',
		abilityImage: '/images/combat/ability_icons/tripleshot.png',
		damageScaling: [{ affinity: 'Range', scaling: 1.1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1.5 }],
		baseSpeedCoeff: 1.0,
		baseMinimumDamageCoeff: 0.33,
		baseMaximumDamageCoeff: 1.0,
		baseAccuracyCoeff: 0.75,
		maxTargets: 2,
		cooldown: 20000, // 20 seconds
		description:
			'Use a fancy elven technique to fire two powerful arrows in quick succession at two different targets. Has inherently lower accuracy, but skilled archers can easily overcome this shortcoming.',
		useRangedAmmo: true,
	},

	172: {
		id: 172,
		abilityName: 'Thunderclap',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/thunderclap.png',
		runeCost: [{ itemID: itemsIds.air_rune, amount: 1 }],
		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Lightning', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Lightning', scaling: 1.5 },
		],
		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.33,
		baseMaximumDamageCoeff: 0.9,
		baseAccuracyCoeff: 0.8,
		maxTargets: 5,
		cooldown: 30000,
		description:
			'An area-of-effect lightning attack that has a 50% chance on hit to apply Stunned 3 to enemies, reducing their attack speed by 30%.',
		targetBuff: {
			enchantmentApply: enchantmentsIds.stunned,
			enchantmentChanceToApply: 0.5,
			enchantmentAmount: 1,
			enchantmentStrength: 3,
			onlyOnHit: true,
			refreshToAmount: 1,
		},
	},

	173: {
		id: 173,
		abilityName: 'Chain Lightning',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/lightning_jump.png',
		runeCost: [{ itemID: itemsIds.air_rune, amount: 1 }],
		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Lightning', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Lightning', scaling: 1.5 },
		],
		baseSpeedCoeff: 1.1,
		baseMinimumDamageCoeff: 0.3,
		baseMaximumDamageCoeff: 1.1,
		baseAccuracyCoeff: 1.0,
		maxTargets: 2,
		cooldown: 30000,
		description:
			'Fire a bolt of lightning that jumps between enemies. Has a 50% chance on hit to apply Stunned 3 to enemies, reducing their attack speed by 30%.',
		targetBuff: {
			enchantmentApply: enchantmentsIds.stunned,
			enchantmentChanceToApply: 0.5,
			enchantmentAmount: 1,
			enchantmentStrength: 3,
			onlyOnHit: true,
			refreshToAmount: 1,
		},
	},

	174: {
		id: 174,
		abilityName: 'Greater Chain Lightning',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/lightning_jump.png',
		runeCost: [{ itemID: itemsIds.air_rune, amount: 1 }],
		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Lightning', scaling: 2.0 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Lightning', scaling: 2.0 },
		],
		baseSpeedCoeff: 1.1,
		baseMinimumDamageCoeff: 0.3,
		baseMaximumDamageCoeff: 1.3,
		baseAccuracyCoeff: 1.0,
		maxTargets: 5,
		cooldown: 30000,
		description:
			'Fire a powerful bolt of lightning that jumps between enemies. Has a 50% chance on hit to apply Stunned 3 to enemies, reducing their attack speed by 30%.',
		targetBuff: {
			enchantmentApply: enchantmentsIds.stunned,
			enchantmentChanceToApply: 0.5,
			enchantmentAmount: 1,
			enchantmentStrength: 3,
			onlyOnHit: true,
			refreshToAmount: 1,
		},
	},

	175: {
		id: 175,
		abilityName: 'Smoke Bomb',
		damageType: 'Range',
		abilityImage: '/images/combat/ability_icons/bomb.png',
		damageScaling: [{ affinity: 'Range', scaling: 1.1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1.1 }],
		baseSpeedCoeff: 1.0,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.3,
		baseAccuracyCoeff: 1.15,
		maxTargets: 3,
		cooldown: 30000,
		description:
			'Throw a smoke bomb at up to 3 enemies that has a 50% chance to blind them on hit, reducing their accuracy by 30%, and grants self camouflage to reduce threat by 50%.',
		targetBuff: {
			enchantmentApply: enchantmentsIds.blind,
			enchantmentChanceToApply: 0.5,
			enchantmentAmount: 2,
			enchantmentStrength: 3,
			onlyOnHit: true,
			refreshToAmount: 2,
		},
		selfBuff: {
			enchantmentApply: enchantmentsIds.camouflage,
			enchantmentChanceToApply: 1.0,
			enchantmentAmount: 6,
			enchantmentStrength: 3,
			refreshToAmount: 6,
			onlyOnHit: false,
		},
	},

	176: {
		id: 176,
		abilityName: 'Critical Strike',
		damageType: 'Melee',
		abilityImage: '/images/combat/ability_icons/enhanced_critical.png',
		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],
		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		cooldown: 5000,
		description: 'A standard melee attack that does 1.5x critical damage.',
		critical: {
			damageMult: 1.5, // +50% crit damage
		},
	},

	177: {
		id: 177,
		abilityName: 'Critical Shot',
		damageType: 'Range',
		abilityImage: '/images/combat/ability_icons/enhanced_critical_range.png',
		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],
		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		cooldown: 5000,
		description: 'A standard range attack that does 1.5x critical damage.',
		critical: {
			damageMult: 1.5, // +50% crit damage
		},
	},

	178: {
		id: 178,
		abilityName: 'Critical Bolt',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/enhanced_critical_magic.png',
		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		cooldown: 5000,
		description: 'A standard magic attack that does 1.5x critical damage.',
		critical: {
			damageMult: 1.5, // +50% crit damage
		},
	},

	179: {
		id: 179,
		abilityName: 'Take Aim',
		damageType: 'Range',
		abilityImage: '/images/combat/ability_icons/take_aim.png',
		cooldown: 30000,

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],
		baseSpeedCoeff: 0.01,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		canTargetSelf: true,
		dealsNoDamage: true,
		skipReactives: true,
		selfBuff: {
			enchantmentApply: enchantmentsIds.critical_strike,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 1,
			enchantmentStrength: 5,
			onlyOnHit: true,
			refreshToAmount: 1,
		},
		description:
			'While this is generally considered a Ranger technique, it can be applied to any attack. Focus on making your next hit count. Grants Critical Strike 5, increasing your chance to crit by 12.5% and crit multiplier by +0.125x.',
	},

	180: {
		id: 180,
		abilityName: 'Greater Take Aim',
		abilityImage: '/images/combat/ability_icons/take_aim.png',
		damageType: 'Range',
		cooldown: 45000,
		runeCost: [{ itemID: itemsIds.nature_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1 }],
		baseSpeedCoeff: 0.01,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		canTargetSelf: true,
		dealsNoDamage: true,
		skipReactives: true,
		selfBuff: {
			enchantmentApply: enchantmentsIds.critical_strike,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 1,
			enchantmentStrength: 10,
			onlyOnHit: true,
			refreshToAmount: 1,
		},
		description:
			'While this is generally considered a Ranger technique, it can be applied to any attack. The potent nature of this technique requires a sacrifice of a Nature Rune. Focus on making your next hit count. Grants Critical Strike 10, increasing your chance to crit by 25% and crit multiplier by +0.25.',
	},

	181: {
		id: 181,
		abilityName: 'Stone Skin Self',
		abilityImage: '/images/combat/ability_icons/stone_skin.png',
		damageType: 'Magic',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.earth_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 0.01,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		targetFriendly: true,
		canTargetSelf: true,
		dealsNoDamage: true,
		skipReactives: true,
		selfBuff: {
			enchantmentApply: enchantmentsIds.guarded,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			enchantmentStrength: 5,
			onlyOnHit: true,
			refreshToAmount: 6,
		},
		description:
			'Make your skin as hard as stone for a short time, grants Guarded 5, doubling your protection and resistance.',
	},

	182: {
		id: 182,
		abilityName: 'Stone Skin Party',
		abilityImage: '/images/combat/ability_icons/stone_skin.png',
		damageType: 'Magic',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.earth_rune, amount: 2 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 0.01,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,
		maxTargets: 5,
		targetFriendly: true,
		canTargetSelf: true,
		dealsNoDamage: true,
		skipReactives: true,
		selfBuff: {
			enchantmentApply: enchantmentsIds.guarded,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			enchantmentStrength: 5,
			onlyOnHit: true,
			refreshToAmount: 6,
		},
		description:
			'Makes the skin of your party as hard as stone for a short time, grants Guarded 5, doubling their protection and resistance.',
	},

	183: {
		id: 183,
		abilityName: 'Backstab',
		damageType: 'Melee',
		abilityImage: '/images/combat/ability_icons/backstab.png',
		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Piercing', scaling: 2 },
		],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1.25 }],
		baseSpeedCoeff: 1.0,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 0.5,
		maxTargets: 1,
		cooldown: 60000,
		description:
			'A melee single target attack with a base 50% chance to hit with improved melee accuracy scaling, but with an additive +100% crit chance and additive +100% crit damage.',
		critical: {
			chanceAdditive: 1, // +100% crit chance
			damageAdditive: 1, // +100% crit damage
		},
	},

	184: {
		id: 184,
		abilityName: 'Headshot',
		abilityImage: '/images/combat/ability_icons/headshot.png',
		damageType: 'Range',
		damageScaling: [{ affinity: 'Range', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Range', scaling: 1.25 }],
		baseSpeedCoeff: 1.0,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 0.5,
		maxTargets: 1,
		cooldown: 60000,
		description:
			'A range single target attack with a base 50% chance to hit with improved range accuracy scaling, but with an additive +100% crit chance and additive +100% crit damage.',
		critical: {
			chanceAdditive: 1, // +100% crit chance
			damageAdditive: 1, // +100% crit damage
		},
	},

	185: {
		id: 185,
		abilityName: 'Demonic Protection',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/demonic_prot.png',
		cooldown: 60000,
		runeCost: [{ itemID: itemsIds.blood_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 0.01,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,
		maxTargets: 5,
		targetFriendly: true,
		canTargetSelf: true,
		dealsNoDamage: true,
		skipReactives: true,
		selfBuff: {
			enchantmentApply: enchantmentsIds.demon_skin,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 6,
			enchantmentStrength: 5,
			onlyOnHit: true,
			refreshToAmount: 12,
		},
		targetBuff: {
			enchantmentApply: enchantmentsIds.demon_skin,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 6,
			enchantmentStrength: 5,
			onlyOnHit: true,
			refreshToAmount: 12,
		},
		description: 'Bind demonic energies into protection for your party, granting Demon Skin 5.',
	},

	186: {
		id: 186,
		abilityName: 'Mirror Image',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/mirror_image.png',
		cooldown: 30000,
		runeCost: [{ itemID: itemsIds.air_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 0.01,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
		canTargetSelf: true,
		dealsNoDamage: true,
		skipReactives: true,
		selfBuff: {
			enchantmentApply: enchantmentsIds.hard_to_hit,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			enchantmentStrength: 5,
			onlyOnHit: true,
			refreshToAmount: 6,
		},
		description: 'Cast a minor illusion to improve your odds at dodging for a short time.',
	},

	187: {
		id: 187,
		abilityName: 'Berserk',
		damageType: 'Melee',
		abilityImage: '/images/combat/ability_icons/berserk.png',
		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,
		skipReactives: true,
		maxTargets: 1,

		cooldown: 180000,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.berserker_rage,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 30,
			refreshToAmount: 60,
		},

		description:
			'Enter a berserker trance and enrage yourself. Increases your damage output and input by 15%, but increases your threat by 150%.',
	},

	188: {
		id: 188,
		abilityName: 'Lesser Soul Theft',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/soul_theft.png',
		runeCost: [{ itemID: itemsIds.blood_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		targetFriendly: true,
		dealsNoDamage: true,
		skipReactives: true,
		maxTargets: 1,
		cooldown: 120000, // 120 seconds
		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.soul_stealing,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 20,
		},
		description: 'Grants 3 stacks of Soul Stealing.',
	},

	189: {
		id: 189,
		abilityName: 'Greater Soul Theft',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/soul_theft.png',
		runeCost: [{ itemID: itemsIds.blood_rune, amount: 1 }],

		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		targetFriendly: true,
		dealsNoDamage: true,
		skipReactives: true,
		maxTargets: 5,
		cooldown: 180000, // 180 seconds
		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.soul_stealing,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 20,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.soul_stealing,
			enchantmentStrength: 5,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 3,
			refreshToAmount: 20,
		},
		description: 'Grants 3 stacks of Soul Stealing to your party.',
	},

	190: {
		id: 190,
		abilityName: 'Magic Missile',
		description:
			'Launch a enemy seeking magical missile at your opponent. It knows where it is because it knows where it is not.',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/magic_missile.png',
		cooldown: 5000,

		damageScaling: [{ affinity: 'Magic', scaling: 1.1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1.1 }],

		baseSpeedCoeff: 0.75,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 5,

		maxTargets: 1,
	},

	191: {
		id: 191,
		abilityName: 'Disintegrate',
		description: 'A ray of incredibly potent magic.',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/disintegrate.png',
		cooldown: 180000,
		runeCost: [{ itemID: itemsIds.chaos_rune, amount: 1 }],

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.0,
		baseMinimumDamageCoeff: 2.0,
		baseMaximumDamageCoeff: 2.0,
		baseAccuracyCoeff: 1.0,

		maxTargets: 1,
		critical: {
			damageMult: 2.0,
		},
	},

	192: {
		id: 192,
		abilityName: 'Cone of Frost',
		damageType: 'Magic',
		abilityImage: '/images/combat/ability_icons/frost_cone.png',
		runeCost: [{ itemID: itemsIds.water_rune, amount: 1 }],
		damageScaling: [
			{ affinity: 'Magic', scaling: 1.1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Ice', scaling: 1.5 },
		],
		baseSpeedCoeff: 1,
		baseMinimumDamageCoeff: 0.25,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1.0,
		maxTargets: 3,
		cooldown: 40000,
		description: 'Spray a cone of frost on your enemies, partially freezing them.',
		targetBuff: {
			enchantmentApply: enchantmentsIds.ice_prison,
			enchantmentChanceToApply: 1.0,
			enchantmentAmount: 3,
			enchantmentStrength: 3,
			onlyOnHit: true,
			refreshToAmount: 9,
		},
	},
	193: {
		id: 193,
		abilityName: 'Chaos Crush',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'Strike an enemy with the brute force of Chaos.',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Blunt',
				scaling: 1.5,
			},
			{
				affinity: 'Chaos',
				scaling: 1.5,
			},
		],
		accuracyScaling: [
			{
				affinity: 'Melee',
				scaling: 1.1,
			},
			{
				affinity: 'Blunt',
				scaling: 1.5,
			},
			{
				affinity: 'Chaos',
				scaling: 1.5,
			},
		],

		baseSpeedCoeff: 1.05,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.15,
		baseAccuracyCoeff: 0.8,

		maxTargets: 1,
	},

	194: {
		id: 194,
		abilityName: "Giant's Berserk",
		damageType: 'Melee',
		abilityImage: '/images/combat/ability_icons/berserk.png',
		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.01,
		baseMaximumDamageCoeff: 0.01,
		baseAccuracyCoeff: 1,

		dealsNoDamage: true,
		skipReactives: true,
		maxTargets: 1,

		cooldown: 180000,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.berserker_rage,
			enchantmentStrength: 2,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 30,
			refreshToAmount: 60,
		},

		description:
			'Channel the rage of the Giants for the misfortunes that have befallen them. Increases your damage output and input by 30%, but increases your threat by 300%.',
	},

	// Boss Specials
	// Shrimp God instant kill - targets half the party (5) and unless stunned or skipped will almost always one shot kill
	300: {
		id: 300,
		abilityName: 'Dimensional Tear',
		abilityImage: '/images/combat/ability_icons/dissolve_2.png',
		description: 'Become nothing.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 5 },
			{ affinity: 'Chaos', scaling: 5 },
			{ affinity: 'Poison', scaling: 5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		messages: {
			onCast: {
				message: 'You will join me in the void!',
				type: 'group-chat',
			},
			onKill: {
				message: 'It was inevitable.',
				type: 'whisper',
			},
		},

		baseSpeedCoeff: 7,
		baseMinimumDamageCoeff: 7,
		baseMaximumDamageCoeff: 14,
		baseAccuracyCoeff: 10,

		healthChangeEvent: {
			dotCount: 50,
			healthChange: -1000,
			healthChangeDelay: 7500,
		},

		maxTargets: 5,
	},
	301: {
		id: 301,
		abilityName: 'Greater Void Resolution',
		abilityImage: '/images/combat/ability_icons/dissolve_2.png',
		description: 'Become nothing.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.transcendant_vitality,
			enchantmentStrength: 100,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 9999,
			refreshToAmount: 9999,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.enhanced_stun,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 0.25,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},
		messages: {
			onCast: {
				message: 'How could you ever hope to subdue the Abyss?',
				type: 'group-chat',
			},
		},

		maxTargets: 10,
	},
	302: {
		id: 302,
		abilityName: 'Lesser Void Resolution',
		abilityImage: '/images/combat/ability_icons/dissolve_2.png',
		description: 'Become nothing.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.transcendant_vitality,
			enchantmentStrength: 10,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 9999,
			refreshToAmount: 9999,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.enhanced_stun,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 0.25,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},

		maxTargets: 10,
	},
	303: {
		id: 303,
		abilityName: 'Greater Void Verdict',
		abilityImage: '/images/combat/ability_icons/dissolve_2.png',
		description: 'Become nothing.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.unbreakable_will,
			enchantmentStrength: 100,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 9999,
			refreshToAmount: 9999,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.enhanced_silence,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 0.25,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},
		messages: {
			onCast: {
				message: 'You dare to think you can silence the Abyss? Foolish.',
				type: 'group-chat',
			},
		},

		maxTargets: 10,
	},
	304: {
		id: 304,
		abilityName: 'Lesser Void Verdict',
		abilityImage: '/images/combat/ability_icons/dissolve_2.png',
		description: 'Become nothing.',
		damageType: 'Magic',

		damageScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],

		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 0.1,
		baseMaximumDamageCoeff: 0.25,
		baseAccuracyCoeff: 1,

		selfBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.unbreakable_will,
			enchantmentStrength: 10,
			enchantmentChanceToApply: 1,
			enchantmentAmount: 999,
			refreshToAmount: 999,
		},
		targetBuff: {
			onlyOnHit: false,
			enchantmentApply: enchantmentsIds.enhanced_silence,
			enchantmentStrength: 1,
			enchantmentChanceToApply: 0.25,
			enchantmentAmount: 1,
			refreshToAmount: 1,
		},

		maxTargets: 10,
	},
	305: {
		id: 305,
		abilityName: 'Structural Dissolution',
		abilityImage: '/images/combat/ability_icons/dissolve_2.png',
		description: 'Become nothing.',
		damageType: 'Melee',

		damageScaling: [
			{ affinity: 'Melee', scaling: 5 },
			{ affinity: 'Chaos', scaling: 5 },
			{ affinity: 'Poison', scaling: 5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		messages: {
			onCast: {
				message: 'So fragile. So weak. Your kind are nothing.',
				type: 'group-chat',
			},
			onKill: {
				message: 'A mere inconvenience, nothing more.',
				type: 'whisper',
			},
		},

		baseSpeedCoeff: 7,
		baseMinimumDamageCoeff: 7,
		baseMaximumDamageCoeff: 14,
		baseAccuracyCoeff: 10,

		healthChangeEvent: {
			dotCount: 50,
			healthChange: -1000,
			healthChangeDelay: 7500,
		},

		maxTargets: 5,
	},
	306: {
		id: 306,
		abilityName: 'Spatial Rending',
		abilityImage: '/images/combat/ability_icons/dissolve_2.png',
		description: 'Become nothing.',
		damageType: 'Range',

		damageScaling: [
			{ affinity: 'Range', scaling: 5 },
			{ affinity: 'Chaos', scaling: 5 },
			{ affinity: 'Poison', scaling: 5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1.5 },
			{ affinity: 'Chaos', scaling: 1.5 },
			{ affinity: 'Poison', scaling: 1.5 },
		],
		messages: {
			onCast: {
				message: 'So readily destroyed, did you expect anything else?',
				type: 'group-chat',
			},
			onKill: {
				message: 'I hunger for more. Bring me more.',
				type: 'whisper',
			},
		},

		baseSpeedCoeff: 7,
		baseMinimumDamageCoeff: 7,
		baseMaximumDamageCoeff: 14,
		baseAccuracyCoeff: 10,

		healthChangeEvent: {
			dotCount: 50,
			healthChange: -1000,
			healthChangeDelay: 7500,
		},

		maxTargets: 5,
	},
	307: {
		// Ranged Chaos Rend
		id: 307,
		abilityName: 'Greater Chaotic Spikes',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'A greater chaos range attack',
		damageType: 'Range',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 0.9,

		maxTargets: 6,
		diminishingAOEDamageMult: 0.1,
	},
	308: {
		id: 308,
		abilityName: 'Lesser Chaotic Spikes',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'A lesser chaos range attack.',
		damageType: 'Range',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Range', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.05,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.15,
		baseAccuracyCoeff: 0.8,

		maxTargets: 3,
		diminishingAOEDamageMult: 0.1,
	},
	309: {
		// Magic Chaos Rend
		id: 309,
		abilityName: 'Greater Void Ray',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'A greater chaos magic attack',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 0.9,

		maxTargets: 6,
		diminishingAOEDamageMult: 0.1,
	},
	310: {
		id: 310,
		abilityName: 'Lesser Void Ray',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'A lesser chaos magic attack.',
		damageType: 'Magic',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Magic', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.05,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1.15,
		baseAccuracyCoeff: 0.8,

		maxTargets: 3,
		diminishingAOEDamageMult: 0.1,
	},
	311: {
		// Raid version of Chaos Rend
		id: 311,
		abilityName: 'Void Rend',
		abilityImage: '/images/combat/ability_icons/chaos_melee.png',
		description: 'A greater chaos melee attack',
		damageType: 'Melee',
		cooldown: 30000,

		damageScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],
		accuracyScaling: [
			{ affinity: 'Melee', scaling: 1 },
			{ affinity: 'Chaos', scaling: 1.5 },
		],

		baseSpeedCoeff: 1.25,
		baseMinimumDamageCoeff: 0.75,
		baseMaximumDamageCoeff: 1.25,
		baseAccuracyCoeff: 0.9,

		maxTargets: 6,
		diminishingAOEDamageMult: 0.1,
	},
	// The lesser version of void rend is just Greater Chaos Rend
	// Fake abilities
	10000: {
		id: 10000,
		abilityName: 'Deflected Damage',
		abilityImage: '/images/combat/ability_icons/deflect.png',
		description: 'Fake Ability to represent deflected damage from an incoming attack.',
		damageType: 'Melee',
		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
	},
	10001: {
		id: 10001,
		abilityName: 'Thorns Damage',
		abilityImage: '/images/combat/ability_icons/root.png',
		description: 'Fake Ability to represent deflected damage from an incoming attack.',
		damageType: 'Melee',
		damageScaling: [{ affinity: 'Melee', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Melee', scaling: 1 }],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
	},
	10002: {
		id: 10002,
		abilityName: 'Swift Agony Damage',
		abilityImage: '/images/combat/ability_icons/chaos_hand.png',
		description: 'Fake Ability to represent Swift Agony damage.',
		damageType: 'Magic',
		damageScaling: [{ affinity: 'Magic', scaling: 1 }],
		accuracyScaling: [{ affinity: 'Magic', scaling: 1 }],
		baseSpeedCoeff: 0.1,
		baseMinimumDamageCoeff: 1,
		baseMaximumDamageCoeff: 1,
		baseAccuracyCoeff: 1,
		maxTargets: 1,
	},
};
