import React from 'react';
import { itemList } from '../../../../utils/itemList';

import { Box, Grid, Heading } from '@chakra-ui/react';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import ItemSelector from '../../Inventory/ItemSelector';
import { isMystSeed } from '../../../../utils/farmingFunctions';
import { usePlayerField } from '../../../../hooks/hooks';
import { Item } from '../../Inventory/Item';
import { tabs } from '../../../../helper/NavigationTabsList';

function FarmingSeeds(props: {
	selectedSeed: IItem | null;
	stockpile: IItem[];
	selectSeed: (selectedSeed: IItem) => void;
}) {
	const skills = usePlayerField('skills');
	const seedList = props.stockpile.filter((item) => itemList[item.itemID].tags?.includes('seed'));
	seedList.sort(function (a, b) {
		const aData = itemList[a.itemID];
		const bData = itemList[b.itemID];
		if (!aData.farmingStats?.requiredLevel) return 1;
		if (!bData.farmingStats?.requiredLevel) return -1;
		if (aData.farmingStats?.requiredLevel < bData.farmingStats?.requiredLevel) return -1;
		if (aData.farmingStats?.requiredLevel > bData.farmingStats?.requiredLevel) return 1;
		const aName = aData.name.toUpperCase();
		const bName = bData.name.toUpperCase();
		if (aName < bName) return -1;
		if (aName > bName) return 1;
		return 0;
	});
	const seedCategories = {
		'Single Slot Seeds': seedList.filter((item) => {
			const itemData = itemList[item.itemID];
			return (
				!isMystSeed(item.itemID) &&
				itemData.farmingStats?.height === 1 &&
				itemData.farmingStats?.width === 1 &&
				!itemData.name.includes('Totem') &&
				!itemData.name.includes('Trap')
			);
		}),
		'Multi Slot Seeds': seedList.filter((item) => {
			const itemData = itemList[item.itemID];
			return (
				!isMystSeed(item.itemID) &&
				(itemData.farmingStats?.height !== 1 || itemData.farmingStats?.width !== 1) &&
				!itemData.name.includes('Trap')
			);
		}),
		'Mysterious Seeds': seedList.filter((item) => isMystSeed(item.itemID)),
		Totems: seedList.filter((item) => itemList[item.itemID].name.includes('Totem')),
		Traps: seedList.filter((item) => itemList[item.itemID].name.includes('Trap')),
	};
	Object.keys(seedCategories).forEach((categoryString) => {
		const category = categoryString as keyof typeof seedCategories;
		if (seedCategories[category].length === 0) delete seedCategories[category];
	});
	return (
		<Grid
			gridTemplateRows={`repeat(${Object.keys(seedCategories).length}, 27px max-content)`}
			className='idlescape-container idlescape-container-green'
			minWidth='300px'
			maxWidth='450px'
			width='100%'
			textAlign='center'
			flex='1'
			gap='10px'
		>
			{Object.entries(seedCategories).map(([category, items]) => {
				const lockedItems: IItem[] = [];
				const filterItems = items.filter((item) => {
					const itemData = itemList[item.itemID];
					const requiredLevel = itemData.farmingStats?.requiredLevel || 0;
					if (requiredLevel > skills.farming.level) {
						lockedItems.push(item);
						return false;
					}
					return true;
				});
				return (
					<React.Fragment key={category}>
						<Heading
							size={'md'}
							margin={'0'}
							borderBottom='1px solid white'
							borderRadius='10px'
							width='80%'
							justifySelf='center'
						>
							{category}
						</Heading>
						<ItemSelector
							selectedId={props.selectedSeed?.id ?? -1}
							select={(item: IItem) => props.selectSeed(item)}
							items={filterItems}
							multiSelection={false}
							overflowY='auto'
							justifyContent='center'
						>
							{lockedItems.map((item) => {
								const itemData = itemList[item.itemID];
								return (
									<Box key={item.id} position='relative'>
										<Item item={item} filter='grayscale(0.8)' />
										<Box
											className='insufficient-level'
											fontSize='14px'
											position='absolute'
											left='2px'
										>
											<img
												className='crafting-tab-icon'
												src={tabs.farming.icon}
												alt='farming-level'
											/>
											{itemData.farmingStats?.requiredLevel}
										</Box>
									</Box>
								);
							})}
						</ItemSelector>
					</React.Fragment>
				);
			})}
			{Object.keys(seedCategories).length === 0 && (
				<Heading size='md' margin='0' justifySelf='center' alignSelf='center'>
					Collect some seeds to start farming
				</Heading>
			)}
		</Grid>
	);
}

export default FarmingSeeds;
