import {
	Box,
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput, IdlescapeNumberInput } from '@idlescape/ui';
import React, { useEffect, useState } from 'react';
import {
	TInventoryType,
	TTrueInventoryType,
} from '../../../../../../game-server/src/modules/items/Inventory.interface';
import { IItem } from '../../../../../../game-server/src/modules/items/items.interface';
import useIsMobile from '../../../../hooks/useIsMobile';
import { socket } from '../../../../services/socket.service';
import { filterItem } from '../../../../helper/helperFunctions';
import { Item } from '../../../game/Inventory/Item';
import { itemOrder } from '../../../../services/PlayerManager';
import { usePlayerField } from '../../../../hooks/hooks';
import { GENERAL_CHAT_ID, SORTED_INVENTORIES } from '../../../../utils/constantsCollection';
import { IUser } from '../../../../../../game-server/src/repositories/User.repository';
import { gearSlots } from '../../../game/Combat/CombatCharacterEquipment';
import { TEquipments } from '../../../../../../game-server/src/modules/player/PlayerTypes';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';

const displayNames = {
	ammo: 'Ammo Pouch',
	augmentingItemSlot: 'Augmenting Queue',
	combatInventory: 'Combat Inventory',
	equipment: 'Equipment',
	stockpile: 'Stockpile',
	tacklebox: 'Tacklebox',
	vault: 'Vault',
	scrapQueue: 'Scrap Queue',
} as const;
const inventoryNames = Object.keys(displayNames) as Exclude<TInventoryType, 'cookingPot'>[];

export default function Inventories(props: { playerId: IUser['id']; username: IUser['username'] }) {
	const accountStatus = usePlayerField('accountStatus');
	const [inventories, setInventories] = useState<{ [key: string]: IItem[] }>({});
	const [equipment, setEquipment] = useState<TEquipments>({});
	const [filter, setFilter] = useState('');
	const [selectedItem, setSelectedItem] = useState<IItem | null>(null);
	const [removeAmount, setRemoveAmount] = useState(0);

	const isMobile = useIsMobile();

	useEffect(() => {
		socket.on('moderator:inventory:get', (data) => {
			if (data.playerId !== props.playerId) return;
			if (data.inventoryName === 'affixRerollOption' || data.inventoryName == 'affixRerollSelected') return;
			if (data.inventoryName === 'equipment') {
				setEquipment(data.items as TEquipments);
			} else {
				if (!SORTED_INVENTORIES.includes(data.inventoryName)) {
					data.items = (data.items as IItem[]).sort(itemOrder);
				}
				setInventories({
					...inventories,
					[data.inventoryName]: data.items as IItem[],
				});
			}
		});
		return () => {
			socket.off('moderator:inventory:get');
		};
	}, [props.playerId]);

	function getInventory(
		inventoryName: Exclude<TInventoryType, 'cookingPot' | 'affixRerollOption' | 'affixRerollSelected'>
	) {
		socket.emit('inventory:moderator:get', { playerId: props.playerId, inventoryName });
	}

	function isLoaded(inventoryName: TInventoryType) {
		if (inventoryName === 'equipment') return Object.keys(equipment).length !== 0;
		return inventories[inventoryName] !== undefined;
	}

	function handleOnClick(item: IItem) {
		const allowedStatuses = ['lead-mod', 'admin'];
		if (!allowedStatuses.includes(accountStatus)) return;
		setRemoveAmount(0);
		setSelectedItem(item);
	}

	function removeItem() {
		if (!selectedItem || removeAmount <= 0) return;
		socket.emit('chat:command:send', {
			channelId: GENERAL_CHAT_ID,
			commandString: `/takeitembyid ${props.playerId} ${selectedItem.id} ${removeAmount}`,
		});
		setRemoveAmount(0);
		setSelectedItem(null);
	}

	function renderEquipment() {
		return (
			<Box className='combat-gear'>
				<img src='/images/combat/combat_stick_figure.png' alt='Combat stick figure' />
				{gearSlots.map((slot) => {
					const item = equipment[slot];
					return (
						<Box key={slot} id={'gear-' + slot} className='combat-gear-item'>
							{item && (
								<Item
									item={item}
									onClick={() => handleOnClick(item)}
									width='48px'
									height='48px'
									margin='1px'
								/>
							)}
						</Box>
					);
				})}
			</Box>
		);
	}

	function renderItems(inventoryName: TTrueInventoryType) {
		const filteredItems = inventories[inventoryName].filter((item) => filterItem(item, filter));
		if (filteredItems.length === 0) return <div>No items</div>;
		const columns = inventoryName === 'combatInventory' ? '4' : 'auto-fill';
		const columnWidth = isMobile ? '50px' : '60px';
		return (
			<Grid gridTemplateColumns={`repeat(${columns}, ${columnWidth})`} gap='0.5rem'>
				{filteredItems.map((item) => (
					<Item key={item.id} item={item} onClick={() => handleOnClick(item)} />
				))}
			</Grid>
		);
	}

	return (
		<Tabs>
			<TabList flexWrap='wrap'>
				{inventoryNames.map((inventoryName) =>
					inventoryName === 'affixRerollOption' || inventoryName === 'affixRerollSelected' ? null : (
						<Tab key={inventoryName}>{displayNames[inventoryName]}</Tab>
					)
				)}
			</TabList>
			<TabPanels>
				{inventoryNames.map((inventoryName) =>
					inventoryName === 'affixRerollOption' || inventoryName === 'affixRerollSelected' ? null : (
						<TabPanel key={inventoryName} paddingX='0'>
							<Flex>
								<IdlescapeButton
									onClick={() => getInventory(inventoryName)}
									variant='green'
									size='medium'
								>
									{isLoaded(inventoryName) ? 'Reload' : 'Load'} Data
								</IdlescapeButton>
								{inventoryName !== 'equipment' && isLoaded(inventoryName) && (
									<IdlescapeInput
										value={filter}
										setValue={setFilter}
										placeholder='Filter'
										variant='search'
									/>
								)}
							</Flex>
							{inventoryName === 'equipment' && isLoaded(inventoryName) && renderEquipment()}
							{inventoryName !== 'equipment' && isLoaded(inventoryName) && renderItems(inventoryName)}
						</TabPanel>
					)
				)}
			</TabPanels>
			<Modal isOpen={selectedItem !== null} onClose={() => setSelectedItem(null)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Set new amount for {selectedItem?.name}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{selectedItem !== null && (
							<>
								<Box>
									How many {selectedItem?.name} do you want to remove:
									<IdlescapeNumberInput
										value={removeAmount}
										setValue={setRemoveAmount}
										placeholder='Items to remove'
										min={0}
										max={selectedItem.itemID === itemsIds.gold ? Infinity : selectedItem.stackSize}
									/>
								</Box>
								<Box>
									Or enter the new amount for {selectedItem?.name}:
									<IdlescapeNumberInput
										value={selectedItem.stackSize - removeAmount}
										setValue={(value) => {
											if (typeof value === 'number') {
												setRemoveAmount(selectedItem.stackSize - value);
											}
										}}
										placeholder='New amount'
										min={selectedItem.itemID === itemsIds.gold ? -Infinity : 0}
										max={selectedItem?.stackSize}
									/>
								</Box>
							</>
						)}
					</ModalBody>
					<ModalFooter>
						<IdlescapeButton onClick={() => setSelectedItem(null)} variant='red'>
							Cancel
						</IdlescapeButton>
						<IdlescapeButton onClick={removeItem} variant='green'>
							Remove
						</IdlescapeButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Tabs>
	);
}
