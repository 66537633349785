import React, { useEffect, useState } from 'react';
import VendorDialog from './VendorDialog';
import ConsumeDialog from './ConsumeDialog';
import DisenchantingDialog from './DisenchantingDialog';
import TabDialog from './TabDialog';
import { SellMarketplaceDialogue } from '../../../layout/dialogs/SellMarketplaceDialogue';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { activeTabAtom } from '../../../../atoms/activeTabAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { IItem, IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { socket } from '../../../../services/socket.service';
import { itemList } from '../../../../utils/itemList';
import { leagueList } from '../../../../utils/leagueList';
import { EnchantmentData } from '../../EnchantmentData';
import { buffStacks } from '../../../../utils/itemFunctions';
import { shareItemSelector } from '../../../../atoms/chat/chatHistoryAtom';
import { TokenInputDialog, RespecTokenDialog } from './TokenDialogs';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import { marketplaceItemAtom } from '../../../../atoms/marketplaceItemAtom';
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { combatZoneAtom } from '../../../../atoms/combatZoneAtom';
import {
	Flex,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Slider,
	SliderFilledTrack,
	SliderThumb,
	SliderTrack,
} from '@chakra-ui/react';
import { TTrueInventoryType } from '../../../../../../game-server/src/modules/items/Inventory.interface';
import { IdlescapeButton, IdlescapeNumberInput } from '@idlescape/ui';
import ResetNameDialog from './ResetNameDialog';
import { DO_NOT_RENAME } from '../../../../utils/constantsCollection';
import { TSkillName } from '../../../../../../game-server/src/Atypes/Skills';
import ClearAffixDialog from './ClearAffixDialog';
import { mobileTooltipViewAtom } from '../../../../atoms/mobileTooltipViewAtom';
import ItemTooltip from '../../Tooltips/ItemTooltip';
import useIsMobile from '../../../../hooks/useIsMobile';
import { marketplaceTabAtom } from '../../../../atoms/marketplaceTabAtom';

type TItemAction =
	| 'none'
	| 'vendor'
	| 'consume'
	| 'tokenInput'
	| 'disenchant'
	| 'marketplace'
	| 'tab'
	| 'resetName'
	| 'respecTalents'
	| 'clearAffix';

interface IItemDialogButton {
	key: string;
	color: string;
	text: string | React.ReactElement;
	onClick: () => void;
}

export interface IDialogProps {
	item: IItem;
	itemData: IItemData | undefined;
	name: string;
	sliderValue: number;
	inventoryName: TTrueInventoryType | '';
	onClose: () => void;
	renameTokenId?: IItem['id'];
}

function MainItemDialog(props: {
	item: IItem;
	inventoryName: TTrueInventoryType | '';
	onClose: () => void;
	doAction?: boolean;
	renameTokenId?: IItem['id'];
}) {
	const [itemAmount, setItemAmount] = useState(1);
	const [currentAction, setCurrentAction] = useState<TItemAction>('none');
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const setItemToBuy = useSetRecoilState(marketplaceItemAtom);
	const setMarketTab = useSetRecoilState(marketplaceTabAtom);
	const shareItem = useSetRecoilState(shareItemSelector);
	const setCombatZone = useSetRecoilState(combatZoneAtom);
	const [mobileView, setMobileView] = useRecoilState(mobileTooltipViewAtom);
	const isMobile = useIsMobile();

	const learnedAbilities = usePlayerField('learnedAbilities');
	const league = usePlayerField('league');
	const skills = usePlayerField('skills');

	const itemData = itemList[props.item.itemID];
	const name = props.item.name ?? itemData.name;

	const isInStockpile = props.inventoryName === 'stockpile';
	const isInVault = props.inventoryName === 'vault';
	const isInTacklebox = props.inventoryName === 'tacklebox';
	const isInAugmentation = props.inventoryName === 'augmentingItemSlot';
	const isInAmmo = props.inventoryName === 'ammo';

	const showMarketplace = leagueList[league].rules.marketplace;

	let missingRequirements = false;
	if (itemData.requiredLevel) {
		for (const [skillName, reqLevel] of Object.entries(itemData.requiredLevel)) {
			if (skills[skillName as TSkillName].level < reqLevel) {
				missingRequirements = true;
				break;
			}
		}
	}
	const actions: (() => void)[] = [];

	useEffect(() => {
		if (props.doAction && actions[0]) {
			// Exception for cosmetics as they come after equip
			if (itemData?.equipmentStats?.isTransmog) {
				handleCosmetic();
				return;
			} else {
				actions[0]();
			}
			props.onClose();
		}
	}, []);

	const buttons: IItemDialogButton[] = [];
	if (
		(itemData.id === itemsIds.talent_respec_token || itemData.id === itemsIds.tradeable_talent_respec_token) &&
		!isInVault
	) {
		buttons.push({
			key: 'respecTalents',
			color: 'green',
			text: 'Respec Talents',
			onClick: () => setCurrentAction('respecTalents'),
		});
	}
	if (itemData.isToken && !isInVault) {
		buttons.push({
			key: 'useToken',
			color: 'green',
			text: 'Use Token',
			onClick: handleToken,
		});
	}
	if (itemData.isTokenInput && !isInVault) {
		buttons.push({
			key: 'useTokenInput',
			color: 'green',
			text: 'Use Token',
			onClick: () => setCurrentAction('tokenInput'),
		});
	}
	if (itemData.essenceID && !isInVault) {
		buttons.push({
			key: 'consumeTalisman',
			color: 'red',
			text: 'Consume Talisman',
			onClick: handleConsumeTalisman,
		});
	}
	if (itemData.id === itemsIds.book || itemData.id === itemsIds.task) {
		buttons.push({
			key: 'readBook',
			color: 'red',
			text: 'Read Book',
			onClick: handleReadBook,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (itemData.fishingBait && !isInVault) {
		buttons.push({
			key: 'tacklebox',
			color: 'orange',
			text: isInTacklebox ? 'Take from Tacklebox' : 'Move to Tacklebox',
			onClick: handleTacklebox,
		});
		if (isInTacklebox) {
			buttons.push({
				key: 'baitToFront',
				color: 'green',
				text: 'Move to Front',
				onClick: handleBaitToFront,
			});
		}
	}
	if (itemData.champEncounter && !isInAugmentation && !isInVault) {
		buttons.push({
			key: 'combat',
			color: 'green',
			text: 'Prepare for Dungeon/Scroll',
			onClick: handleEliteScroll,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (itemData.class === 'equipment' && !isInAugmentation && itemData?.equipmentStats?.slot !== undefined) {
		buttons.push({
			key: 'equip',
			color: 'green',
			text: 'Equip',
			onClick: handleEquipItem,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (!isInAugmentation && itemData?.equipmentStats?.isTransmog) {
		buttons.push({
			key: 'equipCosmetic',
			color: 'green',
			text: 'Equip as Cosmetic',
			onClick: handleCosmetic,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (
		!isInVault &&
		(itemData.class === 'ammo' ||
			(itemData.class === 'rune' && itemData.tags?.includes('ammunition') && !isInVault))
	) {
		buttons.push({
			key: 'toggleAmmoPouch',
			color: missingRequirements && isInStockpile ? 'disabled' : 'green',
			text: missingRequirements && isInStockpile ? 'Missing Requirements' : 'Toggle Ammo Pouch',
			onClick: handleAmmoPouch,
		});
	}
	if (itemData.relatedAbility !== undefined && !isInVault && !isInTacklebox && !isInAugmentation) {
		buttons.push({
			key: 'learnAbility',
			color: learnedAbilities.indexOf(itemData.relatedAbility) === -1 ? 'green' : 'gray',
			text: learnedAbilities.indexOf(itemData.relatedAbility) === -1 ? 'Learn Ability' : 'Ability Known',
			onClick: learnedAbilities.indexOf(itemData.relatedAbility) === -1 ? handleLearnAbility : () => null,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (itemData.class === 'equipment' && props.renameTokenId !== undefined) {
		buttons.push({
			key: 'renameItem',
			color: 'green',
			text: 'Rename Item',
			onClick: () => setCurrentAction('tokenInput'),
		});
	}
	if (itemData.canBeOpened && !isInVault) {
		buttons.push({
			key: 'openItem',
			color: 'red',
			text: (
				<>
					Open {itemAmount} <img src={itemData.itemImage} alt={props.item.name} className='icon16' />
				</>
			),
			onClick: handleOpenItem,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (itemData.id === itemsIds.event_token && !isInVault) {
		buttons.push({
			key: 'openEventShop',
			color: 'green',
			text: 'Open Event Shop',
			onClick: handleEventToken,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (
		isInStockpile &&
		props.item.name !== itemData.name &&
		!props.item.forceUntradeable &&
		!DO_NOT_RENAME.includes(props.item.itemID)
	) {
		buttons.push({
			key: 'resetName',
			color: 'red',
			text: 'Reset Name',
			onClick: () => setCurrentAction('resetName'),
		});
	}
	if (
		props.item.enchantmentID !== undefined &&
		props.item.enchantmentStrength !== undefined &&
		!itemData.healing &&
		!isInTacklebox &&
		!isInVault &&
		!isInAugmentation
	) {
		buttons.push({
			key: 'disenchant',
			color: 'red',
			text: 'Disenchant',
			onClick: () => setCurrentAction('disenchant'),
		});
	}
	if (
		itemData.value !== undefined &&
		itemData.tradeable &&
		!isInVault &&
		!isInTacklebox &&
		!isInAugmentation &&
		showMarketplace
	) {
		buttons.push({
			key: 'openInMarketplace',
			color: 'green',
			text: (
				<>
					<img src='images/ui/marketplace_icon.png' alt='market' className='icon16' /> Open Marketplace
					Listings
					<img src='images/ui/marketplace_icon.png' alt='market' className='icon16' />
				</>
			),
			onClick: handleMarketplace,
		});
	}
	if (
		itemData.value !== undefined &&
		itemData.tradeable &&
		!isInVault &&
		!isInTacklebox &&
		!isInAugmentation &&
		!isInAmmo &&
		showMarketplace
	) {
		buttons.push({
			key: 'sellInMarketplace',
			color: 'green',
			text: (
				<>
					<img src='images/ui/marketplace_icon.png' alt='market' className='icon16' /> Sell on Marketplace
					<img src='images/ui/marketplace_icon.png' alt='market' className='icon16' />
				</>
			),
			onClick: () => setCurrentAction('marketplace'),
		});
	}
	if (
		(itemData.healing || itemData.stackStrength) &&
		isInStockpile &&
		props.item.enchantmentID &&
		!EnchantmentData.isCombatEnchantment(props.item.enchantmentID)
	) {
		const enchantmentMult = EnchantmentData.findEnchantmentByID(props.item.enchantmentID).stackMult;
		const stackCount = buffStacks(props.item.augmentations, itemData.stackMultiplier, enchantmentMult, itemAmount);

		buttons.push({
			key: 'consume',
			color: 'orange',
			text: (
				<>
					Drink {itemAmount} <img src={itemData.itemImage} alt={props.item.name} className='icon16' /> for{' '}
					{stackCount + ' actions'}
				</>
			),
			onClick: () => setCurrentAction('consume'),
		});
	}
	if (itemData.healing !== undefined && !isInVault) {
		buttons.push({
			key: 'addToInventory',
			color: 'orange',
			text: (
				<>
					Add {itemAmount} <img src={itemData.itemImage} alt={props.item.name} className='icon16' /> to combat
					inventory
				</>
			),
			onClick: handleFoodToInventory,
		});
		actions.push(buttons[buttons.length - 1].onClick);
	}
	if (!isInTacklebox && !isInAmmo) {
		buttons.push({
			key: 'share',
			color: 'red',
			text: 'Share in chat',
			onClick: handleShareItem,
		});
	}
	if (!isInTacklebox && !isInAugmentation && !isInAmmo) {
		buttons.push({
			key: 'toggleVault',
			color: 'blue',
			text: isInStockpile ? (
				<>
					Move to Vault
					<AiFillLock />
				</>
			) : (
				<>
					Move to Inventory
					<AiFillUnlock />
				</>
			),
			onClick: handleVaultItem,
		});
	}
	if (itemData.value !== undefined && !isInVault && !isInTacklebox && !isInAugmentation && !isInAmmo) {
		buttons.push({
			key: 'vendor',
			color: 'red',
			text: (
				<>
					Sell for {(itemData.value * itemAmount)?.toLocaleString('en-us')}{' '}
					<img src='/images/gold_coin.png' alt='coins' className='icon16' />
				</>
			),
			onClick: () => setCurrentAction('vendor'),
		});
	}
	if (itemData.heat !== undefined && !isInVault) {
		buttons.push({
			key: 'burn',
			color: 'red',
			text: (
				<>
					Burn for {(itemData.heat * itemAmount)?.toLocaleString('en-us')}{' '}
					<img src='/images/heat_small_icon.png' alt='heat' className='icon16' />
				</>
			),
			onClick: handleBurnLogs,
		});
	}
	if (!isInVault && props.item.affixes) {
		buttons.push({
			key: 'clearAffix',
			color: 'red',
			text: <>Clear Affixes</>,
			onClick: () => setCurrentAction('clearAffix'),
		});
	}
	if (isInVault || isInStockpile) {
		buttons.push({
			key: 'tabSelection',
			color: 'green',
			text: 'Open Tab Selection',
			onClick: () => setCurrentAction('tab'),
		});
	}

	function handleCosmetic() {
		const item = itemData;
		if (
			item.equipmentStats &&
			(item.equipmentStats.cosmeticSlot || item.equipmentStats.slot) &&
			item.equipmentStats.isTransmog
		) {
			socket.emit('settings:cosmetic', {
				slot: item.equipmentStats.cosmeticSlot ?? item.equipmentStats.slot,
				option: props.item.itemID,
			});
			props.onClose();
		}
	}

	function handleEliteScroll() {
		setActiveTab('attack');
		setCombatZone(itemData.champEncounter ?? -1);
		props.onClose();
	}

	function handleEquipItem() {
		socket.emit('equipment:equip', {
			inventoryItemID: props.item.id,
			sourceInventory: props.inventoryName,
		});
		props.onClose();
	}

	function handleAmmoPouch() {
		socket.emit('inventory:ammoPouchToggle', {
			inventoryItemID: props.item.id,
			itemAmount: itemAmount,
			sourceInventory: props.inventoryName,
		});
		props.onClose();
	}

	function handleFoodToInventory() {
		socket.emit('inventory:toCombatInventory', {
			inventoryItemID: props.item.id,
			itemAmount: itemAmount,
			sourceInventory: props.inventoryName,
		});
		props.onClose();
	}

	function handleShareItem() {
		shareItem(props.item.id.toString());
		props.onClose();
	}

	function handleBurnLogs() {
		socket.emit('consumable:burn', {
			inventoryItemID: props.item.id,
			consumeCount: itemAmount,
		});
		props.onClose();
	}

	function handleReadBook() {
		socket.emit('books:read', { bookDBID: props.item.id });
		props.onClose();
	}

	function handleLearnAbility() {
		socket.emit('consumable:learn', {
			inventoryItemID: props.item.id,
		});
		props.onClose();
	}

	function handleConsumeTalisman() {
		socket.emit('consumable:talisman', {
			inventoryItemID: props.item.id,
			consumeCount: itemAmount,
		});
		props.onClose();
	}

	function handleToken() {
		socket.emit('consumable:token', {
			inventoryItemID: props.item.id,
		});
		props.onClose();
	}

	function handleOpenItem() {
		socket.emit('consumable:chest', {
			inventoryItemID: props.item.id,
			sourceInventory: props.inventoryName,
			consumeCount: itemAmount,
		});
		props.onClose();
	}

	function handleVaultItem() {
		socket.emit('inventory:vaultToggle', {
			inventoryItemID: props.item.id,
			sourceInventory: props.inventoryName,
			itemAmount: itemAmount,
		});
		props.onClose();
	}

	function handleTacklebox() {
		socket.emit('inventory:tackleboxToggle', {
			inventoryItemID: props.item.id,
			sourceInventory: props.inventoryName,
			itemAmount: itemAmount,
		});
		props.onClose();
	}

	function handleBaitToFront() {
		socket.emit('inventory:tackleboxToFront', props.item.id);
		props.onClose();
	}

	function handleMarketplace() {
		setActiveTab('marketplace');
		setMarketTab('buy');
		setItemToBuy(props.item.itemID);
		props.onClose();
	}

	function handleEventToken() {
		setActiveTab('event-shop');
		props.onClose();
	}

	function getSlider() {
		return (
			<Flex className='item-input' gap='10px' alignItems='center'>
				<Slider
					value={itemAmount}
					onChange={setItemAmount}
					min={1}
					max={props.item.stackSize}
					focusThumbOnChange={false}
					aria-labelledby='continuous-slider'
				>
					<SliderTrack>
						<SliderFilledTrack />
					</SliderTrack>
					<SliderThumb />
				</Slider>
				<IdlescapeNumberInput
					value={itemAmount}
					setValue={setItemAmount}
					min={1}
					max={props.item.stackSize}
					aria-labelledby='input-slider'
					width='200px'
					paddingX='4px'
					textAlign='center'
				/>
				<IdlescapeButton
					width='50px'
					variant='green'
					onClick={() => {
						setItemAmount(props.item.stackSize);
					}}
				>
					Max
				</IdlescapeButton>
			</Flex>
		);
	}

	function getDialog() {
		let ItemDialog: React.FC<IDialogProps>;
		switch (currentAction) {
			case 'marketplace':
				return <SellMarketplaceDialogue itemProp={props.item} onClose={props.onClose} />;
			case 'vendor':
				ItemDialog = VendorDialog;
				break;
			case 'consume':
				ItemDialog = ConsumeDialog;
				break;
			case 'disenchant':
				ItemDialog = DisenchantingDialog;
				break;
			case 'tokenInput':
				ItemDialog = TokenInputDialog;
				break;
			case 'resetName':
				ItemDialog = ResetNameDialog;
				break;
			case 'tab':
				ItemDialog = TabDialog;
				break;
			case 'clearAffix':
				ItemDialog = ClearAffixDialog;
				break;
			case 'respecTalents':
				ItemDialog = RespecTokenDialog;
				break;
			default:
				return (
					<Modal isOpen={true} onClose={props.onClose}>
						<ModalOverlay />
						<ModalContent className='sell-item-dialog'>
							{isMobile && (
								<Flex justifyContent='center' width='100%' top='0.5rem' margin='-13px 0'>
									<IdlescapeButton variant='blue' onClick={() => setMobileView(false)}>
										Details
									</IdlescapeButton>
									<IdlescapeButton variant='green' onClick={() => setMobileView(true)}>
										Action
									</IdlescapeButton>
								</Flex>
							)}
							<ModalHeader>
								{name}
								{name !== itemData.name ? ` (${itemData.name})` : ''}
							</ModalHeader>
							<ModalCloseButton />
							<ModalBody>
								{isMobile && !mobileView ? (
									<Flex justifyContent='center'>
										<ItemTooltip item={props.item} embed={true} />
									</Flex>
								) : (
									<>
										{getSlider()}
										<Flex flexDirection='column' alignItems='center' gap='5px' padding='5px'>
											{buttons.map((button) => (
												<IdlescapeButton
													key={button.key}
													variant={button.color}
													className='item-dialogue-button'
													onClick={button.onClick}
													width='80%'
												>
													{button.text}
												</IdlescapeButton>
											))}
										</Flex>
									</>
								)}
							</ModalBody>
						</ModalContent>
					</Modal>
				);
		}
		return (
			<ItemDialog
				item={props.item}
				itemData={itemData}
				name={name}
				sliderValue={itemAmount}
				inventoryName={props.inventoryName}
				onClose={props.onClose}
				renameTokenId={props.renameTokenId}
			/>
		);
	}
	if (props.doAction) {
		return null;
	}
	return getDialog();
}

export default MainItemDialog;
