// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { ICookingData } from '../../../game-server/src/modules/skills/cooking/Cooking.interface';
import { enchantmentsIds } from '../utils/lookup-dictionaries/lookupEnchantmentList';

export const cookingList: Record<number, ICookingData> = {
	[itemsIds.egg]: {
		/*size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['egg'],*/
	},
	[itemsIds.filleted_fish]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['fish'],
	},
	[itemsIds.ground_flour]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['flour'],
	},
	[itemsIds.churned_dairy]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['dairy'],
	},
	[itemsIds.diced_fruit]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['fruit'],
	},
	[itemsIds.chopped_vegetables]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['vegetable'],
	},
	[itemsIds.minced_meat]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['meat'],
	},
	[itemsIds.butchered_monster]: {
		size: 1,
		difficulty: 1.5,
		prepared: true,
		ingredientTags: ['monster'],
	},
	[itemsIds.giant_kings_nails]: {
		size: 1,
		difficulty: 4,
		prepared: true,
		ingredientTags: ['nail'],
	},
	[itemsIds.cookie_dough]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['christmas'],
	},
	[itemsIds.sour_essence]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['sour'],
	},
	[itemsIds.bitter_essence]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['bitter'],
	},
	[itemsIds.sweet_essence]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['sweet'],
	},
	[itemsIds.spicy_essence]: {
		size: 1,
		difficulty: 1,
		prepared: true,
		ingredientTags: ['spicy'],
	},
	[itemsIds.raw_anchovy]: {
		level: 2,
		size: 1,
		difficulty: 2,
		ingredientTags: ['fish'],
	},
	[itemsIds.raw_trout]: {
		level: 16,
		size: 2,
		difficulty: 2,
		ingredientTags: ['fish'],
	},
	[itemsIds.raw_salmon]: {
		level: 18,
		size: 2,
		difficulty: 3,
		ingredientTags: ['fish'],
	},
	[itemsIds.raw_crab]: {
		level: 38,
		size: 3,
		difficulty: 4,
		ingredientTags: ['fish', 'meat'],
	},
	[itemsIds.raw_lobster]: {
		level: 35,
		size: 2,
		difficulty: 3,
		ingredientTags: ['fish', 'meat'],
	},
	[itemsIds.raw_tuna]: {
		level: 45,
		size: 4,
		difficulty: 5,
		ingredientTags: ['fish'],
	},
	[itemsIds.raw_shark]: {
		level: 50,
		size: 4,
		difficulty: 6,
		ingredientTags: ['fish'],
	},
	[itemsIds.raw_hammerhead_shark]: {
		level: 55,
		size: 5,
		alchemySize: 1,
		difficulty: 8,
		ingredientTags: ['fish', 'sour'],
	},
	[itemsIds.raw_huge_tuna]: {
		level: 60,
		size: 8,
		alchemySize: 1,
		difficulty: 10,
		ingredientTags: ['fish', 'sweet'],
	},
	[itemsIds.raw_ancient_lobster]: {
		level: 40,
		size: 5,
		difficulty: 8,
		ingredientTags: ['monster', 'meat'],
	},
	[itemsIds.raw_megalodon]: {
		level: 65,
		size: 8,
		alchemySize: 1,
		difficulty: 12,
		ingredientTags: ['monster', 'spicy'],
	},
	[itemsIds.raw_tentacle_meat]: {
		level: 80,
		size: 12,
		alchemySize: 1,
		difficulty: 18,
		ingredientTags: ['monster'],
	},
	[itemsIds.raw_chicken]: {
		size: 2,
		difficulty: 2,
		ingredientTags: ['meat' /*'egg'*/],
	},
	[itemsIds.raw_beef]: {
		level: 6,
		size: 4,
		difficulty: 5,
		ingredientTags: ['meat'],
	},
	[itemsIds.milk]: {
		level: 4,
		size: 2,
		difficulty: 2,
		ingredientTags: ['dairy'],
	},
	[itemsIds.butter]: {
		level: 12,
		size: 6,
		difficulty: 8,
		ingredientTags: ['dairy'],
	},
	[itemsIds.choccy_milk]: {
		level: 19,
		size: 8,
		difficulty: 10,
		ingredientTags: ['dairy'],
	},
	[itemsIds.carrot]: {
		size: 1,
		difficulty: 1,
		ingredientTags: ['vegetable'],
	},
	[itemsIds.potato]: {
		level: 3,
		size: 2,
		difficulty: 3,
		ingredientTags: ['vegetable', 'flour'],
	},
	[itemsIds.pumpkin]: {
		level: 25,
		size: 6,
		alchemySize: 1,
		difficulty: 8,
		ingredientTags: ['vegetable', 'sweet'],
	},
	[itemsIds.wildberry]: {
		level: 5,
		size: 1,
		difficulty: 2,
		ingredientTags: ['fruit'],
	},
	[itemsIds.tomato]: {
		level: 12,
		size: 3,
		alchemySize: 1,
		difficulty: 4,
		ingredientTags: ['fruit', 'vegetable', 'sour'],
	},
	[itemsIds.apple]: {
		level: 20,
		size: 5,
		alchemySize: 1,
		difficulty: 6,
		ingredientTags: ['fruit', 'sweet'],
	},
	[itemsIds.wheat]: {
		level: 8,
		size: 3,
		difficulty: 4,
		ingredientTags: ['flour'],
	},
	[itemsIds.rice]: {
		level: 27,
		size: 6,
		difficulty: 7,
		ingredientTags: ['flour'],
	},
	[itemsIds.onion]: {
		level: 7,
		size: 1,
		alchemySize: 2,
		difficulty: 2,
		ingredientTags: ['vegetable', 'spicy'],
	},
	[itemsIds.pineapple]: {
		level: 9,
		size: 2,
		alchemySize: 2,
		difficulty: 3,
		ingredientTags: ['fruit', 'sweet', 'sour'],
	},
	[itemsIds.glow_spore_shroom]: {
		level: 14,
		alchemySize: 2,
		difficulty: 5,
		ingredientTags: ['bitter', 'sour'],
	},
	[itemsIds.ghost_shrooms]: {
		level: 22,
		alchemySize: 5,
		difficulty: 6,
		ingredientTags: ['spicy'],
	},
	[itemsIds.thorny_root]: {
		level: 24,
		size: 1,
		alchemySize: 3,
		difficulty: 2,
		ingredientTags: ['flour', 'bitter'],
	},
	[itemsIds.thorny_vine]: {
		level: 26,
		size: 1,
		alchemySize: 3,
		difficulty: 3,
		ingredientTags: ['vegetable', 'bitter'],
	},
	[itemsIds.tangle_root]: {
		level: 28,
		size: 2,
		alchemySize: 4,
		difficulty: 4,
		ingredientTags: ['flour', 'bitter'],
	},
	[itemsIds.black_berry]: {
		level: 42,
		size: 1,
		alchemySize: 6,
		difficulty: 7,
		ingredientTags: ['fruit', 'sweet'],
	},
	[itemsIds.mandrake]: {
		level: 52,
		size: 2,
		alchemySize: 7,
		difficulty: 8,
		ingredientTags: ['vegetable', 'bitter'],
	},
	[itemsIds.fairy_dust]: {
		level: 54,
		alchemySize: 4,
		difficulty: 10,
		ingredientTags: ['sweet', 'spicy'],
	},
	[itemsIds.lotus_flower]: {
		level: 64,
		size: 1,
		alchemySize: 6,
		difficulty: 14,
		ingredientTags: ['vegetable', 'sour', 'spicy'],
	},
	[itemsIds.jellyfish]: {
		level: 33,
		size: 1,
		alchemySize: 3,
		difficulty: 3,
		ingredientTags: ['fish', 'sour', 'spicy'],
	},
	[itemsIds.seed_oil]: {
		level: 20,
		alchemySize: 2,
		difficulty: 3,
		ingredientTags: ['bitter', 'spicy'],
	},
	[itemsIds.cookie]: {
		recipeTags: { christmas: 1 },
	},
	[itemsIds.raw_shrimp]: {
		size: 1,
		difficulty: 1,
		ingredientTags: ['fish'],
		alchemyEnchantment: enchantmentsIds.shrimp_lord,
		recipeTags: { sweet: 1, fish: 1 },
	},
	[itemsIds.seaweed]: {
		alchemyEnchantment: enchantmentsIds.pungent_bait,
		recipeTags: { bitter: 1, vegetable: 1 },
	},
	[itemsIds.raw_magnetic_minnow]: {
		alchemyEnchantment: enchantmentsIds.fishing_magnetism,
		recipeTags: { sour: 1, fish: 1 },
	},
	[itemsIds.raw_tigerfish]: {
		alchemyEnchantment: enchantmentsIds.reinforced_line,
		recipeTags: { bitter: 1, fish: 1 },
	},
	[itemsIds.raw_slippery_eel]: {
		alchemyEnchantment: enchantmentsIds.oil_spill,
		recipeTags: { sour: 1, spicy: 1, fish: 1 },
	},
	[itemsIds.raw_great_white_shark]: {
		alchemyEnchantment: enchantmentsIds.deadliest_catch,
		recipeTags: { spicy: 1, meat: 1 },
	},
	[itemsIds.goblin_brain]: {
		alchemyEnchantment: enchantmentsIds.runecrafting,
		recipeTags: { bitter: 1, monster: 1 },
	},
	[itemsIds.pepper]: {
		alchemyEnchantment: enchantmentsIds.inferno,
		recipeTags: { sour: 1, spicy: 1, flour: 1 },
	},
	[itemsIds.salt]: {
		alchemyEnchantment: enchantmentsIds.cooking,
		recipeTags: { spicy: 1, vegetable: 1 },
	},
	[itemsIds.ashes]: {
		alchemyEnchantment: enchantmentsIds.pyromancy,
		recipeTags: { sour: 1, bitter: 1, flour: 1 },
	},
	[itemsIds.sugar]: {
		alchemyEnchantment: enchantmentsIds.efficiency,
		recipeTags: { bitter: 1, sweet: 2 },
	},
	[itemsIds.honey]: {
		alchemyEnchantment: enchantmentsIds.fishing,
		recipeTags: { sour: 1, sweet: 1 },
	},
	[itemsIds.mushroom]: {
		level: 15,
		alchemySize: 1,
		difficulty: 1,
		ingredientTags: ['bitter'],
		alchemyEnchantment: enchantmentsIds.root_digging,
		recipeTags: { bitter: 1, spicy: 1 },
	},
	[itemsIds.chili_pepper]: {
		level: 20,
		alchemySize: 1,
		difficulty: 1,
		ingredientTags: ['spicy'],
		alchemyEnchantment: enchantmentsIds.superheating,
		recipeTags: { spicy: 2 },
	},
	[itemsIds.banana]: {
		alchemyEnchantment: enchantmentsIds.gathering,
		recipeTags: { sweet: 1, fruit: 1 },
	},
	[itemsIds.sageberry]: {
		alchemyEnchantment: enchantmentsIds.intuition,
		recipeTags: { sour: 1, bitter: 1, sweet: 1, spicy: 1 },
	},
	[itemsIds.watermelon]: {
		alchemyEnchantment: enchantmentsIds.empowered_gathering,
		recipeTags: { sour: 1, sweet: 1, fruit: 1 },
	},
	[itemsIds.durian]: {
		alchemyEnchantment: enchantmentsIds.naturalist,
		recipeTags: { sour: 1, sweet: 1 },
	},
	[itemsIds.frost_flowers]: {
		alchemyEnchantment: enchantmentsIds.supercooling,
		recipeTags: { bitter: 1, sweet: 1 },
	},
	[itemsIds.yellow_pepper]: {
		alchemyEnchantment: enchantmentsIds.empowered_prospecting,
		recipeTags: { bitter: 1, spicy: 1 },
	},
	[itemsIds.fish_oil]: {
		alchemyEnchantment: enchantmentsIds.deep_fry,
		recipeTags: { sour: 1, bitter: 1, spicy: 1 },
	},
	[itemsIds.ichor]: {
		level: 17,
		size: 1,
		difficulty: 1.5,
		ingredientTags: ['monster'],
		cookingEnchantment: enchantmentsIds.demon_skin,
	},
	[itemsIds.spider_legs]: {
		level: 21,
		size: 2,
		difficulty: 3,
		ingredientTags: ['monster'],
		cookingEnchantment: enchantmentsIds.nimble,
	},
	[itemsIds.water_imbued_dust]: {
		cookingEnchantment: enchantmentsIds.flame_resistance,
	},
	[itemsIds.mana_berry]: {
		cookingEnchantment: enchantmentsIds.rune_reserves,
	},
	[itemsIds.fourleaf_clover]: {
		cookingEnchantment: enchantmentsIds.treasure_hunter,
		// alchemyEnchantment: enchantmentsIds.treasure_hunter,
		// recipeTags: { sour: 1, bitter: 1, dairy: 1 },
	},
	[itemsIds.questionable_food]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.seared_poultry]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.birdie_pasta]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.birdie_pie]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.candied_fruit]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.jerky]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.pudding]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.ice_cream]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.birdie_curry]: {
		// recipeTags: {},
		variableConsumable: true,
	},
	[itemsIds.monster_surprise]: {
		recipeTags: { monster: 1 },
		variableConsumable: true,
	},
	[itemsIds.seared_steak]: {
		//recipeTags: { meat: 1 },
		variableConsumable: true,
	},
	[itemsIds.seared_fish]: {
		recipeTags: { fish: 1 },
		variableConsumable: true,
	},
	[itemsIds.spaghetti_meat]: {
		//recipeTags: { meat: 1, flour: 1 },
		variableConsumable: true,
	},
	[itemsIds.sushi]: {
		recipeTags: { fish: 1, flour: 1 },
		variableConsumable: true,
	},
	[itemsIds.cottage_pie]: {
		recipeTags: { meat: 1, flour: 1, dairy: 1 },
		variableConsumable: true,
	},
	[itemsIds.fishermans_pie]: {
		recipeTags: { fish: 1, flour: 1, dairy: 1 },
		variableConsumable: true,
	},
	[itemsIds.fruit_pie]: {
		recipeTags: { fruit: 1, flour: 1, dairy: 1 },
		variableConsumable: true,
	},
	[itemsIds.demon_cake]: {
		recipeTags: { monster: 1, flour: 1 },
		variableConsumable: true,
	},
	[itemsIds.cake]: {
		//recipeTags: { dairy: 1, flour: 1, egg: 1 },
		variableConsumable: true,
	},
	[itemsIds.fruit_cake]: {
		recipeTags: { fruit: 1, flour: 1 },
		variableConsumable: true,
	},
	[itemsIds.grain_cake]: {
		//recipeTags: { flour: 1, dairy: 1 },
		variableConsumable: true,
	},
	[itemsIds.omelette]: {
		//recipeTags: { egg: 1 },
		variableConsumable: true,
	},
	[itemsIds.monster_omelette]: {
		//recipeTags: { egg: 1, monster: 1 },
		variableConsumable: true,
	},
	[itemsIds.meaty_omelette]: {
		//recipeTags: { egg: 1, meat: 1 },
		variableConsumable: true,
	},
	[itemsIds.veggie_crepe]: {
		//recipeTags: { egg: 1, vegetable: 1 },
		variableConsumable: true,
	},
	[itemsIds.fruity_crepe]: {
		//recipeTags: { egg: 1, fruit: 1 },
		variableConsumable: true,
	},
	[itemsIds.eggnog]: {
		//recipeTags: { egg: 1, dairy: 1 },
		variableConsumable: true,
	},
	[itemsIds.meat_roast]: {
		recipeTags: { meat: 1, fish: 1, monster: 1 },
		variableConsumable: true,
	},
	[itemsIds.fried_fruit]: {
		recipeTags: { fruit: 1 },
		variableConsumable: true,
	},
	[itemsIds.grilled_veggies]: {
		recipeTags: { vegetable: 1 },
		variableConsumable: true,
	},
	[itemsIds.kebabs]: {
		recipeTags: { meat: 1, vegetable: 1, fruit: 1 },
		variableConsumable: true,
	},
	[itemsIds.milkshake]: {
		recipeTags: { fruit: 1, dairy: 1 },
		variableConsumable: true,
	},
	[itemsIds.vegetable_stew]: {
		//recipeTags: { dairy: 1, vegetable: 1 },
		variableConsumable: true,
	},
	[itemsIds.meaty_stew]: {
		recipeTags: { dairy: 1, meat: 1 },
		variableConsumable: true,
	},
	[itemsIds.monster_stew]: {
		//recipeTags: { dairy: 1, monster: 1 },
		variableConsumable: true,
	},
	[itemsIds.monster_curry]: {
		recipeTags: { monster: 1, flour: 1, vegetable: 1 },
		variableConsumable: true,
	},
	[itemsIds.beast_curry]: {
		recipeTags: { meat: 1, flour: 1, vegetable: 1 },
		variableConsumable: true,
	},
	[itemsIds.seafood_curry]: {
		recipeTags: { fish: 1, flour: 1, vegetable: 1 },
		variableConsumable: true,
	},
	[itemsIds.monster_gelato]: {
		//recipeTags: { monster: 1, dairy: 1, egg: 1 },
		variableConsumable: true,
	},
	[itemsIds.cooked_giant_kings_nails]: {
		recipeTags: { nail: 1 },
		variableConsumable: true,
	},
	[itemsIds.empty_vial]: {
		nextPotion: itemsIds.common_potion,
	},
	[itemsIds.common_potion]: {
		size: 2,
		difficulty: 1,
		nextPotion: itemsIds.rare_potion,
		variableConsumable: true,
	},
	[itemsIds.rare_potion]: {
		size: 4,
		difficulty: 4,
		nextPotion: itemsIds.epic_potion,
		variableConsumable: true,
	},
	[itemsIds.epic_potion]: {
		size: 10,
		difficulty: 11,
		nextPotion: itemsIds.legendary_potion,
		variableConsumable: true,
	},
	[itemsIds.legendary_potion]: {
		variableConsumable: true,
		//
	},
	[itemsIds.empty_large_vial]: {
		// nextPotion: itemsIds.common_large_potion,
	},
	[itemsIds.common_large_potion]: {
		// size: 2,
		// difficulty: 2,
		// nextPotion: itemsIds.rare_large_potion,
		variableConsumable: true,
	},
	[itemsIds.rare_large_potion]: {
		// size: 4,
		// difficulty: 6,
		// nextPotion: itemsIds.epic_large_potion,
		variableConsumable: true,
	},
	[itemsIds.epic_large_potion]: {
		// size: 10,
		// difficulty: 14,
		// nextPotion: itemsIds.legendary_large_potion,
		variableConsumable: true,
	},
	[itemsIds.legendary_large_potion]: {
		variableConsumable: true,
	},
	[itemsIds.empty_reinforced_vial]: {
		// size: 2,
		// difficulty: 4,
		// nextPotion: itemsIds.common_concentrated_potion,
		variableConsumable: true,
	},
	[itemsIds.common_concentrated_potion]: {
		// size: 6,
		// difficulty: 10,
		// nextPotion: itemsIds.rare_concentrated_potion,
		variableConsumable: true,
	},
	[itemsIds.rare_concentrated_potion]: {
		// size: 8,
		// difficulty: 15,
		// nextPotion: itemsIds.epic_concentrated_potion,
		variableConsumable: true,
	},
	[itemsIds.epic_concentrated_potion]: {
		variableConsumable: true,
	},
	[itemsIds.prismatic_pudding]: {
		successChance: 1,
		recipeTags: { prismatic: 1, dairy: 1 },
	},
	[itemsIds.prismatic_extract]: {
		size: 1,
		difficulty: 2500,
		prepared: true,
		ingredientTags: ['prismatic'],
	},
};
