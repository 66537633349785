import { Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { animationAtom } from '../../../../atoms/animationAtom';
import { usePlayerField } from '../../../../hooks/hooks';
import { ActionTypes } from '../../../../utils/enums/ActionTypes';
import { locations } from '../../../../utils/locationList';

export function ForagingInfo() {
	const actionQueue = usePlayerField('actionQueue');
	const nodeData = useRecoilValue(animationAtom)?.node;
	const active = actionQueue?.actionType === ActionTypes.Foraging;

	function getCurrentNodeInformation() {
		if (!active) {
			return (
				<>
					<Heading className='fishing-info-title-border' size='md'>
						Waiting for Node...
					</Heading>
					<p>Find a node!</p>
				</>
			);
		}
		return (
			<>
				<Heading className='fishing-info-title-border' size='md'>
					Current Node
				</Heading>
				<p>
					Zone: {locations[actionQueue.location].name}
					<br />
					Node:{' '}
					<Text as='span' color='green'>
						{(nodeData?.resourcesLeft && nodeData?.nodeID) ?? 'Searching...'}
					</Text>
					<br />
					Remaining: {nodeData?.resourcesLeft ?? 0}
				</p>
			</>
		);
	}

	return (
		<div className='foraging-info idlescape-container idlescape-container-green'>{getCurrentNodeInformation()}</div>
	);
}
