import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { BASE_BONDS_PER_DOLLAR, idlescapeBondPacks } from '../../../../utils/idlescapeBondPackList';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import { PremiumPack } from '../Platinum/PlatinumShopPlatinumContainer';
import { PlatinumShopPurchaseReview } from '../PlatinumShopPurchaseReview';

export default function PlatinumShopIdlescapeBonds() {
	const [selectedPack, setSelectedPack] = useState(0);

	const BondPackList = () => {
		const packs = Object.values(idlescapeBondPacks).map((bondPackData) => {
			const basePlat = bondPackData.price * BASE_BONDS_PER_DOLLAR;
			const extra = bondPackData.bondAmount / basePlat - 1;
			return (
				<PremiumPack
					key={'bond-pack-' + bondPackData.id}
					amount={bondPackData.bondAmount}
					price={bondPackData.price}
					image={bondPackData.image}
					onClick={() => setSelectedPack(bondPackData.id)}
					itemID={itemsIds.idlescape_bond}
					extra={extra}
				/>
			);
		});

		return <div className='platinum-pack-list'>{packs}</div>;
	};

	return (
		<div className='platinum-shop-platinum-container'>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={selectedPack}
					timeout={300}
					addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					classNames='fade'
				>
					{selectedPack > 0 ? (
						<PlatinumShopPurchaseReview bondPackId={selectedPack} setSelectedPack={setSelectedPack} />
					) : (
						<BondPackList />
					)}
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
