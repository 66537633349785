"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  IdlescapeButton: () => IdlescapeButton,
  IdlescapeCheckbox: () => IdlescapeCheckbox,
  IdlescapeContainer: () => IdlescapeContainer,
  IdlescapeFrameBox: () => IdlescapeFrameBox,
  IdlescapeInput: () => IdlescapeInput,
  IdlescapeNumberInput: () => IdlescapeNumberInput,
  IdlescapePill: () => IdlescapePill,
  IdlescapeSelect: () => IdlescapeSelect,
  IdlescapeTooltip: () => IdlescapeTooltip,
  IdlescapeWrappingTooltip: () => IdlescapeWrappingTooltip,
  getImage: () => getImage
});
module.exports = __toCommonJS(src_exports);

// src/IdlescapePill/IdlescapePill.tsx
var import_react2 = require("@chakra-ui/react");

// src/IdlescapeTooltip/IdlescapeTooltip.tsx
var import_react = __toESM(require("react"));
var import_react_tiny_popover = require("react-tiny-popover");
var import_jsx_runtime = require("react/jsx-runtime");
var IdlescapeTooltip = (props) => {
  const divStyle = {
    zIndex: "999",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    position: "absolute"
  };
  const [tooltipEnabled, setTooltipEnabled] = import_react.default.useState(false);
  function actions() {
    if (props.onClick === true) {
      return /* @__PURE__ */ (0, import_jsx_runtime.jsx)("span", { onClick: () => setTooltipEnabled(!tooltipEnabled), style: divStyle });
    } else {
      return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
        "span",
        {
          onMouseOver: () => setTooltipEnabled(true),
          onMouseOut: () => setTooltipEnabled(false),
          onClick: () => setTooltipEnabled(true),
          onMouseDown: (e) => {
            var _a;
            return (_a = props.onMouseDown) == null ? void 0 : _a.call(props, e);
          },
          onMouseEnter: (e) => {
            var _a;
            return (_a = props.onMouseEnter) == null ? void 0 : _a.call(props, e);
          },
          onMouseUp: (e) => {
            var _a;
            return (_a = props.onMouseUp) == null ? void 0 : _a.call(props, e);
          },
          style: divStyle
        }
      );
    }
  }
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    import_react_tiny_popover.Popover,
    {
      isOpen: tooltipEnabled,
      positions: ["top", "bottom", "left", "right"],
      onClickOutside: () => setTooltipEnabled(false),
      padding: 2,
      reposition: true,
      content: () => {
        return /* @__PURE__ */ (0, import_jsx_runtime.jsx)("div", { className: `idlescape-tooltip ${props.className}`, children: props.children });
      },
      children: actions()
    }
  );
};

// src/Images.ts
var Images = {
  BorderImage: "@idlescape-ui/stone-9slice_ibsk11",
  BorderImagePlatinum: "@idlescape-ui/stone-9slice-platinum-v2_acuxfh",
  BorderImageDark: "@idlescape-ui/stone-9slice-dark-gray_vnevh5",
  FramePanel: "@idlescape-ui/frame_panel_smrtlt",
  FramePanelDark: "@idlescape-ui/frame_panel_dark_ts9b5p",
  FramePanelLight: "@idlescape-ui/frame_panel_light_bhqjay",
  FrameBox: "@idlescape-ui/frame_box_piriim",
  FrameBoxDark: "@idlescape-ui/frame_box_dark_gqmdnt",
  FrameBoxLight: "@idlescape-ui/frame_box_light_idz6uz",
  LandingPage: "@idlescape-ui/landing_page_tl6ij8",
  LandingPageBlurred: "@idlescape-ui/landing_page_blurred_epqnau",
  ButtonGray: "@idlescape-ui/stone_button_gray_nc8pbv",
  ButtonRed: "@idlescape-ui/stone_button_red_jrc1p1",
  ButtonGreen: "@idlescape-ui/stone_button_green_i4s1fk",
  ButtonBlue: "@idlescape-ui/stone_button_blue_nceshj",
  ButtonOrange: "@idlescape-ui/stone_button_orange_p0uq39",
  ButtonPurple: "@idlescape-ui/stone_button_purple_rlazur",
  Logo: "@idlescape-ui/logo_2.0_g6srhe.png",
  StoneBackground: "@idlescape-ui/stone_ui_tile_rn6lta",
  StoneBackgroundDarkGray: "@idlescape-ui/stone_ui_tile_dark_gray_o4swsc"
};
var baseUrl = "https://res.cloudinary.com/dxwkhyrde/image/upload/";
var getImage = (imageId) => {
  return `${baseUrl}f_auto/${Images[imageId]}`;
};

// src/IdlescapePill/IdlescapePill.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var variants = {
  primary: {
    backgroundImage: getImage("FramePanel")
  },
  dark: { backgroundImage: getImage("FramePanelDark") },
  light: { backgroundImage: getImage("FramePanelLight") },
  progress: { backgroundImage: `url(${getImage("FramePanelLight")}), url(${getImage("FramePanelDark")})` },
  disabled: { backgroundImage: getImage("FramePanel") }
};
var IdlescapePill = (_a) => {
  var _b = _a, {
    image,
    variant = "primary",
    tooltipText,
    progress,
    children
  } = _b, props = __objRest(_b, [
    "image",
    "variant",
    "tooltipText",
    "progress",
    "children"
  ]);
  const isProgress = variant === "progress" && progress !== void 0;
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
    import_react2.Flex,
    __spreadProps(__spreadValues(__spreadValues({
      backgroundSize: isProgress ? `${progress}% 100%, ${100 - progress}% 100%` : "100% 100%",
      backgroundPosition: isProgress ? "left, right" : "center",
      backgroundRepeat: "no-repeat",
      height: "50px",
      lineHeight: "40px",
      borderRadius: "20px",
      padding: "5px 8px",
      position: "relative",
      fontSize: "24px",
      opacity: variant === "disabled" ? "50%" : ""
    }, variants[variant]), props), {
      children: [
        typeof image === "string" ? /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react2.Image, { maxHeight: "100%", height: "100%", width: "40px", marginRight: "10px", src: image, objectFit: "contain" }) : /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react2.Flex, { alignItems: "center", maxHeight: "100%", height: "100%", width: "40px", marginRight: "10px", children: image }),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react2.Box, { noOfLines: 1, width: "100%", children }),
        tooltipText && /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(IdlescapeTooltip, { children: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(import_react2.Box, { children: tooltipText }) })
      ]
    })
  );
};

// src/IdlescapeButton/IdlescapeButton.tsx
var import_react3 = require("@chakra-ui/react");
var import_system = require("@chakra-ui/system");
var import_jsx_runtime3 = require("react/jsx-runtime");
var variants2 = {
  red: {
    backgroundImage: getImage("ButtonRed")
  },
  green: {
    backgroundImage: getImage("ButtonGreen")
  },
  blue: {
    backgroundImage: getImage("ButtonBlue")
  },
  orange: {
    backgroundImage: getImage("ButtonOrange")
  },
  purple: {
    backgroundImage: getImage("ButtonPurple")
  },
  gray: {
    backgroundImage: getImage("ButtonGray")
  },
  disabled: {
    backgroundImage: getImage("ButtonGray"),
    _hover: {
      transform: "unset",
      filter: "unset"
    },
    _active: {
      transform: "unset",
      filter: "unset"
    },
    cursor: "not-allowed"
  }
};
var sizes = {
  icon: {
    width: "35px",
    fontSize: "20px",
    padding: "0"
  },
  small: {
    width: "100px"
  },
  medium: {
    width: "150px"
  },
  large: {
    width: "200px"
  }
};
var IdlescapeButton = (0, import_system.forwardRef)(
  (_a, ref) => {
    var _b = _a, { variant = "gray", size = "small" } = _b, props = __objRest(_b, ["variant", "size"]);
    return /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(
      import_react3.Button,
      __spreadProps(__spreadValues(__spreadProps(__spreadValues(__spreadValues({
        border: "none",
        height: "40px",
        lineHeight: "40px",
        backgroundColor: "transparent",
        backgroundSize: "100% 100%",
        cursor: "pointer",
        scale: "2",
        transition: "all 0.1s linear",
        _hover: { transform: "scale(1.05)", filter: "brightness(1.3)" },
        _active: { transform: "scale(0.95)", filter: "brightness(0.9)" }
      }, variants2[variant]), sizes[size]), {
        ref
      }), props), {
        onClick: (e) => {
          var _a2;
          if (variant === "disabled") {
            e.preventDefault();
            e.stopPropagation();
          } else {
            (_a2 = props.onClick) == null ? void 0 : _a2.call(props, e);
          }
        }
      })
    );
  }
);

// src/IdlescapeTooltip/IdlescapeWrappingTooltip.tsx
var import_react4 = __toESM(require("react"));
var import_react_tiny_popover2 = require("react-tiny-popover");
var import_jsx_runtime4 = require("react/jsx-runtime");
var IdlescapeWrappingTooltip = (_a) => {
  var _b = _a, {
    content,
    children,
    className,
    activateOnClick
  } = _b, props = __objRest(_b, [
    "content",
    "children",
    "className",
    "activateOnClick"
  ]);
  const [tooltipEnabled, setTooltipEnabled] = import_react4.default.useState(false);
  function renderChild() {
    const child = import_react4.Children.only(children);
    if (activateOnClick) {
      return import_react4.default.cloneElement(child, {
        onClick: () => {
          var _a2, _b2;
          (_b2 = (_a2 = child.props).onClick) == null ? void 0 : _b2.call(_a2);
          setTooltipEnabled(!tooltipEnabled);
        }
      });
    } else {
      return import_react4.default.cloneElement(child, {
        onMouseOver: () => {
          var _a2, _b2;
          (_b2 = (_a2 = child.props).onMouseOver) == null ? void 0 : _b2.call(_a2);
          setTooltipEnabled(true);
        },
        onMouseOut: () => {
          var _a2, _b2;
          (_b2 = (_a2 = child.props).onMouseOut) == null ? void 0 : _b2.call(_a2);
          setTooltipEnabled(false);
        },
        onClick: () => {
          var _a2, _b2;
          (_b2 = (_a2 = child.props).onClick) == null ? void 0 : _b2.call(_a2);
          setTooltipEnabled(true);
        }
      });
    }
  }
  if (!content) {
    return renderChild();
  }
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
    import_react_tiny_popover2.Popover,
    __spreadProps(__spreadValues({
      isOpen: tooltipEnabled,
      content: /* @__PURE__ */ (0, import_jsx_runtime4.jsx)("div", { className: `idlescape-tooltip ${className != null ? className : ""}`, children: content }),
      onClickOutside: () => setTooltipEnabled(false)
    }, props), {
      children: renderChild()
    })
  );
};

// src/IdlescapeInput/IdlescapeInput.tsx
var import_react5 = require("react");
var import_md = require("react-icons/md");
var import_react6 = require("@chakra-ui/react");
var import_system2 = require("@chakra-ui/system");
var import_ai = require("react-icons/ai");
var import_jsx_runtime5 = require("react/jsx-runtime");
var variants3 = {
  default: {
    box: {
      padding: "0 5px",
      backgroundImage: getImage("FramePanel"),
      backgroundSize: "100% 100%"
    },
    input: {
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 50%), 0 0 5px 5px rgb(0 0 0 / 50%) inset",
      borderColor: "black",
      borderRadius: "5px",
      textAlign: "inherit"
    }
  },
  simple: {
    box: {
      backgroundColor: "rgba(0, 0, 0, 0.4)"
    },
    input: {}
  },
  search: {
    box: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      width: "100%"
    },
    input: {
      width: "100%"
    }
  }
};
var IdlescapeInput = (0, import_system2.forwardRef)(
  (_a, ref) => {
    var _b = _a, {
      variant = "default",
      type,
      value,
      defaultValue,
      min,
      margin,
      disableEsc = false,
      contentEditable,
      className,
      children,
      setValue,
      onKeyUp,
      onChange
    } = _b, props = __objRest(_b, [
      "variant",
      "type",
      "value",
      "defaultValue",
      "min",
      "margin",
      "disableEsc",
      "contentEditable",
      "className",
      "children",
      "setValue",
      "onKeyUp",
      "onChange"
    ]);
    var _a2, _b2, _c;
    const [currentValue, setCurrentValue] = (0, import_react5.useState)(((_a2 = value != null ? value : defaultValue) != null ? _a2 : "").toString());
    (0, import_react5.useEffect)(() => {
      if (value !== void 0)
        setAllValues(value.toString());
    }, [value]);
    function setAllValues(currentValue2) {
      if (type === "number") {
        setValue(Number(currentValue2));
        setCurrentValue(Number(currentValue2));
      } else {
        setValue(currentValue2);
        setCurrentValue(currentValue2);
      }
    }
    function keyPressEvent(e) {
      if (onKeyUp)
        onKeyUp(e);
      if (e.key === "Escape" && !disableEsc) {
        clearInput();
        e.stopPropagation();
      }
    }
    function changeEvent(e) {
      if (onChange)
        onChange(e);
      if (type === "number" && e.target.value === "") {
        setAllValues(currentValue);
        return;
      }
      setAllValues(e.target.value);
    }
    function clearInput() {
      if (type === "number") {
        setAllValues(min != null ? min : 0);
      } else {
        setAllValues("");
      }
    }
    return /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_react6.Box, __spreadProps(__spreadValues(__spreadValues({ margin }, (_b2 = variants3[variant]) == null ? void 0 : _b2.box), props), { className: "anchor-idlescape-input", children: /* @__PURE__ */ (0, import_jsx_runtime5.jsxs)(import_react6.InputGroup, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
        import_react6.Input,
        __spreadValues(__spreadValues({
          ref,
          type,
          value: currentValue,
          min,
          contentEditable,
          className,
          onKeyUp: keyPressEvent,
          onChange: changeEvent
        }, (_c = variants3[variant]) == null ? void 0 : _c.input), props)
      ),
      children,
      variant === "search" && /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_react6.InputLeftElement, { pointerEvents: "none", children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_ai.AiOutlineSearch, {}) }),
      currentValue && type !== "number" && /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_react6.InputRightElement, { children: /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(import_md.MdClose, { cursor: "pointer", onClick: clearInput }) })
    ] }) }));
  }
);

// src/IdlescapeNumberInput/IdlescapeNumberInput.tsx
var import_react7 = __toESM(require("react"));
var import_react8 = require("@chakra-ui/react");
var import_jsx_runtime6 = require("react/jsx-runtime");
var IdlescapeNumberInput = (_a) => {
  var _b = _a, {
    value = "",
    setValue,
    allowFractions = false,
    min = 0,
    max = Infinity,
    step = 1,
    maxLength = 12,
    width = "100%",
    maxWidth = void 0,
    disabled
  } = _b, props = __objRest(_b, [
    "value",
    "setValue",
    "allowFractions",
    "min",
    "max",
    "step",
    "maxLength",
    "width",
    "maxWidth",
    "disabled"
  ]);
  const [valueString, setValueString] = (0, import_react7.useState)(value === 0 ? "" : value.toString());
  const currentValue = parseCompactNumber(value.toString());
  const stepNum = Number(step);
  const inputRef = import_react7.default.createRef();
  (0, import_react7.useEffect)(() => {
    var _a2;
    (_a2 = inputRef.current) == null ? void 0 : _a2.addEventListener("wheel", preventDefault, { passive: false });
    return () => {
      var _a3;
      (_a3 = inputRef.current) == null ? void 0 : _a3.removeEventListener("wheel", preventDefault, false);
    };
  }, [inputRef]);
  (0, import_react7.useEffect)(() => {
    if (allowFractions && Number(value) !== parseCompactNumber(valueString) || Number(value) !== Math.floor(parseCompactNumber(valueString))) {
      setValueString(value.toString());
    }
  }, [value]);
  function setCurrentValue(value2) {
    if (value2 < min)
      value2 = Number(min);
    if (value2 > max)
      value2 = Number(max);
    setValueString(value2.toString());
    setValue(value2);
  }
  function keyDownEvent(e) {
    if (e.key === "ArrowUp") {
      setCurrentValue(currentValue + stepNum);
    } else if (e.key === "ArrowDown") {
      setCurrentValue(currentValue - stepNum);
    }
  }
  function wheelEvent(e) {
    if (e.deltaY < 0) {
      setCurrentValue(currentValue + stepNum);
    } else if (e.deltaY > 0) {
      setCurrentValue(currentValue - stepNum);
    }
  }
  function changeEvent(e) {
    const numberString = sanitizeCompactNumber(e.target.value, Number(min), Number(max));
    let value2 = parseCompactNumber(numberString);
    if (!allowFractions) {
      value2 = Math.floor(value2);
    }
    setValue(value2);
    setValueString(numberString);
  }
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_react8.InputGroup, { width, maxWidth, children: [
    /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
      import_react8.Input,
      __spreadValues({
        ref: inputRef,
        value: valueString,
        onChange: changeEvent,
        onKeyDown: keyDownEvent,
        onWheel: wheelEvent,
        maxLength,
        disabled
      }, props)
    ),
    !disabled && /* @__PURE__ */ (0, import_jsx_runtime6.jsxs)(import_react8.InputRightElement, { flexDirection: "column", width: "25px", children: [
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
        IdlescapeButton,
        {
          onClick: () => setCurrentValue(currentValue + stepNum),
          minWidth: "25px",
          width: "25px",
          tabIndex: -1,
          children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react8.StatUpArrow, {})
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
        IdlescapeButton,
        {
          onClick: () => setCurrentValue(currentValue - stepNum),
          minWidth: "25px",
          width: "25px",
          tabIndex: -1,
          children: /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(import_react8.StatDownArrow, {})
        }
      )
    ] })
  ] });
};
function preventDefault(e) {
  e.preventDefault();
}
function sanitizeCompactNumber(numberString, min = 0, max = Infinity) {
  numberString = numberString.replace(/[^0-9kKmMbB.]/g, "");
  let number = parseFloat(numberString);
  if (isNaN(number))
    number = 0;
  const scale = {
    k: 1e3,
    m: 1e6,
    b: 1e9
  };
  const stringExponent = numberString.match(/[kKmMbB]/i);
  if (stringExponent) {
    const factor = stringExponent[0];
    numberString = number.toString() + factor;
    number *= scale[factor.toLowerCase()];
  }
  if (number < min || number > max) {
    number = Math.max(min, Math.min(max, number));
    numberString = number.toString();
  }
  return numberString;
}
function parseCompactNumber(numberString) {
  numberString = numberString.replace(/[^0-9kKmMbB.]/g, "");
  let number = parseFloat(numberString);
  if (isNaN(number))
    number = 0;
  const scale = {
    k: 1e3,
    m: 1e6,
    b: 1e9
  };
  const stringExponent = numberString.match(/[kKmMbB]/i);
  if (stringExponent) {
    const factor = stringExponent[0];
    number *= scale[factor.toLowerCase()];
  }
  return number;
}

// src/IdlescapeContainer/IdlescapeContainer.tsx
var import_react9 = require("@chakra-ui/react");
var import_system3 = require("@chakra-ui/system");
var import_jsx_runtime7 = require("react/jsx-runtime");
var types = {
  default: {
    borderImageWidth: "100px",
    padding: "30px"
  },
  thin: {
    borderImageWidth: "50px",
    padding: "15px"
  }
};
var variants4 = {
  primary: {
    borderBackgroundImage: getImage("BorderImage")
  },
  platinum: {
    borderBackgroundImage: getImage("BorderImagePlatinum")
  },
  dark: {
    borderBackgroundImage: getImage("BorderImageDark")
  },
  red: {
    borderBackgroundImage: "/images/ui/stone-9slice-red.png"
  }
};
var IdlescapeContainer = (0, import_system3.forwardRef)(
  (_a, ref) => {
    var _b = _a, { variant = "primary", type = "default", children, className } = _b, props = __objRest(_b, ["variant", "type", "children", "className"]);
    return /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
      import_react9.Box,
      __spreadProps(__spreadValues({
        className: `idlescape-container ${className}`,
        style: __spreadValues({
          borderImageSource: `url(${variants4[variant].borderBackgroundImage})`,
          borderImageSlice: "100 fill",
          borderImageOutset: 0,
          borderImageRepeat: "round"
        }, types[type]),
        ref
      }, props), {
        children
      })
    );
  }
);

// src/IdlescapeFrameBox/IdlescapeFrameBox.tsx
var import_react10 = require("@chakra-ui/react");
var import_jsx_runtime8 = require("react/jsx-runtime");
var variants5 = {
  primary: {
    backgroundImage: getImage("FrameBox")
  },
  dark: { backgroundImage: getImage("FrameBoxDark") },
  light: { backgroundImage: getImage("FrameBoxLight") }
};
var IdlescapeFrameBox = (_a) => {
  var _b = _a, { variant = "primary", children } = _b, props = __objRest(_b, ["variant", "children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
    import_react10.Box,
    __spreadProps(__spreadValues(__spreadValues({
      backgroundSize: "100% 100%",
      backgroundPosition: "center",
      borderRadius: "20px",
      position: "relative",
      transition: "transform 0.05s linear",
      _hover: {
        transform: "scale(1.05)"
      }
    }, variants5[variant]), props), {
      children
    })
  );
};

// src/IdlescapeSelect/IdlescapeSelect.tsx
var import_react11 = require("react");
var import_react12 = require("@chakra-ui/react");
var import_ai2 = require("react-icons/ai");
var import_jsx_runtime9 = require("react/jsx-runtime");
var variants6 = {
  primary: { backgroundImage: getImage("FramePanel") },
  dark: { backgroundImage: getImage("FramePanelDark") },
  light: { backgroundImage: getImage("FramePanelLight") }
};
var IdlescapeSelect = (_a) => {
  var _b = _a, {
    selected,
    placeholder = "Select an option",
    select,
    variant = "primary",
    options
  } = _b, props = __objRest(_b, [
    "selected",
    "placeholder",
    "select",
    "variant",
    "options"
  ]);
  const [dropdownShouldOpen, setDropdownShouldOpen] = (0, import_react11.useState)(false);
  const menu = (0, import_react11.useRef)(null);
  (0, import_react12.useOutsideClick)({
    ref: menu,
    handler: () => setDropdownShouldOpen(false)
  });
  function handleSelect(id) {
    setDropdownShouldOpen(false);
    if (id !== selected)
      select(id);
  }
  function renderSelected() {
    return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
      import_react12.Flex,
      __spreadProps(__spreadValues(__spreadValues({
        justifyContent: "space-between",
        paddingLeft: "15px",
        paddingRight: "25px",
        alignItems: "center",
        onClick: () => setDropdownShouldOpen(!dropdownShouldOpen),
        backgroundSize: "100% 100%"
      }, variants6[variant]), props), {
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(import_react12.Flex, { alignItems: "center", children: [
            selected && options[selected] && options[selected].image && renderOptionsImage(options[selected]),
            /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_react12.Text, { fontSize: "xl", marginY: "10px", children: selected && options[selected] ? options[selected].label : placeholder })
          ] }),
          dropdownShouldOpen ? /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_ai2.AiFillCaretUp, {}) : /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_ai2.AiFillCaretDown, {})
        ]
      })
    );
  }
  function renderOptions() {
    return Object.keys(options).map((id) => {
      return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
        import_react12.Flex,
        __spreadProps(__spreadValues(__spreadValues({
          justifyContent: "start",
          paddingX: "15px",
          alignItems: "center",
          onClick: () => handleSelect(id),
          backgroundSize: "100% 100%"
        }, variants6[variant]), props), {
          _hover: {
            filter: "brightness(1.5)"
          },
          children: [
            options[id].image && renderOptionsImage(options[id]),
            /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(import_react12.Text, { fontSize: "xl", marginY: "10px", children: options[id].label })
          ]
        }),
        id
      );
    });
  }
  function renderOptionsImage(option) {
    return /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
      import_react12.Image,
      {
        src: option.image,
        alt: option.label,
        width: "30px",
        height: "30px",
        objectFit: "contain",
        filter: "drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))",
        paddingRight: "5px"
      }
    );
  }
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(import_react12.Box, __spreadProps(__spreadValues({}, props), { position: "relative", cursor: "pointer", ref: menu, children: [
    renderSelected(),
    dropdownShouldOpen && /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
      import_react12.Box,
      {
        position: "absolute",
        top: "100%",
        width: "100%",
        maxHeight: "calc(56px * 4)",
        overflow: "clip auto",
        zIndex: 1e3,
        bgImage: "linear-gradient(to left,transparent, black 10%, black 90%, transparent);",
        children: renderOptions()
      }
    )
  ] }));
};

// src/IdlescapeCheckbox/IdlescapeCheckbox.tsx
var import_react13 = require("@chakra-ui/react");
var import_jsx_runtime10 = require("react/jsx-runtime");
var IdlescapeCheckbox = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(import_react13.Checkbox, __spreadProps(__spreadValues({}, props), { defaultChecked: true, children }));
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  IdlescapeButton,
  IdlescapeCheckbox,
  IdlescapeContainer,
  IdlescapeFrameBox,
  IdlescapeInput,
  IdlescapeNumberInput,
  IdlescapePill,
  IdlescapeSelect,
  IdlescapeTooltip,
  IdlescapeWrappingTooltip,
  getImage
});
