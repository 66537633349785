/* eslint-disable react/no-children-prop */
import React from 'react';
import { useState } from 'react';
import { itemList } from '../../utils/itemList';
import './AllItems.css';
import { Box, Grid, Image } from '@chakra-ui/react';
import { AiFillLock, AiFillUnlock } from 'react-icons/ai';
import { filterItem } from '../../helper/helperFunctions';
import { usePlayerField } from '../../hooks/hooks';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import { IoSettingsSharp } from 'react-icons/io5';
import useIsMobile from '../../hooks/useIsMobile';
import { itemsIds } from '../../utils/lookup-dictionaries/lookupItemList';
import { CurrencyPanel } from './CurrencyPanel';
import { IItem } from '../../../../game-server/src/modules/items/items.interface';
import { getSocket } from '../../services/socket.service';
import InventoryItem from './Inventory/InventoryItem';
import { MULTI_MOVE_COOLDOWN } from '../../utils/constantsCollection';
import { TTrueInventoryType } from '../../../../game-server/src/modules/items/Inventory.interface';
import TabEditor from './Inventory/TabEditor';

/**
 * Inventories:
 * stockpile, vault, augmentingItemSlot, combatInventory, tacklebox, ammo
 */

const invName: { [inv in TTrueInventoryType]: string } = {
	ammo: 'Pouch',
	augmentingItemSlot: 'Augmenting Queue',
	combatInventory: 'Combat Food',
	scrapQueue: 'Research Queue',
	stockpile: 'Inventory',
	tacklebox: 'Tacklebox',
	vault: 'Vault',
};

export default function AllItems({
	inventoryName = 'stockpile',
	hideSorting = false,
	hideCurrency = false,
	defaultSearch,
}: {
	inventoryName?: TTrueInventoryType;
	hideSorting?: boolean;
	hideCurrency?: boolean;
	defaultSearch?: string;
}) {
	const [filters, setFilters] = useState([
		{
			image: '/images/combat/attack_icon.png',
			filter: 'armor|weapon|key|consumable',
			alt: 'combat',
			enabled: false,
		},
		{
			image: '/images/ui/equipment_icon.png',
			filter: 'armor|weapon|equipment',
			alt: 'equipment',
			enabled: false,
		},
		{ image: '/images/misc/elite/Elite_Scroll_Chaos.png', filter: 'elite', alt: 'scroll', enabled: false },
		{ image: '/images/misc/keys/Complete_Disc.png', filter: 'dungeon|fragment', alt: 'dungeon', enabled: false },
		{ image: '/images/mining/iron_pickaxe.png', filter: 'mining', alt: 'mining', enabled: false },
		{ image: '/images/foraging/foraging_icon.png', filter: 'foraging', alt: 'foraging', enabled: false },
		{ image: '/images/fishing/fishing_logo.png', filter: 'fishing', alt: 'fishing', enabled: false },
		{ image: '/images/farming/farming_icon.png', filter: 'farming', alt: 'farming', enabled: false },
		{ image: '/images/cooking/cooking_icon.png', filter: 'cooking', alt: 'cooking', enabled: false },
		{ image: '/images/runecrafting/mind_rune.png', filter: 'rune', alt: 'rune', enabled: false },
		{ image: '/images/smithing/smithing_icon.png', filter: 'smithing', alt: 'smithing', enabled: false },
		{ image: '/images/combat/equipment/range/arrow_0.png', filter: 'ammo', alt: 'ammo', enabled: false },
		{ image: '/images/misc/geode.png', filter: 'chest', alt: 'chest', enabled: false },
		{ image: '/images/ui/blocked_icon.png', filter: 'clear', alt: 'clear', enabled: false },
	]);

	const isMobile = useIsMobile();
	const playerSettings = usePlayerField('settings');
	const showCurrency = playerSettings.miscellaneous.showCurrencyAsItems;
	const tabRules = playerSettings.miscellaneous.inventoryTabRules;
	const bonusInventoryTabs = usePlayerField('bonusInventoryTabs');
	const tabNames = playerSettings.miscellaneous.inventoryTabNames;
	let tabOrder = playerSettings.miscellaneous.inventoryTabOrder;
	const tabCount = bonusInventoryTabs + 2;
	const [sort, setSort] = useState(defaultSearch ?? '');
	const [settingsVisible, setIsSettingsVisible] = useState(false);
	const [multiSelect, setMultiSelect] = useState(false);
	const [multiSelectDisable, setMultiSelectDisable] = useState(false);
	const [selectedItemIds, setSelectedItemIds] = useState<number[]>([]);
	const [activeInventory, setActiveInventory] = useState(inventoryName);
	const [activeInventoryTabs, setActiveInventoryTabs] = useState<number[]>([]); // empty means none selected, so show everything

	const currentInventory = usePlayerField(activeInventory);
	const socket = getSocket();

	const filterInventory = (item: IItem) => {
		let sortString = sort;
		if (sortString.length > 0) {
			sortString += '|';
		}
		for (const filter of filters) {
			if (filter.enabled) {
				sortString += filter.filter + '|';
			}
		}
		// Trim off the last character if it's a pipe
		if (sortString[sortString.length - 1] === '|') {
			sortString = sortString.substring(0, sortString.length - 1);
		}
		// Show currency if the item is a currency in the vault, or showCurrency is true
		if (item.itemID <= 49 && !showCurrency && activeInventory !== 'vault') return false;
		return filterItem(item, sortString);
	};

	function renderTab(tab: number, index: number, items: IItem[], renameTokenId: number | undefined) {
		return (
			<Box key={tab}>
				{index !== 0 && <hr />}
				{tab !== 0 && (
					<TabEditor
						tab={Number(tab)}
						allowEdit={settingsVisible}
						allowUp={index !== 0}
						allowDown={index !== tabCount - 1}
					/>
				)}
				<div className='all-items' data-tab={tab}>
					{items.map((item) => {
						if (!itemList[item.itemID]) return;
						const selected = selectedItemIds.includes(item.id);
						const boxShadow = selected
							? '0 0 5px 3px rgb(208, 108, 238), inset 0 0 2px 5px rgb(208, 108, 238)'
							: undefined;
						return (
							<InventoryItem
								key={activeInventory + item.id}
								inventoryName={activeInventory}
								item={item}
								renameTokenId={renameTokenId}
								overrideOnClick={multiSelect ? () => multiSelectItem(item.id) : undefined}
								boxShadow={boxShadow}
								updaterList={[multiSelect, selectedItemIds.length]}
							/>
						);
					})}
				</div>
			</Box>
		);
	}

	function renderItems() {
		const sortedInventory = currentInventory.filter(filterInventory);
		const renameTokenId =
			activeInventory === 'stockpile'
				? currentInventory.find((item) => item.itemID === itemsIds.item_name_token)?.id
				: undefined;
		const useActiveTabs = activeInventoryTabs.length > 0;
		// We need to render each tab separately, preprocess the items
		const tabbedItems: IItem[][] = [];
		for (const item of sortedInventory) {
			const tab = tabRules[item.itemID] ?? 0;
			if (useActiveTabs && !activeInventoryTabs.includes(tab)) {
				continue;
			}
			if (!tabbedItems[tab]) {
				tabbedItems[tab] = [];
			}
			tabbedItems[tab].push(item);
		}
		if (tabOrder.length !== tabCount) {
			// Replace, not modify the original settings by pushing
			tabOrder = tabbedItems.map((items, tab) => tab);
		}

		let index = -1;
		return (
			<div className='inventory-container'>
				<div className='multi-tabs'>
					{tabOrder.map((tab) => {
						if (!tabbedItems[tab]) return;
						index++;
						return renderTab(tab, index, tabbedItems[tab], renameTokenId);
					})}
					{Object.keys(tabbedItems).length === 0 && 'No items found.'}
				</div>
			</div>
		);
	}

	function renderSorting() {
		if (hideSorting) return;
		return (
			<div className='inventory-search-container'>
				<IdlescapeInput
					variant='search'
					setValue={setSort}
					value={sort}
					type='search'
					className='anchor-inventory-filter'
				/>
				<IdlescapeButton
					{...utilityButtonStyle}
					fontSize='1.8rem'
					onClick={toggleSettings}
					variant={settingsVisible ? 'green' : 'red'}
				>
					<IoSettingsSharp />
				</IdlescapeButton>
				{renderVaultButton()}
			</div>
		);
	}

	function utilitySellJunk() {
		// emit the command /selljunk
		const command = '/selljunk';

		const commandToSend = {
			channelId: 1, // general
			commandString: command,
		};

		socket.emit('chat:command:send', commandToSend);
	}
	function utilityEmptyFood() {
		socket.emit('inventory:emptyFoodInventory');
	}
	function utilityUnequipAll() {
		socket.emit('inventory:unequipAll');
	}
	function utilityMultiSelect() {
		if (multiSelect) {
			setSelectedItemIds([]);
		}
		setMultiSelect(!multiSelect);
	}
	function disableMultiSelect() {
		setSelectedItemIds([]);
		setMultiSelect(false);
	}

	const utilityButtonStyle = {
		margin: 'auto',
		width: '100%',
		height: '100%',
		padding: '0 1px',
		size: 'large' as 'small' | 'medium' | 'large',
		fontSize: '1rem',
	};

	function renderUtility() {
		return (
			<Grid
				gridTemplateColumns='repeat(auto-fit, minmax(100px, 1fr))'
				justifyContent='space-between'
				alignItems='center'
				marginBottom='10px'
				columnGap='5px'
			>
				<IdlescapeButton {...utilityButtonStyle} onClick={utilitySellJunk} variant='green'>
					Sell Junk
				</IdlescapeButton>
				<IdlescapeButton {...utilityButtonStyle} onClick={utilityEmptyFood} variant='green'>
					Empty Food Inv
				</IdlescapeButton>
				<IdlescapeButton {...utilityButtonStyle} onClick={utilityUnequipAll} variant='green'>
					Unequip All
				</IdlescapeButton>
				<IdlescapeButton
					{...utilityButtonStyle}
					onClick={utilityMultiSelect}
					variant={multiSelectDisable ? 'disabled' : 'green'}
				>
					{multiSelectDisable ? 'Please Wait...' : 'Multi-Select'}
				</IdlescapeButton>
			</Grid>
		);
	}

	function multiSelectItem(itemId: IItem['id']) {
		const found = selectedItemIds.indexOf(itemId);
		if (found !== -1) {
			setSelectedItemIds(selectedItemIds.filter((i) => i !== itemId));
		} else {
			setSelectedItemIds([...selectedItemIds, itemId]);
		}
		return true;
	}

	function multiVaultToggle() {
		if (selectedItemIds.length === 0) {
			disableMultiSelect();
			return;
		}
		socket.emit('inventory:vaultToggle:batch', {
			inventoryItemIDs: selectedItemIds,
			sourceInventory: activeInventory,
		});
		disableMultiSelect();
		setMultiSelectDisable(true);
		setTimeout(() => {
			setMultiSelectDisable(false);
		}, MULTI_MOVE_COOLDOWN);
	}
	function multiSelectAll() {
		const items: IItem[] = currentInventory.filter(filterInventory);
		setSelectedItemIds(items.map((i) => i.id));
	}
	function multiRemoveAll() {
		setSelectedItemIds([]);
	}
	function multiSendTab(i: number) {
		socket.emit('inventory:globalTab', { inventoryItemID: selectedItemIds, tab: i });
		disableMultiSelect();
	}

	function renderMultiSelect() {
		return (
			<Grid
				gridTemplateColumns='repeat(auto-fit, minmax(100px, 1fr))'
				justifyContent='space-between'
				alignItems='center'
				marginBottom='10px'
				columnGap='5px'
			>
				<IdlescapeButton {...utilityButtonStyle} onClick={multiVaultToggle} variant='blue'>
					Move to {activeInventory === 'stockpile' ? 'Vault' : 'Inventory'}
				</IdlescapeButton>
				<IdlescapeButton {...utilityButtonStyle} onClick={multiSelectAll} variant='green'>
					Select All
				</IdlescapeButton>
				<IdlescapeButton {...utilityButtonStyle} onClick={multiRemoveAll} variant='orange'>
					Remove All
				</IdlescapeButton>
				<IdlescapeButton {...utilityButtonStyle} onClick={utilityMultiSelect} variant='red'>
					Exit Selection
				</IdlescapeButton>
			</Grid>
		);
	}

	function renderSettings() {
		if (!settingsVisible) return;
		return (
			<div className='inventory-settings idlescape-container thin'>
				{multiSelect ? renderMultiSelect() : renderUtility()}
				{renderFilters()}
				{renderTabs()}
			</div>
		);
	}

	function renderVaultButton() {
		return (
			<IdlescapeButton {...utilityButtonStyle} fontSize='1.8rem' onClick={toggleVault}>
				{activeInventory === 'vault' ? <AiFillLock></AiFillLock> : <AiFillUnlock></AiFillUnlock>}
			</IdlescapeButton>
		);
	}

	function toggleVault() {
		if (activeInventory === 'vault') {
			setActiveInventory('stockpile');
		} else {
			setActiveInventory('vault');
		}
	}

	function toggleSettings() {
		if (settingsVisible) {
			disableMultiSelect();
		}
		setIsSettingsVisible(!settingsVisible);
	}

	function toggleTab(tab: number) {
		if (activeInventoryTabs.includes(tab)) {
			// Remove tab
			setActiveInventoryTabs(activeInventoryTabs.filter((t) => t !== tab));
		} else {
			// Add tab
			setActiveInventoryTabs([...activeInventoryTabs, tab]);
		}
	}

	function toggleFilter(index: number) {
		filters[index].enabled = !filters[index].enabled;
		// If it's the last filter, clear all filters
		if (index === filters.length - 1) {
			for (const filter of filters) {
				filter.enabled = false;
			}
		}
		setFilters([...filters]);
	}

	function renderFilters() {
		const filterElements: React.ReactElement[] = [];
		for (let index = 0; index < filters.length; index++) {
			const filter = filters[index];
			const color = filters[index].enabled ? 'green' : 'red';
			filterElements.push(
				<IdlescapeButton
					margin='auto'
					height='50px'
					width='100%'
					padding='10% 1px'
					size='medium'
					onClick={() => toggleFilter(index)}
					variant={color}
					key={filter.alt}
				>
					<Image
						src={filter.image}
						alt={filter.alt}
						maxHeight='75%'
						maxWidth='75%'
						margin='auto'
						object-fit='contain'
						filter='drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.9))'
					/>
				</IdlescapeButton>
			);
		}
		return (
			<Grid
				gridTemplateColumns='repeat(auto-fit, minmax(30px, 1fr))'
				justifyContent='space-between'
				alignItems='center'
				marginBottom='10px'
				columnGap='7px'
			>
				{filterElements}
			</Grid>
		);
	}

	function renderTabs() {
		// If it's not the vault or stockpile, return
		if (activeInventory !== 'vault' && activeInventory !== 'stockpile') return;
		const tabs: React.ReactElement[] = [];
		for (let i = 0; i < tabCount; i++) {
			const tab = tabOrder[i] ?? i;
			let onClick = () => toggleTab(tab);
			let variant = activeInventoryTabs.includes(tab) ? 'green' : 'gray';
			if (multiSelect) {
				onClick = () => multiSendTab(tab);
				variant = 'blue';
			} else {
				if (tab === 0) {
					continue; // This is the default tab / no tab selected, enabled during multi-select
				}
			}
			const tabName = tabNames[tab] ?? `Tab ${tab}`;
			tabs.push(
				<IdlescapeButton
					key={tab}
					margin='auto'
					padding='0 30px'
					size='small'
					onClick={onClick}
					variant={variant}
				>
					{tabName}
				</IdlescapeButton>
			);
		}
		return (
			<Grid
				display='grid'
				gridTemplateColumns='repeat(auto-fill, 100px)'
				justifyContent='space-between'
				alignItems='center'
			>
				{tabs}
			</Grid>
		);
	}

	function renderCurrency() {
		// hideCurrency is currently unused, but it's here
		if (hideCurrency) return;
		// If it's mobile, currency is always shown
		if (!isMobile) {
			// If it's not the vault or stockpile, return
			if (activeInventory !== 'vault' && activeInventory !== 'stockpile') return;
		}
		return <CurrencyPanel setFilter={setSort} />;
	}

	const currency = renderCurrency();
	const sorting = renderSorting();
	const settings = renderSettings();
	const items = renderItems();

	return (
		<Grid
			className='inventory-panel'
			gridTemplateRows={`repeat(${
				(currency !== undefined ? 1 : 0) + (sorting !== undefined ? 1 : 0) + (settings !== undefined ? 1 : 0)
			}, max-content) 1fr`}
		>
			{currency}
			{sorting}
			{settings}
			{items}
		</Grid>
	);
}
