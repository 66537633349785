import {
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	Image,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { IdlescapeWrappingTooltip } from '@idlescape/ui';
import { concat } from 'lodash';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { TSkillTab } from '../../../../../game-server/src/Atypes/Skills';
import { ILocationData, TLocationNodeTags } from '../../../../../game-server/src/modules/locations/location.interface';
import { activeTabAtom } from '../../../atoms/activeTabAtom';
import { enchantingTabAtom } from '../../../atoms/enchantingTabAtom';
import { gatheringTooltipTabAtom } from '../../../atoms/gatheringTooltipTabAtom';
import { scrollcraftingFilterAtom } from '../../../atoms/scrollcraftingFilterAtom';
import { usePlayerAffixStrength, usePlayerEnchantmentStrength, usePlayerField } from '../../../hooks/hooks';
import useIsMobile from '../../../hooks/useIsMobile';
import { MINIMUM_ACTION_TIME_IN_SECONDS } from '../../../utils/constantsCollection';
import { enchantmentsList } from '../../../utils/enchantmentList';
import {
	getFishingGatherLength,
	getFishingNodeLoot,
	getFishingNodes,
	getNodeSize,
} from '../../../utils/fishingFunctions';
import { itemList } from '../../../utils/itemList';
import { enchantmentsIds } from '../../../utils/lookup-dictionaries/lookupEnchantmentList';
import { itemsIds } from '../../../utils/lookup-dictionaries/lookupItemList';
import { locationsIds } from '../../../utils/lookup-dictionaries/lookupLocationList';
import { locationsLootTable } from '../../../utils/lootTableLocations';
import { CraftingAugmentingData } from '../CraftingAugmenting/CraftingAugmentingData';
import ResourceList from '../ResourceList';
import { IFishingData } from './Fishing/FishingData';

export default function GatheringResourceTooltip(props: { location: ILocationData; fishData?: IFishingData }) {
	const [gatheringTooltipTab, setGatheringTooltipTab] = useRecoilState(gatheringTooltipTabAtom);
	const setActiveTab = useSetRecoilState(activeTabAtom);
	const setEnchantingTab = useSetRecoilState(enchantingTabAtom);
	const setScrollcraftingCategory = useSetRecoilState(scrollcraftingFilterAtom);

	const skills = usePlayerField('skills');
	const skillEquipmentStats = usePlayerField('skillEquipmentStats');
	const skill = props.location.actionType.replace('Action-', '').toLowerCase() as TSkillTab;
	const effectiveLevel = skills[skill].level + skills[skill].masteryLevel + skillEquipmentStats[skill];

	const nodeBonusSize = usePlayerAffixStrength('fishing.node_bonus_size');
	const dangerZonePlus = usePlayerAffixStrength('gathering.dangerous_zone_chance');
	const dangerZoneMinus = usePlayerAffixStrength('gathering.dangerous_zone_chance_inverted');

	const hasteStrength = usePlayerEnchantmentStrength(enchantmentsIds.haste, skill);
	const wealthStrength = usePlayerEnchantmentStrength(enchantmentsIds.wealth, skill);
	const spiralingOutOfControlStrength = usePlayerEnchantmentStrength(enchantmentsIds.spiraling_out_of_control, skill);
	const hamsterHunterStrength = usePlayerEnchantmentStrength(enchantmentsIds.hamster_hunter, skill);

	const superHeatingStrength = usePlayerEnchantmentStrength(enchantmentsIds.superheating, skill);
	const superCoolingStrength = usePlayerEnchantmentStrength(enchantmentsIds.supercooling, skill);

	const natureStrength = usePlayerEnchantmentStrength(enchantmentsIds.nature, skill);
	const herbalistStrength = usePlayerEnchantmentStrength(enchantmentsIds.herbalist, skill);
	const seedHarvestingStrength = usePlayerEnchantmentStrength(enchantmentsIds.seed_harvesting, skill);

	const fishingMagnetismStrength = usePlayerEnchantmentStrength(enchantmentsIds.fishing_magnetism, skill);
	const deadliestCatchStrength = usePlayerEnchantmentStrength(enchantmentsIds.deadliest_catch, skill);
	const fiberFinderStrength = usePlayerEnchantmentStrength(enchantmentsIds.fiber_finder, skill);
	const fishyFortuneStrength = usePlayerEnchantmentStrength(enchantmentsIds.fishy_fortune, skill);
	const junkSnaggingStrength = usePlayerEnchantmentStrength(enchantmentsIds.junk_snagging, skill);

	const calmingStrength = usePlayerEnchantmentStrength(enchantmentsIds.calming_aura, skill);
	const dangerZoneStrength = usePlayerEnchantmentStrength(enchantmentsIds.danger_zone, skill);

	const isMobile = useIsMobile();

	const overallTime = getGatheringTime(
		props.location,
		hasteStrength,
		effectiveLevel,
		spiralingOutOfControlStrength,
		skills.runecrafting.masteryLevel,
		hamsterHunterStrength
	);
	let actionsPerHour = Math.floor(3600 / overallTime);
	if (props.fishData) {
		const gatherTime = getFishingGatherLength(props.location, 1 + hasteStrength, props.fishData.reel);
		actionsPerHour = Math.floor(3600 / (gatherTime / 1000));
	}

	const locationLoot = locationsLootTable[props.location.locID];
	const locationNodeLoot = locationLoot.nodes;
	// generate tooltip
	let resources: {
		id: number;
		frequency?: number;
		minAmount?: number;
		maxAmount?: number;
		minRarity?: number;
	}[] = locationNodeLoot ? getLocationNodeFrequencies() : miningLoot();

	// Clear resources if Wealth is active
	if (wealthStrength) {
		let gold = 0;
		for (const item of resources) {
			const itemData = itemList[item.id];
			const value = itemData.value ?? 0;
			const minAmount = item.minAmount ?? item.maxAmount ?? 0;
			const maxAmount = item.maxAmount ?? minAmount;
			gold += value * (item.frequency ?? 1) * ((minAmount + maxAmount) / 2) * (1 + wealthStrength);
		}
		gold = Math.floor(gold);
		resources = [{ id: itemsIds.gold, frequency: 1, minAmount: gold, maxAmount: gold }];
	}

	function miningLoot() {
		if (!locationLoot.loot) return [];
		const temperaturePower = superHeatingStrength - superCoolingStrength;
		let totalFrequency = 0;
		for (const lootItem of locationLoot.loot) {
			let frequency = lootItem.frequency ?? 0;
			if (temperaturePower !== 0) {
				const heatValue = barHeatFromOre(lootItem.id);
				if (heatValue > 0) {
					const heatTier = 1 + heatValue ** (1 / 3) / 10;
					const temperatureEffect = heatTier ** (50 * temperaturePower);
					frequency *= temperatureEffect;
				}
			}
			totalFrequency += frequency;
		}
		return locationLoot.loot.map((loot) => ({
			id: loot.id,
			frequency: (loot.frequency ?? 0) / totalFrequency,
			minAmount: loot.minAmount,
			maxAmount: loot.maxAmount,
		}));
	}

	function barHeatFromOre(itemId: number) {
		const craftingList = CraftingAugmentingData.getCraftingAugmentingList();
		for (const recipe of Object.values(craftingList)) {
			if (!recipe.smithing) continue;
			if (Object.keys(recipe.smithing).includes(String(itemId))) {
				return recipe.smithing[itemsIds.heat] ?? 0;
			}
		}
		return 0;
	}

	function getLocationNodeFrequencies() {
		const nodes = props.location.nodes;
		const nodeLoot = locationsLootTable[props.location.locID].nodes;
		if (!nodes || !nodeLoot) return [];
		return (
			concat(
				...nodes.map((node) =>
					nodeLoot[node.nodeID].map((loot) => ({
						id: loot.id,
						minRarity: Math.max(0, -(loot.frequency ?? 0), -node.frequency),
					}))
				)
			)
				// only keep the lowest minRarity of each item
				.sort((a, b) => (a.minRarity ?? 0) - (b.minRarity ?? 0))
				.filter((item, index, self) => index === self.findIndex((loot) => loot.id === item.id))
				// remove negative minRarity
				.map((item) => ({
					id: item.id,
					minRarity: item.minRarity > 0 ? item.minRarity : undefined,
				}))
		);
	}

	function getHint() {
		switch (skill) {
			case 'foraging':
				return (
					<Text gridColumn='1/-1'>
						The frequency of nodes that have an icon next to them, can be increased with that respective{' '}
						<Text
							as='span'
							onClick={showHatchetEnchants}
							cursor='pointer'
							color='blue.500'
							_hover={{ textDecoration: 'underline' }}
						>
							enchantment
						</Text>
						.
					</Text>
				);
			case 'fishing':
				return (
					<Text gridColumn='1/-1'>
						To find nodes and loot with a rarity you need more bonus rarity than the threshold.
					</Text>
				);
			default:
				return null;
		}
	}

	function showHatchetEnchants() {
		setActiveTab('enchanting');
		setEnchantingTab('scrollcrafting');
		setScrollcraftingCategory('hatchet');
	}

	function renderNodeLoot() {
		if (props.location.nodes === undefined || locationNodeLoot === undefined) return null;
		const nodes = structuredClone(props.location.nodes);
		if (props.fishData) {
			const fishingNodes = getFishingNodes(
				props.location,
				props.fishData.bonus,
				props.fishData.level,
				fishingMagnetismStrength,
				fiberFinderStrength,
				fishyFortuneStrength,
				junkSnaggingStrength
			);
			for (let i = nodes.length - 1; i >= 0; i--) {
				const fishNode = fishingNodes.find((fish) => fish.id === i);
				const node = nodes[i];
				if (fishNode) {
					const nodeSize = getNodeSize(node, props.fishData.level, props.fishData.bait, nodeBonusSize);
					node.frequency = fishNode.frequency ?? 0;
					node.minimumBaseAmount = nodeSize.minAmount;
					node.maximumBaseAmount = nodeSize.maxAmount;
				} else {
					// nodes.splice(i, 1);
				}
			}
		} else if (skill === 'foraging') {
			for (const node of nodes) {
				// Multiply by strength
				if (node.tags?.includes('tree')) {
					node.frequency += natureStrength;
				}
				if (node.tags?.includes('plants')) {
					node.frequency += herbalistStrength;
				}
				if (node.tags?.includes('seeds')) {
					node.frequency += seedHarvestingStrength;
				}
				node.frequency = Math.min(node.frequency, node.maxFrequency ?? 999);
			}
		}
		const bonusRarity = skill === 'fishing' ? skillEquipmentStats.fishingRarityPower : 0;
		const totalNodeFrequency = nodes.reduce((acc, node) => acc + (node.frequency > 0 ? node.frequency : 0), 0);

		return nodes.map((nodeData) => {
			const loot =
				skill === 'fishing'
					? getFishingNodeLoot(
							locationLoot,
							nodeData.nodeID,
							bonusRarity,
							effectiveLevel,
							deadliestCatchStrength,
							fiberFinderStrength,
							itemList,
							true
					  )
					: locationNodeLoot[nodeData.nodeID];
			const totalFrequency = loot.reduce((acc, item) => acc + Math.max(0, item.frequency ?? 0), 0);

			const formattedLoot = loot.map((item) => {
				// for fishing getFishingNodeLoot already handles maxFrequency, since fishing allows to go over the cap
				const frequency =
					skill === 'fishing'
						? item.frequency ?? 0
						: Math.max(0, Math.min(item.frequency ?? 0, item.maxFrequency ?? Infinity));
				return {
					id: item.id,
					frequency: frequency / totalFrequency,
					minAmount: item.minAmount,
					maxAmount: item.maxAmount,
					minRarity: (item.frequency ?? 0) <= 0 ? item.frequency ?? 0 : undefined,
				};
			});

			const nodeSize = props.fishData
				? getNodeSize(nodeData, props.fishData.level, props.fishData.bait, nodeBonusSize)
				: { minAmount: nodeData.minimumBaseAmount, maxAmount: nodeData.maximumBaseAmount };

			return (
				<AccordionItem key={nodeData.nodeID}>
					<AccordionButton backgroundColor={skill === 'foraging' ? '#14471a' : '#346677'}>
						<Flex justifyContent='space-between' width='100%'>
							{nodeData.nodeID}
							<Flex>
								{nodeData.frequency < 0 ? (
									<Box color='#F88'>{-nodeData.frequency} Rarity</Box>
								) : nodeData.frequency === 0 ? (
									<Box>Hidden</Box>
								) : (
									<Box>{((nodeData.frequency / totalNodeFrequency) * 100).toFixed(1)}%</Box>
								)}
								{nodeData.tags && nodeData.tags.map(nodeTagIcon)}
							</Flex>
						</Flex>
					</AccordionButton>
					<AccordionPanel>
						<Flex justifyContent='center' alignItems='center' gap='3px'>
							Node Size: {nodeSize.minAmount.toLocaleString('en-us', { maximumFractionDigits: 2 })} -{' '}
							{(nodeSize.maxAmount ?? 0).toLocaleString('en-us', { maximumFractionDigits: 2 })}
							<IdlescapeWrappingTooltip
								content={`The final node size is chosen randomly between these two values and then floored.${
									skill === 'fishing'
										? ' There is a small chance based on your bait to significantly increase the size of the node.'
										: ''
								}`}
								activateOnClick
							>
								<Text fontSize='20px' cursor='pointer' margin='0'>
									<AiOutlineInfoCircle />
								</Text>
							</IdlescapeWrappingTooltip>
						</Flex>
						<div className='resource-container-resource-list'>
							<ResourceList resources={formattedLoot} skill={skill} />
						</div>
					</AccordionPanel>
				</AccordionItem>
			);
		});
	}

	function nodeTagIcon(tag: TLocationNodeTags) {
		let icon, alt;
		switch (tag) {
			case 'tree':
				icon = enchantmentsList[enchantmentsIds.nature].buffIcon;
				alt = 'Nature';
				break;
			case 'plants':
				icon = enchantmentsList[enchantmentsIds.herbalist].buffIcon;
				alt = 'Herbalist';
				break;
			case 'seeds':
				icon = enchantmentsList[enchantmentsIds.seed_harvesting].buffIcon;
				alt = 'Seed Harvesting';
				break;
			case 'fish':
				icon = enchantmentsList[enchantmentsIds.fishy_fortune].buffIcon;
				alt = 'Fishy Fortune';
				break;
			case 'fiber':
				icon = enchantmentsList[enchantmentsIds.fiber_finder].buffIcon;
				alt = 'Fiber Finder';
				break;
			case 'driftwood':
				icon = enchantmentsList[enchantmentsIds.junk_snagging].buffIcon;
				alt = 'Junk Snagging';
				break;
		}
		return (
			<IdlescapeWrappingTooltip key={tag} content={alt}>
				<Image src={icon} alt={alt} height='20px' width='20px' marginLeft='2px' />
			</IdlescapeWrappingTooltip>
		);
	}

	function renderDangerousEncounter() {
		if (!props.location.dangerousGatheringZoneChance || !props.location.dangerousGatheringZoneChance) return null;

		const chanceMultiplier = 1 + dangerZoneStrength - calmingStrength;
		const affixDangerMult = 1 + dangerZonePlus - dangerZoneMinus;
		const chance = props.location.dangerousGatheringZoneChance * chanceMultiplier * affixDangerMult;
		return <Text>Chance for a dangerous encounter: {(chance * 100).toFixed(1)}%</Text>;
	}

	return (
		<Box
			className='resource-container-tooltip'
			{...(!isMobile && skill !== 'mining' ? { maxWidth: '300px' } : null)}
		>
			<div className='resource-container-loot-title'>Loot</div>
			{skill === 'mining' ? (
				<Box className='resource-container-resource-list'>
					<ResourceList resources={resources} skill={skill} />
				</Box>
			) : (
				<Tabs align='center' index={gatheringTooltipTab} onChange={setGatheringTooltipTab}>
					<TabList>
						{/* Using preventDefault the tab switches onMouseUp instead,
						which prevents the popup from closing unintentionally */}
						<Tab onMouseDown={(e) => e.preventDefault()}>Overview</Tab>
						<Tab onMouseDown={(e) => e.preventDefault()}>Nodes</Tab>
					</TabList>
					<TabPanels>
						<TabPanel paddingX='0' paddingBottom='0'>
							<Box
								className='resource-container-resource-list'
								gridTemplateColumns={`repeat(4, ${isMobile ? '50px' : '60px'})`}
								justifyContent='center'
							>
								<ResourceList resources={resources} skill={skill} />
							</Box>
						</TabPanel>
						<TabPanel paddingX='0' paddingBottom='0'>
							<Accordion allowMultiple variant='lootLog'>
								{renderNodeLoot()}
							</Accordion>
							{getHint()}
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}
			<hr></hr>
			{props.location.extraTooltipInfo !== undefined && props.location.extraTooltipInfo !== 'WIP' && (
				<p className={'red-text'}>{props.location.extraTooltipInfo}</p>
			)}
			{renderDangerousEncounter()}
			<div className='resource-container-resource-aph'>Actions Per Hour: ~{actionsPerHour}</div>
		</Box>
	);
}

export function getGatheringTime(
	location: ILocationData,
	hasteStrength: number,
	effectiveLevel: number,
	spiralingOutOfControlStrength: number,
	runecraftingMasteryLevels: number,
	hamsterHunterStrength: number
) {
	let time = location.baseDuration / 1000;
	time = time / (1 + hasteStrength);

	if (location.actionType) {
		if (location.actionType.includes('Foraging') || location.actionType.includes('Mining')) {
			time /= 99 + effectiveLevel;
			time *= 100;
		}
		if (spiralingOutOfControlStrength && location.locID === locationsIds.slate_spires) {
			time /= 1 + spiralingOutOfControlStrength * runecraftingMasteryLevels;
		}
		if (hamsterHunterStrength && location.locID === locationsIds.underground_mine) {
			time /= 1 + hamsterHunterStrength;
		}
	}
	return Math.max(MINIMUM_ACTION_TIME_IN_SECONDS, time);
}
