import { Flex, Image, useDisclosure } from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useEffect, useState } from 'react';
import { TSkillName } from '../../../../../game-server/src/Atypes/Skills';
import { socket } from '../../../services/socket.service';
import { tabs } from '../../../helper/NavigationTabsList';

export default function MaxLevelPopup() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [skillName, setSkillName] = useState<TSkillName | null>(null);

	useEffect(() => {
		socket.on('maxlevel:popup', (data) => {
			setSkillName(data.skill);
			onOpen();
		});

		return () => {
			socket.off('maxlevel:popup');
		};
	}, []);

	function handleCloseModal() {
		setSkillName(null);
		onClose();
	}

	function renderSkill() {
		if (!skillName) {
			return (
				<React.Fragment key={skillName}>
					<DialogTitle>Invalid Skill Popup</DialogTitle>
				</React.Fragment>
			);
		}

		const tab = Object.values(tabs).find((t) => t.id === skillName);

		if (!tab) {
			return (
				<React.Fragment key={skillName}>
					<DialogTitle>Invalid Skill Popup</DialogTitle>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment key={'maxpopup' + tab.id}>
				<DialogTitle>{tab.name} Maxed</DialogTitle>
				<div className='popup-content'>
					<Flex direction='column' align='center'>
						{tab.icon ? (
							<Image
								src={tab.icon}
								alt={tab.id}
								width='auto'
								height='auto'
								maxWidth='150px'
								maxHeight='150px'
							/>
						) : (
							''
						)}
						<p>Congratulations, you have reached the maximum level for {tab.name}!</p>
						{skillName !== 'total' ? <p>You will now receive mastery experience.</p> : ''}
					</Flex>
				</div>
			</React.Fragment>
		);
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleCloseModal}
			className='donate-dialog feedback-dialog sell-item-dialog popup-dialog'
		>
			{renderSkill()}
			<DialogActions>
				<IdlescapeButton variant='red' onClick={handleCloseModal} size='medium'>
					Close
				</IdlescapeButton>
			</DialogActions>
		</Dialog>
	);
}
