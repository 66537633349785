// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { Action } from '../../../game-server/src/modules/actions/Action';
import { CraftingAugmentingData } from '../components/game/CraftingAugmenting/CraftingAugmentingData';
import { IItem, IItemData, TItemList } from '../../../game-server/src/modules/items/items.interface';
import { locationsIds } from '../utils/lookup-dictionaries/lookupLocationList';
import { gameShopList } from '../utils/gameShopList';
import { AFFIX_DUST_PER_ITEM_TIER, MINIMUM_ACTION_TIME_IN_SECONDS } from './constantsCollection';
import { hasEnoughResources } from './itemFunctions';
import { itemsIds } from '../utils/lookup-dictionaries/lookupItemList';
import { CUPS } from '../utils/constantsCollection';

export const DISENCHANT_REDUCTION_PER_TIER = 0.1;
export const DISENCHANT_EXPERIENCE_MULTIPLIER = 0.5;

export function getItemTier(itemData: IItemData) {
	let highestRequiredLevel;
	if (itemData.requiredLevel) {
		// Highest level of the required skills
		highestRequiredLevel = Math.max(...Object.values(itemData.requiredLevel));
		highestRequiredLevel = Number((highestRequiredLevel / 10).toFixed(1));
	}
	const itemTier = itemData.overrideItemTier ?? highestRequiredLevel ?? itemData.enchantmentTier ?? 1;
	return itemTier;
}

export function sortAugmentingQueue(list: IItem[], ascending: boolean) {
	const factor = ascending ? 1 : -1;
	list.sort((itemA, itemB) => {
		const augmentationsA = itemA.augmentations ?? 0;
		const augmentationsB = itemB.augmentations ?? 0;
		return (augmentationsA - augmentationsB) * factor;
	});
	return list;
}

export function getAugmentingCost(item: IItem, itemData: IItemData, baseCost: { [key: number]: number }) {
	const finalCost: { id: number; amount: number }[] = [];

	const counterThreshold = getAugmentingTrialCount(item, itemData);
	if ((item.augmentCounter ?? 0) >= counterThreshold) {
		const copyCost = getAugmentItemCopyCost(item, itemData);
		if (copyCost > 0) {
			finalCost.push({ id: item.itemID, amount: copyCost });
		}
	}

	for (const [itemID, cost] of Object.entries(baseCost)) {
		const amount = Math.max(1, Math.round(cost));
		finalCost.push({ id: Number(itemID), amount });
	}

	return finalCost;
}

export function isCurrentlyAugmenting(currentAction: Action | null) {
	return currentAction && currentAction.location === locationsIds.the_academy_of_augmentation;
}

export function hasAugmentationCost(item: IItem, itemList: TItemList) {
	const itemData = itemList[item.itemID];
	if (itemData.blockAugmenting ?? false) {
		return false;
	}
	const augmentationCost = CraftingAugmentingData.getScrappingByID(itemData.id);
	return augmentationCost !== undefined;
}

export function hasScrappingCost(item: IItem, itemList: TItemList) {
	const itemData = itemList[item.itemID];
	if (itemData.blockResearching ?? false) {
		return false;
	}
	const augmentationCost = CraftingAugmentingData.getScrappingByID(itemData.id);
	return augmentationCost !== undefined;
}

export function validToScrap(item: IItem, itemList: TItemList) {
	const itemData = itemList[item.itemID];
	if (itemData.blockResearching ?? false) {
		return false;
	}
	if (!hasScrappingCost(item, itemList)) {
		return false;
	}
	// Allow CUPS
	if (!CUPS.includes(item.itemID)) {
		const isInGameShop = Object.values(gameShopList).some((shopItem) => shopItem.itemID === item.itemID);
		if (isInGameShop) {
			return false;
		}
	}
	return true;
}

export function itemCanBeAugmented(
	item: IItem,
	effectiveEnchantingLevel: number,
	masteryEnchantingLevel: number,
	stockpile: IItem[],
	maxAugmentLevel: number,
	itemList: TItemList
): boolean {
	if (!hasAugmentationCost(item, itemList)) {
		return false;
	}
	const itemData = itemList[item.itemID];
	const augmentations = item.augmentations ?? 0;
	maxAugmentLevel = maxAugmentLevel === 0 ? Infinity : maxAugmentLevel;
	if (augmentations >= maxAugmentLevel) {
		return false;
	}

	if (augmentations >= (itemData.maxAugLevel ?? Infinity)) {
		return false;
	}

	const baseCost = CraftingAugmentingData.getScrappingByID(item.itemID);
	const augCosts = getAugmentingCost(
		{ ...item, augmentCounter: (item.augmentCounter ?? 0) + 2 },
		itemData,
		baseCost ?? {}
	);
	return hasEnoughResources(stockpile, augCosts, itemList);
}

export function augmentingBlockReason(
	item: IItem,
	effectiveEnchantingLevel: number,
	masteryEnchantingLevel: number,
	stockpile: IItem[],
	maxAugmentLevel: number,
	itemList: TItemList
): string | false {
	const augmentations = item.augmentations ?? 0;
	maxAugmentLevel = maxAugmentLevel === 0 ? Infinity : maxAugmentLevel;
	if (augmentations >= maxAugmentLevel) {
		return 'Max augmentation setting';
	}

	const itemData = itemList[item.itemID];
	if (itemData.maxAugLevel && augmentations >= itemData.maxAugLevel) {
		return 'Max item augmentation level';
	}

	const baseCost = CraftingAugmentingData.getScrappingByID(item.itemID);
	const augCosts = getAugmentingCost(item, itemData, baseCost ?? {});
	if (!hasEnoughResources(stockpile, augCosts, itemList)) {
		return 'Not enough resources';
	}
	return false;
}

export function getAugmentChance(
	effectiveEnchantingLevel: number,
	item: IItem,
	itemData: IItemData,
	bonusChance = 0
): number {
	if (itemData.blockCritAugment) {
		return 0;
	}
	return AUGMENT_BASE_CRIT_CHANCE + bonusChance;
}

// Scrapping specific stuff, since it's also technically enchanting
export const SCRAPPING_BASE_ATTEMPTS_PER_FAIL = 10;
const SCRAPPING_BASE_XP = 20;
const SCRAPPING_XP_POWER = 2;
const SCRAPPING_TIME_POWER = 1.5;
const AUGMENT_BASE_CRIT_CHANCE = 0.1;

export function scrappingTime(itemData: IItemData, effectiveEnchantingLevel: number, hasteStrength: number) {
	const tier = getItemTier(itemData);
	let time = Math.pow(2 + tier, SCRAPPING_TIME_POWER);
	time /= 1 + hasteStrength;
	time /= (effectiveEnchantingLevel + 100) / 100;
	return Math.max(MINIMUM_ACTION_TIME_IN_SECONDS, time);
}

export function getScrapSuccessChance(itemData: IItemData, bonusAttempts: number): number {
	if (CUPS.includes(itemData.id)) {
		return 1;
	}
	return 1 - 1 / Math.max(1, SCRAPPING_BASE_ATTEMPTS_PER_FAIL + bonusAttempts);
}

export function getScrapExperience(itemData: IItemData): number {
	const itemTier = getItemTier(itemData);
	const xp = SCRAPPING_BASE_XP * Math.pow(itemTier, SCRAPPING_XP_POWER);
	return Math.round(xp);
}

export function isCurrentlyScrapping(currentAction: Action | null) {
	return currentAction && currentAction.location === locationsIds.the_institute_of_research;
}

export function getAugmentItemCopyCost(item: IItem, itemData: IItemData): number {
	const cost = Math.pow(Math.floor((item.augmentations ?? 0) / 5), 2);
	if (itemData.augmentOverride?.fixedBaseCount !== undefined) {
		return itemData.augmentOverride.fixedBaseCount;
	}
	return cost;
}

export function getAugmentingTrialCount(item: IItem, itemData: IItemData): number {
	if (itemData.augmentOverride?.fixedSuccessCount !== undefined) {
		return itemData.augmentOverride.fixedSuccessCount;
	}
	return 10 * (5 + Math.pow(item.augmentations ?? 0, 2));
}

export function getDustIDFromItemTier(itemTier?: number) {
	let tier = Math.floor(itemTier ?? 0);
	if (tier < 0) {
		tier = 0;
	}
	if (tier >= AFFIX_DUST_PER_ITEM_TIER.length) {
		tier = AFFIX_DUST_PER_ITEM_TIER.length - 1;
	}
	return AFFIX_DUST_PER_ITEM_TIER[tier];
}

export function itemCanBeScrapped(item: IItem, stockpile: IItem[], itemList: TItemList): boolean {
	if (!hasScrappingCost(item, itemList)) {
		return false;
	}
	const baseCost = CraftingAugmentingData.getScrappingByID(item.itemID);
	const convertedCost = baseCost
		? Object.entries(baseCost).map(([id, amount]) => ({ id: parseInt(id, 10), amount }))
		: [];
	return hasEnoughResources(stockpile, convertedCost, itemList);
}

export function salvageResources(
	item: IItem,
	itemData: IItemData,
	stackSize: number,
	scrollData?: IItemData
): { resources: { itemID: number; stackSize: number }[]; experience: number } {
	const resources: { itemID: number; stackSize: number }[] = [];
	const enchantmentStrength = item.enchantmentStrength ?? 0;
	const scrollExperience = scrollData ? (scrollData.experience || 0) * DISENCHANT_EXPERIENCE_MULTIPLIER : 0;
	const scrollResources = scrollData ? CraftingAugmentingData.getScrollcraftingByID(scrollData.id) : {};
	if (scrollResources) {
		const itemTier = getItemTier(itemData);
		const refundMultiplier = 1 - itemTier * DISENCHANT_REDUCTION_PER_TIER;
		for (const [resourceID, amount] of Object.entries(scrollResources)) {
			const amountToSalvage = Math.round(refundMultiplier * amount * enchantmentStrength * stackSize);
			if (amountToSalvage > 0) {
				resources.push({ itemID: Number(resourceID), stackSize: amountToSalvage });
			}
		}
	}

	return { resources, experience: scrollExperience * stackSize };
}
