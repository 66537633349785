import React, { useState } from 'react';
import { BASE_PLAT_PER_DOLLAR, platinumPacks } from '../../../../utils/platinumPackList';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { PlatinumShopPurchaseReview } from '../PlatinumShopPurchaseReview';
import ChatItem from '../../Chat/Links/ChatItem';
import LocaleCurrency from 'locale-currency';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import { IItemData } from '../../../../../../game-server/src/modules/items/items.interface';
import { itemList } from '../../../../utils/itemList';

export const PlatinumShopPlatinumContainer = () => {
	const [selectedPack, setSelectedPack] = useState(0);

	const PlatinumPackList = () => {
		const packs = Object.values(platinumPacks).map((platinumPackData) => {
			const basePlat = platinumPackData.price * BASE_PLAT_PER_DOLLAR;
			const extra = platinumPackData.platinumAmount / basePlat - 1;
			return (
				<PremiumPack
					key={'platinum-pack-' + platinumPackData.id}
					amount={platinumPackData.platinumAmount}
					price={platinumPackData.price}
					image={platinumPackData.image}
					onClick={() => setSelectedPack(platinumPackData.id)}
					itemID={itemsIds.platinum}
					extra={extra}
				/>
			);
		});

		return <div className='platinum-pack-list'>{packs}</div>;
	};

	return (
		<div className='platinum-shop-platinum-container'>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={selectedPack}
					timeout={300}
					addEndListener={(node, done) => node.addEventListener('transitionend', done, false)}
					classNames='fade'
				>
					{selectedPack > 0 ? (
						<PlatinumShopPurchaseReview platinumPackId={selectedPack} setSelectedPack={setSelectedPack} />
					) : (
						<PlatinumPackList />
					)}
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
};

export const PremiumPack = (props: {
	amount: number;
	price: number;
	image: string;
	onClick: () => void;
	itemID: IItemData['id'];
	extra: number;
}) => {
	const locale = navigator.language;
	const localeCurrency = LocaleCurrency.getCurrency(locale) === 'EUR' ? '€' : '$';
	return (
		<div onClick={props.onClick} className='platinum-pack idlescape-container idlescape-container-platinum'>
			{props.extra > 0.01 && (
				<div className='platinum-pack-discount'>
					+{Math.floor(props.extra * 100).toLocaleString('en-us', { maximumFractionDigits: 0 })}%
				</div>
			)}
			<div className='platinum-pack-titles'>
				<div className='platinum-pack-main-title'>{props.amount.toLocaleString('en-us')}</div>
				<div className='platinum-pack-sub-title'>
					<ChatItem item={{ itemID: props.itemID, stackSize: props.amount }} />
				</div>
			</div>
			<img src={props.image} alt={props.amount + ' ' + itemList[props.itemID].name} />
			<div className='platinum-pack-price'>
				{localeCurrency}
				{props.price}
			</div>
		</div>
	);
};
