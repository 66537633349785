// @@@ THIS FILE IS DYNAMICALLY GENERATED.
// @@@ MODIFICATIONS WILL NEVER BE SAVED.
// @@@ MODIFY THE MASTER FILE AT THE ROOT SERVER DIRECTORY TO MAKE CHANGES
/* eslint-disable */
import { TIdlescapeBondPackList } from '../../../game-server/src/modules/monetization/IdlescapeBondPackData';

export const idlescapeBondPacks: TIdlescapeBondPackList = {
	1: {
		id: 1,
		name: '1 Idlescape Bond',
		description: 'Idlescape Bonds are used to gift one month of Idlescape Plus to other players.',
		bondAmount: 1,
		price: 4.95,
		priceId: {
			development: 'price_1QhTOOEnEMjApR1KOxoa5Na6',
			production: 'price_1Qg9fHEnEMjApR1KkttoZDnX',
		},
		image: '/images/chaticons/gift-sub-5.png',
	},
	2: {
		id: 2,
		name: '5 Idlescape Bonds',
		description: 'Idlescape Bonds are used to gift one month of Idlescape Plus to other players.',
		bondAmount: 5,
		price: 23.45,
		priceId: {
			development: 'TODO',
			production: 'TODO',
		},
		image: '/images/chaticons/gift-sub-5.png',
	},
	3: {
		id: 3,
		name: '10 Idlescape Bonds',
		description: 'Idlescape Bonds are used to gift one month of Idlescape Plus to other players.',
		bondAmount: 10,
		price: 44.95,
		priceId: {
			development: 'TODO',
			production: 'TODO',
		},
		image: '/images/chaticons/gift-sub-5.png',
	},
};

const basePack = idlescapeBondPacks[1];
export const BASE_BONDS_PER_DOLLAR = basePack.bondAmount / basePack.price;
