import {
	Box,
	Grid,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeWrappingTooltip } from '@idlescape/ui';
import React from 'react';
import { usePlayerField } from '../../../hooks/hooks';
import { socket } from '../../../services/socket.service';
import { chatIconList } from '../../../utils/chatIconList';
import { chatIconsIds } from '../../../utils/lookup-dictionaries/lookupChatIconList';
import { modIcons } from './ChatMessage';

export default function ChatSettingsPopup(props: { isOpen: boolean; onClose: () => void }) {
	const settings = usePlayerField('settings');
	const unlockedIcons = usePlayerField('unlockedIcons');
	const tempIcons = usePlayerField('tempIcons');
	const accountStatus = usePlayerField('accountStatus');
	const subscription = usePlayerField('subscription');
	const ownedIcons = unlockedIcons.concat(tempIcons);
	const modIcon = modIcons[accountStatus];

	const selectedIcons: number[] = [];
	if (settings.chat.selectedIcon) selectedIcons.push(settings.chat.selectedIcon);
	if (modIcon !== -1) {
		ownedIcons.unshift({ iconID: modIcon, accountId: 0 });
		if (settings.chat.showMyAccountStatus) {
			selectedIcons.push(modIcon);
		}
	}
	if (subscription.active) {
		ownedIcons.unshift({ iconID: chatIconsIds.idlescape_plus, accountId: 0 });
		if (settings.chat.showMyPremiumIcon) {
			selectedIcons.push(chatIconsIds.idlescape_plus);
		}
	}
	// Filter out duplicate icons in ownedIcons that have the same icon.iconID
	const filteredIcons = ownedIcons.filter((icon, index, self) => {
		return index === self.findIndex((t) => t.iconID === icon.iconID);
	});

	function setActiveIcon(iconID: number) {
		if (iconID === chatIconsIds.idlescape_plus) {
			socket.emit('settings:update', {
				value: !settings.chat.showMyPremiumIcon,
				path: 'chat.showMyPremiumIcon',
			});
			return;
		}
		if (iconID === modIcon) {
			socket.emit('settings:update', {
				value: !settings.chat.showMyAccountStatus,
				path: 'chat.showMyAccountStatus',
			});
			return;
		}
		if (iconID && selectedIcons.includes(iconID)) {
			socket.emit('chat:icon:set', {
				iconID: null,
			});
			return;
		}

		socket.emit('chat:icon:set', {
			iconID,
		});
	}

	return (
		<Modal isOpen={props.isOpen} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Icons</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Grid gridTemplateColumns='repeat(auto-fit, 45px)' gap='5px' justifyContent='center'>
						{filteredIcons.map((icon) => {
							const iconData = chatIconList[icon.iconID];
							if (!iconData) return null;
							return (
								<IdlescapeWrappingTooltip key={iconData.id} content={iconData.name}>
									<Box
										cursor='pointer'
										onClick={() => setActiveIcon(iconData.id)}
										border={selectedIcons.includes(icon.iconID) ? '1px solid #fff' : ''}
										borderRadius='5px'
										height='45px'
										width='45px'
										padding='3px'
										aria-selected={selectedIcons.includes(icon.iconID)}
									>
										<Image
											src={iconData.source}
											alt={iconData.name}
											className={iconData?.css}
											width='100%'
											height='100%'
											objectFit='contain'
										/>
									</Box>
								</IdlescapeWrappingTooltip>
							);
						})}
					</Grid>
					{filteredIcons.length === 0 && 'No icons unlocked yet.'}
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Close
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
