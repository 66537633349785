import {
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Switch,
	Text,
} from '@chakra-ui/react';
import { IdlescapeButton, IdlescapeInput } from '@idlescape/ui';
import React, { useState } from 'react';
import { socket } from '../../../../services/socket.service';
import { itemsIds } from '../../../../utils/lookup-dictionaries/lookupItemList';
import { IDialogProps } from './MainItemDialog';

export function TokenInputDialog(props: IDialogProps) {
	const [tokenInput, setTokenInput] = useState('');
	const [anonymous, setAnonymous] = useState(false);

	function handleTokenInput() {
		socket.emit('consumable:token', {
			inventoryItemID: props.item.id,
			optionalInput: tokenInput,
			optionalTargetItemDB: props.item.id,
			anonymous,
		});
		props.onClose();
	}

	function getHeader() {
		switch (props.item.itemID) {
			case itemsIds.username_change_token:
				return 'Username Change';
			case itemsIds.item_name_token:
				return 'Item Name Change';
			case itemsIds.idlescape_bond:
				return 'Idlescape Plus Gift';
		}
	}

	function getContent() {
		switch (props.item.itemID) {
			case itemsIds.username_change_token:
				return (
					<>
						<Text>
							Make sure to double check your intended name! You will need another token in order to change
							it again.
						</Text>
						<Text>
							Your username will NOT be held for you! You give it up the moment your name is changed.
						</Text>
						<Text>
							If you input a name that you already own on another character, the names will be swapped.
						</Text>
						<IdlescapeInput
							value={tokenInput}
							contentEditable={true}
							variant='simple'
							setValue={setTokenInput}
							className='chat-input-field'
							type='text'
							placeholder='NEW USERNAME HERE'
							autoComplete='off'
							width='250px'
							maxLength={24}
						/>
					</>
				);
			case itemsIds.item_name_token:
				return (
					<>
						<Text>
							Enter your desired item name here. Allowed are up to 24 characters including letters,
							numbers and spaces
						</Text>
						<Text>
							Make sure to double check your intended name! You will need another token in order to change
							it again.
						</Text>
						<IdlescapeInput
							value={tokenInput}
							contentEditable={true}
							variant='simple'
							setValue={setTokenInput}
							className='chat-input-field'
							type='text'
							placeholder='NEW ITEMNAME HERE'
							autoComplete='off'
							width='250px'
							maxLength={24}
						/>
					</>
				);
			case itemsIds.idlescape_bond:
				return (
					<>
						<Text>
							Enter a character name of the user you want to gift one month of Idlescape Plus to. They
							will have it active on all their characters.
						</Text>
						<Text>
							When you enter one of your own character names, you will get the month yourself, but this
							won&apos;t count towards gifted subs.
						</Text>
						<IdlescapeInput
							value={tokenInput}
							contentEditable={true}
							variant='simple'
							setValue={setTokenInput}
							className='chat-input-field'
							type='text'
							placeholder='USERNAME HERE'
							autoComplete='off'
							width='250px'
							maxLength={24}
						/>
						<FormControl display='flex' alignItems='center' textAlign='center' marginTop='5px'>
							<FormLabel htmlFor='anonymous' mb='0'>
								Anonymous
							</FormLabel>
							<Switch
								id='anonymous'
								size='lg'
								onChange={() => setAnonymous(!anonymous)}
								isChecked={anonymous}
							/>
						</FormControl>
					</>
				);
		}
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>{getHeader()}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>{getContent()}</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleTokenInput}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export function RespecTokenDialog(props: IDialogProps) {
	// No input or anything, just an ARE YOU SURE? with the button to confirm
	function handleToken() {
		socket.emit('talent:clearTalents');
		props.onClose();
	}

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Respec Token</ModalHeader>
				<ModalBody>
					<Text>
						Are you sure you want to respec your talents? This will reset all of your talents and refund
						your talent points.
					</Text>
					<Text>Untradeable Respec Tokens will be consumed first, followed by tradeable Respec Tokens.</Text>
				</ModalBody>
				<ModalFooter className='item-dialogue-button-div'>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleToken}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
