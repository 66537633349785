import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import { IdlescapeButton } from '@idlescape/ui';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { socket } from '../../../../services/socket.service';
import { salvageResources } from '../../../../utils/augmentingFunctions';
import { enchantmentsList } from '../../../../utils/enchantmentList';
import { itemList } from '../../../../utils/itemList';
import FakeItem from '../FakeItem';
import { IDialogProps } from './MainItemDialog';

function DisenchantingDialog(props: IDialogProps) {
	function handleDisenchant() {
		socket.emit('enchanting:disenchant', {
			id: props.item.id,
			amount: props.sliderValue,
		});
		props.onClose();
	}

	const disenchantedItem = structuredClone(props.item);
	const itemData = itemList[props.item.itemID];
	delete disenchantedItem.enchantmentID;
	delete disenchantedItem.enchantmentStrength;
	disenchantedItem.stackSize = props.sliderValue;

	const enchantment = props.item.enchantmentID ? enchantmentsList[props.item.enchantmentID] : undefined;
	const scrollData = enchantment?.scrollID ? itemList[enchantment.scrollID] : undefined;
	const { resources, experience } = salvageResources(props.item, itemData, props.sliderValue, scrollData);

	return (
		<Modal isOpen={true} onClose={props.onClose}>
			<ModalOverlay />
			<ModalContent className='sell-item-dialog'>
				<ModalHeader>Disenchanting {props.name}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					Are you sure you want to disenchant {props.sliderValue} {props.name}
					{props.sliderValue > 1 && 's'}? This cannot be undone!
					<p>
						Enchanted items partially salvage the materials used to create the scroll that this enchant is
						related to as well as give a portion of the experience that would be given when enchanting.
					</p>
					<p>Enchanting Experience: {experience}</p>
					<Box className='all-items' justifyItems='center' alignItems='center'>
						<FakeItem item={{ ...props.item, stackSize: props.sliderValue }} />
						<FaArrowRight size='20px' />
						<FakeItem item={disenchantedItem} />
						{resources.map((item) => {
							return <FakeItem key={item.itemID} item={item} />;
						})}
					</Box>
				</ModalBody>
				<ModalFooter>
					<IdlescapeButton variant='red' onClick={props.onClose}>
						Cancel
					</IdlescapeButton>
					<IdlescapeButton variant='green' onClick={handleDisenchant}>
						Confirm
					</IdlescapeButton>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}

export default DisenchantingDialog;
